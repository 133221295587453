import React, { useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Copy, Search as SearchIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import { darken } from "polished";
import { Link } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import "../../../../Styles/Custom.css";
import Switch from "@mui/material/Switch";
import MenuList from "@mui/material/MenuList";
import Form from "../../../pages/Calendar/RoutingForms/Form";
import Fields from "../../../pages/Calendar/RoutingForms/Fields";
import Routing from "../../../pages/Calendar/RoutingForms/Routing";

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Checkbox,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  Menu,
  MenuItem,
  InputBase,
  DialogActions,
  TextField,
  CardContent,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card as MuiCard,
  CardMedia as MuiCardMedia,
  CardActions,
  AvatarGroup as MuiAvatarGroup,
  FormControlLabel,
} from "@mui/material";
import { green, orange } from "@mui/material/colors";
import {
  Add as AddIcon,
  ArrowBack as ArrowBackIcon,
  FilterList,
  Download,
  Mail,
  ContentCopy,
  Visibility,
  Code as CodeIcon,
  Share,
} from "@mui/icons-material";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import LinkIcon from "@mui/icons-material/Link";
import CommentIcon from "@mui/icons-material/Comment";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function RegistrationTab() {
  const [RTab, setRTab] = React.useState("Form");

  return (
    <Grid container>
      <Grid md={3}>
        <Paper>
          <MenuList style={{ height: "150px" }}>
            {RTab == "Form" ? (
              <MenuItem
                onClick={(e) => {
                  setRTab("Form");
                }}
                style={{
                  borderLeft: "3px solid",
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                  minHeight: "50px",
                }}
              >
                <ListItem
                  disableGutters
                  secondaryAction={
                    <IconButton aria-label="comment">
                      <KeyboardArrowRightIcon />
                    </IconButton>
                  }
                >
                  <ListItemText primary="Form" />
                </ListItem>
              </MenuItem>
            ) : (
              <MenuItem
                onClick={(e) => {
                  setRTab("Form");
                }}
                style={{ minHeight: "50px" }}
              >
                <ListItem disableGutters>
                  <ListItemText primary="Form" />
                </ListItem>
              </MenuItem>
            )}
            {RTab == "Fields" ? (
              <MenuItem
                onClick={(e) => {
                  setRTab("Fields");
                }}
                style={{
                  borderLeft: "3px solid",
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                  minHeight: "50px",
                }}
              >
                <ListItem
                  disableGutters
                  secondaryAction={
                    <IconButton aria-label="comment">
                      <KeyboardArrowRightIcon />
                    </IconButton>
                  }
                >
                  <ListItemText primary="Fields" />
                </ListItem>
              </MenuItem>
            ) : (
              <MenuItem
                onClick={(e) => {
                  setRTab("Fields");
                }}
                style={{ minHeight: "50px" }}
              >
                <ListItem disableGutters>
                  <ListItemText primary="Fields" />
                </ListItem>
              </MenuItem>
            )}
            {RTab == "Routing" ? (
              <MenuItem
                onClick={(e) => {
                  setRTab("Routing");
                }}
                style={{
                  borderLeft: "3px solid",
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                  minHeight: "50px",
                }}
              >
                <ListItem
                  disableGutters
                  secondaryAction={
                    <IconButton aria-label="comment">
                      <KeyboardArrowRightIcon />
                    </IconButton>
                  }
                >
                  <ListItemText primary="Routing" />
                </ListItem>
              </MenuItem>
            ) : (
              <MenuItem
                onClick={(e) => {
                  setRTab("Routing");
                }}
                style={{ minHeight: "50px" }}
              >
                <ListItem disableGutters>
                  <ListItemText primary="Routing" />
                </ListItem>
              </MenuItem>
            )}
          </MenuList>
        </Paper>
      </Grid>
      <Grid md={9} sx={{ paddingLeft: "25px" }}>
        {RTab == "Form" ? (
          <Form />
        ) : RTab == "Fields" ? (
          <Fields />
        ) : (
          <Routing />
        )}
      </Grid>
    </Grid>
  );
}

function RoutingFormEdit() {
  return (
    <React.Fragment>
      <Helmet title="Edit Routing Form" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Edit Routing Form
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/calendar/dashboard">
              Dashboard
            </Link>
            <Link component={NavLink} to="/calendar/routing-forms">
              Routing Form List
            </Link>
            <Typography>Edit Routing Form</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            <Button
              variant="contained"
              color="inherit"
              sx={{ mx: "0.5rem" }}
              component={Link}
              to="/calendar/routing-forms"
            >
              <ArrowBackIcon />
              Back
            </Button>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <RegistrationTab />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default RoutingFormEdit;
