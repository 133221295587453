import React, { useState } from "react";
import styled from "@emotion/styled";
import FormControl from "@mui/material/FormControl";
import "../../../../Styles/Custom.css";
import Switch from "@mui/material/Switch";
import CardMedia from "@mui/material/CardMedia";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import ReactQuill from "react-quill";

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  Paper as MuiPaper,
  TableCell,
  TableRow,
  Typography,
  Card as MuiCard,
  CardMedia as MuiCardMedia,
  AvatarGroup as MuiAvatarGroup,
  FormControlLabel,
  Alert as MuiAlert,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  InputLabel,
  Select,
} from "@mui/material";

import { spacing } from "@mui/system";

const Paper = styled(MuiPaper)(spacing);

const Alert = styled(MuiAlert)(spacing);

const QuillWrapper = styled.div`
  .ql-editor {
    min-height: 200px;
  }
`;

function RequestOptions({ handleChildData }) {
  const [ButtonStyle, setButtonStyle] = React.useState("Button");
  const [Mode, setMode] = React.useState("Standard");
  const sendDataToParent = (d) => {
    handleChildData(d);
  };

  const [IsLeadForm, setIsLeadForm] = useState("false");
  const handleIsLeadFormButtonChange = (event) => {
    setIsLeadForm(JSON.stringify(event.target.checked));
  };

  const [IsPrivacyInformation, setIsPrivacyInformation] = useState("false");
  const handleIsPrivacyInformationButtonChange = (event) => {
    setIsPrivacyInformation(JSON.stringify(event.target.checked));
  };

  const [IsPreselection, setIsPreselection] = useState("false");
  const handleIsPreselectionChange = (event) => {
    setIsPreselection(JSON.stringify(event.target.checked));
  };

  const [chatStyle, setChatStyle] = useState("contained");
  const [phoneStyle, setPhoneStyle] = useState("contained");
  const [videoStyle, setVideoStyle] = useState("contained");
  const [audioStyle, setAudioStyle] = useState("contained");

  const handleClick = () => {
    console.info("You clicked the Chip.");
  };

  const handleDelete = () => {
    console.info("You clicked the delete icon.");
  };
  const [open, setOpen] = useState(false);
  return (
    <Grid justifyContent="space-between" container>
      <Grid item md={12}>
        <Typography variant="h3" gutterBottom>
          Customize how visitors can contact you
        </Typography>
        <Grid container spacing={6}>
          <Grid item md={5} style={{ marginBottom: "15px" }}>
            <Typography variant="h6" gutterBottom>
              1. Preselection screen (optional)
            </Typography>
            <Alert mt={3} mb={3} severity="info">
              e.g: General / Product information / Support <br /> Inquiries are
              forwarded to assigned team members
            </Alert>
            <Box mb={4} mt={4}>
              <Grid item md={12} mb={4}>
                <Grid container>
                  <Grid item md={2}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch onChange={handleIsPreselectionChange} />
                        }
                        label="Yes"
                      />
                    </FormGroup>
                  </Grid>
                  {IsPreselection == "true" ? (
                    <React.Fragment>
                      <Grid item md={10}>
                        <Button
                          variant="contained"
                          style={{ marginRight: "10px", marginBottom: "10px" }}
                          onClick={() => setOpen(true)}
                        >
                          Add New Routing
                        </Button>
                      </Grid>
                      <Paper mt={4}>
                        <div>
                          <Dialog
                            open={open}
                            onClose={() => setOpen(false)}
                            aria-labelledby="form-dialog-title"
                          >
                            <DialogTitle id="form-dialog-title">
                              Add New Routing
                            </DialogTitle>
                            <DialogContent>
                              <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="Display Name"
                                type="text"
                                fullWidth
                                style={{ marginBottom: "10px" }}
                              />
                              <FormControl style={{ width: "100%" }}>
                                <InputLabel htmlFor="max-width">
                                  Select User Group
                                </InputLabel>
                                <Select
                                  autoFocus
                                  // value={maxWidth}
                                  label="maxWidth"
                                  inputProps={{
                                    name: "max-width",
                                    id: "max-width",
                                  }}
                                >
                                  {/* <MenuItem value={false}>false</MenuItem>
                                  <MenuItem value="xs">xs</MenuItem>
                                  <MenuItem value="sm">sm</MenuItem>
                                  <MenuItem value="md">md</MenuItem>
                                  <MenuItem value="lg">lg</MenuItem>
                                  <MenuItem value="xl">xl</MenuItem> */}
                                </Select>
                              </FormControl>
                            </DialogContent>
                            <DialogActions>
                              <Button
                                onClick={() => setOpen(false)}
                                color="primary"
                              >
                                Close
                              </Button>
                              <Button
                                onClick={() => setOpen(false)}
                                color="primary"
                              >
                                Add
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </div>
                      </Paper>
                    </React.Fragment>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                </Grid>
                {IsPreselection == "true" ? (
                  <React.Fragment>
                    <Typography variant="h6" gutterBottom>
                      Routing Screen Buttons
                    </Typography>
                    <Stack direction="row" spacing={1}>
                      <Chip
                        label="Clickable Deletable"
                        onClick={handleClick}
                        onDelete={handleDelete}
                      />
                      <Chip
                        label="Clickable Deletable"
                        variant="outlined"
                        onClick={handleClick}
                        onDelete={handleDelete}
                      />
                    </Stack>
                  </React.Fragment>
                ) : (
                  <React.Fragment></React.Fragment>
                )}
              </Grid>
            </Box>
            <Typography variant="h6" gutterBottom>
              2. Automatic chat response after request (optional)
            </Typography>
            <Box mb={4} mt={4}>
              <Grid item md={12} mb={4}>
                <FormGroup>
                  <FormControlLabel
                    control={<Switch defaultChecked />}
                    label="Yes"
                  />
                </FormGroup>
              </Grid>
              <Grid item md={12} mb={4}>
                <FormControl fullWidth my={2} variant="outlined">
                  <TextField
                    label="Automatic chat response text"
                    id="biography"
                    multiline={true}
                    rows={3}
                    maxRows={4}
                    variant="outlined"
                    defaultValue="We are taking care of your request - pls stay tuned"
                  />
                </FormControl>
              </Grid>
            </Box>
            <Typography variant="h6" gutterBottom>
              3. Contact options
            </Typography>
            <Box mb={4} mt={4}>
              <Grid md={12}>
                {chatStyle == "contained" ? (
                  <Button
                    variant="contained"
                    style={{ marginRight: "10px", marginBottom: "10px" }}
                    onClick={(e) => {
                      setChatStyle("outlined");
                    }}
                  >
                    Chat
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    style={{ marginRight: "10px", marginBottom: "10px" }}
                    onClick={(e) => {
                      setChatStyle("contained");
                    }}
                  >
                    Chat
                  </Button>
                )}
                {phoneStyle == "contained" ? (
                  <Button
                    variant="contained"
                    style={{ marginRight: "10px", marginBottom: "10px" }}
                    onClick={(e) => {
                      setPhoneStyle("outlined");
                    }}
                  >
                    Phone
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    style={{ marginRight: "10px", marginBottom: "10px" }}
                    onClick={(e) => {
                      setPhoneStyle("contained");
                    }}
                  >
                    Phone
                  </Button>
                )}
                {videoStyle == "contained" ? (
                  <Button
                    variant="contained"
                    style={{ marginRight: "10px", marginBottom: "10px" }}
                    onClick={(e) => {
                      setVideoStyle("outlined");
                    }}
                  >
                    Video
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    style={{ marginRight: "10px", marginBottom: "10px" }}
                    onClick={(e) => {
                      setVideoStyle("contained");
                    }}
                  >
                    Video
                  </Button>
                )}
                {audioStyle == "contained" ? (
                  <Button
                    variant="contained"
                    style={{ marginRight: "10px", marginBottom: "10px" }}
                    onClick={(e) => {
                      setAudioStyle("outlined");
                    }}
                  >
                    Audio
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    style={{ marginRight: "10px", marginBottom: "10px" }}
                    onClick={(e) => {
                      setAudioStyle("contained");
                    }}
                  >
                    Audio
                  </Button>
                )}
              </Grid>
              <Grid item md={12}>
                <FormControl component="fieldset" variant="standard">
                  <FormLabel component="legend">
                    Do you want to activate a lead form before starting a
                    conversation?
                  </FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          name="gilad"
                          onChange={handleIsLeadFormButtonChange}
                        />
                      }
                      label="Yes"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              {IsLeadForm == "true" ? (
                <React.Fragment>
                  <Grid item md={12}>
                    <FormControl component="fieldset" variant="standard">
                      <FormLabel component="legend">
                        Enable Privacy Information
                      </FormLabel>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              name="gilad"
                              onChange={handleIsPrivacyInformationButtonChange}
                            />
                          }
                          label="Yes"
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                  {IsPrivacyInformation == "true" ? (
                    <Grid item md={12} mb={4}>
                      <QuillWrapper className="editor">
                        <ReactQuill
                          theme="snow"
                          value="I have read and accept the privacy policy and terms of service."
                        />
                      </QuillWrapper>
                      {/* <FormControl fullWidth my={2} variant="outlined">
                        <TextField
                          label=""
                          id="biography"
                          multiline={true}
                          rows={3}
                          maxRows={4}
                          variant="outlined"
                          defaultValue="I have read and accept the privacy policy and terms of service."
                        />
                      </FormControl> */}
                    </Grid>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                </React.Fragment>
              ) : (
                <React.Fragment></React.Fragment>
              )}
            </Box>
          </Grid>
          <Grid item md={6} style={{ marginBottom: "15px" }}>
            {/* <Typography variant="h6" gutterBottom>
              Preview
            </Typography> */}
            <CardMedia
              component="img"
              image="/static/img/others/RequestOptions.png"
              alt="Paella dish"
              style={{
                objectFit: "contain",
                backgroundPosition: "center",
                width: "100%",
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default RequestOptions;
