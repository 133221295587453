import React, { useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Plus, Search as SearchIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import { darken } from "polished";
import { Link } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import "../../../../Styles/Custom.css";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import ListItem from "@mui/material/ListItem";
import FormGroup from "@mui/material/FormGroup";
import List from "@mui/material/List";
import { Copy } from "react-feather";
import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Checkbox,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  Menu,
  MenuItem,
  InputBase,
  DialogActions,
  TextField,
  CardContent,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card as MuiCard,
  CardMedia as MuiCardMedia,
  CardHeader,
  CardActions,
  AvatarGroup as MuiAvatarGroup,
  FormControlLabel,
} from "@mui/material";
import { green, orange } from "@mui/material/colors";
import {
  Add as AddIcon,
  ArrowBack as ArrowBackIcon,
  Close,
  LocationOn,
  NearMe,
  Schedule,
  Videocam,
  FilterAlt,
  Remove,
} from "@mui/icons-material";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Chip = styled(MuiChip)`
  ${spacing};

  background: ${(props) => props.paid && green[500]};
  background: ${(props) => props.sent && orange[700]};
  color: ${(props) =>
    (props.paid || props.sent) && props.theme.palette.common.white};
`;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

const ToolbarTitle = styled.div`
  min-width: 220px;

  ${(props) => props.theme.breakpoints.up("md")} {
    min-width: 900px;
  }
`;

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;

const checkboxStyle = `
  position:absolute;
  bottom:0;
`;

const Customer = styled.div`
  display: flex;
  align-items: center;
`;

const CardMedia = styled(MuiCardMedia)`
  height: 220px;
`;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

function createData(customer, customerEmail, date) {
  return { customer, customerEmail, date };
}

const rows = [
  createData("Event A", "Description", "A", 0, "$530,00", "7", "2023-01-02"),
  createData("Event B", "Description", "D", 2, "$209,00", "10", "2023-02-13"),
  createData("Event C", "Description", "E", 2, "$535,00", "9", "2023-03-04"),
  createData("Event D", "Description", "E", 2, "$678,00", "8", "2023-02-17"),
  createData("Event E", "Description", "G", 0, "$314,00", "7", "2023-03-28"),
  createData("Event F", "Description", "J", 0, "$864,00", "9", "2023-01-23"),
  createData("Event G", "Description", "L", 2, "$341,00", "10", "2023-03-14"),
  createData("Event H", "Description", "M", 2, "$781,00", "12", "2023-02-22"),
  createData("Event I", "Description", "M", 0, "$273,00", "15", "2023-05-31"),
  createData("Event J", "Description", "S", 0, "$713,00", "10", "2023-04-25"),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  { id: "customer", alignment: "left", label: "First Name" },
  { id: "lastname", alignment: "left", label: "Last Name" },
  { id: "email", alignment: "left", label: "Email Id" },
  { id: "phone", alignment: "left", label: "Phone No" },
  { id: "status", alignment: "left", label: "Country" },
];

const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all" }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
function TableRows({ rows, tableRowRemove, onValUpdate }) {
  return rows.map((rowsData, index) => {
    var opt = "Email";
    const { name, email, profile } = rowsData;
    return (
      <tr key={index}>
        <td>
          <Grid container item>
            <Grid
              item
              md={2}
              style={{ marginTop: "10px", marginRight: "10px" }}
            >
              <FormControl sx={{ width: "100%" }}>
                {/* <InputLabel htmlFor="max-width">Before Event</InputLabel> */}
                <Select
                  autoFocus
                  value="false"
                  // label="maxWidth"
                  inputProps={{
                    name: "max-width",
                    id: "max-width",
                  }}
                >
                  <MenuItem value={false}>First Name</MenuItem>
                  <MenuItem value="xs">Last Name</MenuItem>
                  <MenuItem value="sm">Email Id</MenuItem>
                  <MenuItem value="md">Phone No</MenuItem>
                  <MenuItem value="lg">Country</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              md={2}
              style={{ marginTop: "10px", marginRight: "10px" }}
            >
              <FormControl sx={{ width: "100%" }}>
                {/* <InputLabel htmlFor="max-width">Before Event</InputLabel> */}
                <Select
                  autoFocus
                  value="false"
                  // label="maxWidth"
                  inputProps={{
                    name: "max-width",
                    id: "max-width",
                  }}
                >
                  <MenuItem value={false}>Equals</MenuItem>
                  <MenuItem value="xs">Does Not Equal</MenuItem>
                  <MenuItem value="sm">Contains</MenuItem>
                  <MenuItem value="md">Not Contains</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={2}>
              <FormControl sx={{ width: "100%" }}>
                {/* <InputLabel htmlFor="max-width">Before Event</InputLabel> */}
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  // label="First Name"
                  placeholder="Value"
                  type="text"
                  fullWidth
                />
              </FormControl>
            </Grid>
            <Grid
              item
              md={1}
              style={{
                marginTop: "10px",
                textAlign: "center",
              }}
            >
              <DeleteIcon
                onClick={() => tableRowRemove(index)}
                style={{ position: "relative", top: "15px" }}
              />
            </Grid>
          </Grid>
        </td>
      </tr>
    );
  });
}
var i = false,
  noOfRows = 0;
const EnhancedTableToolbar = (props) => {
  const [viewStyle, setViewStyle] = React.useState("List");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const { numSelected } = props;
  const [open, setOpen] = useState(false);
  const [personName, setPersonName] = React.useState([]);
  var [showFilter, setShowFilter] = React.useState(false);
  const [showAddGuest, setShowAddGuest] = useState("false");

  const [rows, initRow] = useState([]);
  const addRowTable = () => {
    if (showAddGuest == "true") {
      const data = {
        name: "",
      };
      initRow([...rows, data]);
    }
    setShowAddGuest("true");
    noOfRows++;
  };
  const tableRowRemove = (index) => {
    const dataRow = [...rows];
    dataRow.splice(index, 1);
    initRow(dataRow);
    noOfRows--;
  };
  const onValUpdate = (i, event) => {
    const { name, value } = event.target;
    const data = [...rows];
    data[i][name] = value;
    initRow(data);
  };
  if (i == false) {
    addRowTable();
    i = true;
  }
  return (
    <>
      <Toolbar style={{ paddingLeft: "0" }}>
        <ToolbarTitle>
          <Grid container>
            <Grid md={12}>
              <Button
                variant="contained"
                onClick={(e) => {
                  addRowTable();
                  setShowAddGuest("true");
                }}
                style={{ marginTop: "10px" }}
              >
                <Plus />
                Add Filter
              </Button>
            </Grid>
          </Grid>
          {noOfRows > 2 ? (
            <Grid container>
              <Grid
                item
                md={2}
                style={{ marginTop: "10px", marginRight: "10px" }}
              >
                <Typography
                  variant="h7"
                  style={{ position: "relative", top: "15px" }}
                >
                  Query where
                </Typography>
              </Grid>
              <Grid
                item
                md={2}
                style={{ marginTop: "10px", marginRight: "10px" }}
              >
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    autoFocus
                    value="false"
                    inputProps={{
                      name: "max-width",
                      id: "max-width",
                    }}
                  >
                    <MenuItem value={false}>All</MenuItem>
                    <MenuItem value="xs">Any</MenuItem>
                    <MenuItem value="sm">None</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={2} style={{ marginTop: "10px" }}>
                <Typography
                  variant="h7"
                  style={{ position: "relative", top: "15px" }}
                >
                  match
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
          {/* <Grid container>
            <Grid
              item
              md={2}
              style={{ marginTop: "10px" }}
            >
              <FormControl sx={{ width: "100%" }}>
                <Select
                  autoFocus
                  value="false"
                  inputProps={{
                    name: "max-width",
                    id: "max-width",
                  }}
                >
                  <MenuItem value={false}>12:00 am</MenuItem>
                  <MenuItem value="xs">12:15 am</MenuItem>
                  <MenuItem value="sm">12:30 am</MenuItem>
                  <MenuItem value="md">12:45 am</MenuItem>
                  <MenuItem value="lg">1:00 am</MenuItem>
                  <MenuItem value="xl">1:15 am</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              md={1}
              style={{
                marginTop: "10px",
                textAlign: "center",
              }}
            >
              <Remove style={{ position: "relative", top: "15px" }} />
            </Grid>
            <Grid
              item
              md={2}
              style={{ marginTop: "10px" }}
            >
              <FormControl sx={{ width: "100%" }}>
                <Select
                  autoFocus
                  value="false"
                  inputProps={{
                    name: "max-width",
                    id: "max-width",
                  }}
                >
                  <MenuItem value={false}>12:00 am</MenuItem>
                  <MenuItem value="xs">12:15 am</MenuItem>
                  <MenuItem value="sm">12:30 am</MenuItem>
                  <MenuItem value="md">12:45 am</MenuItem>
                  <MenuItem value="lg">1:00 am</MenuItem>
                  <MenuItem value="xl">1:15 am</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              md={1}
              style={{
                marginTop: "10px",
                textAlign: "center",
              }}
            >
              <AddIcon
                style={{ position: "relative", top: "15px" }}
                onClick={(e) => {
                  addRowTable();
                  setShowAddGuest("true");
                }}
              />
            </Grid>
          </Grid> */}
          {showAddGuest == "true" ? (
            <table style={{ width: "100%" }}>
              <tbody>
                <TableRows
                  rows={rows}
                  tableRowRemove={tableRowRemove}
                  onValUpdate={onValUpdate}
                />
              </tbody>
            </table>
          ) : (
            <></>
          )}
        </ToolbarTitle>
      </Toolbar>
    </>
  );
};

function EnhancedTable() {
  const [viewStyle, setViewStyle] = useState("List");
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("customer");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      // setViewStyle("Card");
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div>
      <Paper>
        <EnhancedTableToolbar
          numSelected={selected.length}
          doChangeProps={setViewStyle}
        />
      </Paper>
      {/* <ViewMenu doChangeProps={setViewStyle} /> */}
      <Paper>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={`${row.id}-${index}`}
                      selected={isItemSelected}
                    >
                      {/* <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                          onClick={(event) => handleClick(event, row.id)}
                        />
                      </TableCell> */}
                      <TableCell component="th" id={labelId} scope="row">
                        James
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row">
                        Basker
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row">
                        james@gmail.com
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row">
                        9000000000
                      </TableCell>
                      <TableCell>Canada</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={7} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}

function Reporting() {
  return (
    <React.Fragment>
      <Wrapper style={{ width: "60%" }}>
        <Helmet title="Bookings" />
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Typography id="form-dialog-title" variant="h4">
              Form1 Report
              <br />
              <DialogContentText>This is a test description</DialogContentText>
            </Typography>
            <EnhancedTable />
          </Grid>
        </Grid>
      </Wrapper>
    </React.Fragment>
  );
}

export default Reporting;
