import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { rgba, size } from "polished";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";

import {
  Avatar,
  Box,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Chip as MuiChip,
  Typography as MuiTypography,
  CardActions,
  Button,
  Grid,
  DialogActions,
  Drawer,
} from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import { AvatarGroup as MuiAvatarGroup } from "@mui/material";
import { spacing } from "@mui/system";

const AvatarGroup = styled(MuiAvatarGroup)`
  margin-left: ${(props) => props.theme.spacing(2)};
`;

const illustrationCardStyle = (props) => css`
  ${props.illustration &&
  props.theme.palette.mode !== "dark" &&
  `
    background: ${rgba(props.theme.palette.primary.main, 0.125)};
    color: ${props.theme.palette.primary.main};
  `}
`;

const Card = styled(MuiCard)`
  position: relative;
  margin-bottom: ${(props) => props.theme.spacing(6)};

  ${illustrationCardStyle}
`;

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)};
  }
`;

const Chip = styled(MuiChip)`
  position: absolute;
  top: 16px;
  right: 16px;
  height: 20px;
  padding: 4px 0;
  font-size: 85%;
  background-color: ${(props) => props.theme.palette.secondary.main};
  color: ${(props) => props.theme.palette.common.white};
  margin-bottom: ${(props) => props.theme.spacing(4)};

  span {
    padding-left: ${(props) => props.theme.spacing(2)};
    padding-right: ${(props) => props.theme.spacing(2)};
  }
`;

const illustrationPercentageStyle = (props) => css`
  ${props.illustration &&
  props.theme.palette.mode !== "dark" &&
  `
    color: ${rgba(props.theme.palette.primary.main, 0.85)};
  `}
`;

const Percentage = styled(MuiTypography)`
  span {
    color: ${(props) => props.percentagecolor};
    font-weight: ${(props) => props.theme.typography.fontWeightBold};
    background: ${(props) => rgba(props.percentagecolor, 0.1)};
    padding: 2px;
    border-radius: 3px;
    margin-right: ${(props) => props.theme.spacing(2)};
  }

  ${illustrationPercentageStyle}
`;

const IllustrationImage = styled.img`
  height: 100px;
  position: absolute;
  right: ${(props) => props.theme.spacing(1)};
  bottom: ${(props) => props.theme.spacing(1)};
  display: none;

  ${(props) => props.theme.breakpoints.between("xs", "lg")} {
    display: block;
  }

  @media (min-width: 1600px) {
    display: block;
  }
`;

const SpaceAppCards = ({
  title,
  amount,
  chip,
  percentagetext,
  percentagecolor,
  to,
  illustration,
}) => {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const toggleDrawer = (anchor, open, oo) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      style={{ width: "350px" }}
    >
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item style={{ margin: "15px", paddingBottom: "10px" }}>
          <Typography variant="h3" gutterBottom display="inline">
            Demo Contents
          </Typography>
          <Typography>
            Participants who have registered for the event can now enter before
            the event begins. You can enable your attendees to build their
            agenda and explore networking opportunities in advance.
          </Typography>
        </Grid>
      </Grid>
      <DialogActions>
        <Button onClick={toggleDrawer("right", false)} color="primary">
          Close
        </Button>
      </DialogActions>
    </Box>
  );
  return (
    <Card illustration={illustration}>
      <Link
        component={NavLink}
        color="primary"
        to={to}
        style={{ textDecoration: "none" }}
      >
        <CardContent>
          {/* <Typography variant="h6" mb={4}>
            {title}
          </Typography> */}
          <Typography variant="h3" mb={3}>
            <Box fontWeight="fontWeightRegular">{amount}</Box>
          </Typography>
          <Typography mb={4} color="textSecondary" component="p">
            Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem
            quam semper libero
          </Typography>
          {/* <Percentage
            variant="subtitle2"
            color="textSecondary"
            percentagecolor={percentagecolor}
            illustration={illustration}
          >
            <span>{percentagetext}</span> Since last month
          </Percentage> */}
          {!illustration && <Chip label={chip} />}
          <AvatarGroup max={3}>
            <Avatar alt="Avatar" src="/static/img/avatars/avatar-1.jpg" />
            <Avatar alt="Avatar" src="/static/img/avatars/avatar-2.jpg" />
            <Avatar alt="Avatar" src="/static/img/avatars/avatar-3.jpg" />
          </AvatarGroup>
        </CardContent>
      </Link>
      {!!illustration && (
        <IllustrationImage src={illustration} alt="Illustration" />
      )}
      <CardActions style={{ float: "right" }}>
        <Button
          size="small"
          color="primary"
          onClick={toggleDrawer("right", true)}
        >
          Learn More
          <ArrowForward style={{ fontSize: "1rem" }} />
        </Button>
        <Drawer
          anchor={"right"}
          open={state["right"]}
          onClose={toggleDrawer("right", false)}
        >
          {list("right")}
        </Drawer>
      </CardActions>
    </Card>
  );
};

export default SpaceAppCards;
