import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import ListItem from "@mui/material/ListItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import "../../../../Styles/Custom.css";
import Switch from "@mui/material/Switch";
import InputAdornment from "@mui/material/InputAdornment";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FormGroup from "@mui/material/FormGroup";
import { MessageCircle } from "react-feather";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { faker } from "@faker-js/faker";
import Drawer from "@mui/material/Drawer";
import ListItemText from "@mui/material/ListItemText";
import FormLabel from "@mui/material/FormLabel";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import {
  Avatar,
  AvatarGroup as MuiAvatarGroup,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Checkbox,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  Menu,
  MenuItem,
  InputBase,
  DialogActions,
  TextField,
  CardContent,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card as MuiCard,
  CardMedia as MuiCardMedia,
  CardActions,
  FormControlLabel,
} from "@mui/material";
import { green, orange, blue } from "@mui/material/colors";
import {
  Add as AddIcon,
  ArrowBack as ArrowBackIcon,
  FilterList,
  Download,
  Mail,
  ContentCopy,
  Visibility,
} from "@mui/icons-material";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import LanguageIcon from "@mui/icons-material/Language";
import { tableCellClasses } from "@mui/material/TableCell";
import { spacing } from "@mui/system";

const AvatarGroup = styled(MuiAvatarGroup)`
  display: inline-flex;
`;

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Chip = styled(MuiChip)`
  ${spacing};
  background: "#eee";
`;

const CustomTableRow = styled(TableRow)`
  // &:nth-of-type(odd) {
  //   background-color: rgba(0, 0, 0, 0.025);
  // }
`;
const QuillWrapper = styled.div`
  .ql-editor {
    min-height: 200px;
  }
`;
const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: ${(props) => props.theme.palette.common.black};
    color: ${(props) => props.theme.palette.common.white};
  }
  &.${tableCellClasses.body} {
    font-size: 14px;
  }
`;
// Data
let id = 0;
function createData(name, calories, fat, carbs, protein) {
  id += 1;
  return { id, name, calories, fat, carbs, protein };
}
const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

function CustomizedTableDemo() {
  return (
    <Card mb={6} mt={6}>
      <Paper>
        <Table>
          <TableBody>
            {rows.map((row) => (
              <CustomTableRow key={row.id}>
                <CustomTableCell component="th" scope="row">
                  UTC -12:00
                </CustomTableCell>
                <CustomTableCell align="right">
                  Canada/Yukon Time
                  <br />
                  <small style={{ color: "rgba(0, 0, 0, 0.6)" }}>
                    (UTC-07:00) Yukon
                  </small>
                </CustomTableCell>
              </CustomTableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Card>
  );
}

const TaskWrapper = styled(Card)`
  border: 1px solid ${(props) => props.theme.palette.grey[300]};
  margin-bottom: ${(props) => props.theme.spacing(4)};
  cursor: grab;

  &:hover {
    background: ${(props) => props.theme.palette.background.default};
  }
`;

const TaskWrapperContent = styled(CardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)};
  }
`;

const TaskAvatars = styled.div`
  margin-top: ${(props) => props.theme.spacing(1)};
`;

const MessageCircleIcon = styled(MessageCircle)`
  color: ${(props) => props.theme.palette.grey[500]};
  vertical-align: middle;
`;

const TaskBadge = styled.div`
  background: ${(props) => props.color};
  width: 40px;
  height: 6px;
  border-radius: 6px;
  display: inline-block;
  margin-right: ${(props) => props.theme.spacing(2)};
`;

const TaskNotifications = styled.div`
  display: flex;
  position: absolute;
  bottom: ${(props) => props.theme.spacing(4)};
  right: ${(props) => props.theme.spacing(4)};
`;

const TaskNotificationsAmount = styled.div`
  color: ${(props) => props.theme.palette.grey[500]};
  font-weight: 600;
  margin-right: ${(props) => props.theme.spacing(1)};
  line-height: 1.75;
`;

const mockItems1 = [
  {
    id: faker.datatype.uuid(),
    title: "Redesign the homepage",
    badges: [green[600], orange[600]],
    notifications: 2,
    avatars: [1, 2, 3, 4],
  },
  {
    id: faker.datatype.uuid(),
    title: "Upgrade dependencies to latest versions",
    badges: [green[600]],
    notifications: 1,
    avatars: [2],
  },
  {
    id: faker.datatype.uuid(),
    title: "Google Adwords best practices",
    badges: [],
    notifications: 0,
    avatars: [2, 3],
  },
  {
    id: faker.datatype.uuid(),
    title: "Improve site speed",
    badges: [green[600]],
    notifications: 3,
    avatars: [],
  },
  {
    id: faker.datatype.uuid(),
    title: "Stripe payment integration",
    badges: [blue[600]],
    notifications: 0,
    avatars: [],
  },
];

const mockItems2 = [
  {
    id: faker.datatype.uuid(),
    title: "Google Adwords best practices",
    badges: [],
    notifications: 0,
    avatars: [2, 3],
  },
  {
    id: faker.datatype.uuid(),
    title: "Stripe payment integration",
    badges: [blue[600]],
    notifications: 0,
    avatars: [2],
  },
];

const mockItems3 = [
  {
    id: faker.datatype.uuid(),
    title: "Improve site speed",
    badges: [green[600]],
    notifications: 3,
    avatars: [1, 2],
  },
  {
    id: faker.datatype.uuid(),
    title: "Google Adwords best practices",
    badges: [],
    notifications: 0,
    avatars: [2],
  },
  {
    id: faker.datatype.uuid(),
    title: "Redesign the homepage",
    badges: [green[600], orange[600]],
    notifications: 2,
    avatars: [],
  },
];

const mockColumns = {
  [faker.datatype.uuid()]: {
    title: "Booking questions",
    description: "Customize the questions asked on the booking page",
    items: mockItems1,
  },
  // [faker.datatype.uuid()]: {
  //   title: "In Progress",
  //   description: "Curabitur ligula sapien, tincidunt non.",
  //   items: mockItems2,
  // },
  // [faker.datatype.uuid()]: {
  //   title: "Completed",
  //   description: "Aenean posuere, tortor sed cursus feugiat.",
  //   items: mockItems3,
  // },
};

const onDragEnd = (result, columns, setColumns) => {
  if (!result.destination) return;
  const { source, destination } = result;

  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems,
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems,
      },
    });
  } else {
    const column = columns[source.droppableId];
    const copiedItems = [...column.items];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...column,
        items: copiedItems,
      },
    });
  }
};

const Lane = ({ column, children }) => {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open, oo) => (event) => {
    i = false;
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      style={{ width: "350px" }}
    >
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item style={{ margin: "15px", paddingBottom: "10px" }}>
          <Typography variant="h3" gutterBottom display="inline">
            Add Question
          </Typography>
          <Typography>
            Customize the questions asked on the booking page
          </Typography>
        </Grid>
      </Grid>
      <Grid item style={{ margin: "15px", paddingBottom: "10px" }}>
        <Grid container spacing={6}>
          <Grid item md={12}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel htmlFor="max-width">Before Event</InputLabel>
              <Select
                autoFocus
                value="xs"
                label="Input Type"
                inputProps={{
                  name: "max-width",
                  id: "max-width",
                }}
              >
                <MenuItem value="xs">Email</MenuItem>
                <MenuItem value="sm">Phone</MenuItem>
                <MenuItem value="md">Address</MenuItem>
                <MenuItem value="lg">Short Text</MenuItem>
                <MenuItem value="xl">Number</MenuItem>
                <MenuItem value="xl">Long Text</MenuItem>
                <MenuItem value="xl">Select</MenuItem>
                <MenuItem value="xl">Multi Select</MenuItem>
                <MenuItem value="xl">Multiple Emails</MenuItem>
                <MenuItem value="xl">Checkbox Group</MenuItem>
                <MenuItem value="xl">Radio Group</MenuItem>
                <MenuItem value="xl">Checkbox</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={12}>
            <TextField
              id="username"
              label="Identifier"
              defaultValue=""
              variant="outlined"
              fullWidth
              my={2}
              rows={4}
              maxRows={6}
            />
          </Grid>
          <Grid item md={12}>
            <TextField
              id="username"
              label="Label"
              defaultValue=""
              variant="outlined"
              fullWidth
              my={2}
              rows={4}
              maxRows={6}
            />
          </Grid>
          <Grid item md={12}>
            <TextField
              id="username"
              label="Placeholder"
              defaultValue=""
              variant="outlined"
              fullWidth
              my={2}
              rows={4}
              maxRows={6}
            />
          </Grid>
          <Grid item md={12}>
            <Table1 />
          </Grid>
          <Grid item md={12}>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
                Required
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="yes"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <DialogActions>
        <Button onClick={toggleDrawer("right", false)} color="primary">
          Cancel
        </Button>
        <Button onClick={toggleDrawer("right", false)} color="primary">
          Done
        </Button>
      </DialogActions>
    </Box>
  );
  return (
    <Grid item xs={12} lg={12} xl={12}>
      <Card
        mb={6}
        style={{ backgroundColor: "transparent", padding: "0px !important" }}
      >
        <CardContent pb={0} style={{ paddingLeft: "0px" }}>
          <Typography variant="h6" gutterBottom>
            {column.title}
          </Typography>
          <Typography variant="body2" mb={4}>
            {column.description}
          </Typography>
          {children}
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={toggleDrawer("right", true)}
          >
            <AddIcon />
            Add new question
          </Button>
          <Drawer
            anchor={"right"}
            open={state["right"]}
            onClose={toggleDrawer("right", false)}
          >
            {list("right")}
          </Drawer>
        </CardContent>
      </Card>
    </Grid>
  );
};

const Task = ({ item }) => {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open, oo) => (event) => {
    i = false;
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      style={{ width: "350px" }}
    >
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item style={{ margin: "15px", paddingBottom: "10px" }}>
          <Typography variant="h3" gutterBottom display="inline">
            Add Question
          </Typography>
          <Typography>
            Customize the questions asked on the booking page
          </Typography>
        </Grid>
      </Grid>
      <Grid item style={{ margin: "15px", paddingBottom: "10px" }}>
        <Grid container spacing={6}>
          <Grid item md={12}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel htmlFor="max-width">Before Event</InputLabel>
              <Select
                autoFocus
                value="xs"
                label="Input Type"
                inputProps={{
                  name: "max-width",
                  id: "max-width",
                }}
              >
                <MenuItem value="xs">Email</MenuItem>
                <MenuItem value="sm">Phone</MenuItem>
                <MenuItem value="md">Address</MenuItem>
                <MenuItem value="lg">Short Text</MenuItem>
                <MenuItem value="xl">Number</MenuItem>
                <MenuItem value="xl">Long Text</MenuItem>
                <MenuItem value="xl">Select</MenuItem>
                <MenuItem value="xl">Multi Select</MenuItem>
                <MenuItem value="xl">Multiple Emails</MenuItem>
                <MenuItem value="xl">Checkbox Group</MenuItem>
                <MenuItem value="xl">Radio Group</MenuItem>
                <MenuItem value="xl">Checkbox</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={12}>
            <TextField
              id="username"
              label="Identifier"
              defaultValue=""
              variant="outlined"
              fullWidth
              my={2}
              rows={4}
              maxRows={6}
            />
          </Grid>
          <Grid item md={12}>
            <TextField
              id="username"
              label="Label"
              defaultValue=""
              variant="outlined"
              fullWidth
              my={2}
              rows={4}
              maxRows={6}
            />
          </Grid>
          <Grid item md={12}>
            <TextField
              id="username"
              label="Placeholder"
              defaultValue=""
              variant="outlined"
              fullWidth
              my={2}
              rows={4}
              maxRows={6}
            />
          </Grid>
          <Grid item md={12}>
            <Table1 />
          </Grid>
          <Grid item md={12}>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
                Required
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="yes"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <DialogActions>
        <Button onClick={toggleDrawer("right", false)} color="primary">
          Cancel
        </Button>
        <Button onClick={toggleDrawer("right", false)} color="primary">
          Done
        </Button>
      </DialogActions>
    </Box>
  );
  return (
    <TaskWrapper mb={4}>
      <TaskWrapperContent>
        {/* {item.badges &&
          item.badges.map((color, i) => <TaskBadge color={color} key={i} />)} */}

        <TaskTitle variant="body1" gutterBottom>
          {item.title}
          <Chip label="Required" style={{ marginLeft: "5px" }} />
        </TaskTitle>
        <h4 style={{ margin: "0", color: "#908e8e" }}>Long Text</h4>
        {/* <TaskAvatars>
          <AvatarGroup max={3}>
            {!!item.avatars &&
              item.avatars.map((avatar, i) => (
                <Avatar
                  src={`/static/img/avatars/avatar-${avatar}.jpg`}
                  key={i}
                />
              ))}
          </AvatarGroup>
        </TaskAvatars> */}

        {!!item.notifications && item.notifications > 0 && (
          <TaskNotifications style={{ top: "16px" }}>
            <TaskNotificationsAmount
              style={{ position: "relative", bottom: "8px" }}
            >
              {/* {item.notifications} */}
              <Switch inputProps={{ "aria-label": "controlled" }} />
            </TaskNotificationsAmount>
            <EditIcon onClick={toggleDrawer("right", true)} />
            <Drawer
              anchor={"right"}
              open={state["right"]}
              onClose={toggleDrawer("right", false)}
            >
              {list("right")}
            </Drawer>
          </TaskNotifications>
        )}
      </TaskWrapperContent>
    </TaskWrapper>
  );
};

const TaskTitle = styled(Typography)`
  font-weight: 600;
  font-size: 15px;
  margin-right: ${(props) => props.theme.spacing(10)};
`;
function TableRows({ rows, tableRowRemove, onValUpdate }) {
  return rows.map((rowsData, index) => {
    var opt = "Option " + (index + 1);
    const { name, email, profile } = rowsData;
    return (
      <tr key={index}>
        <td>
          <TextField
            id="username"
            label={opt}
            defaultValue={name}
            variant="outlined"
            fullWidth
            my={2}
            rows={4}
            maxRows={6}
            name="name"
            onChange={(event) => onValUpdate(index, event)}
          />
        </td>
        <td>
          {/* <button
            className="btn btn-dark"
            onClick={() => tableRowRemove(index)}
          >
            Delete Row
          </button> */}
          <DeleteIcon onClick={() => tableRowRemove(index)} />
        </td>
      </tr>
    );
  });
}
var i = false;
function Table1() {
  const [rows, initRow] = useState([]);
  const addRowTable = () => {
    const data = {
      name: "",
    };
    initRow([...rows, data]);
  };
  const tableRowRemove = (index) => {
    const dataRow = [...rows];
    dataRow.splice(index, 1);
    initRow(dataRow);
  };
  const onValUpdate = (i, event) => {
    const { name, value } = event.target;
    const data = [...rows];
    data[i][name] = value;
    initRow(data);
  };

  if (i == false) {
    addRowTable();
    i = true;
  }
  return (
    <>
      <Typography variant="body2" gutterBottom>
        Options
        <button
          onClick={addRowTable}
          style={{
            float: "right",
            border: "0",
            backgroundColor: "transparent",
            fontWeight: "bold",
            color: "#376fd1",
          }}
          color="primary"
        >
          Add
          <AddIcon style={{ position: "relative", top: "7px" }} />
        </button>
      </Typography>
      <table>
        <tbody>
          <TableRows
            rows={rows}
            tableRowRemove={tableRowRemove}
            onValUpdate={onValUpdate}
          />
        </tbody>
      </table>
    </>
  );
}

function EventEntryTab() {
  const [columns, setColumns] = useState(mockColumns);
  const [documentReady, setDocumentReady] = useState(false);

  useEffect(() => {
    setDocumentReady(true);
  }, []);
  const [maxWidth, setMaxWidth] = useState("sm");
  const handleMaxWidthChange = (event) => {
    setMaxWidth(event.target.value);
  };

  const [RequiresConfirmation, setRequiresConfirmation] = useState("false");
  const handleRequiresConfirmationChange = (event) => {
    setRequiresConfirmation(JSON.stringify(event.target.checked));
  };

  const [limitByDate, setLimitByDate] = useState("true");
  const handleLimitByDateChange = (event) => {
    setLimitByDate(event.target.value);
  };

  const [RedirectOnBooking, setRedirectOnBooking] = useState("false");
  const handleRedirectOnBookingChange = (event) => {
    setRedirectOnBooking(JSON.stringify(event.target.checked));
  };

  const [EnablePrivateUrl, setEnablePrivateUrl] = useState("false");
  const handleEnablePrivateUrlChange = (event) => {
    setEnablePrivateUrl(JSON.stringify(event.target.checked));
  };

  const [offerSeats, setOfferSeats] = useState("false");
  const handleOfferSeatsChange = (event) => {
    setOfferSeats(JSON.stringify(event.target.checked));
  };

  // const [value, setValue] = React.useState([
  //   dayjs("2022-04-17"),
  //   dayjs("2022-04-21"),
  // ]);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid justifyContent="space-between" container spacing={10}>
      <Grid item md={12}>
        <Typography variant="h3" gutterBottom display="inline">
          Booking Appearance
        </Typography>
        <Typography>
          Configure what information should be captured during registration
        </Typography>
      </Grid>
      <Grid item md={12}>
        {/* <Typography variant="h6" gutterBottom>
            Basic Information
          </Typography> */}
        <Box>
          <Grid container spacing={6}>
            <Grid item xs={12} lg={12} xl={12}>
              <Card
                mb={6}
                style={{
                  backgroundColor: "transparent",
                  padding: "0px !important",
                }}
              >
                <CardContent pb={0} style={{ paddingLeft: "0px" }}>
                  <Typography variant="h6" gutterBottom>
                    Booking Page Theme
                  </Typography>
                  <Typography variant="body2" mb={4}>
                    This only applies to your public booking pages
                  </Typography>
                  <Grid container>
                    <Grid item md={4}>
                      <img src="/static/img/others/theme-system.svg" />
                      <FormGroup>
                        <FormControlLabel
                          required
                          control={<Checkbox defaultChecked />}
                          label="System default"
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item md={4}>
                      <img src="/static/img/others/theme-light.svg" />
                      <FormGroup>
                        <FormControlLabel
                          required
                          control={<Checkbox />}
                          label="Light"
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item md={4} style={{ marginBottom: "15px" }}>
                      <img src="/static/img/others/theme-dark.svg" />
                      <FormGroup>
                        <FormControlLabel
                          required
                          control={<Checkbox />}
                          label="Dark"
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item md={12}>
                      <Button variant="contained" color="primary">
                        {/* <EditIcon /> */}
                        Update
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
        {/* <Divider /> */}
        <Box style={{ marginTop: "15px", marginBottom: "15px" }}>
          <Grid item md={12}>
            <ListItem alignItems="flex-start" disablePadding>
              <Grid container>
                <Grid md={10}>
                  <Typography variant="subtitle1">
                    Custom Brand Colors
                    <Typography
                      color="success"
                      style={{ paddingLeft: "12px !important" }}
                    >
                      <small
                        style={{
                          position: "relative",
                          bottom: "4px",
                          paddingLeft: "2px",
                        }}
                      >
                        Customize your own brand colour into your booking page
                      </small>
                    </Typography>
                  </Typography>
                </Grid>
                <Grid md={2}>
                  <Switch
                    inputProps={{ "aria-label": "controlled" }}
                    style={{ float: "right" }}
                    onChange={handleRedirectOnBookingChange}
                  />
                </Grid>
              </Grid>
            </ListItem>
            {/* <Switch inputProps={{ "aria-label": "controlled" }} />
            <ListItemText primary="Allow booker to select duration" /> */}
          </Grid>
          {RedirectOnBooking == "true" ? (
            <Grid container spacing={6}>
              <Grid
                item
                md={6}
                style={{ marginBottom: "15px", marginTop: "15px" }}
              >
                <TextField
                  id="username"
                  label="Brand Color (Light Theme)"
                  defaultValue=""
                  variant="outlined"
                  placeholder=""
                  fullWidth
                  my={2}
                />
              </Grid>
              <Grid
                item
                md={6}
                style={{ marginBottom: "15px", marginTop: "15px" }}
              >
                <TextField
                  id="username"
                  label="Brand Color (Dark Theme)"
                  defaultValue=""
                  variant="outlined"
                  placeholder=""
                  fullWidth
                  my={2}
                />
              </Grid>
              <Grid item md={12}>
                <Button variant="contained" color="primary">
                  {/* <EditIcon /> */}
                  Update
                </Button>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
        </Box>
        <Divider />
        <Box style={{ marginTop: "15px", marginBottom: "15px" }}>
          <Grid item md={12}>
            <ListItem alignItems="flex-start" disablePadding>
              <Grid container>
                <Grid md={10}>
                  <Typography variant="subtitle1">
                    Disable instaconnect.com branding
                    <Typography
                      color="success"
                      style={{ paddingLeft: "12px !important" }}
                    >
                      <small
                        style={{
                          position: "relative",
                          bottom: "4px",
                          paddingLeft: "2px",
                        }}
                      >
                        Removes any instaconnect.com related brandings, i.e.
                        'Powered by Instaconnect.com.'
                      </small>
                    </Typography>
                  </Typography>
                </Grid>
                <Grid md={2}>
                  <Switch
                    inputProps={{ "aria-label": "controlled" }}
                    style={{ float: "right" }}
                    onChange={handleOfferSeatsChange}
                  />
                </Grid>
              </Grid>
            </ListItem>
          </Grid>
        </Box>
        <Divider />
        <Box style={{ marginTop: "15px", marginBottom: "15px" }}>
          <Grid item md={12}>
            <ListItem alignItems="flex-start" disablePadding>
              <Grid container>
                <Grid md={10}>
                  <Typography variant="subtitle1">
                    Hide Book a Team Member Button
                    <Typography
                      color="success"
                      style={{ paddingLeft: "12px !important" }}
                    >
                      <small
                        style={{
                          position: "relative",
                          bottom: "4px",
                          paddingLeft: "2px",
                        }}
                      >
                        Hide Book a Team Member Button from your public pages
                      </small>
                    </Typography>
                  </Typography>
                </Grid>
                <Grid md={2}>
                  <Switch
                    inputProps={{ "aria-label": "controlled" }}
                    style={{ float: "right" }}
                  />
                </Grid>
              </Grid>
            </ListItem>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}

function Appearance() {
  return (
    <React.Fragment>
      <EventEntryTab />
      {/* <Table1 /> */}
    </React.Fragment>
  );
}

export default Appearance;
