import React, { useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Copy, Search as SearchIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import { darken } from "polished";
import { Link } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import "../../../Styles/Custom.css";
import Switch from "@mui/material/Switch";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import Chip from "@mui/material/Chip";
import TagFacesIcon from "@mui/icons-material/TagFaces";
import Autocomplete from "@mui/material/Autocomplete";

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Checkbox,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  Menu,
  MenuItem,
  InputBase,
  DialogActions,
  TextField,
  CardContent,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card as MuiCard,
  CardMedia as MuiCardMedia,
  CardActions,
  AvatarGroup as MuiAvatarGroup,
  FormControlLabel,
} from "@mui/material";
import { green, orange } from "@mui/material/colors";
import {
  Add as AddIcon,
  ArrowBack as ArrowBackIcon,
  FilterList,
  Download,
  Mail,
  ContentCopy,
  Visibility,
} from "@mui/icons-material";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const blue = {
  100: "#DAECFF",
  200: "#b6daff",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};
const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const CardMedia = styled(MuiCardMedia)`
  height: 220px;
`;

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;

const Customer = styled.div`
  display: flex;
  align-items: center;
`;

const ToolbarTitle = styled.div`
  min-width: 100%;
`;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const rows = [
  createData(
    "Anna Walley",
    "anna@walley.com",
    "A",
    0,
    "$530,00",
    "000112",
    "2023-01-02"
  ),
  createData(
    "Doris Fritz",
    "doris@fritz.com",
    "D",
    2,
    "$209,00",
    "000114",
    "2023-02-13"
  ),
  createData(
    "Edward Adkins",
    "edward@adkins.com",
    "E",
    2,
    "$535,00",
    "000117",
    "2023-03-04"
  ),
  createData(
    "Edwin Baker",
    "edwin@baker.com",
    "E",
    2,
    "$678,00",
    "000115",
    "2023-02-17"
  ),
  createData(
    "Gordon Workman",
    "gordan@workman.com",
    "G",
    0,
    "$314,00",
    "000119",
    "2023-03-28"
  ),
  createData(
    "Jo Avery",
    "jo@avery.com",
    "J",
    0,
    "$864,00",
    "000113",
    "2023-01-23"
  ),
  createData(
    "Leigha Hyden",
    "leigha@hyden.com",
    "L",
    2,
    "$341,00",
    "000118",
    "2023-03-14"
  ),
  createData(
    "Maureen Gagnon",
    "maureen@gagnon.com",
    "M",
    2,
    "$781,00",
    "000116",
    "2023-02-22"
  ),
  createData(
    "Maxine Thompson",
    "maxine@thompson.com",
    "M",
    0,
    "$273,00",
    "000121",
    "2023-05-31"
  ),
  createData(
    "Shawn Waddell",
    "shawn@waddell.com",
    "S",
    0,
    "$713,00",
    "000120",
    "2023-04-25"
  ),
];

function createData(
  customer,
  customerEmail,
  customerAvatar,
  status,
  amount,
  id,
  date
) {
  return { customer, customerEmail, customerAvatar, status, amount, id, date };
}

const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" style={{ borderBottom: "0" }}>
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ borderBottom: "0" }}
          >
            {/* <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            > */}
            {headCell.label}
            {/* </TableSortLabel> */}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const headCells = [{ id: "customer", alignment: "left", label: "Select All" }];

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const EnhancedTableToolbar = (props) => {
  const [viewStyle, setViewStyle] = React.useState("List");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { numSelected } = props;

  const Input = styled(InputBase)`
    color: inherit;
    width: 100%;

    > input {
      color: ${(props) => props.theme.header.search.color};
      padding-top: ${(props) => props.theme.spacing(2.5)};
      padding-right: ${(props) => props.theme.spacing(2.5)};
      padding-bottom: ${(props) => props.theme.spacing(2.5)};
      padding-left: ${(props) => props.theme.spacing(12)};
    }
  `;

  const { t } = useTranslation();

  const Search = styled.div`
    border-radius: 2px;
    background-color: ${(props) => props.theme.header.background};
    display: none;
    position: relative;
    width: 100%;

    &:hover {
      background-color: ${(props) =>
        darken(0.05, props.theme.header.background)};
    }

    ${(props) => props.theme.breakpoints.up("md")} {
      display: block;
    }
  `;

  const SearchIconWrapper = styled.div`
    width: 50px;
    height: 100%;
    position: absolute;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 22px;
      height: 22px;
    }
  `;

  const { doChangeProps } = props;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    setAnchorEl(null);
  };

  return (
    <Toolbar>
      <ToolbarTitle style={{ width: "100% !important" }}>
        <Typography>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <Input placeholder={t("Search")} style={{ width: "100%" }} />
          </Search>
        </Typography>
      </ToolbarTitle>
    </Toolbar>
  );
};

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = [
  { title: "The Shawshank Redemption", year: 1994 },
  { title: "The Godfather", year: 1972 },
  { title: "The Godfather: Part II", year: 1974 },
  { title: "The Dark Knight", year: 2008 },
  { title: "12 Angry Men", year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: "Pulp Fiction", year: 1994 },
  {
    title: "The Lord of the Rings: The Return of the King",
    year: 2003,
  },
  { title: "The Good, the Bad and the Ugly", year: 1966 },
  { title: "Fight Club", year: 1999 },
  {
    title: "The Lord of the Rings: The Fellowship of the Ring",
    year: 2001,
  },
  {
    title: "Star Wars: Episode V - The Empire Strikes Back",
    year: 1980,
  },
  { title: "Forrest Gump", year: 1994 },
  { title: "Inception", year: 2010 },
  {
    title: "The Lord of the Rings: The Two Towers",
    year: 2002,
  },
  { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { title: "Goodfellas", year: 1990 },
  { title: "The Matrix", year: 1999 },
  { title: "Seven Samurai", year: 1954 },
  {
    title: "Star Wars: Episode IV - A New Hope",
    year: 1977,
  },
  { title: "City of God", year: 2002 },
  { title: "Se7en", year: 1995 },
  { title: "The Silence of the Lambs", year: 1991 },
  { title: "It's a Wonderful Life", year: 1946 },
  { title: "Life Is Beautiful", year: 1997 },
  { title: "The Usual Suspects", year: 1995 },
  { title: "Léon: The Professional", year: 1994 },
  { title: "Spirited Away", year: 2001 },
  { title: "Saving Private Ryan", year: 1998 },
  { title: "Once Upon a Time in the West", year: 1968 },
  { title: "American History X", year: 1998 },
  { title: "Interstellar", year: 2014 },
  { title: "Casablanca", year: 1942 },
  { title: "City Lights", year: 1931 },
  { title: "Psycho", year: 1960 },
  { title: "The Green Mile", year: 1999 },
  { title: "The Intouchables", year: 2011 },
  { title: "Modern Times", year: 1936 },
  { title: "Raiders of the Lost Ark", year: 1981 },
  { title: "Rear Window", year: 1954 },
  { title: "The Pianist", year: 2002 },
  { title: "The Departed", year: 2006 },
  { title: "Terminator 2: Judgment Day", year: 1991 },
  { title: "Back to the Future", year: 1985 },
  { title: "Whiplash", year: 2014 },
  { title: "Gladiator", year: 2000 },
  { title: "Memento", year: 2000 },
  { title: "The Prestige", year: 2006 },
  { title: "The Lion King", year: 1994 },
  { title: "Apocalypse Now", year: 1979 },
  { title: "Alien", year: 1979 },
  { title: "Sunset Boulevard", year: 1950 },
  {
    title: "Dr. Strangelove or: How I Learned to",
    year: 1964,
  },
  { title: "The Great Dictator", year: 1940 },
  { title: "Cinema Paradiso", year: 1988 },
  { title: "The Lives of Others", year: 2006 },
  { title: "Grave of the Fireflies", year: 1988 },
  { title: "Paths of Glory", year: 1957 },
  { title: "Django Unchained", year: 2012 },
  { title: "The Shining", year: 1980 },
  { title: "WALL·E", year: 2008 },
  { title: "American Beauty", year: 1999 },
  { title: "The Dark Knight Rises", year: 2012 },
  { title: "Princess Mononoke", year: 1997 },
  { title: "Aliens", year: 1986 },
  { title: "Oldboy", year: 2003 },
  { title: "Once Upon a Time in America", year: 1984 },
  { title: "Witness for the Prosecution", year: 1957 },
  { title: "Das Boot", year: 1981 },
  { title: "Citizen Kane", year: 1941 },
  { title: "North by Northwest", year: 1959 },
  { title: "Vertigo", year: 1958 },
  {
    title: "Star Wars: Episode VI - Return of the Jedi",
    year: 1983,
  },
  { title: "Reservoir Dogs", year: 1992 },
  { title: "Braveheart", year: 1995 },
  { title: "M", year: 1931 },
  { title: "Requiem for a Dream", year: 2000 },
  { title: "Amélie", year: 2001 },
  { title: "A Clockwork Orange", year: 1971 },
  { title: "Like Stars on Earth", year: 2007 },
  { title: "Taxi Driver", year: 1976 },
  { title: "Lawrence of Arabia", year: 1962 },
  { title: "Double Indemnity", year: 1944 },
  {
    title: "Eternal Sunshine of the Spotless Mind",
    year: 2004,
  },
  { title: "Amadeus", year: 1984 },
  { title: "To Kill a Mockingbird", year: 1962 },
  { title: "Toy Story 3", year: 2010 },
  { title: "Logan", year: 2017 },
  { title: "Full Metal Jacket", year: 1987 },
  { title: "Dangal", year: 2016 },
  { title: "The Sting", year: 1973 },
  { title: "2001: A Space Odyssey", year: 1968 },
  { title: "Singin' in the Rain", year: 1952 },
  { title: "Toy Story", year: 1995 },
  { title: "Bicycle Thieves", year: 1948 },
  { title: "The Kid", year: 1921 },
  { title: "Inglourious Basterds", year: 2009 },
  { title: "Snatch", year: 2000 },
  { title: "3 Idiots", year: 2009 },
  { title: "Monty Python and the Holy Grail", year: 1975 },
];

function AvailableHosts() {
  const fixedOptions = [top100Films[6]];
  const [value, setValue] = React.useState([...fixedOptions, top100Films[13]]);

  return (
    <Grid item md={12}>
      <Autocomplete
        multiple
        id="fixed-tags-demo"
        value={value}
        onChange={(event, newValue) => {
          setValue([
            ...fixedOptions,
            ...newValue.filter((option) => fixedOptions.indexOf(option) === -1),
          ]);
        }}
        options={top100Films}
        getOptionLabel={(option) => option.title}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip label={option.title} {...getTagProps({ index })} />
          ))
        }
        renderInput={(params) => (
          <TextField {...params} label="Host" placeholder="" />
        )}
      />
    </Grid>
  );
}

function AvailableCoHosts() {
  const fixedOptions = [top100Films[6]];
  const [value, setValue] = React.useState([...fixedOptions, top100Films[13]]);

  return (
    <Grid item md={12}>
      <Autocomplete
        multiple
        id="fixed-tags-demo"
        value={value}
        onChange={(event, newValue) => {
          setValue([
            ...fixedOptions,
            ...newValue.filter((option) => fixedOptions.indexOf(option) === -1),
          ]);
        }}
        options={top100Films}
        getOptionLabel={(option) => option.title}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip label={option.title} {...getTagProps({ index })} />
          ))
        }
        renderInput={(params) => (
          <TextField {...params} label="Co-Hosts" placeholder="" />
        )}
      />
    </Grid>
  );
}

function EnhancedTable() {
  const [viewStyle, setViewStyle] = useState("List");
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("customer");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      // setViewStyle("Card");
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div>
      <Paper>
        <EnhancedTableToolbar
          numSelected={selected.length}
          doChangeProps={setViewStyle}
        />
      </Paper>
      <Paper>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={`${row.id}-${index}`}
                      selected={isItemSelected}
                    >
                      <TableCell
                        padding="checkbox"
                        style={{ borderBottom: "0" }}
                      >
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                          onClick={(event) => handleClick(event, row.id)}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        style={{ borderBottom: "0" }}
                      >
                        <Customer>
                          <Avatar>{row.customerAvatar}</Avatar>
                          <Box ml={3}>
                            {row.customer}
                            <br />
                            {row.customerEmail}
                          </Box>
                        </Customer>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={7} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

const Project = ({ image, title, description, chip }) => {
  return (
    <Paper>
      <Card>
        {image ? (
          <div
            style={{
              position: "relative",
            }}
          >
            <CardMedia image={image} title="Contemplative Reptile" />
            <Checkbox
              value="remember"
              color="primary"
              style={{ position: "absolute", top: "0px", left: "0px" }}
            />
          </div>
        ) : null}
        <CardContent>
          <Typography gutterBottom variant="h4" component="h2">
            {title}
          </Typography>
          <Typography mb={4} color="textSecondary" component="p">
            anna@gmail.com
          </Typography>
          {chip}
          {/* <AvatarGroup max={3}>
          <Avatar alt="Avatar" src="/static/img/avatars/avatar-1.jpg" />
          <Avatar alt="Avatar" src="/static/img/avatars/avatar-2.jpg" />
          <Avatar alt="Avatar" src="/static/img/avatars/avatar-3.jpg" />
        </AvatarGroup> */}
        </CardContent>
        {/* <CardActions>
        <Button size="small" color="primary">
          Share
        </Button>
        <Button size="small" color="primary">
          Learn More
        </Button>
      </CardActions> */}
      </Card>
    </Paper>
  );
};

function AvailableSpeakers() {
  const [open, setOpen] = useState(false);
  const [age, setAge] = React.useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [chipData, setChipData] = React.useState([
    { key: 0, label: "Track A" },
    { key: 1, label: "Track B" },
    { key: 2, label: "Track C" },
    { key: 3, label: "Track D" },
    { key: 4, label: "Track E" },
  ]);

  const handleDelete = (chipToDelete) => () => {
    setChipData((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );
  };

  const toggleDrawer = (anchor, open, oo) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      style={{ width: "350px" }}
    >
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item style={{ margin: "15px", paddingBottom: "10px" }}>
          <Typography variant="h3" gutterBottom display="inline">
            Select Speakers
          </Typography>
          <Typography>
            Tracks are curated group of sessions designed to target a specific
            audience group. Select the track for this session.
          </Typography>
        </Grid>
      </Grid>
      <Grid container style={{ display: "block", textAlign: "right" }}>
        <NewSpeaker />
      </Grid>
      <EnhancedTable />
      <DialogActions>
        <Button onClick={toggleDrawer("right", false)} color="primary">
          Cancel
        </Button>
        <Button onClick={toggleDrawer("right", false)} color="primary">
          Done
        </Button>
      </DialogActions>
    </Box>
  );
  return (
    <Grid item md={12}>
      <FormLabel id="demo-radio-buttons-group-label">Speakers</FormLabel>
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          listStyle: "none",
          p: 0.5,
          m: 0,
        }}
        component="ul"
        style={{ border: "1px solid #c4c4c4" }}
        onClick={toggleDrawer("right", true)}
      >
        {chipData.map((data) => {
          let icon;
          if (data.label === "React") {
            icon = <TagFacesIcon />;
          }
          return (
            <ListItem
              key={data.key}
              style={{
                width: "auto",
                paddingRight: "0",
                paddingLeft: "10px",
              }}
            >
              <Chip
                icon={icon}
                label={data.label}
                onDelete={
                  data.label === "React" ? undefined : handleDelete(data)
                }
              />
            </ListItem>
          );
        })}
      </Paper>
      <Drawer
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
      >
        {list("right")}
      </Drawer>
    </Grid>
  );
}

function NewSpeaker() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open, oo) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      style={{ width: "350px" }}
    >
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item style={{ margin: "15px", paddingBottom: "10px" }}>
          <Typography variant="h3" gutterBottom display="inline">
            Add Speaker
          </Typography>
        </Grid>
      </Grid>
      <Grid item style={{ margin: "15px", paddingBottom: "10px" }}>
        <Grid container spacing={6}>
          <Grid item md={6}>
            <TextField
              id="username"
              label="First Name"
              defaultValue=""
              variant="outlined"
              fullWidth
              my={2}
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              id="username"
              label="Last Name"
              defaultValue=""
              variant="outlined"
              fullWidth
              my={2}
            />
          </Grid>
          <Grid item md={12}>
            <TextField
              id="username"
              label="Email Id"
              defaultValue=""
              variant="outlined"
              fullWidth
              my={2}
            />
          </Grid>
        </Grid>
      </Grid>
      <DialogActions>
        <Button onClick={toggleDrawer("right", false)} color="primary">
          Cancel
        </Button>
        <Button onClick={toggleDrawer("right", false)} color="primary">
          Done
        </Button>
      </DialogActions>
    </Box>
  );
  return (
    <React.Fragment>
      <Link
        variant="contained"
        color="primary"
        onClick={toggleDrawer("right", true)}
        style={{
          textDecoration: "none",
          fontWeight: "bold",
          paddingRight: "15px",
          fontSize: "0.9rem",
        }}
      >
        <EditIcon style={{ position: "relative", top: "5px" }} />
        Add New Speaker
      </Link>
      <Drawer
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
      >
        {list("right")}
      </Drawer>
    </React.Fragment>
  );
}

function AvailableTracks() {
  const [open, setOpen] = useState(false);
  const [age, setAge] = React.useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [chipData, setChipData] = React.useState([
    { key: 0, label: "Track A" },
    { key: 1, label: "Track B" },
    { key: 2, label: "Track C" },
    { key: 3, label: "Track D" },
    { key: 4, label: "Track E" },
  ]);

  const handleDelete = (chipToDelete) => () => {
    setChipData((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );
  };

  const toggleDrawer = (anchor, open, oo) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      style={{ width: "350px" }}
    >
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item style={{ margin: "15px", paddingBottom: "10px" }}>
          <Typography variant="h3" gutterBottom display="inline">
            Select Track
          </Typography>
          <Typography>
            Tracks are curated group of sessions designed to target a specific
            audience group. Select the track for this session.
          </Typography>
        </Grid>
      </Grid>
      <List>
        <ListItem>
          <Grid container spacing={6}>
            <Grid item md={12} className="checkboxPadding">
              <List
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                }}
              >
                <ListItem alignItems="flex-start">
                  <Grid container>
                    <Grid item md={12} className="checkboxPadding">
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Select all Tracks"
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                </ListItem>
                <ListItem alignItems="flex-start">
                  <Grid container>
                    <Grid item md={12} className="checkboxPadding">
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Track A"
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                </ListItem>
                <ListItem alignItems="flex-start">
                  <Grid container>
                    <Grid item md={12} className="checkboxPadding">
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Track B"
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                </ListItem>
                <ListItem alignItems="flex-start">
                  <Grid container>
                    <Grid item md={12} className="checkboxPadding">
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Track C"
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                </ListItem>
                <ListItem alignItems="flex-start">
                  <Grid container>
                    <Grid item md={12} className="checkboxPadding">
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Track D"
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                </ListItem>
                <ListItem alignItems="flex-start">
                  <Grid container>
                    <Grid item md={12} className="checkboxPadding">
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Track E"
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </ListItem>
      </List>
      <DialogActions>
        <Button onClick={toggleDrawer("right", false)} color="primary">
          Cancel
        </Button>
        <Button onClick={toggleDrawer("right", false)} color="primary">
          Done
        </Button>
      </DialogActions>
    </Box>
  );
  return (
    <Grid item md={12}>
      <FormLabel id="demo-radio-buttons-group-label">Tracks</FormLabel>
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          listStyle: "none",
          p: 0.5,
          m: 0,
        }}
        component="ul"
        style={{ border: "1px solid #c4c4c4" }}
        onClick={toggleDrawer("right", true)}
      >
        {chipData.map((data) => {
          let icon;
          if (data.label === "React") {
            icon = <TagFacesIcon />;
          }
          return (
            <ListItem
              key={data.key}
              style={{
                width: "auto",
                paddingRight: "0",
                paddingLeft: "10px",
              }}
            >
              <Chip
                icon={icon}
                label={data.label}
                onDelete={
                  data.label === "React" ? undefined : handleDelete(data)
                }
              />
            </ListItem>
          );
        })}
      </Paper>
      <Drawer
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
      >
        {list("right")}
      </Drawer>
    </Grid>
  );
}

function AllowAccess() {
  const fixedOptions = [top100Films[6]];
  const [value, setValue] = React.useState([...fixedOptions, top100Films[13]]);

  return (
    <Grid item md={12}>
      <Autocomplete
        multiple
        id="fixed-tags-demo"
        value={value}
        onChange={(event, newValue) => {
          setValue([
            ...fixedOptions,
            ...newValue.filter((option) => fixedOptions.indexOf(option) === -1),
          ]);
        }}
        options={top100Films}
        getOptionLabel={(option) => option.title}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip label={option.title} {...getTagProps({ index })} />
          ))
        }
        renderInput={(params) => (
          <TextField {...params} label="Allow access" placeholder="" />
        )}
      />
    </Grid>
  );
}

function RestrictAccess() {
  const fixedOptions = [top100Films[6]];
  const [value, setValue] = React.useState([...fixedOptions, top100Films[13]]);

  return (
    <Grid item md={12}>
      <Autocomplete
        multiple
        id="fixed-tags-demo"
        value={value}
        onChange={(event, newValue) => {
          setValue([
            ...fixedOptions,
            ...newValue.filter((option) => fixedOptions.indexOf(option) === -1),
          ]);
        }}
        options={top100Films}
        getOptionLabel={(option) => option.title}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip label={option.title} {...getTagProps({ index })} />
          ))
        }
        renderInput={(params) => (
          <TextField {...params} label="Restrict access" placeholder="" />
        )}
      />
    </Grid>
  );
}

function SessionTags() {
  const fixedOptions = [top100Films[6]];
  const [value, setValue] = React.useState([...fixedOptions, top100Films[13]]);

  return (
    <Grid item md={12}>
      <Autocomplete
        multiple
        id="fixed-tags-demo"
        value={value}
        onChange={(event, newValue) => {
          setValue([
            ...fixedOptions,
            ...newValue.filter((option) => fixedOptions.indexOf(option) === -1),
          ]);
        }}
        options={top100Films}
        getOptionLabel={(option) => option.title}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip label={option.title} {...getTagProps({ index })} />
          ))
        }
        renderInput={(params) => (
          <TextField {...params} label="Tags" placeholder="" />
        )}
      />
    </Grid>
  );
}

function SessionTab() {
  const [open, setOpen] = useState(false);
  const [age, setAge] = React.useState("");

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open, oo) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      style={{ width: "600px" }}
    >
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item style={{ margin: "15px", paddingBottom: "10px" }}>
          <Typography variant="h3" gutterBottom display="inline">
            Add Session
          </Typography>
        </Grid>
      </Grid>
      <List>
        <ListItem>
          <Typography variant="h5" gutterBottom display="inline">
            Basic Info
          </Typography>
        </ListItem>
        <ListItem>
          <Grid container spacing={6}>
            <Grid item md={12}>
              <TextField
                id="username"
                label="Name"
                defaultValue=""
                placeholder="Be clear and descriptive"
                variant="outlined"
                fullWidth
                my={2}
              />
            </Grid>
            <Grid item md={4}>
              <DatePicker
                id="username"
                label="Session Date"
                variant="outlined"
                fullWidth
                slotProps={{ textField: { fullWidth: true } }}
              />
            </Grid>
            <Grid item md={4}>
              <TimePicker
                id="username"
                label="Session Time"
                variant="outlined"
                fullWidth
                slotProps={{ textField: { fullWidth: true } }}
              />
            </Grid>
            <Grid item md={4}>
              <TextField
                id="username"
                label="Duration"
                defaultValue=""
                placeholder="In Minutes"
                variant="outlined"
                fullWidth
                my={2}
              />
            </Grid>
            <AvailableHosts />
            <AvailableCoHosts />
            <AvailableSpeakers />
            <Grid item md={12}>
              <TextField
                id="username"
                label="Description"
                defaultValue=""
                placeholder="Add more details about this item for better clarity to attendees"
                variant="outlined"
                fullWidth
                my={2}
                multiline
                rows={4}
                maxRows={6}
              />
            </Grid>
            <AvailableTracks />
            <SessionTags />
          </Grid>
        </ListItem>
        <ListItem>
          <Typography variant="h5" gutterBottom display="inline">
            Access
          </Typography>
        </ListItem>
        <ListItem>
          <Grid container spacing={6}>
            <AllowAccess />
            <RestrictAccess />
            <Grid item container>
              <Grid md={8}>
                <ListItemText
                  primary="Should session be hidden from people who cannot join it?"
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        Sessions will be visible to all participants by default
                        including people who can't join it. You may choose to
                        keep it visible only to people who can join it.
                      </Typography>
                    </React.Fragment>
                  }
                />
              </Grid>
              <Grid md={4}>
                <Switch inputProps={{ "aria-label": "controlled" }} />
              </Grid>
            </Grid>
          </Grid>
        </ListItem>
      </List>
      <DialogActions>
        <Button onClick={toggleDrawer("right", false)} color="primary">
          Cancel
        </Button>
        <Button onClick={toggleDrawer("right", false)} color="primary">
          Done
        </Button>
      </DialogActions>
    </Box>
  );
  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        onClick={toggleDrawer("right", true)}
        style={{ marginRight: "1rem" }}
      >
        <AddIcon />
        Session
      </Button>
      <Drawer
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
      >
        {list("right")}
      </Drawer>
    </React.Fragment>
  );
}

function Session() {
  return (
    <React.Fragment>
      <SessionTab />
    </React.Fragment>
  );
}

export default Session;
