import React, { useState } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SupportTickets from "./SupportTickets";
import SupportTicketAdd from "./SupportTicketAdd";

import {
  Badge,
  Box,
  Grid,
  Card,
  TextField as MuiTextField,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Fab,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Link,
  Button as MuiButton,
  Menu,
  MenuItem,
  Tooltip,
  IconButton,
  DialogActions,
  TableCell,
  TableRow,
  TableBody,
  Table,
  Paper as MuiPaper,
  DialogContent,
  DialogTitle,
  Dialog,
  Chip as MuiChip,
} from "@mui/material";

import { green, orange } from "@mui/material/colors";

import { spacing, textAlign } from "@mui/system";
import SendIcon from "@mui/icons-material/Send";
import {
  ArrowDropDown,
  DesktopMac,
  Draw,
  NearMe,
  OpenInFull,
  Videocam,
  ArrowBack as ArrowBackIcon,
  RadioButtonUnchecked,
  RadioButtonChecked,
  ContactSupport,
  Add as AddIcon,
} from "@mui/icons-material";
import { Info, Settings } from "react-feather";
import { padding } from "polished";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

const Chip = styled(MuiChip)`
  ${spacing};

  background: ${(props) => props.paid && green[500]};
  background: ${(props) => props.sent && orange[700]};
  color: ${(props) =>
    (props.paid || props.sent) && props.theme.palette.common.white};
`;

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

const ChatContainer = styled(Grid)`
  width: 100%;
  height: 75vh;
`;

const ChatSidebar = styled(Grid)`
  border-right: 1px solid ${(props) => props.theme.palette.divider};
`;

const ChatMain = styled(Grid)``;

const ChatMessages = styled.div`
  overflow-y: scroll;
  height: calc(75vh - 90px);
`;

const ChatMessage = styled.div`
  margin: 30px;
  text-align: ${(props) => props.position};
`;

const ChatMessageInner = styled.div`
  display: inline-block;
`;

const ChatMessageTime = styled(Typography)`
  text-align: right;
  opacity: 0.8;
`;

const ChatMessageAvatar = styled(Avatar)`
  position: relative;
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-right: ${(props) => props.theme.spacing(2)};
`;

const ChatMessageBubble = styled.div`
  display: inline-block;
  margin-right: auto;
  background: ${(props) =>
    props.highlighted
      ? props.theme.palette.secondary.main
      : props.theme.palette.action.hover};
  color: ${(props) =>
    props.highlighted
      ? props.theme.palette.common.white
      : props.theme.palette.text.primary};
  border-radius: 3px;
  padding: ${(props) => props.theme.spacing(2)};
  margin-bottom: ${(props) => props.theme.spacing(1)};
  ${(props) => props.theme.shadows[1]};
`;

const ChatMessageBubbleName = styled(Typography)`
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
`;

const ChatInput = styled(Grid)`
  min-height: 94px;
  padding: ${(props) => props.theme.spacing(5)};
`;

const Online = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)};
  span {
    background-color: ${(props) =>
      props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

function ChatMessageComponent({
  name,
  message,
  time,
  avatar,
  position = "left",
}) {
  return (
    <ChatMessage position={position}>
      <ChatMessageInner>
        <ChatMessageAvatar alt="Lucy Lavender" src={avatar} />
        <ChatMessageBubble highlighted={position === "right"}>
          <Box>
            <ChatMessageBubbleName variant="body1">
              {name}
            </ChatMessageBubbleName>
          </Box>
          <Typography variant="body2">{message}</Typography>
        </ChatMessageBubble>
        <ChatMessageTime variant="body2">{time}</ChatMessageTime>
      </ChatMessageInner>
    </ChatMessage>
  );
}

function ChatWindow() {
  const [showSupportTicketAdd, setShowSupportTicketAdd] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");
  const [fullWidth1, setFullWidth1] = React.useState(true);
  const [maxWidth1, setMaxWidth1] = React.useState("lg");

  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl1, setAnchorEl1] = React.useState(null);

  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  return (
    <ChatContainer container component={Card}>
      <ChatSidebar item xs={12} md={2} lg={2}>
        <Grid item xs={12}>
          <Box p={2}>
            <TextField label="Search contacts" variant="outlined" fullWidth />
          </Box>
        </Grid>
        <Divider />
        <List style={{ overflowY: "auto", height: "calc(75vh - 94px)" }}>
          <ListItem button>
            <ListItemIcon>
              <Online
                overlap="circular"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                variant="dot"
              >
                <Avatar
                  alt="Lucy Lavender"
                  src="https://storage.googleapis.com/pathadvice-app.appspot.com/flags/IN.svg"
                />
              </Online>
            </ListItemIcon>
            <ListItemText
              primary="Widget 1"
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="h6"
                    color="text.primary"
                  >
                    Kolkata
                  </Typography>
                  <br />
                  <Typography
                    component="span"
                    variant="h7"
                    color="text.primary"
                  >
                    June 16, 2024 10:38 PM
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <Online
                overlap="circular"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                variant="dot"
              >
                <Avatar
                  alt="Lucy Lavender"
                  src="https://storage.googleapis.com/pathadvice-app.appspot.com/flags/IN.svg"
                />
              </Online>
            </ListItemIcon>
            <ListItemText
              primary="Widget 1"
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="h6"
                    color="text.primary"
                  >
                    Kolkata
                  </Typography>
                  <br />
                  <Typography
                    component="span"
                    variant="h7"
                    color="text.primary"
                  >
                    June 16, 2024 10:38 PM
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <Online
                overlap="circular"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                variant="dot"
              >
                <Avatar
                  alt="Lucy Lavender"
                  src="https://storage.googleapis.com/pathadvice-app.appspot.com/flags/IN.svg"
                />
              </Online>
            </ListItemIcon>
            <ListItemText
              primary="Widget 1"
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="h6"
                    color="text.primary"
                  >
                    Kolkata
                  </Typography>
                  <br />
                  <Typography
                    component="span"
                    variant="h7"
                    color="text.primary"
                  >
                    June 16, 2024 10:38 PM
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <Online
                overlap="circular"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                variant="dot"
              >
                <Avatar
                  alt="Lucy Lavender"
                  src="https://storage.googleapis.com/pathadvice-app.appspot.com/flags/IN.svg"
                />
              </Online>
            </ListItemIcon>
            <ListItemText
              primary="Widget 1"
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="h6"
                    color="text.primary"
                  >
                    Kolkata
                  </Typography>
                  <br />
                  <Typography
                    component="span"
                    variant="h7"
                    color="text.primary"
                  >
                    June 16, 2024 10:38 PM
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
        </List>
      </ChatSidebar>
      <ChatSidebar item xs={12} md={7} lg={7}>
        <Grid item xs={12}>
          <Box p={2} style={{ textAlign: "center" }}>
            <Button
              variant="contained"
              color="secondary"
              aria-owns={anchorEl ? "simple-menu" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              startIcon={<Videocam />}
              endIcon={<ArrowDropDown />}
            >
              Call
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>Video Call</MenuItem>
              <MenuItem onClick={handleClose}>Audio Call</MenuItem>
            </Menu>
            <Button
              variant="contained"
              color="secondary"
              aria-owns={anchorEl1 ? "simple-menu1" : undefined}
              aria-haspopup="true"
              onClick={handleClick1}
              style={{ marginLeft: "5px" }}
            >
              <NearMe />
            </Button>
            <Button
              variant="contained"
              color="secondary"
              aria-owns={anchorEl1 ? "simple-menu1" : undefined}
              aria-haspopup="true"
              onClick={handleClick1}
              startIcon={<DesktopMac />}
              endIcon={<ArrowDropDown />}
              style={{ marginLeft: "5px" }}
            >
              Share
            </Button>
            <Menu
              id="simple-menu1"
              anchorEl={anchorEl1}
              open={Boolean(anchorEl1)}
              onClose={handleClose1}
            >
              <MenuItem onClick={handleClose}>Share My Screen</MenuItem>
              <MenuItem onClick={handleClose}>Request Screenshare</MenuItem>
            </Menu>
            <Button
              variant="contained"
              color="secondary"
              aria-owns={anchorEl1 ? "simple-menu1" : undefined}
              aria-haspopup="true"
              startIcon={<RadioButtonChecked />}
              style={{ marginLeft: "5px" }}
            >
              Ask
            </Button>
            {/* <Tooltip title="Control" style={{ marginLeft: "5px" }}>
              <IconButton aria-label="Details" size="large">
                <NearMe />
              </IconButton>
            </Tooltip> */}
            <Button
              variant="contained"
              color="secondary"
              aria-owns={anchorEl1 ? "simple-menu1" : undefined}
              aria-haspopup="true"
              style={{ marginLeft: "5px" }}
            >
              <Draw />
            </Button>
            {/* <Tooltip title="Draw">
              <IconButton aria-label="Details" size="large">
                <Draw />
              </IconButton>
            </Tooltip> */}
            <Button
              variant="contained"
              color="secondary"
              aria-owns={anchorEl1 ? "simple-menu1" : undefined}
              aria-haspopup="true"
              style={{ marginLeft: "5px" }}
            >
              <Info onClick={() => setOpen(true)} />
            </Button>
            {/* <Tooltip title="View Information">
              <IconButton aria-label="Details" size="large">
                <Info onClick={() => setOpen(true)} />
              </IconButton>
            </Tooltip> */}
            <Button
              variant="contained"
              color="secondary"
              aria-owns={anchorEl1 ? "simple-menu1" : undefined}
              aria-haspopup="true"
              onClick={handleClick1}
              startIcon={<Settings />}
              endIcon={<ArrowDropDown />}
              style={{ marginLeft: "5px" }}
            >
              Settings
            </Button>
            <Menu
              id="simple-menu1"
              anchorEl={anchorEl1}
              open={Boolean(anchorEl1)}
              onClose={handleClose1}
            >
              <MenuItem onClick={handleClose}>Share My Screen</MenuItem>
              <MenuItem onClick={handleClose}>Request Screenshare</MenuItem>
            </Menu>
            <Button
              variant="contained"
              color="secondary"
              aria-owns={anchorEl1 ? "simple-menu1" : undefined}
              aria-haspopup="true"
              style={{ marginLeft: "5px" }}
            >
              <OpenInFull />
            </Button>
            <Button
              variant="contained"
              color="secondary"
              aria-owns={anchorEl1 ? "simple-menu1" : undefined}
              aria-haspopup="true"
              style={{ marginLeft: "5px" }}
            >
              <ContactSupport onClick={() => setOpen1(true)} />
            </Button>
            {/* <Tooltip title="Expand Control">
              <IconButton aria-label="Details" size="large">
                <OpenInFull />
              </IconButton>
            </Tooltip> */}
          </Box>
        </Grid>
        <Divider />
        <List>
          <ListItem button>
            <img
              src="/static/img/others/IncomingRequests/Offline.png"
              style={{ height: "65vh", width: "100%" }}
            />
          </ListItem>
        </List>
      </ChatSidebar>
      <ChatMain item xs={12} md={3} lg={3}>
        <ChatMessages>
          <ChatMessageComponent
            name="Remy Sharp"
            avatar="/static/img/avatars/avatar-2.jpg"
            message="Sit meis deleniti eu, pri vidit meliore docendi ut, an eum erat animal commodo."
            time="20 minutes ago"
            position="left"
          />
          <ChatMessageComponent
            name="You"
            avatar="/static/img/avatars/avatar-1.jpg"
            message="Lorem ipsum dolor sit amet, vis erat denique in, dicunt prodesset te vix."
            time="12 minutes ago"
            position="right"
          />
          <ChatMessageComponent
            name="Remy Sharp"
            avatar="/static/img/avatars/avatar-2.jpg"
            message="Cum ea graeci tractatos. 😄"
            time="8 minutes ago"
            position="left"
          />
          <ChatMessageComponent
            name="You"
            avatar="/static/img/avatars/avatar-1.jpg"
            message="Cras pulvinar, sapien id vehicula aliquet, diam velit elementum orci. 👍"
            time="5 minutes ago"
            position="right"
          />
          <ChatMessageComponent
            name="Remy Sharp"
            avatar="/static/img/avatars/avatar-2.jpg"
            message="Lorem ipsum dolor sit amet, vis erat denique in, dicunt prodesset te vix."
            time="3 minutes ago"
            position="left"
          />
        </ChatMessages>
        <Divider />
        <ChatInput container>
          <Grid item style={{ flexGrow: 1 }}>
            <TextField variant="outlined" label="Type your message" fullWidth />
          </Grid>
          <Grid item>
            <Box ml={2}>
              <Fab color="primary" aria-label="add" size="medium">
                <SendIcon />
              </Fab>
            </Box>
          </Grid>
        </ChatInput>
      </ChatMain>
      <Paper mt={4}>
        <div>
          <Dialog
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              Visitor Information
            </DialogTitle>
            <DialogContent>
              {/* <DialogContentText>Some Demo Contents.</DialogContentText> */}
              <div>
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography>MAIN INFORMATION</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Paper>
                      <TableWrapper>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell
                                scope="row"
                                style={{ borderBottom: 0 }}
                              >
                                Country:
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    marginLeft: "15px",
                                  }}
                                >
                                  India
                                </span>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                scope="row"
                                style={{ borderBottom: 0 }}
                              >
                                City:
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    marginLeft: "15px",
                                  }}
                                >
                                  Kolkata
                                </span>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                scope="row"
                                style={{ borderBottom: 0 }}
                              >
                                First Seen:
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    marginLeft: "15px",
                                  }}
                                >
                                  May 16 2024 | 4:09 PM
                                </span>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                scope="row"
                                style={{ borderBottom: 0 }}
                              >
                                Last Seen:
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    marginLeft: "15px",
                                  }}
                                >
                                  May 16 2024 | 4:09 PM
                                </span>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                scope="row"
                                style={{ borderBottom: 0 }}
                              >
                                Total Page views:
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    marginLeft: "15px",
                                  }}
                                >
                                  2
                                </span>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                scope="row"
                                style={{ borderBottom: 0 }}
                              >
                                Last Page Location:
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    marginLeft: "15px",
                                  }}
                                >
                                  equitechsoftwares.in
                                </span>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                scope="row"
                                style={{ borderBottom: 0 }}
                              >
                                Browser:
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    marginLeft: "15px",
                                  }}
                                >
                                  Google Chrome
                                </span>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                scope="row"
                                style={{ borderBottom: 0 }}
                              >
                                Online Status:
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    marginLeft: "15px",
                                    color: "green",
                                  }}
                                >
                                  Online
                                </span>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableWrapper>
                    </Paper>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    Visitor Information
                  </AccordionSummary>
                  <AccordionDetails>
                    <Paper>
                      <TableWrapper>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell
                                scope="row"
                                style={{ borderBottom: 0 }}
                              >
                                Name:
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    marginLeft: "15px",
                                  }}
                                >
                                  Amit Dutta
                                </span>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                scope="row"
                                style={{ borderBottom: 0 }}
                              >
                                Email Id:
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    marginLeft: "15px",
                                  }}
                                >
                                  amit@gmail.com
                                </span>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                scope="row"
                                style={{ borderBottom: 0 }}
                              >
                                Phone:
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    marginLeft: "15px",
                                  }}
                                >
                                  9888899999
                                </span>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                scope="row"
                                style={{ borderBottom: 0 }}
                              >
                                Country:
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    marginLeft: "15px",
                                  }}
                                >
                                  India
                                </span>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                scope="row"
                                style={{ borderBottom: 0 }}
                              >
                                Company:
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    marginLeft: "15px",
                                  }}
                                >
                                  Demo Company
                                </span>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableWrapper>
                    </Paper>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography>PAGE VIEWS</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Paper>
                      <TableWrapper>
                        <Table>
                          {/* <TableHead>
                            <TableRow>
                              <TableCell>Name</TableCell>
                              <TableCell>Count</TableCell>
                            </TableRow>
                          </TableHead> */}
                          <TableBody>
                            <TableRow>
                              <TableCell
                                scope="row"
                                style={{ borderBottom: 0 }}
                              >
                                appointcare.com:
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    marginLeft: "15px",
                                  }}
                                >
                                  <Chip label="2" color="success" />
                                </span>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                scope="row"
                                style={{ borderBottom: 0 }}
                              >
                                appointcare.com/privacy:
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    marginLeft: "15px",
                                  }}
                                >
                                  <Chip label="12" color="success" />
                                </span>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableWrapper>
                    </Paper>
                  </AccordionDetails>
                </Accordion>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen(false)} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            fullWidth={fullWidth1}
            maxWidth={maxWidth1}
            open={open1}
            onClose={() => setOpen1(false)}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              {showSupportTicketAdd == false ? (
                <Grid justifyContent="space-between" container spacing={10}>
                  <Grid item>
                    <Typography variant="h3" gutterBottom display="inline">
                      Support Tickets
                    </Typography>
                  </Grid>
                  <Grid item>
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setShowSupportTicketAdd(true)}
                      >
                        <AddIcon />
                        New Ticket
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              ) : (
                <Grid justifyContent="space-between" container spacing={10}>
                  <Grid item>
                    <Typography variant="h3" gutterBottom display="inline">
                      Lights inside my fridge always on?
                    </Typography>
                  </Grid>
                  <Grid item>
                    <div>
                      <Button
                        variant="contained"
                        color="inherit"
                        sx={{ mx: "0.5rem" }}
                        component={Link}
                        onClick={() => setShowSupportTicketAdd(false)}
                      >
                        <ArrowBackIcon />
                        Back
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              )}
            </DialogTitle>
            <DialogContent>
              {/* <DialogContentText>Some Demo Contents.</DialogContentText> */}
              {showSupportTicketAdd == false ? (
                <SupportTickets />
              ) : (
                <SupportTicketAdd />
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen1(false)} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Paper>
    </ChatContainer>
  );
}

function Chat() {
  return (
    <React.Fragment>
      <Wrapper style={{ width: "100%" }}>
        <Helmet title="Chat" />

        <Grid justifyContent="space-between" container spacing={10}>
          <Grid item>
            <Typography variant="h3" gutterBottom display="inline">
              Incoming Requests
            </Typography>

            <Breadcrumbs aria-label="Breadcrumb" mt={2}>
              <Link component={NavLink} to="/widget/dashboard">
                Dashboard
              </Link>
              <Typography>Incoming Requests</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item>
            <div>
              <Button
                variant="contained"
                color="inherit"
                sx={{ mx: "0.5rem" }}
                component={Link}
                to="/widget/dashboard"
              >
                <ArrowBackIcon />
                Back
              </Button>
            </div>
          </Grid>
        </Grid>

        <Divider my={6} />

        <ChatWindow />
      </Wrapper>
    </React.Fragment>
  );
}

export default Chat;
