import { grid } from "@mui/system";
import { Code, Users, Settings, Grid } from "react-feather";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import { Diversity3, Groups2 } from "@mui/icons-material";

const gettingStartedSection = [
  {
    href: "/settings",
    icon: Settings,
    title: "Settings",
    children: [
      {
        href: "/settings/business-settings",
        title: "Business Settings",
      },
      {
        href: "/settings/profile",
        title: "Profile",
      },
      {
        href: "/settings/my-availability",
        title: "Availability",
      },
      {
        href: "/settings/change-password",
        title: "Change Password",
      },
      // {
      //   href: "/spaces/space-apps",
      //   title: "spaces Apps",
      // },
    ],
  },
  {
    href: "/settings/users",
    icon: Users,
    title: "User Management",
  },
  {
    href: "/settings/groups",
    icon: Diversity3,
    title: "Groups",
  },
  {
    href: "/settings/roles",
    icon: Grid,
    title: "Roles and Permission",
  },
  {
    href: "/settings/spaces",
    icon: WorkspacesIcon,
    title: "Space Management",
  },
];

const settingItems = [
  {
    title: "Getting started",
    pages: gettingStartedSection,
  },
  // {
  //   title: "Development",
  //   pages: developmentSection,
  // },
  // {
  //   title: "Help",
  //   pages: supportSection,
  // },
];

export default settingItems;
