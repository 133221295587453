import React, { useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Code, Search as SearchIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import { darken } from "polished";
import { Link } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import "../../../../Styles/Custom.css";
import Drawer from "@mui/material/Drawer";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
// import { ArrowBackIcon as BackIcon } from "@mui/icons-material/ArrowBack";
import Switch from "@mui/material/Switch";
import ListItemIcon from "@mui/material/ListItemIcon";
import FormGroup from "@mui/material/FormGroup";

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Checkbox,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  Menu,
  MenuItem,
  InputBase,
  DialogActions,
  TextField,
  CardContent,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card as MuiCard,
  CardMedia as MuiCardMedia,
  CardHeader,
  CardActions,
  AvatarGroup as MuiAvatarGroup,
  FormControlLabel,
} from "@mui/material";
import { green, orange } from "@mui/material/colors";
import {
  Add as AddIcon,
  ArrowBack as ArrowBackIcon,
  ArrowDropDown,
  ContentCopy,
  KeyboardArrowDown,
  Launch,
  MoreHoriz,
  CloudUpload as MuiCloudUpload,
} from "@mui/icons-material";
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Link as LinkIcon,
} from "@mui/icons-material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { spacing, width } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Chip = styled(MuiChip)`
  ${spacing};

  background: ${(props) => props.paid && green[500]};
  background: ${(props) => props.sent && orange[700]};
  color: ${(props) =>
    (props.paid || props.sent) && props.theme.palette.common.white};
`;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const ToolbarTitle = styled.div`
  min-width: 220px;

  ${(props) => props.theme.breakpoints.up("md")} {
    min-width: 900px;
  }
`;

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;

const checkboxStyle = `
  position:absolute;
  bottom:0;
`;

const Customer = styled.div`
  display: flex;
  align-items: center;
`;

const CardMedia = styled(MuiCardMedia)`
  height: 220px;
`;

function createData(
  customer,
  customerEmail,
  customerAvatar,
  status,
  amount,
  id,
  date
) {
  return { customer, customerEmail, customerAvatar, status, amount, id, date };
}

const rows = [
  createData(
    "30 Min Meeting",
    "Description",
    "A",
    0,
    "$530,00",
    "cal.com/equitechsoftwares/30min",
    "30m"
  ),
  createData(
    "15 Min Meeting",
    "Description",
    "D",
    2,
    "$209,00",
    "cal.com/equitechsoftwares/15min",
    "15m"
  ),
  createData(
    "Secret Meeting",
    "Description",
    "E",
    2,
    "$535,00",
    "cal.com/equitechsoftwares/secret",
    "60m"
  ),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  { id: "customer", alignment: "left", label: "Title" },
  { id: "status", alignment: "left", label: "Url" },
  // { id: "date", alignment: "left", label: "Date" },
  { id: "id", alignment: "left", label: "Duration" },
];

const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const EnhancedTableToolbar = (props) => {
  const [viewStyle, setViewStyle] = React.useState("List");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const { numSelected } = props;

  const Input = styled(InputBase)`
    color: inherit;
    width: 100%;
    > input {
      color: ${(props) => props.theme.header.search.color};
      padding-top: ${(props) => props.theme.spacing(2.5)};
      padding-right: ${(props) => props.theme.spacing(2.5)};
      padding-bottom: ${(props) => props.theme.spacing(2.5)};
      padding-left: ${(props) => props.theme.spacing(12)};
      width: 160px;
    }
  `;

  const { t } = useTranslation();

  const Search = styled.div`
    border-radius: 2px;
    margin-left: 12px;
    background-color: ${(props) => props.theme.header.background};
    position: relative;
    width: 100%;

    &:hover {
      background-color: ${(props) =>
        darken(0.05, props.theme.header.background)};
    }

    // ${(props) => props.theme.breakpoints.up("md")} {
    //   display: block;
    // }
  `;

  const SearchIconWrapper = styled.div`
    width: 50px;
    height: 100%;
    position: absolute;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 22px;
      height: 22px;
    }
  `;

  const { doChangeProps } = props;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    setAnchorEl(null);
  };
  const handleClickShowing = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleCloseShowing = (event) => {
    setAnchorEl1(null);
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [anchorElM, setAnchorElM] = React.useState(null);
  const openM = Boolean(anchorElM);
  const handleClickM = (event) => {
    setAnchorElM(event.currentTarget);
  };
  const handleCloseM = () => {
    setAnchorElM(null);
  };

  return (
    <Toolbar style={{ paddingLeft: "0" }}>
      <ToolbarTitle>
        {numSelected > 0 ? (
          <Typography
            color="inherit"
            variant="subtitle1"
            style={{ paddingLeft: "12px" }}
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Grid container>
            {viewStyle === "Card" ? (
              <Grid>
                <Checkbox md={1} />
              </Grid>
            ) : (
              <div></div>
            )}
            <Grid md={3}>
              <Typography>
                <Search className="searchBar">
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <Input
                    placeholder={t("Search")}
                    sx={{ width: { xs: "120px", md: "160px" } }}
                  />
                </Search>
              </Typography>
            </Grid>
            <Grid md={8} className="filter">
              <Button
                variant="outlined"
                sx={{ mr: 2, display: { xs: "none", md: "inline" } }}
                id="basic-button"
                aria-controls={openM ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openM ? "true" : undefined}
                onClick={handleClickM}
                endIcon={
                  <ArrowDropDown style={{ position: "relative", top: "5px" }} />
                }
              >
                Teams
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorElM}
                open={openM}
                onClose={handleCloseM}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem>
                  <ListItem
                    alignItems="flex-start"
                    style={{
                      paddingLeft: "0",
                      paddingTop: "0",
                      paddingBottom: "0",
                    }}
                  >
                    <Grid container>
                      <Grid item md={12} className="checkboxPadding">
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox />}
                            label="All"
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>
                  </ListItem>
                </MenuItem>
                <MenuItem>
                  <ListItem
                    alignItems="flex-start"
                    style={{
                      paddingLeft: "0",
                      paddingTop: "0",
                      paddingBottom: "0",
                    }}
                  >
                    <Grid container>
                      <Grid item md={12} className="checkboxPadding">
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox />}
                            label="Your account"
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>
                  </ListItem>
                </MenuItem>
                <MenuItem>
                  <ListItem
                    alignItems="flex-start"
                    style={{
                      paddingLeft: "0",
                      paddingTop: "0",
                      paddingBottom: "0",
                    }}
                  >
                    <Grid container>
                      <Grid item md={12} className="checkboxPadding">
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox />}
                            label="Instacal"
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>
                  </ListItem>
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
        )}
      </ToolbarTitle>
      {/* <Spacer /> */}
      <Grid container display="block" textAlign="right">
        {numSelected === 1 ? (
          <Grid>
            {/* <Tooltip title="View">
              <IconButton
                aria-label="View"
                size="large"
                component={NavLink}
                to="/events/view-event"
              >
                <VisibilityIcon />
              </IconButton>
            </Tooltip> */}
            <Tooltip title="Preview">
              <IconButton
                aria-label="Preview"
                size="large"
                component={NavLink}
                to="/book"
              >
                <Launch />
              </IconButton>
            </Tooltip>
            <Tooltip title="Copy link to event">
              <IconButton
                aria-label="Copy link to event"
                size="large"
                component={NavLink}
                to="/book"
              >
                <LinkIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton aria-label="Delete" size="large">
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="">
              <IconButton
                id="basic-button"
                aria-controls={openM ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openM ? "true" : undefined}
                onClick={handleClickM}
              >
                <MoreHoriz />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorElM}
                open={openM}
                onClose={handleCloseM}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem component={Link} to="/calendar/edit-event-type">
                  <ListItemIcon style={{ minWidth: "30px" }}>
                    <EditIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Edit</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleCloseM}>
                  <ListItemIcon style={{ minWidth: "30px" }}>
                    <ContentCopy fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Duplicate</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleCloseM}>
                  <ListItemIcon style={{ minWidth: "30px" }}>
                    <Code fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Embed</ListItemText>
                </MenuItem>
                {/* <MenuItem onClick={handleCloseM}>
                  <ListItemIcon style={{ minWidth: "30px" }}>
                    <DeleteIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Delete</ListItemText>
                </MenuItem> */}
              </Menu>
            </Tooltip>
          </Grid>
        ) : numSelected > 1 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete" size="large">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Paper>
            {/* <IconButton
              aria-label="Edit"
              size="large"
              aria-owns={anchorEl1 ? "simple-menu-showing" : undefined}
              aria-haspopup="true"
              onClick={handleClickShowing}
              variant="contained"
              color="secondary"
              sx={{ mr: 2, display: { xs: "inline", md: "none" } }}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="simple-menu-showing"
              anchorEl={anchorEl1}
              open={Boolean(anchorEl1)}
              onClose={handleCloseShowing}
            >
              <MenuItem>All</MenuItem>
              <MenuItem>Upcoming</MenuItem>
              <MenuItem>Ongoing</MenuItem>
              <MenuItem>Paused</MenuItem>
              <MenuItem>Completed</MenuItem>
            </Menu> */}
            <Button
              aria-owns={anchorEl ? "simple-menu" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              variant="contained"
              color="secondary"
            >
              View
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={(e) => {
                  doChangeProps("List");
                  setViewStyle("List");
                  setAnchorEl(null);
                }}
              >
                List
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  doChangeProps("Card");
                  setViewStyle("Card");
                  setAnchorEl(null);
                }}
              >
                Card
              </MenuItem>
            </Menu>
          </Paper>
        )}
      </Grid>
    </Toolbar>
  );
};

function EnhancedTable() {
  const [viewStyle, setViewStyle] = useState("List");
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("customer");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      // setViewStyle("Card");
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div>
      <Paper>
        <EnhancedTableToolbar
          numSelected={selected.length}
          doChangeProps={setViewStyle}
        />
      </Paper>
      {/* <ViewMenu doChangeProps={setViewStyle} /> */}
      {viewStyle === "List" ? (
        <Paper>
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size={"medium"}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={`${row.id}-${index}`}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                            onClick={(event) => handleClick(event, row.id)}
                          />
                        </TableCell>
                        <TableCell component="th" id={labelId} scope="row">
                          <Customer>
                            {/* <Avatar>{row.customerAvatar}</Avatar> */}
                            {/* <Card sx={{ height: 90, maxWidth: 148 }}>
                              <CardMedia
                                component="img"
                                height="194"
                                image="https://d2xqcdy5rl17k2.cloudfront.net/images/default-landing-banner-min.png"
                                alt="Paella dish"
                              />
                            </Card> */}
                            <Box ml={3}>
                              <Typography
                                variant="h6"
                                gutterBottom
                                display="inline"
                              >
                                {row.customer}
                              </Typography>
                              <br />
                              {row.customerEmail}
                            </Box>
                          </Customer>
                        </TableCell>
                        <TableCell>{row.id}</TableCell>
                        {/* <TableCell>
                          Wed 20 Dec 2023
                          <br />
                          11:00 AM - 12:00 PM IST
                        </TableCell> */}
                        <TableCell align="left">{row.date}</TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={7} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <Box m={1} pt={3} style={{ margin: "0" }}>
          <Grid container spacing={6}>
            <Grid item xs={12} lg={6} xl={3}>
              <Project
                title="Anna Walley"
                description="Nam pretium turpis et arcu. Duis arcu tortor, suscipit eget, imperdiet nec, imperdiet iaculis, ipsum. Sed aliquam ultrices mauris."
                chip={<Chip label="Ongoing" color="warning" />}
                image="https://d2xqcdy5rl17k2.cloudfront.net/images/default-landing-banner-min.png"
              />
            </Grid>
            <Grid item xs={12} lg={6} xl={3}>
              <Project
                title="Anna Walley"
                description="Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum."
                chip={<Chip label="Upcoming" color="success" />}
                image="https://d2xqcdy5rl17k2.cloudfront.net/images/default-landing-banner-min.png"
              />
            </Grid>
            <Grid item xs={12} lg={6} xl={3}>
              <Project
                title="Anna Walley"
                description="Nam pretium turpis et arcu. Duis arcu tortor, suscipit eget, imperdiet nec, imperdiet iaculis, ipsum. Sed aliquam ultrices mauris."
                chip={<Chip label="Upcoming" color="success" />}
                image="https://d2xqcdy5rl17k2.cloudfront.net/images/default-landing-banner-min.png"
              />
            </Grid>
            <Grid item xs={12} lg={6} xl={3}>
              <Project
                title="Anna Walley"
                description="Curabitur ligula sapien, tincidunt non, euismod vitae, posuere imperdiet, leo. Maecenas malesuada. Praesent congue erat at massa."
                chip={<Chip label="Upcoming" color="success" />}
                image="https://d2xqcdy5rl17k2.cloudfront.net/images/default-landing-banner-min.png"
              />
            </Grid>
          </Grid>
        </Box>
      )}
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}

const AvatarGroup = styled(MuiAvatarGroup)`
  margin-left: ${(props) => props.theme.spacing(2)};
`;

const Project = ({ image, title, description, chip }) => {
  return (
    <Paper>
      <Card>
        <Checkbox
          value="remember"
          color="primary"
          style={{ position: "relative", top: "0px", left: "0px" }}
        />
        <CardContent>
          <Box>
            <Typography variant="h4" gutterBottom display="inline">
              30 Min Meeting
            </Typography>
            <br />
            Description
          </Box>
          <Typography mb={4} mt={4} color="textSecondary" component="p">
            Url: cal.com/equitechsoftwares/30min
            <br />
            Duration: 30m
          </Typography>
        </CardContent>
      </Card>
    </Paper>
  );
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CloudUpload = styled(MuiCloudUpload)(spacing);

const CenteredContent = styled.div`
  text-align: center;
`;

const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 0 auto ${(props) => props.theme.spacing(2)};
`;

function EventTypes() {
  const [open, setOpen] = useState(false);
  const [anchorElM, setAnchorElM] = React.useState(null);
  const openM = Boolean(anchorElM);
  const handleClickM = (event) => {
    setAnchorElM(event.currentTarget);
  };
  const handleCloseM = () => {
    setAnchorElM(null);
  };
  return (
    <React.Fragment>
      <Helmet title="Event Types" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Event Types
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/calendar/dashboard">
              Dashboard
            </Link>
            <Typography>Events Types</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            <Button
              variant="contained"
              color="primary"
              // component={NavLink}
              // to="/calendar/add-event-type"
              id="basic-button"
              aria-controls={openM ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openM ? "true" : undefined}
              onClick={handleClickM}
            >
              <AddIcon />
              New Event Type
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorElM}
              open={openM}
              onClose={handleCloseM}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem disabled="true">
                <ListItemText variant="h5">Create Event On</ListItemText>
              </MenuItem>
              <MenuItem component={Link} to="/calendar/add-event-type">
                {/* <ListItemIcon style={{ minWidth: "30px" }}>
                  <EditIcon fontSize="small" />
                </ListItemIcon> */}
                <ListItemText>Shabbir Bakh</ListItemText>
              </MenuItem>
              <MenuItem component={Link} to="/calendar/add-event-type">
                {/* <ListItemIcon style={{ minWidth: "30px" }}>
                  <ContentCopy fontSize="small" />
                </ListItemIcon> */}
                <ListItemText>Instacal</ListItemText>
              </MenuItem>
            </Menu>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default EventTypes;
