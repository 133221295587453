import React from "react";
import async from "./components/Async";

// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import WidgetLayout from "./layouts/Widget";
import CalendarLayout from "./layouts/Calendar";
import EventsLayout from "./layouts/Events";
import DocLayout from "./layouts/Doc";
import SettingLayout from "./layouts/Setting";
import SpaceLayout from "./layouts/Space";
import PresentationLayout from "./layouts/Presentation";
import CalendarBookLayout from "./layouts/CalendarBook";

// Booking Page
import Book from "./pages/pages/Calendar/Book";
import BookingConfirm from "./pages/pages/Calendar/BookingConfirm";

import Preview from "./pages/pages/Calendar/Preview";
import TeamPreview from "./pages/pages/Calendar/TeamPreview";

import Form from "./pages/pages/Calendar/Form";
import Reporting from "./pages/pages/Calendar/RoutingForms/Reporting";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Auth components
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ResetPassword from "./pages/auth/ResetPassword";
import SignUpOtp from "./pages/auth/SignUpOtp";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";

// Components
import Accordion from "./pages/components/Accordion";
import Alerts from "./pages/components/Alerts";
import Avatars from "./pages/components/Avatars";
import Badges from "./pages/components/Badges";
import Buttons from "./pages/components/Buttons";
import Cards from "./pages/components/Cards";
import Chips from "./pages/components/Chips";
import Dialogs from "./pages/components/Dialogs";
import Lists from "./pages/components/Lists";
import Menus from "./pages/components/Menus";
import Pagination from "./pages/components/Pagination";
import Progress from "./pages/components/Progress";
import Snackbars from "./pages/components/Snackbars";
import Tooltips from "./pages/components/Tooltips";

// Form components
import SelectionCtrls from "./pages/forms/SelectionControls";
import Selects from "./pages/forms/Selects";
import TextFields from "./pages/forms/TextFields";

// Icon components
import MaterialIcons from "./pages/icons/MaterialIcons";

// Page components
import Blank from "./pages/pages/Blank";
import InvoiceDetails from "./pages/pages/InvoiceDetails";
import InvoiceList from "./pages/pages/InvoiceList";
import Orders from "./pages/pages/Orders";
import Pricing from "./pages/pages/Pricing";
import Settings from "./pages/pages/Settings";
import Projects from "./pages/pages/Projects";
import Events from "./pages/pages/Events/Events";
import EventAdd from "./pages/pages/Events/EventAdd";
import EventView from "./pages/pages/Events/EventView";
import Chat from "./pages/pages/Chat";
import EventTypes from "./pages/pages/Calendar/EventTypes/EventTypes";
import EventTypeAdd from "./pages/pages/Calendar/EventTypes/EventTypeAdd";
import EventTypeEdit from "./pages/pages/Calendar/EventTypes/EventTypeEdit";
import CalendarBookings from "./pages/pages/Calendar/Bookings/Bookings";
import MyAvailabilityGlobal from "./pages/pages/Global/Availability/MyAvailability";
import MyAvailabilityAddGlobal from "./pages/pages/Global/Availability/MyAvailabilityAdd";
// import TeamAvailability from "./pages/pages/Global/Availability/TeamAvailability";
import MyAvailability from "./pages/pages/Calendar/Availability/MyAvailability";
import MyAvailabilityAdd from "./pages/pages/Calendar/Availability/MyAvailabilityAdd";
import TeamAvailability from "./pages/pages/Calendar/Availability/TeamAvailability";
import Groups from "./pages/pages/Global/Groups/Groups";
import Teams from "./pages/pages/Calendar/Teams/Teams";
import TeamEdit from "./pages/pages/Calendar/Teams/TeamEdit";
import RoutingForms from "./pages/pages/Calendar/RoutingForms/RoutingForms";
import RoutingFormEdit from "./pages/pages/Calendar/RoutingForms/RoutingFormEdit";
import CalendarDashboard from "./pages/pages/Calendar/Dashboard/Dashboard.js";
import WidgetDashboard from "./pages/pages/Widget/Dashboard/Dashboard.js";
import ChannelConnect from "./pages/pages/Widget/ChannelConnect/ChannelConnect.js";
import ChannelConnectAdd from "./pages/pages/Widget/ChannelConnect/ChannelConnectAdd.js";
import QRConnect from "./pages/pages/Widget/QRConnect/QRConnect.js";
import QRConnectAdd from "./pages/pages/Widget/QRConnect/QRConnectAdd.js";
import Visitors from "./pages/pages/Widget/Visitors/Visitors.js";
import IncomingRequests from "./pages/pages/Widget/IncomingRequests/IncomingRequests.js";
import LeadOverview from "./pages/pages/Widget/LeadOverview/LeadOverview.js";
import WidgetSettings from "./pages/pages/Widget/Settings/Settings.js";
import WidgetSupportTickets from "./pages/pages/Widget/SupportTickets/SupportTickets.js";
import WidgetSupportTicketAdd from "./pages/pages/Widget/SupportTickets/SupportTicketAdd.js";

// Table components
import SimpleTable from "./pages/tables/SimpleTable";
import AdvancedTable from "./pages/tables/AdvancedTable";

// Documentation
import Welcome from "./pages/docs/Welcome";
import GettingStarted from "./pages/docs/GettingStarted";
import Routing from "./pages/docs/Routing";
import Auth0 from "./pages/docs/auth/Auth0";
import Cognito from "./pages/docs/auth/Cognito";
import Firebase from "./pages/docs/auth/Firebase";
import JWT from "./pages/docs/auth/JWT";
import Guards from "./pages/docs/Guards";
import EnvironmentVariables from "./pages/docs/EnvironmentVariables";
import Deployment from "./pages/docs/Deployment";
import Theming from "./pages/docs/Theming";
import APICalls from "./pages/docs/APICalls";
import Redux from "./pages/docs/Redux";
import Internationalization from "./pages/docs/Internationalization";
import ESLintAndPrettier from "./pages/docs/ESLintAndPrettier";
import Support from "./pages/docs/Support";
import Changelog from "./pages/docs/Changelog";

// Landing
import Landing from "./pages/presentation/Landing";

// Protected routes
import ProtectedPage from "./pages/protected/ProtectedPage";
import ChangePassword from "./pages/pages/ChangePassword";
import Users from "./pages/pages/Users";
import Role from "./pages/pages/Roles/Roles";
import RoleAdd from "./pages/pages/Roles/RoleAdd";
import Spaces from "./pages/pages/Spaces";
import SpaceApps from "./pages/pages/SpaceApps/SpaceApps";

// Dashboard components
const Default = async(() => import("./pages/dashboards/Default"));
const Analytics = async(() => import("./pages/dashboards/Analytics"));
const SaaS = async(() => import("./pages/dashboards/SaaS"));

// Form components
const Pickers = async(() => import("./pages/forms/Pickers"));
const Editors = async(() => import("./pages/forms/Editors"));
const Formik = async(() => import("./pages/forms/Formik"));

// Icon components
const FeatherIcons = async(() => import("./pages/icons/FeatherIcons"));
const Profile = async(() => import("./pages/pages/Profile"));
const BusinessSettings = async(() => import("./pages/pages/BusinessSettings"));
const Tasks = async(() => import("./pages/pages/Tasks"));
const Calendar = async(() => import("./pages/pages/Calendar"));

// Table components
const DataGrid = async(() => import("./pages/tables/DataGrid"));

// Chart components
const Chartjs = async(() => import("./pages/charts/Chartjs"));
const ApexCharts = async(() => import("./pages/charts/ApexCharts"));

// Maps components
const GoogleMaps = async(() => import("./pages/maps/GoogleMaps"));
const VectorMaps = async(() => import("./pages/maps/VectorMaps"));

const routes = [
  {
    path: "/",
    element: <PresentationLayout />,
    children: [
      {
        path: "",
        element: <Landing />,
      },
    ],
  },
  {
    path: "/book",
    element: <CalendarBookLayout />,
    children: [
      {
        path: "",
        element: <Book />,
      },
    ],
  },
  {
    path: "/preview",
    element: <CalendarBookLayout />,
    children: [
      {
        path: "",
        element: <Preview />,
      },
    ],
  },
  {
    path: "/team-preview",
    element: <CalendarBookLayout />,
    children: [
      {
        path: "",
        element: <TeamPreview />,
      },
    ],
  },
  {
    path: "/book/confirm",
    element: <CalendarBookLayout />,
    children: [
      {
        path: "",
        element: <BookingConfirm />,
      },
    ],
  },
  {
    path: "/form",
    element: <CalendarBookLayout />,
    children: [
      {
        path: "",
        element: <Form />,
      },
    ],
  },
  {
    path: "/reporting",
    element: <CalendarBookLayout />,
    children: [
      {
        path: "",
        element: <Reporting />,
      },
    ],
  },
  {
    path: "/widget",
    element: <CalendarBookLayout />,
    children: [
      {
        path: "incoming-requests",
        element: <IncomingRequests />,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "signup-otp",
        element: <SignUpOtp />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "widget",
    element: <WidgetLayout />,
    children: [
      {
        path: "dashboard",
        element: <WidgetDashboard />,
      },
      {
        path: "channel-connect",
        element: <ChannelConnect />,
      },
      {
        path: "add-channel-connect",
        element: <ChannelConnectAdd />,
      },
      {
        path: "qr-connect",
        element: <QRConnect />,
      },
      {
        path: "add-qr-connect",
        element: <QRConnectAdd />,
      },
      {
        path: "visitors",
        element: <Visitors />,
      },
      // {
      //   path: "incoming-requests",
      //   element: <IncomingRequests />,
      // },
      {
        path: "lead-overview",
        element: <LeadOverview />,
      },
      {
        path: "settings",
        element: <WidgetSettings />,
      },
      {
        path: "support-tickets",
        element: <WidgetSupportTickets />,
      },
      {
        path: "add-support-ticket",
        element: <WidgetSupportTicketAdd />,
      },
    ],
  },
  {
    path: "calendar",
    element: <CalendarLayout />,
    children: [
      {
        path: "dashboard",
        element: <CalendarDashboard />,
      },
      {
        path: "event-types",
        element: <EventTypes />,
      },
      {
        path: "add-event-type",
        element: <EventTypeAdd />,
      },
      {
        path: "edit-event-type",
        element: <EventTypeEdit />,
      },
      {
        path: "bookings",
        element: <CalendarBookings />,
      },
      {
        path: "my-availability",
        element: <MyAvailability />,
      },
      {
        path: "add-my-availability",
        element: <MyAvailabilityAdd />,
      },
      {
        path: "team-availability",
        element: <TeamAvailability />,
      },
      {
        path: "teams",
        element: <Teams />,
      },
      {
        path: "edit-team",
        element: <TeamEdit />,
      },
      {
        path: "routing-forms",
        element: <RoutingForms />,
      },
      {
        path: "edit-routing-form",
        element: <RoutingFormEdit />,
      },
    ],
  },
  {
    path: "events",
    element: <EventsLayout />,
    children: [
      {
        path: "dashboard",
        element: <Default />,
      },
      {
        path: "events",
        element: <Events />,
      },
      {
        path: "add-event",
        element: <EventAdd />,
      },
      {
        path: "view-event",
        element: <EventView />,
      },
    ],
  },
  {
    path: "settings",
    element: <SettingLayout />,
    children: [
      {
        path: "business-settings",
        element: <BusinessSettings />,
      },
      {
        path: "my-availability",
        element: <MyAvailabilityGlobal />,
      },
      {
        path: "add-my-availability",
        element: <MyAvailabilityAddGlobal />,
      },
      // {
      //   path: "team-availability",
      //   element: <TeamAvailability />,
      // },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "change-password",
        element: <ChangePassword />,
      },
      {
        path: "users",
        element: <Users />,
      },
      {
        path: "groups",
        element: <Groups />,
      },
      {
        path: "roles",
        element: <Role />,
      },
      {
        path: "add-role",
        element: <RoleAdd />,
      },
      {
        path: "spaces",
        element: <Spaces />,
      },
    ],
  },
  {
    path: "spaces",
    element: <SpaceLayout />,
    children: [
      {
        path: "space-apps",
        element: <SpaceApps />,
      },
    ],
  },
];

export default routes;
