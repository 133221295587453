import React, { useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Copy, Search as SearchIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import { darken } from "polished";
import { Link } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import "../../../Styles/Custom.css";

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Checkbox,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  Menu,
  MenuItem,
  InputBase,
  DialogActions,
  TextField,
  CardContent,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card as MuiCard,
  CardMedia as MuiCardMedia,
  CardActions,
  AvatarGroup as MuiAvatarGroup,
  FormControlLabel,
} from "@mui/material";
import { green, orange } from "@mui/material/colors";
import {
  Add as AddIcon,
  ArrowBack as ArrowBackIcon,
  FilterList,
  Download,
  Mail,
  ContentCopy,
  Visibility,
} from "@mui/icons-material";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Chip = styled(MuiChip)`
  ${spacing};

  background: ${(props) => props.paid && green[500]};
  background: ${(props) => props.sent && orange[700]};
  color: ${(props) =>
    (props.paid || props.sent) && props.theme.palette.common.white};
`;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const ToolbarTitle = styled.div`
  min-width: 200px;
`;

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;

const checkboxStyle = `
  position:absolute;
  bottom:0;
`;

const Customer = styled.div`
  display: flex;
  align-items: center;
`;

const CardMedia = styled(MuiCardMedia)`
  height: 220px;
`;

const label = { inputProps: { "aria-label": "Checkbox demo" } };

function createData(
  customer,
  customerEmail,
  customerAvatar,
  status,
  amount,
  id,
  date
) {
  return { customer, customerEmail, customerAvatar, status, amount, id, date };
}

const rows = [
  createData(
    "Anna Walley",
    "anna@walley.com",
    "A",
    0,
    "$530,00",
    "000112",
    "2023-01-02"
  ),
  createData(
    "Doris Fritz",
    "doris@fritz.com",
    "D",
    2,
    "$209,00",
    "000114",
    "2023-02-13"
  ),
  createData(
    "Edward Adkins",
    "edward@adkins.com",
    "E",
    2,
    "$535,00",
    "000117",
    "2023-03-04"
  ),
  createData(
    "Edwin Baker",
    "edwin@baker.com",
    "E",
    2,
    "$678,00",
    "000115",
    "2023-02-17"
  ),
  createData(
    "Gordon Workman",
    "gordan@workman.com",
    "G",
    0,
    "$314,00",
    "000119",
    "2023-03-28"
  ),
  createData(
    "Jo Avery",
    "jo@avery.com",
    "J",
    0,
    "$864,00",
    "000113",
    "2023-01-23"
  ),
  createData(
    "Leigha Hyden",
    "leigha@hyden.com",
    "L",
    2,
    "$341,00",
    "000118",
    "2023-03-14"
  ),
  createData(
    "Maureen Gagnon",
    "maureen@gagnon.com",
    "M",
    2,
    "$781,00",
    "000116",
    "2023-02-22"
  ),
  createData(
    "Maxine Thompson",
    "maxine@thompson.com",
    "M",
    0,
    "$273,00",
    "000121",
    "2023-05-31"
  ),
  createData(
    "Shawn Waddell",
    "shawn@waddell.com",
    "S",
    0,
    "$713,00",
    "000120",
    "2023-04-25"
  ),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  { id: "customer", alignment: "left", label: "Participants" },
  { id: "status", alignment: "left", label: "Email ID" },
  { id: "id", alignment: "right", label: "Added Via" },
  { id: "date", alignment: "left", label: "Registration Status" },
];

const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const EnhancedTableToolbar = (props) => {
  const [viewStyle, setViewStyle] = React.useState("List");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { numSelected } = props;

  const Input = styled(InputBase)`
    color: inherit;
    width: 100%;

    > input {
      color: ${(props) => props.theme.header.search.color};
      padding-top: ${(props) => props.theme.spacing(2.5)};
      padding-right: ${(props) => props.theme.spacing(2.5)};
      padding-bottom: ${(props) => props.theme.spacing(2.5)};
      padding-left: ${(props) => props.theme.spacing(12)};
      width: 160px;
    }
  `;

  const { t } = useTranslation();

  const Search = styled.div`
    border-radius: 2px;
    background-color: ${(props) => props.theme.header.background};
    display: none;
    position: relative;
    width: 100%;

    &:hover {
      background-color: ${(props) =>
        darken(0.05, props.theme.header.background)};
    }

    ${(props) => props.theme.breakpoints.up("md")} {
      display: block;
    }
  `;

  const SearchIconWrapper = styled.div`
    width: 50px;
    height: 100%;
    position: absolute;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 22px;
      height: 22px;
    }
  `;

  const { doChangeProps } = props;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    setAnchorEl(null);
  };

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item style={{ margin: "15px", paddingBottom: "10px" }}>
          <Typography variant="h3" gutterBottom display="inline">
            Add Filter
          </Typography>
          <Typography> Add filter to the attendees list</Typography>
        </Grid>
      </Grid>
      <List>
        <ListItem>
          <Grid container spacing={6}>
            <Grid item md={12} className="checkboxPadding">
              <FormLabel id="demo-radio-buttons-group-label">
                Added via
              </FormLabel>
              <FormGroup>
                <FormControlLabel control={<Checkbox />} label="Registration" />
                <FormControlLabel control={<Checkbox />} label="Invitation" />
              </FormGroup>
            </Grid>
          </Grid>
        </ListItem>
      </List>
      <List>
        <ListItem>
          <Grid container spacing={6}>
            <Grid item md={12} className="checkboxPadding">
              <FormLabel id="demo-radio-buttons-group-label">
                Registration status
              </FormLabel>
              <FormGroup>
                <FormControlLabel control={<Checkbox />} label="Registered" />
                <FormControlLabel control={<Checkbox />} label="Pending" />
              </FormGroup>
            </Grid>
          </Grid>
        </ListItem>
      </List>
      <DialogActions>
        <Button onClick={toggleDrawer("right", false)} color="primary">
          Cancel
        </Button>
        <Button onClick={toggleDrawer("right", false)} color="primary">
          Done
        </Button>
      </DialogActions>
    </Box>
  );

  return (
    <Toolbar>
      <ToolbarTitle>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Grid container>
            {viewStyle === "Card" ? (
              <Grid md={2}>
                <Checkbox />
              </Grid>
            ) : (
              <div></div>
            )}
            <Grid md={10}>
              <Typography>
                <Search>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <Input placeholder={t("Search Attendees")} />
                </Search>
              </Typography>
            </Grid>
          </Grid>
        )}
      </ToolbarTitle>
      <Spacer />
      <Grid container display="block" textAlign="right">
        {numSelected === 1 ? (
          <Grid>
            <Tooltip title="Send Confirmation Email">
              <IconButton aria-label="Send Confirmation Email" size="large">
                <Mail />
              </IconButton>
            </Tooltip>
            <Tooltip title="Copy Invitation Link">
              <IconButton aria-label="Copy Invitation Link" size="large">
                <ContentCopy />
              </IconButton>
            </Tooltip>
            <Tooltip title="Preview">
              <IconButton aria-label="Preview" size="large">
                <Visibility />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit">
              <IconButton aria-label="Edit" size="large">
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton aria-label="Delete" size="large">
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        ) : numSelected > 1 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete" size="large">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Paper>
            <Tooltip title="Filter">
              <IconButton
                aria-label="Filter"
                size="large"
                onClick={toggleDrawer("right", true)}
              >
                <FilterList />
              </IconButton>
              <Drawer
                anchor={"right"}
                open={state["right"]}
                onClose={toggleDrawer("right", false)}
              >
                {list("right")}
              </Drawer>
            </Tooltip>
            <Tooltip title="Download List">
              <IconButton
                aria-label="Download List"
                size="large"
                style={{ marginRight: "10px" }}
              >
                <Download />
              </IconButton>
            </Tooltip>
            <Button
              aria-owns={anchorEl ? "simple-menu" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              variant="contained"
              color="secondary"
            >
              View
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={(e) => {
                  doChangeProps("List");
                  setViewStyle("List");
                  setAnchorEl(null);
                }}
              >
                List
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  doChangeProps("Card");
                  setViewStyle("Card");
                  setAnchorEl(null);
                }}
              >
                Card
              </MenuItem>
            </Menu>
          </Paper>
        )}
      </Grid>
    </Toolbar>
  );
};

function EnhancedTable() {
  const [viewStyle, setViewStyle] = useState("List");
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("customer");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      // setViewStyle("Card");
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div>
      <Paper>
        <EnhancedTableToolbar
          numSelected={selected.length}
          doChangeProps={setViewStyle}
        />
      </Paper>
      {/* <ViewMenu doChangeProps={setViewStyle} /> */}
      {viewStyle === "List" ? (
        <Paper>
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size={"medium"}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={`${row.id}-${index}`}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                            onClick={(event) => handleClick(event, row.id)}
                          />
                        </TableCell>
                        <TableCell component="th" id={labelId} scope="row">
                          <Customer>
                            <Box ml={3}> {row.customer} </Box>
                          </Customer>
                        </TableCell>
                        <TableCell>{row.customerEmail}</TableCell>
                        <TableCell>Invitation - Email Sent</TableCell>
                        <TableCell>
                          {row.status === 0 && (
                            <Chip
                              size="small"
                              mr={1}
                              mb={1}
                              label="Not Registered"
                              sent={+true}
                            />
                          )}
                          {row.status === 2 && (
                            <Chip
                              size="small"
                              mr={1}
                              mb={1}
                              label="Registered"
                              paid={+true}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={7} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <Box m={1} pt={3} style={{ margin: "0" }}>
          <Grid container spacing={6}>
            <Grid item xs={12} lg={6} xl={3}>
              <Project
                title="Anna Walley"
                description="Nam pretium turpis et arcu. Duis arcu tortor, suscipit eget, imperdiet nec, imperdiet iaculis, ipsum. Sed aliquam ultrices mauris."
                chip={<Chip label="Not Registered" color="warning" />}
                image="/static/img/avatars/avatar-1.jpg"
              />
            </Grid>
            <Grid item xs={12} lg={6} xl={3}>
              <Project
                title="Anna Walley"
                description="Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum."
                chip={<Chip label="Registered" color="success" />}
                image="/static/img/avatars/avatar-1.jpg"
              />
            </Grid>
            <Grid item xs={12} lg={6} xl={3}>
              <Project
                title="Anna Walley"
                description="Nam pretium turpis et arcu. Duis arcu tortor, suscipit eget, imperdiet nec, imperdiet iaculis, ipsum. Sed aliquam ultrices mauris."
                chip={<Chip label="Registered" color="success" />}
                image="/static/img/avatars/avatar-1.jpg"
              />
            </Grid>
            <Grid item xs={12} lg={6} xl={3}>
              <Project
                title="Anna Walley"
                description="Curabitur ligula sapien, tincidunt non, euismod vitae, posuere imperdiet, leo. Maecenas malesuada. Praesent congue erat at massa."
                chip={<Chip label="Registered" color="success" />}
                image="/static/img/avatars/avatar-1.jpg"
              />
            </Grid>
          </Grid>
        </Box>
      )}
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}

const AvatarGroup = styled(MuiAvatarGroup)`
  margin-left: ${(props) => props.theme.spacing(2)};
`;

const Project = ({ image, title, description, chip }) => {
  return (
    <Paper>
      <Card>
        <Checkbox
          value="remember"
          color="primary"
          style={{ position: "relative", top: "0px", left: "0px" }}
        />
        <CardContent>
          <Typography gutterBottom variant="h4" component="h2">
            {title}
          </Typography>
          <Typography gutterBottom component="p">
            amit@gmail.com
          </Typography>
          <Typography mb={4} color="textSecondary" component="p">
            Invitation - Email Sent
          </Typography>
          {chip}
        </CardContent>
      </Card>
    </Paper>
  );
};

function People() {
  const [open, setOpen] = useState(false);
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
    <React.Fragment>
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Attendees
          </Typography>
          <Typography> Manage your attendees from here </Typography>
        </Grid>
        <Grid item>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpen(true)}
            >
              <AddIcon />
              New Attendee
            </Button>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
      <Paper mt={4}>
        <div>
          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title" mb={0}>
              Add New Attendee
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Add attendees to the attendee list. For a seamless one-click
                registration/entry for your attendees, please fill all mandatory
                form fields.
              </DialogContentText>
              <br />
              <Grid container spacing={6}>
                <Grid item md={6}>
                  <TextField
                    id="username"
                    label="First Name"
                    defaultValue=""
                    variant="outlined"
                    fullWidth
                    my={2}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    id="username"
                    label="Last Name"
                    defaultValue=""
                    variant="outlined"
                    fullWidth
                    my={2}
                  />
                </Grid>
                <Grid item md={12}>
                  <TextField
                    id="username"
                    label="Email Id"
                    defaultValue=""
                    variant="outlined"
                    fullWidth
                    my={2}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    id="username"
                    label="Job Title"
                    defaultValue=""
                    variant="outlined"
                    fullWidth
                    my={2}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    id="username"
                    label="Organization"
                    defaultValue=""
                    variant="outlined"
                    fullWidth
                    my={2}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    id="username"
                    label="City"
                    defaultValue=""
                    variant="outlined"
                    fullWidth
                    my={2}
                  />
                </Grid>
                <Grid item md={6}>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        country
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={age}
                        label="Age"
                        onChange={handleChange}
                      >
                        <MenuItem value={10}>Canada</MenuItem>
                        <MenuItem value={20}>Usa</MenuItem>
                        <MenuItem value={30}>India</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <FormControl>
                    {/* <FormLabel id="demo-radio-buttons-group-label">
                      Gender
                    </FormLabel> */}
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      name="radio-buttons-group"
                    >
                      <FormControlLabel
                        value="female"
                        control={<Radio />}
                        label="Register attendee"
                      />
                      <FormControlLabel
                        value="male"
                        control={<Radio />}
                        label="Invite Attendee"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item md={12} className="checkboxPadding">
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Send invitation email with unique access link"
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen(false)} color="primary">
                Cancel
              </Button>
              <Button onClick={() => setOpen(false)} color="primary">
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Paper>
    </React.Fragment>
  );
}

export default People;
