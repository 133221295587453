import React, { useState } from "react";
import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link, NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import "../../../../Styles/Custom.css";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Tabs from "@mui/material/Tabs";
import dayjs from "dayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  Copy,
  Delete,
  PlusCircle,
  Search as SearchIcon,
  Trash,
} from "react-feather";
import { darken } from "polished";
import { useTranslation } from "react-i18next";
import Autocomplete from "@mui/material/Autocomplete";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Switch from "@mui/material/Switch";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import Menu from "@mui/material/Menu";
import MenuList from "@mui/material/MenuList";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ContentCut from "@mui/icons-material/ContentCut";
import ContentCopy from "@mui/icons-material/ContentCopy";
import ContentPaste from "@mui/icons-material/ContentPaste";
import Cloud from "@mui/icons-material/Cloud";
import ListItemButton from "@mui/material/ListItemButton";
import InboxIcon from "@mui/icons-material/Inbox";
import DraftsIcon from "@mui/icons-material/Drafts";

import {
  Avatar,
  Box,
  Grid,
  CardActions,
  CardContent,
  Card as MuiCard,
  CardHeader as MuiCardHeader,
  Breadcrumbs as MuiBreadcrumbs,
  Paper as MuiPaper,
  Divider as MuiDivider,
  TextField as MuiTextField,
  DialogActions,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  InputBase,
  MenuItem,
  Checkbox,
  Paper,
  IconButton,
} from "@mui/material";

import {
  AccessTime,
  ModeEditOutline,
  Language,
  Videocam,
  Delete as DeleteIcon,
  DeleteOutline,
  Edit as EditIcon,
  EditOutlined,
  Add,
} from "@mui/icons-material";
import { PersonAdd } from "@mui/icons-material";

import { tableCellClasses } from "@mui/material/TableCell";

import {
  Add as AddIcon,
  ArrowBack as ArrowBackIcon,
  Padding,
  Remove,
  StarBorder as StarIcon,
} from "@mui/icons-material";

import { color, spacing } from "@mui/system";
import { padding } from "polished";
import { TimePicker } from "@mui/x-date-pickers";

const ChatContainer = styled(Grid)`
  width: 100%;
  height: 50vh;
  height: 100%;
`;

const ChatSidebar = styled(Grid)`
  border-right: 1px solid ${(props) => props.theme.palette.divider};
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

const Header = styled.div`
  padding: ${(props) => props.theme.spacing(6)} 0;
`;

const stepsHorizontal = [
  "Select campaign settings",
  "Create an ad group",
  "Create an ad",
];

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

// Data
let id = 0;
function createData(name, calories, fat, carbs, protein) {
  id += 1;
  return { id, name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = [
  { label: "America/New York GMT -4:00", year: 1994 },
  { label: "America/New York GMT -4:00", year: 1972 },
  { label: "America/New York GMT -4:00", year: 1974 },
  { label: "America/New York GMT -4:00", year: 2008 },
  { label: "America/New York GMT -4:00", year: 1974 },
  { label: "America/New York GMT -4:00", year: 1974 },
  { label: "America/New York GMT -4:00", year: 1974 },
  { label: "America/New York GMT -4:00", year: 1974 },
  { label: "America/New York GMT -4:00", year: 1974 },
  { label: "America/New York GMT -4:00", year: 1974 },
  { label: "America/New York GMT -4:00", year: 1974 },
  { label: "America/New York GMT -4:00", year: 1974 },
  { label: "America/New York GMT -4:00", year: 1974 },
];
function TableRows({ rows, tableRowRemove, onValUpdate }) {
  return rows.map((rowsData, index) => {
    var opt = "Email";
    const { name, email, profile } = rowsData;
    return (
      <tr key={index}>
        <td>
          <Grid container spacing={6}>
            <Grid item md={2}></Grid>
            <Grid md={10}>
              <Grid container item>
                <Grid
                  item
                  md={2}
                  style={{ marginBottom: "15px", marginTop: "15px" }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    {/* <InputLabel htmlFor="max-width">Before Event</InputLabel> */}
                    <Select
                      autoFocus
                      value="false"
                      // label="maxWidth"
                      inputProps={{
                        name: "max-width",
                        id: "max-width",
                      }}
                    >
                      <MenuItem value={false}>12:00 am</MenuItem>
                      <MenuItem value="xs">12:15 am</MenuItem>
                      <MenuItem value="sm">12:30 am</MenuItem>
                      <MenuItem value="md">12:45 am</MenuItem>
                      <MenuItem value="lg">1:00 am</MenuItem>
                      <MenuItem value="xl">1:15 am</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  md={1}
                  style={{
                    marginBottom: "15px",
                    marginTop: "15px",
                    textAlign: "center",
                  }}
                >
                  <Remove style={{ position: "relative", top: "15px" }} />
                </Grid>
                <Grid
                  item
                  md={2}
                  style={{ marginBottom: "15px", marginTop: "15px" }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    {/* <InputLabel htmlFor="max-width">Before Event</InputLabel> */}
                    <Select
                      autoFocus
                      value="false"
                      // label="maxWidth"
                      inputProps={{
                        name: "max-width",
                        id: "max-width",
                      }}
                    >
                      <MenuItem value={false}>12:00 am</MenuItem>
                      <MenuItem value="xs">12:15 am</MenuItem>
                      <MenuItem value="sm">12:30 am</MenuItem>
                      <MenuItem value="md">12:45 am</MenuItem>
                      <MenuItem value="lg">1:00 am</MenuItem>
                      <MenuItem value="xl">1:15 am</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  md={1}
                  style={{
                    marginBottom: "15px",
                    marginTop: "15px",
                    textAlign: "center",
                  }}
                >
                  <DeleteIcon
                    onClick={() => tableRowRemove(index)}
                    style={{ position: "relative", top: "15px" }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </td>
      </tr>
    );
  });
}
var i = false;
function Table1() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [showAddGuest, setShowAddGuest] = useState("false");
  const [IsSunday, setIsSunday] = useState("true");
  const handleSundayChange = (event) => {
    setIsSunday(JSON.stringify(event.target.checked));
  };
  const [rows, initRow] = useState([]);
  const addRowTable = () => {
    if (showAddGuest == "true") {
      const data = {
        name: "",
      };
      initRow([...rows, data]);
    }
    setShowAddGuest("true");
  };
  const tableRowRemove = (index) => {
    const dataRow = [...rows];
    dataRow.splice(index, 1);
    initRow(dataRow);
  };
  const onValUpdate = (i, event) => {
    const { name, value } = event.target;
    const data = [...rows];
    data[i][name] = value;
    initRow(data);
  };
  if (i == false) {
    addRowTable();
    i = true;
  }
  return (
    <>
      <Grid container spacing={6}>
        <Grid item md={2} style={{ marginTop: "20px" }}>
          <FormGroup>
            <FormControlLabel
              control={<Switch defaultChecked onChange={handleSundayChange} />}
              label="Sunday"
            />
          </FormGroup>
        </Grid>
        {IsSunday == "true" ? (
          <Grid md={10} style={{ marginTop: "20px" }}>
            <Grid container>
              <Grid
                item
                md={2}
                style={{ marginBottom: "15px", marginTop: "15px" }}
              >
                <FormControl sx={{ width: "100%" }}>
                  {/* <InputLabel htmlFor="max-width">Before Event</InputLabel> */}
                  <Select
                    autoFocus
                    value="false"
                    // label="maxWidth"
                    inputProps={{
                      name: "max-width",
                      id: "max-width",
                    }}
                  >
                    <MenuItem value={false}>12:00 am</MenuItem>
                    <MenuItem value="xs">12:15 am</MenuItem>
                    <MenuItem value="sm">12:30 am</MenuItem>
                    <MenuItem value="md">12:45 am</MenuItem>
                    <MenuItem value="lg">1:00 am</MenuItem>
                    <MenuItem value="xl">1:15 am</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                md={1}
                style={{
                  marginBottom: "15px",
                  marginTop: "15px",
                  textAlign: "center",
                }}
              >
                <Remove style={{ position: "relative", top: "15px" }} />
              </Grid>
              <Grid
                item
                md={2}
                style={{ marginBottom: "15px", marginTop: "15px" }}
              >
                <FormControl sx={{ width: "100%" }}>
                  {/* <InputLabel htmlFor="max-width">Before Event</InputLabel> */}
                  <Select
                    autoFocus
                    value="false"
                    // label="maxWidth"
                    inputProps={{
                      name: "max-width",
                      id: "max-width",
                    }}
                  >
                    <MenuItem value={false}>12:00 am</MenuItem>
                    <MenuItem value="xs">12:15 am</MenuItem>
                    <MenuItem value="sm">12:30 am</MenuItem>
                    <MenuItem value="md">12:45 am</MenuItem>
                    <MenuItem value="lg">1:00 am</MenuItem>
                    <MenuItem value="xl">1:15 am</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                md={1}
                style={{
                  marginBottom: "15px",
                  marginTop: "15px",
                  textAlign: "center",
                }}
              >
                <AddIcon
                  style={{ position: "relative", top: "15px" }}
                  //onClick={addRowTable}
                  onClick={(e) => {
                    addRowTable();
                    setShowAddGuest("true");
                  }}
                />
                <Copy
                  style={{
                    position: "relative",
                    top: "15px",
                    marginLeft: "15px",
                  }}
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                />
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <Card style={{ border: "1px solid #eee" }}>
                    <Grid
                      item
                      style={{
                        margin: "15px 15px 0 15px",
                        paddingBottom: "10px",
                      }}
                    >
                      <Typography variant="h5" gutterBottom display="inline">
                        Copy Times To
                      </Typography>
                    </Grid>
                    <List
                      sx={{
                        width: "100%",
                        bgcolor: "background.paper",
                      }}
                    >
                      <ListItem style={{ paddingTop: "0", paddingBottom: "0" }}>
                        <Grid container>
                          <Grid item md={12} className="checkboxPadding">
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox />}
                                label="Select All"
                              />
                            </FormGroup>
                          </Grid>
                        </Grid>
                      </ListItem>
                      <ListItem style={{ paddingTop: "0", paddingBottom: "0" }}>
                        <Grid container>
                          <Grid item md={12} className="checkboxPadding">
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox />}
                                label="Sunday"
                              />
                            </FormGroup>
                          </Grid>
                        </Grid>
                      </ListItem>
                      <ListItem style={{ paddingTop: "0", paddingBottom: "0" }}>
                        <Grid container>
                          <Grid item md={12} className="checkboxPadding">
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox />}
                                label="Monday"
                              />
                            </FormGroup>
                          </Grid>
                        </Grid>
                      </ListItem>
                      <ListItem style={{ paddingTop: "0", paddingBottom: "0" }}>
                        <Grid container>
                          <Grid item md={12} className="checkboxPadding">
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox />}
                                label="Tuesday"
                              />
                            </FormGroup>
                          </Grid>
                        </Grid>
                      </ListItem>
                      <ListItem style={{ paddingTop: "0", paddingBottom: "0" }}>
                        <Grid container>
                          <Grid item md={12} className="checkboxPadding">
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox />}
                                label="Wednesday"
                              />
                            </FormGroup>
                          </Grid>
                        </Grid>
                      </ListItem>
                      <ListItem style={{ paddingTop: "0", paddingBottom: "0" }}>
                        <Grid container>
                          <Grid item md={12} className="checkboxPadding">
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox />}
                                label="Thursday"
                              />
                            </FormGroup>
                          </Grid>
                        </Grid>
                      </ListItem>
                      <ListItem style={{ paddingTop: "0", paddingBottom: "0" }}>
                        <Grid container>
                          <Grid item md={12} className="checkboxPadding">
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox />}
                                label="Friday"
                              />
                            </FormGroup>
                          </Grid>
                        </Grid>
                      </ListItem>
                      <ListItem style={{ paddingTop: "0", paddingBottom: "0" }}>
                        <Grid container>
                          <Grid item md={12} className="checkboxPadding">
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox />}
                                label="Saturday"
                              />
                            </FormGroup>
                          </Grid>
                        </Grid>
                      </ListItem>
                    </List>
                  </Card>
                </Menu>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
      {showAddGuest == "true" ? (
        <table style={{ width: "100%" }}>
          <tbody>
            <TableRows
              rows={rows}
              tableRowRemove={tableRowRemove}
              onValUpdate={onValUpdate}
            />
          </tbody>
        </table>
      ) : (
        <></>
      )}
    </>
  );
}
function Table2() {
  const [showAddGuest, setShowAddGuest] = useState("false");
  const [IsSunday, setIsSunday] = useState("true");
  const handleSundayChange = (event) => {
    setIsSunday(JSON.stringify(event.target.checked));
  };
  const [rows, initRow] = useState([]);
  const addRowTable = () => {
    if (showAddGuest == "true") {
      const data = {
        name: "",
      };
      initRow([...rows, data]);
    }
    setShowAddGuest("true");
  };
  const tableRowRemove = (index) => {
    const dataRow = [...rows];
    dataRow.splice(index, 1);
    initRow(dataRow);
  };
  const onValUpdate = (i, event) => {
    const { name, value } = event.target;
    const data = [...rows];
    data[i][name] = value;
    initRow(data);
  };
  if (i == false) {
    addRowTable();
    i = true;
  }
  return (
    <>
      <Grid container spacing={6}>
        <Grid item md={2} style={{ marginTop: "20px" }}>
          <FormGroup>
            <FormControlLabel
              control={<Switch defaultChecked onChange={handleSundayChange} />}
              label="Monday"
            />
          </FormGroup>
        </Grid>
        {IsSunday == "true" ? (
          <Grid md={10} style={{ marginTop: "20px" }}>
            <Grid container>
              <Grid
                item
                md={2}
                style={{ marginBottom: "15px", marginTop: "15px" }}
              >
                <FormControl sx={{ width: "100%" }}>
                  {/* <InputLabel htmlFor="max-width">Before Event</InputLabel> */}
                  <Select
                    autoFocus
                    value="false"
                    // label="maxWidth"
                    inputProps={{
                      name: "max-width",
                      id: "max-width",
                    }}
                  >
                    <MenuItem value={false}>12:00 am</MenuItem>
                    <MenuItem value="xs">12:15 am</MenuItem>
                    <MenuItem value="sm">12:30 am</MenuItem>
                    <MenuItem value="md">12:45 am</MenuItem>
                    <MenuItem value="lg">1:00 am</MenuItem>
                    <MenuItem value="xl">1:15 am</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                md={1}
                style={{
                  marginBottom: "15px",
                  marginTop: "15px",
                  textAlign: "center",
                }}
              >
                <Remove style={{ position: "relative", top: "15px" }} />
              </Grid>
              <Grid
                item
                md={2}
                style={{ marginBottom: "15px", marginTop: "15px" }}
              >
                <FormControl sx={{ width: "100%" }}>
                  {/* <InputLabel htmlFor="max-width">Before Event</InputLabel> */}
                  <Select
                    autoFocus
                    value="false"
                    // label="maxWidth"
                    inputProps={{
                      name: "max-width",
                      id: "max-width",
                    }}
                  >
                    <MenuItem value={false}>12:00 am</MenuItem>
                    <MenuItem value="xs">12:15 am</MenuItem>
                    <MenuItem value="sm">12:30 am</MenuItem>
                    <MenuItem value="md">12:45 am</MenuItem>
                    <MenuItem value="lg">1:00 am</MenuItem>
                    <MenuItem value="xl">1:15 am</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                md={1}
                style={{
                  marginBottom: "15px",
                  marginTop: "15px",
                  textAlign: "center",
                }}
              >
                <AddIcon
                  style={{ position: "relative", top: "15px" }}
                  //onClick={addRowTable}
                  onClick={(e) => {
                    addRowTable();
                    setShowAddGuest("true");
                  }}
                />
                <Copy
                  style={{
                    position: "relative",
                    top: "15px",
                    marginLeft: "15px",
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
      {showAddGuest == "true" ? (
        <table style={{ width: "100%" }}>
          <tbody>
            <TableRows
              rows={rows}
              tableRowRemove={tableRowRemove}
              onValUpdate={onValUpdate}
            />
          </tbody>
        </table>
      ) : (
        <></>
      )}
    </>
  );
}
function Table3() {
  const [showAddGuest, setShowAddGuest] = useState("false");
  const [IsSunday, setIsSunday] = useState("true");
  const handleSundayChange = (event) => {
    setIsSunday(JSON.stringify(event.target.checked));
  };
  const [rows, initRow] = useState([]);
  const addRowTable = () => {
    if (showAddGuest == "true") {
      const data = {
        name: "",
      };
      initRow([...rows, data]);
    }
    setShowAddGuest("true");
  };
  const tableRowRemove = (index) => {
    const dataRow = [...rows];
    dataRow.splice(index, 1);
    initRow(dataRow);
  };
  const onValUpdate = (i, event) => {
    const { name, value } = event.target;
    const data = [...rows];
    data[i][name] = value;
    initRow(data);
  };
  if (i == false) {
    addRowTable();
    i = true;
  }
  return (
    <>
      <Grid container spacing={6}>
        <Grid item md={2} style={{ marginTop: "20px" }}>
          <FormGroup>
            <FormControlLabel
              control={<Switch defaultChecked onChange={handleSundayChange} />}
              label="Tuesday"
            />
          </FormGroup>
        </Grid>
        {IsSunday == "true" ? (
          <Grid md={10} style={{ marginTop: "20px" }}>
            <Grid container>
              <Grid
                item
                md={2}
                style={{ marginBottom: "15px", marginTop: "15px" }}
              >
                <FormControl sx={{ width: "100%" }}>
                  {/* <InputLabel htmlFor="max-width">Before Event</InputLabel> */}
                  <Select
                    autoFocus
                    value="false"
                    // label="maxWidth"
                    inputProps={{
                      name: "max-width",
                      id: "max-width",
                    }}
                  >
                    <MenuItem value={false}>12:00 am</MenuItem>
                    <MenuItem value="xs">12:15 am</MenuItem>
                    <MenuItem value="sm">12:30 am</MenuItem>
                    <MenuItem value="md">12:45 am</MenuItem>
                    <MenuItem value="lg">1:00 am</MenuItem>
                    <MenuItem value="xl">1:15 am</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                md={1}
                style={{
                  marginBottom: "15px",
                  marginTop: "15px",
                  textAlign: "center",
                }}
              >
                <Remove style={{ position: "relative", top: "15px" }} />
              </Grid>
              <Grid
                item
                md={2}
                style={{ marginBottom: "15px", marginTop: "15px" }}
              >
                <FormControl sx={{ width: "100%" }}>
                  {/* <InputLabel htmlFor="max-width">Before Event</InputLabel> */}
                  <Select
                    autoFocus
                    value="false"
                    // label="maxWidth"
                    inputProps={{
                      name: "max-width",
                      id: "max-width",
                    }}
                  >
                    <MenuItem value={false}>12:00 am</MenuItem>
                    <MenuItem value="xs">12:15 am</MenuItem>
                    <MenuItem value="sm">12:30 am</MenuItem>
                    <MenuItem value="md">12:45 am</MenuItem>
                    <MenuItem value="lg">1:00 am</MenuItem>
                    <MenuItem value="xl">1:15 am</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                md={1}
                style={{
                  marginBottom: "15px",
                  marginTop: "15px",
                  textAlign: "center",
                }}
              >
                <AddIcon
                  style={{ position: "relative", top: "15px" }}
                  //onClick={addRowTable}
                  onClick={(e) => {
                    addRowTable();
                    setShowAddGuest("true");
                  }}
                />
                <Copy
                  style={{
                    position: "relative",
                    top: "15px",
                    marginLeft: "15px",
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
      {showAddGuest == "true" ? (
        <table style={{ width: "100%" }}>
          <tbody>
            <TableRows
              rows={rows}
              tableRowRemove={tableRowRemove}
              onValUpdate={onValUpdate}
            />
          </tbody>
        </table>
      ) : (
        <></>
      )}
    </>
  );
}
function Table4() {
  const [showAddGuest, setShowAddGuest] = useState("false");
  const [IsSunday, setIsSunday] = useState("true");
  const handleSundayChange = (event) => {
    setIsSunday(JSON.stringify(event.target.checked));
  };
  const [rows, initRow] = useState([]);
  const addRowTable = () => {
    if (showAddGuest == "true") {
      const data = {
        name: "",
      };
      initRow([...rows, data]);
    }
    setShowAddGuest("true");
  };
  const tableRowRemove = (index) => {
    const dataRow = [...rows];
    dataRow.splice(index, 1);
    initRow(dataRow);
  };
  const onValUpdate = (i, event) => {
    const { name, value } = event.target;
    const data = [...rows];
    data[i][name] = value;
    initRow(data);
  };
  if (i == false) {
    addRowTable();
    i = true;
  }
  return (
    <>
      <Grid container spacing={6}>
        <Grid item md={2} style={{ marginTop: "20px" }}>
          <FormGroup>
            <FormControlLabel
              control={<Switch defaultChecked onChange={handleSundayChange} />}
              label="Wednesday"
            />
          </FormGroup>
        </Grid>
        {IsSunday == "true" ? (
          <Grid md={10} style={{ marginTop: "20px" }}>
            <Grid container>
              <Grid
                item
                md={2}
                style={{ marginBottom: "15px", marginTop: "15px" }}
              >
                <FormControl sx={{ width: "100%" }}>
                  {/* <InputLabel htmlFor="max-width">Before Event</InputLabel> */}
                  <Select
                    autoFocus
                    value="false"
                    // label="maxWidth"
                    inputProps={{
                      name: "max-width",
                      id: "max-width",
                    }}
                  >
                    <MenuItem value={false}>12:00 am</MenuItem>
                    <MenuItem value="xs">12:15 am</MenuItem>
                    <MenuItem value="sm">12:30 am</MenuItem>
                    <MenuItem value="md">12:45 am</MenuItem>
                    <MenuItem value="lg">1:00 am</MenuItem>
                    <MenuItem value="xl">1:15 am</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                md={1}
                style={{
                  marginBottom: "15px",
                  marginTop: "15px",
                  textAlign: "center",
                }}
              >
                <Remove style={{ position: "relative", top: "15px" }} />
              </Grid>
              <Grid
                item
                md={2}
                style={{ marginBottom: "15px", marginTop: "15px" }}
              >
                <FormControl sx={{ width: "100%" }}>
                  {/* <InputLabel htmlFor="max-width">Before Event</InputLabel> */}
                  <Select
                    autoFocus
                    value="false"
                    // label="maxWidth"
                    inputProps={{
                      name: "max-width",
                      id: "max-width",
                    }}
                  >
                    <MenuItem value={false}>12:00 am</MenuItem>
                    <MenuItem value="xs">12:15 am</MenuItem>
                    <MenuItem value="sm">12:30 am</MenuItem>
                    <MenuItem value="md">12:45 am</MenuItem>
                    <MenuItem value="lg">1:00 am</MenuItem>
                    <MenuItem value="xl">1:15 am</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                md={1}
                style={{
                  marginBottom: "15px",
                  marginTop: "15px",
                  textAlign: "center",
                }}
              >
                <AddIcon
                  style={{ position: "relative", top: "15px" }}
                  //onClick={addRowTable}
                  onClick={(e) => {
                    addRowTable();
                    setShowAddGuest("true");
                  }}
                />
                <Copy
                  style={{
                    position: "relative",
                    top: "15px",
                    marginLeft: "15px",
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
      {showAddGuest == "true" ? (
        <table style={{ width: "100%" }}>
          <tbody>
            <TableRows
              rows={rows}
              tableRowRemove={tableRowRemove}
              onValUpdate={onValUpdate}
            />
          </tbody>
        </table>
      ) : (
        <></>
      )}
    </>
  );
}
function Table5() {
  const [showAddGuest, setShowAddGuest] = useState("false");
  const [IsSunday, setIsSunday] = useState("true");
  const handleSundayChange = (event) => {
    setIsSunday(JSON.stringify(event.target.checked));
  };
  const [rows, initRow] = useState([]);
  const addRowTable = () => {
    if (showAddGuest == "true") {
      const data = {
        name: "",
      };
      initRow([...rows, data]);
    }
    setShowAddGuest("true");
  };
  const tableRowRemove = (index) => {
    const dataRow = [...rows];
    dataRow.splice(index, 1);
    initRow(dataRow);
  };
  const onValUpdate = (i, event) => {
    const { name, value } = event.target;
    const data = [...rows];
    data[i][name] = value;
    initRow(data);
  };
  if (i == false) {
    addRowTable();
    i = true;
  }
  return (
    <>
      <Grid container spacing={6}>
        <Grid item md={2} style={{ marginTop: "20px" }}>
          <FormGroup>
            <FormControlLabel
              control={<Switch defaultChecked onChange={handleSundayChange} />}
              label="Thursday"
            />
          </FormGroup>
        </Grid>
        {IsSunday == "true" ? (
          <Grid md={10} style={{ marginTop: "20px" }}>
            <Grid container>
              <Grid
                item
                md={2}
                style={{ marginBottom: "15px", marginTop: "15px" }}
              >
                <FormControl sx={{ width: "100%" }}>
                  {/* <InputLabel htmlFor="max-width">Before Event</InputLabel> */}
                  <Select
                    autoFocus
                    value="false"
                    // label="maxWidth"
                    inputProps={{
                      name: "max-width",
                      id: "max-width",
                    }}
                  >
                    <MenuItem value={false}>12:00 am</MenuItem>
                    <MenuItem value="xs">12:15 am</MenuItem>
                    <MenuItem value="sm">12:30 am</MenuItem>
                    <MenuItem value="md">12:45 am</MenuItem>
                    <MenuItem value="lg">1:00 am</MenuItem>
                    <MenuItem value="xl">1:15 am</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                md={1}
                style={{
                  marginBottom: "15px",
                  marginTop: "15px",
                  textAlign: "center",
                }}
              >
                <Remove style={{ position: "relative", top: "15px" }} />
              </Grid>
              <Grid
                item
                md={2}
                style={{ marginBottom: "15px", marginTop: "15px" }}
              >
                <FormControl sx={{ width: "100%" }}>
                  {/* <InputLabel htmlFor="max-width">Before Event</InputLabel> */}
                  <Select
                    autoFocus
                    value="false"
                    // label="maxWidth"
                    inputProps={{
                      name: "max-width",
                      id: "max-width",
                    }}
                  >
                    <MenuItem value={false}>12:00 am</MenuItem>
                    <MenuItem value="xs">12:15 am</MenuItem>
                    <MenuItem value="sm">12:30 am</MenuItem>
                    <MenuItem value="md">12:45 am</MenuItem>
                    <MenuItem value="lg">1:00 am</MenuItem>
                    <MenuItem value="xl">1:15 am</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                md={1}
                style={{
                  marginBottom: "15px",
                  marginTop: "15px",
                  textAlign: "center",
                }}
              >
                <AddIcon
                  style={{ position: "relative", top: "15px" }}
                  //onClick={addRowTable}
                  onClick={(e) => {
                    addRowTable();
                    setShowAddGuest("true");
                  }}
                />
                <Copy
                  style={{
                    position: "relative",
                    top: "15px",
                    marginLeft: "15px",
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
      {showAddGuest == "true" ? (
        <table style={{ width: "100%" }}>
          <tbody>
            <TableRows
              rows={rows}
              tableRowRemove={tableRowRemove}
              onValUpdate={onValUpdate}
            />
          </tbody>
        </table>
      ) : (
        <></>
      )}
    </>
  );
}
function Table6() {
  const [showAddGuest, setShowAddGuest] = useState("false");
  const [IsSunday, setIsSunday] = useState("true");
  const handleSundayChange = (event) => {
    setIsSunday(JSON.stringify(event.target.checked));
  };
  const [rows, initRow] = useState([]);
  const addRowTable = () => {
    if (showAddGuest == "true") {
      const data = {
        name: "",
      };
      initRow([...rows, data]);
    }
    setShowAddGuest("true");
  };
  const tableRowRemove = (index) => {
    const dataRow = [...rows];
    dataRow.splice(index, 1);
    initRow(dataRow);
  };
  const onValUpdate = (i, event) => {
    const { name, value } = event.target;
    const data = [...rows];
    data[i][name] = value;
    initRow(data);
  };
  if (i == false) {
    addRowTable();
    i = true;
  }
  return (
    <>
      <Grid container spacing={6}>
        <Grid item md={2} style={{ marginTop: "20px" }}>
          <FormGroup>
            <FormControlLabel
              control={<Switch defaultChecked onChange={handleSundayChange} />}
              label="Friday"
            />
          </FormGroup>
        </Grid>
        {IsSunday == "true" ? (
          <Grid md={10} style={{ marginTop: "20px" }}>
            <Grid container>
              <Grid
                item
                md={2}
                style={{ marginBottom: "15px", marginTop: "15px" }}
              >
                <FormControl sx={{ width: "100%" }}>
                  {/* <InputLabel htmlFor="max-width">Before Event</InputLabel> */}
                  <Select
                    autoFocus
                    value="false"
                    // label="maxWidth"
                    inputProps={{
                      name: "max-width",
                      id: "max-width",
                    }}
                  >
                    <MenuItem value={false}>12:00 am</MenuItem>
                    <MenuItem value="xs">12:15 am</MenuItem>
                    <MenuItem value="sm">12:30 am</MenuItem>
                    <MenuItem value="md">12:45 am</MenuItem>
                    <MenuItem value="lg">1:00 am</MenuItem>
                    <MenuItem value="xl">1:15 am</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                md={1}
                style={{
                  marginBottom: "15px",
                  marginTop: "15px",
                  textAlign: "center",
                }}
              >
                <Remove style={{ position: "relative", top: "15px" }} />
              </Grid>
              <Grid
                item
                md={2}
                style={{ marginBottom: "15px", marginTop: "15px" }}
              >
                <FormControl sx={{ width: "100%" }}>
                  {/* <InputLabel htmlFor="max-width">Before Event</InputLabel> */}
                  <Select
                    autoFocus
                    value="false"
                    // label="maxWidth"
                    inputProps={{
                      name: "max-width",
                      id: "max-width",
                    }}
                  >
                    <MenuItem value={false}>12:00 am</MenuItem>
                    <MenuItem value="xs">12:15 am</MenuItem>
                    <MenuItem value="sm">12:30 am</MenuItem>
                    <MenuItem value="md">12:45 am</MenuItem>
                    <MenuItem value="lg">1:00 am</MenuItem>
                    <MenuItem value="xl">1:15 am</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                md={1}
                style={{
                  marginBottom: "15px",
                  marginTop: "15px",
                  textAlign: "center",
                }}
              >
                <AddIcon
                  style={{ position: "relative", top: "15px" }}
                  //onClick={addRowTable}
                  onClick={(e) => {
                    addRowTable();
                    setShowAddGuest("true");
                  }}
                />
                <Copy
                  style={{
                    position: "relative",
                    top: "15px",
                    marginLeft: "15px",
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
      {showAddGuest == "true" ? (
        <table style={{ width: "100%" }}>
          <tbody>
            <TableRows
              rows={rows}
              tableRowRemove={tableRowRemove}
              onValUpdate={onValUpdate}
            />
          </tbody>
        </table>
      ) : (
        <></>
      )}
    </>
  );
}
function Table7() {
  const [showAddGuest, setShowAddGuest] = useState("false");
  const [IsSunday, setIsSunday] = useState("true");
  const handleSundayChange = (event) => {
    setIsSunday(JSON.stringify(event.target.checked));
  };
  const [rows, initRow] = useState([]);
  const addRowTable = () => {
    if (showAddGuest == "true") {
      const data = {
        name: "",
      };
      initRow([...rows, data]);
    }
    setShowAddGuest("true");
  };
  const tableRowRemove = (index) => {
    const dataRow = [...rows];
    dataRow.splice(index, 1);
    initRow(dataRow);
  };
  const onValUpdate = (i, event) => {
    const { name, value } = event.target;
    const data = [...rows];
    data[i][name] = value;
    initRow(data);
  };
  if (i == false) {
    addRowTable();
    i = true;
  }
  return (
    <>
      <Grid container spacing={6}>
        <Grid item md={2} style={{ marginTop: "20px" }}>
          <FormGroup>
            <FormControlLabel
              control={<Switch defaultChecked onChange={handleSundayChange} />}
              label="Saturday"
            />
          </FormGroup>
        </Grid>
        {IsSunday == "true" ? (
          <Grid md={10} style={{ marginTop: "20px" }}>
            <Grid container>
              <Grid
                item
                md={2}
                style={{ marginBottom: "15px", marginTop: "15px" }}
              >
                <FormControl sx={{ width: "100%" }}>
                  {/* <InputLabel htmlFor="max-width">Before Event</InputLabel> */}
                  <Select
                    autoFocus
                    value="false"
                    // label="maxWidth"
                    inputProps={{
                      name: "max-width",
                      id: "max-width",
                    }}
                  >
                    <MenuItem value={false}>12:00 am</MenuItem>
                    <MenuItem value="xs">12:15 am</MenuItem>
                    <MenuItem value="sm">12:30 am</MenuItem>
                    <MenuItem value="md">12:45 am</MenuItem>
                    <MenuItem value="lg">1:00 am</MenuItem>
                    <MenuItem value="xl">1:15 am</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                md={1}
                style={{
                  marginBottom: "15px",
                  marginTop: "15px",
                  textAlign: "center",
                }}
              >
                <Remove style={{ position: "relative", top: "15px" }} />
              </Grid>
              <Grid
                item
                md={2}
                style={{ marginBottom: "15px", marginTop: "15px" }}
              >
                <FormControl sx={{ width: "100%" }}>
                  {/* <InputLabel htmlFor="max-width">Before Event</InputLabel> */}
                  <Select
                    autoFocus
                    value="false"
                    // label="maxWidth"
                    inputProps={{
                      name: "max-width",
                      id: "max-width",
                    }}
                  >
                    <MenuItem value={false}>12:00 am</MenuItem>
                    <MenuItem value="xs">12:15 am</MenuItem>
                    <MenuItem value="sm">12:30 am</MenuItem>
                    <MenuItem value="md">12:45 am</MenuItem>
                    <MenuItem value="lg">1:00 am</MenuItem>
                    <MenuItem value="xl">1:15 am</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                md={1}
                style={{
                  marginBottom: "15px",
                  marginTop: "15px",
                  textAlign: "center",
                }}
              >
                <AddIcon
                  style={{ position: "relative", top: "15px" }}
                  //onClick={addRowTable}
                  onClick={(e) => {
                    addRowTable();
                    setShowAddGuest("true");
                  }}
                />
                <Copy
                  style={{
                    position: "relative",
                    top: "15px",
                    marginLeft: "15px",
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
      {showAddGuest == "true" ? (
        <table style={{ width: "100%" }}>
          <tbody>
            <TableRows
              rows={rows}
              tableRowRemove={tableRowRemove}
              onValUpdate={onValUpdate}
            />
          </tbody>
        </table>
      ) : (
        <></>
      )}
    </>
  );
}
function TableRowsOverride({ rows, tableRowRemove, onValUpdate }) {
  return rows.map((rowsData, index) => {
    var opt = "Email";
    const { name, email, profile } = rowsData;
    return (
      <tr key={index}>
        <td>
          <Grid container>
            <Grid md={12} style={{ marginTop: "10px" }}>
              <Grid container item>
                <Grid item md={5}>
                  <FormControl sx={{ width: "100%" }}>
                    {/* <InputLabel htmlFor="max-width">Before Event</InputLabel> */}
                    <Select
                      autoFocus
                      value="false"
                      // label="maxWidth"
                      inputProps={{
                        name: "max-width",
                        id: "max-width",
                      }}
                    >
                      <MenuItem value={false}>12:00 am</MenuItem>
                      <MenuItem value="xs">12:15 am</MenuItem>
                      <MenuItem value="sm">12:30 am</MenuItem>
                      <MenuItem value="md">12:45 am</MenuItem>
                      <MenuItem value="lg">1:00 am</MenuItem>
                      <MenuItem value="xl">1:15 am</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={1}>
                  <Remove style={{ position: "relative", top: "15px" }} />
                </Grid>
                <Grid item md={5}>
                  <FormControl sx={{ width: "100%" }}>
                    {/* <InputLabel htmlFor="max-width">Before Event</InputLabel> */}
                    <Select
                      autoFocus
                      value="false"
                      // label="maxWidth"
                      inputProps={{
                        name: "max-width",
                        id: "max-width",
                      }}
                    >
                      <MenuItem value={false}>12:00 am</MenuItem>
                      <MenuItem value="xs">12:15 am</MenuItem>
                      <MenuItem value="sm">12:30 am</MenuItem>
                      <MenuItem value="md">12:45 am</MenuItem>
                      <MenuItem value="lg">1:00 am</MenuItem>
                      <MenuItem value="xl">1:15 am</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={1}>
                  <DeleteIcon
                    onClick={() => tableRowRemove(index)}
                    style={{ position: "relative", top: "15px" }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </td>
      </tr>
    );
  });
}
function TableOverride() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [showAddGuest, setShowAddGuest] = useState("false");
  const [IsSunday, setIsSunday] = useState("true");
  const handleSundayChange = (event) => {
    setIsSunday(JSON.stringify(event.target.checked));
  };
  const [rows, initRow] = useState([]);
  const addRowTable = () => {
    if (showAddGuest == "true") {
      const data = {
        name: "",
      };
      initRow([...rows, data]);
    }
    setShowAddGuest("true");
  };
  const tableRowRemove = (index) => {
    const dataRow = [...rows];
    dataRow.splice(index, 1);
    initRow(dataRow);
  };
  const onValUpdate = (i, event) => {
    const { name, value } = event.target;
    const data = [...rows];
    data[i][name] = value;
    initRow(data);
  };
  if (i == false) {
    addRowTable();
    i = true;
  }
  return (
    <>
      <Grid container>
        {/* <Grid md={12}>
          <Typography variant="h7" gutterBottom>
            Which hours are you free?
          </Typography>
        </Grid> */}
        <Grid md={12} style={{ marginTop: "10px" }}>
          <Grid container>
            <Grid item md={5}>
              <FormControl sx={{ width: "100%" }}>
                {/* <InputLabel htmlFor="max-width">Before Event</InputLabel> */}
                <Select
                  autoFocus
                  value="false"
                  // label="maxWidth"
                  inputProps={{
                    name: "max-width",
                    id: "max-width",
                  }}
                >
                  <MenuItem value={false}>12:00 am</MenuItem>
                  <MenuItem value="xs">12:15 am</MenuItem>
                  <MenuItem value="sm">12:30 am</MenuItem>
                  <MenuItem value="md">12:45 am</MenuItem>
                  <MenuItem value="lg">1:00 am</MenuItem>
                  <MenuItem value="xl">1:15 am</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={1}>
              <Remove style={{ position: "relative", top: "15px" }} />
            </Grid>
            <Grid item md={5}>
              <FormControl sx={{ width: "100%" }}>
                {/* <InputLabel htmlFor="max-width">Before Event</InputLabel> */}
                <Select
                  autoFocus
                  value="false"
                  // label="maxWidth"
                  inputProps={{
                    name: "max-width",
                    id: "max-width",
                  }}
                >
                  <MenuItem value={false}>12:00 am</MenuItem>
                  <MenuItem value="xs">12:15 am</MenuItem>
                  <MenuItem value="sm">12:30 am</MenuItem>
                  <MenuItem value="md">12:45 am</MenuItem>
                  <MenuItem value="lg">1:00 am</MenuItem>
                  <MenuItem value="xl">1:15 am</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={1}>
              <AddIcon
                style={{ position: "relative", top: "15px" }}
                //onClick={addRowTable}
                onClick={(e) => {
                  addRowTable();
                  setShowAddGuest("true");
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {showAddGuest == "true" ? (
        <table style={{ width: "100%" }}>
          <tbody>
            <TableRowsOverride
              rows={rows}
              tableRowRemove={tableRowRemove}
              onValUpdate={onValUpdate}
            />
          </tbody>
        </table>
      ) : (
        <></>
      )}
    </>
  );
}

function MonthView() {
  const [isUnavailable, setisUnavailable] = useState(false);
  const [value, setValue] = React.useState(dayjs("2022-04-17"));
  const [tabValue, setTabValue] = React.useState("1");
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [showAddGuest, setShowAddGuest] = useState(false);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleChangeUnavailable = (event, newValue) => {
    console.log(newValue);
    setisUnavailable(newValue);
  };

  const Search = styled.div`
    border-radius: 2px;
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
    position: relative;
    width: 100%;

    &:hover {
      background-color: ${(props) =>
        darken(0.05, props.theme.header.background)};
    }
  `;
  const SearchIconWrapper = styled.div`
    width: 50px;
    height: 100%;
    position: absolute;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 22px;
      height: 22px;
    }
  `;
  const Input = styled(InputBase)`
    color: inherit;
    width: 100%;
    > input {
      color: ${(props) => props.theme.header.search.color};
      padding-top: ${(props) => props.theme.spacing(2.5)};
      padding-right: ${(props) => props.theme.spacing(2.5)};
      padding-bottom: ${(props) => props.theme.spacing(2.5)};
      padding-left: ${(props) => props.theme.spacing(12)};
    }
  `;

  const { t } = useTranslation();

  return (
    <ChatContainer container component={Card}>
      <ChatSidebar item xs={12} md={6} lg={6}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DateCalendar", "DateCalendar"]}>
            <DemoItem label="">
              <DateCalendar
                value={value}
                onChange={(newValue) => setValue(newValue)}
                style={{ width: "100%" }}
              />
            </DemoItem>
          </DemoContainer>
        </LocalizationProvider>
      </ChatSidebar>
      <ChatSidebar item xs={12} md={6} lg={6} style={{ border: "0" }}>
        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        >
          <ListItem alignItems="flex-start">
            <Grid justifyContent="space-between" container spacing={10}>
              <Grid item>
                <Grid container>
                  <Grid md={12}>
                    <Typography variant="h7" gutterBottom>
                      Which hours are you free?
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {isUnavailable == false ? (
                <Grid item style={{ paddingTop: "0" }}>
                  <TableOverride />
                </Grid>
              ) : (
                <Grid item style={{ paddingTop: "0" }}>
                  <TextField
                    id="username"
                    defaultValue=""
                    placeholder="Working Hours"
                    variant="outlined"
                    fullWidth
                    my={4}
                    disabled
                    value="Unavailable all day"
                  />
                </Grid>
              )}
              <Grid item style={{ paddingTop: "10px" }}>
                <FormGroup>
                  <FormControlLabel
                    control={<Switch />}
                    label="Mark unavailable (All day)"
                    onChange={handleChangeUnavailable}
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </ListItem>
        </List>
      </ChatSidebar>
    </ChatContainer>
  );
}

const EventEntryForm = (props) => {
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };
  const { t } = useTranslation();
  return (
    <Card mb={6}>
      <CardContent>
        {/* <Typography variant="h6" gutterBottom>
          Basic Information
        </Typography> */}

        <Grid container spacing={6}>
          <Grid item md={6}>
            <TextField
              id="username"
              label="Name"
              defaultValue=""
              placeholder="Working Hours"
              variant="outlined"
              fullWidth
              my={4}
            />
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item md={6}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={top100Films}
              renderInput={(params) => (
                <TextField {...params} label="Timezone" />
              )}
            />
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={6}>
          <Grid item md={12}>
            <Box style={{ marginTop: "15px", marginBottom: "15px" }}>
              <Grid item md={12}>
                <ListItem alignItems="flex-start" disablePadding>
                  <Grid container>
                    <Grid md={6}>
                      <Typography variant="subtitle1">
                        Set Availability
                        <Typography
                          color="success"
                          style={{ paddingLeft: "12px !important" }}
                        >
                          <small
                            style={{
                              position: "relative",
                              bottom: "4px",
                              paddingLeft: "2px",
                            }}
                          >
                            Set when you are available so that you can be booked
                          </small>
                        </Typography>
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItem>
              </Grid>
              <div>
                <Table1 />
                <Table2 />
                <Table3 />
                <Table4 />
                <Table5 />
                <Table6 />
                <Table7 />
              </div>
              <br />
              <Grid item md={6}>
                <ListItem alignItems="flex-start" disablePadding>
                  <Grid container>
                    <Grid md={8}>
                      <Typography variant="subtitle1">
                        Date overrides
                        <Typography
                          color="success"
                          style={{ paddingLeft: "12px !important" }}
                        >
                          <small
                            style={{
                              position: "relative",
                              bottom: "4px",
                              paddingLeft: "2px",
                            }}
                          >
                            Add dates when your availability changes from your
                            daily hours.
                          </small>
                        </Typography>
                      </Typography>
                    </Grid>
                    <Grid md={4}>
                      <Button
                        variant="outlined"
                        color="primary"
                        style={{ float: "right" }}
                        onClick={() => setOpen(true)}
                      >
                        <AddIcon />
                        Add an override
                      </Button>
                    </Grid>
                  </Grid>
                </ListItem>
              </Grid>
              <Grid>
                <Box>
                  <Grid
                    item
                    md={6}
                    style={{
                      padding: "15px",
                      border: "1px solid #c4c4c4",
                      marginBottom: "15px",
                    }}
                  >
                    <ListItem alignItems="flex-start" disablePadding>
                      <Grid container>
                        <Grid md={10}>
                          <Typography variant="subtitle1">
                            Friday, March 29
                            <Typography
                              color="success"
                              style={{ paddingLeft: "12px !important" }}
                            >
                              <small
                                style={{
                                  position: "relative",
                                  bottom: "4px",
                                  paddingLeft: "2px",
                                }}
                              >
                                9:00 AM - 5:00 PM
                              </small>
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid md={2}>
                          <DeleteIcon style={{ float: "right" }} />
                          <EditIcon style={{ float: "right" }} />
                        </Grid>
                      </Grid>
                    </ListItem>
                  </Grid>
                </Box>
                <Box>
                  <Grid
                    item
                    md={6}
                    style={{
                      padding: "15px",
                      border: "1px solid #c4c4c4",
                      marginBottom: "15px",
                    }}
                  >
                    <ListItem alignItems="flex-start" disablePadding>
                      <Grid container>
                        <Grid md={10}>
                          <Typography variant="subtitle1">
                            Friday, March 29
                            <Typography
                              color="success"
                              style={{ paddingLeft: "12px !important" }}
                            >
                              <small
                                style={{
                                  position: "relative",
                                  bottom: "4px",
                                  paddingLeft: "2px",
                                }}
                              >
                                9:00 AM - 5:00 PM
                              </small>
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid md={2}>
                          <DeleteIcon style={{ float: "right" }} />
                          <EditIcon style={{ float: "right" }} />
                        </Grid>
                      </Grid>
                    </ListItem>
                  </Grid>
                </Box>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Button variant="contained" color="primary">
          Create Availability
        </Button>
      </CardContent>
      <div>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
        >
          <DialogTitle id="form-dialog-title">
            Select the dates to override
          </DialogTitle>
          <DialogContent>
            {/* <DialogContentText>
              To subscribe to this website, please enter your email address
              here. We will send updates occasionally.
            </DialogContentText> */}
            <Wrapper style={{ padding: "0" }}>
              <MonthView />
            </Wrapper>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="primary">
              Close
            </Button>
            <Button onClick={() => setOpen(false)} color="primary">
              Add Override
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Card>
  );
};

function MyAvailabilityAdd() {
  const [eventType, setEventType] = useState("0");
  return (
    <React.Fragment>
      <Helmet title="Add New Availability" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Add New Availability
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/spaces/space-apps">
              Dashboard
            </Link>
            <Link component={NavLink} to="/settings/my-availability">
              Availability
            </Link>
            <Typography>Add New Availability</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            <Button
              variant="contained"
              color="inherit"
              sx={{ mx: "0.5rem" }}
              component={Link}
              to="/settings/my-availability"
            >
              <ArrowBackIcon />
              Back
            </Button>
          </div>
        </Grid>
      </Grid>
      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EventEntryForm />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default MyAvailabilityAdd;
