import React, { useState } from "react";
import styled from "@emotion/styled";
import ListItem from "@mui/material/ListItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import "../../../../Styles/Custom.css";
import Switch from "@mui/material/Switch";
import InputAdornment from "@mui/material/InputAdornment";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Checkbox,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  Menu,
  MenuItem,
  InputBase,
  DialogActions,
  TextField,
  CardContent,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card as MuiCard,
  CardMedia as MuiCardMedia,
  CardActions,
  AvatarGroup as MuiAvatarGroup,
  FormControlLabel,
} from "@mui/material";
import { green, orange } from "@mui/material/colors";
import {
  Add as AddIcon,
  ArrowBack as ArrowBackIcon,
  FilterList,
  Download,
  Mail,
  ContentCopy,
  Visibility,
} from "@mui/icons-material";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import LanguageIcon from "@mui/icons-material/Language";
import { tableCellClasses } from "@mui/material/TableCell";
import { spacing } from "@mui/system";

function EventEntryTab() {
  const [maxWidth, setMaxWidth] = useState("sm");
  const handleMaxWidthChange = (event) => {
    setMaxWidth(event.target.value);
  };

  const [limitBookingFrequency, setlimitBookingFrequency] = useState("false");
  const handleLimitBookingFrequencyChange = (event) => {
    setlimitBookingFrequency(JSON.stringify(event.target.checked));
  };

  const [limitBookingDuration, setlimitBookingDuration] = useState("false");
  const handleLimitBookingDurationChange = (event) => {
    setlimitBookingDuration(JSON.stringify(event.target.checked));
  };

  const [limitFutureBookings, setlimitFutureBookings] = useState("false");
  const handleLimitFutureBookingsChange = (event) => {
    setlimitFutureBookings(JSON.stringify(event.target.checked));
  };

  const [limitByDate, setLimitByDate] = useState("true");
  const handleLimitByDateChange = (event) => {
    setLimitByDate(event.target.value);
  };

  const [offsetStartTimes, setOffsetStartTimes] = useState("false");
  const handleOffsetStartTimesChange = (event) => {
    setOffsetStartTimes(JSON.stringify(event.target.checked));
  };

  const [value, setValue] = React.useState([
    dayjs("2022-04-17"),
    dayjs("2022-04-21"),
  ]);

  return (
    <Grid justifyContent="space-between" container spacing={10}>
      <Grid item md={12}>
        <Typography variant="h3" gutterBottom display="inline">
          Recurring
        </Typography>
        <Typography>
          Configure what information should be captured during registration
        </Typography>
      </Grid>
      <Grid item md={12}>
        {/* <Typography variant="h6" gutterBottom>
            Basic Information
          </Typography> */}
        <Box style={{ marginTop: "15px", marginBottom: "15px" }}>
          <Grid item md={12}>
            <ListItem alignItems="flex-start" disablePadding>
              <Grid container>
                <Grid md={6}>
                  <Typography variant="subtitle1">
                    Limit future bookings
                    <Typography
                      color="success"
                      style={{ paddingLeft: "12px !important" }}
                    >
                      <small
                        style={{
                          position: "relative",
                          bottom: "4px",
                          paddingLeft: "2px",
                        }}
                      >
                        Limit how far in the future this event can be booked
                      </small>
                    </Typography>
                  </Typography>
                </Grid>
                <Grid md={6}>
                  <Switch
                    inputProps={{ "aria-label": "controlled" }}
                    style={{ float: "right" }}
                    onChange={handleLimitFutureBookingsChange}
                  />
                </Grid>
              </Grid>
            </ListItem>
            {/* <Switch inputProps={{ "aria-label": "controlled" }} />
            <ListItemText primary="Allow booker to select duration" /> */}
          </Grid>
          {limitFutureBookings == "true" ? (
            <Grid container spacing={6} style={{ marginTop: "15px" }}>
              <Grid item md={12}>
                <Grid container spacing={6}>
                  <Grid item md={2}>
                    <Typography style={{ position: "relative", top: "15px" }}>
                      Repeats every
                    </Typography>
                  </Grid>
                  <Grid item md={3}>
                    <TextField
                      id="username"
                      //   label="No of days"
                      defaultValue=""
                      variant="outlined"
                      fullWidth
                      value="1"
                      //   my={2}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <Select
                      style={{ width: "100%" }}
                      autoFocus
                      value={maxWidth}
                      onChange={handleMaxWidthChange}
                      //label="maxWidth"
                      inputProps={{
                        name: "max-width",
                        id: "max-width",
                      }}
                    >
                      <MenuItem value="xs">Year</MenuItem>
                      <MenuItem value="sm">Month</MenuItem>
                      <MenuItem value="l">Week</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={12}>
                <Grid container spacing={6}>
                  <Grid item md={2}>
                    <Typography style={{ position: "relative", top: "15px" }}>
                      For a maximum of
                    </Typography>
                  </Grid>
                  <Grid item md={3}>
                    <TextField
                      id="username"
                      //   label="No of days"
                      defaultValue=""
                      variant="outlined"
                      fullWidth
                      value="12"
                      //   my={2}
                    />
                  </Grid>
                  <Grid item md={2}>
                    <Typography style={{ position: "relative", top: "15px" }}>
                      events
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
        </Box>
        <Button variant="contained" color="primary">
          Save
        </Button>
      </Grid>
    </Grid>
  );
}

function Recurring() {
  return (
    <React.Fragment>
      <EventEntryTab />
    </React.Fragment>
  );
}

export default Recurring;
