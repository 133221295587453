import React, { useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Search as SearchIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import { darken } from "polished";
import { Link } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import Switch from "@mui/material/Switch";
import Select from "@mui/material/Select";
import FormGroup from "@mui/material/FormGroup";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { MuiColorInput } from "mui-color-input";
import InputAdornment from "@mui/material/InputAdornment";

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Checkbox,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  Menu,
  MenuItem,
  InputBase,
  DialogActions,
  TextField,
  CardContent,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card as MuiCard,
  CardMedia as MuiCardMedia,
  CardActions,
  AvatarGroup as MuiAvatarGroup,
  FormControlLabel,
} from "@mui/material";
import { green, orange } from "@mui/material/colors";
import {
  Add as AddIcon,
  ArrowBack as ArrowBackIcon,
} from "@mui/icons-material";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Chip = styled(MuiChip)`
  ${spacing};

  background: ${(props) => props.paid && green[500]};
  background: ${(props) => props.sent && orange[700]};
  color: ${(props) =>
    (props.paid || props.sent) && props.theme.palette.common.white};
`;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const ToolbarTitle = styled.div`
  min-width: 200px;
`;

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;

const checkboxStyle = `
  position:absolute;
  bottom:0;
`;

const Customer = styled.div`
  display: flex;
  align-items: center;
`;

const CardMedia = styled(MuiCardMedia)`
  height: 220px;
`;

const TaskWrapper = styled(Card)`
  border: 1px solid ${(props) => props.theme.palette.grey[300]};
  margin-bottom: ${(props) => props.theme.spacing(4)};
  cursor: grab;

  &:hover {
    background: ${(props) => props.theme.palette.background.default};
  }
`;

const TaskWrapperContent = styled(CardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)};
  }
`;

const TaskNotifications = styled.div`
  display: flex;
  position: absolute;
  bottom: ${(props) => props.theme.spacing(4)};
  right: ${(props) => props.theme.spacing(4)};
`;

function createData(
  customer,
  customerEmail,
  customerAvatar,
  status,
  amount,
  id,
  date
) {
  return { customer, customerEmail, customerAvatar, status, amount, id, date };
}

const rows = [
  createData(
    "Segment A",
    "anna@walley.com",
    "A",
    "Niaz Khan",
    "$530,00",
    "000112",
    "2023-01-02"
  ),
  createData(
    "Segment B",
    "doris@fritz.com",
    "D",
    "Niaz Khan",
    "$209,00",
    "000114",
    "2023-02-13"
  ),
  createData(
    "Segment C",
    "edward@adkins.com",
    "E",
    "Niaz Khan",
    "$535,00",
    "000117",
    "2023-03-04"
  ),
  createData(
    "Segment D",
    "edwin@baker.com",
    "E",
    "Niaz Khan",
    "$678,00",
    "000115",
    "2023-02-17"
  ),
  createData(
    "Segment E",
    "gordan@workman.com",
    "G",
    "Niaz Khan",
    "$314,00",
    "000119",
    "2023-03-28"
  ),
  createData(
    "Segment F",
    "jo@avery.com",
    "J",
    "Niaz Khan",
    "$864,00",
    "000113",
    "2023-01-23"
  ),
  createData(
    "Segment G",
    "leigha@hyden.com",
    "L",
    "Niaz Khan",
    "$341,00",
    "000118",
    "2023-03-14"
  ),
  createData(
    "Segment H",
    "maureen@gagnon.com",
    "M",
    "Niaz Khan",
    "$781,00",
    "000116",
    "2023-02-22"
  ),
  createData(
    "Segment I",
    "maxine@thompson.com",
    "M",
    "Niaz Khan",
    "$273,00",
    "000121",
    "2023-05-31"
  ),
  createData(
    "Segment J",
    "shawn@waddell.com",
    "S",
    "Niaz Khan",
    "$713,00",
    "000120",
    "2023-04-25"
  ),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  { id: "customer", alignment: "left", label: "Segment Name" },
  //{ id: "status", alignment: "left", label: "Users" },
  // { id: "id", alignment: "right", label: "User ID" },
  //   { id: "amount", alignment: "right", label: "Amount" },
  //   { id: "date", alignment: "left", label: "Joining Date" },
  //   { id: "actions", alignment: "right", label: "Actions" },
];

const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const EnhancedTableToolbar = (props) => {
  const [viewStyle, setViewStyle] = React.useState("List");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { numSelected } = props;

  const Input = styled(InputBase)`
    color: inherit;
    width: 100%;

    > input {
      color: ${(props) => props.theme.header.search.color};
      padding-top: ${(props) => props.theme.spacing(2.5)};
      padding-right: ${(props) => props.theme.spacing(2.5)};
      padding-bottom: ${(props) => props.theme.spacing(2.5)};
      padding-left: ${(props) => props.theme.spacing(12)};
      width: 160px;
    }
  `;

  const { t } = useTranslation();

  const Search = styled.div`
    border-radius: 2px;
    background-color: ${(props) => props.theme.header.background};
    display: none;
    position: relative;
    width: 100%;

    &:hover {
      background-color: ${(props) =>
        darken(0.05, props.theme.header.background)};
    }

    ${(props) => props.theme.breakpoints.up("md")} {
      display: block;
    }
  `;

  const SearchIconWrapper = styled.div`
    width: 50px;
    height: 100%;
    position: absolute;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 22px;
      height: 22px;
    }
  `;

  const { doChangeProps } = props;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    setAnchorEl(null);
  };

  return (
    <Toolbar style={{ paddingLeft: "0" }}>
      <ToolbarTitle>
        {numSelected > 0 ? (
          <Typography
            color="inherit"
            variant="subtitle1"
            style={{ paddingLeft: "12px" }}
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Grid container>
            {viewStyle === "Card" ? (
              <Grid md={2}>
                <Checkbox />
              </Grid>
            ) : (
              <div></div>
            )}
            <Grid md={10}>
              <Typography>
                <Search>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <Input placeholder={t("Search")} />
                </Search>
              </Typography>
            </Grid>
          </Grid>
        )}
      </ToolbarTitle>
      <Spacer />
      <Grid container display="block" textAlign="right">
        {numSelected === 1 ? (
          <Grid>
            <Tooltip title="Edit">
              <IconButton aria-label="Edit" size="large">
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton aria-label="Delete" size="large">
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        ) : numSelected > 1 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete" size="large">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Paper>
            <Button
              aria-owns={anchorEl ? "simple-menu" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              variant="contained"
              color="secondary"
            >
              View
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={(e) => {
                  doChangeProps("List");
                  setViewStyle("List");
                  setAnchorEl(null);
                }}
              >
                List
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  doChangeProps("Card");
                  setViewStyle("Card");
                  setAnchorEl(null);
                }}
              >
                Card
              </MenuItem>
            </Menu>
          </Paper>
        )}
      </Grid>
    </Toolbar>
  );
};

function EnhancedTable() {
  const [viewStyle, setViewStyle] = useState("List");
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("customer");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      // setViewStyle("Card");
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div>
      <Paper>
        <EnhancedTableToolbar
          numSelected={selected.length}
          doChangeProps={setViewStyle}
        />
      </Paper>
      {/* <ViewMenu doChangeProps={setViewStyle} /> */}
      {viewStyle === "List" ? (
        <Paper>
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size={"medium"}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={`${row.id}-${index}`}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                            onClick={(event) => handleClick(event, row.id)}
                          />
                        </TableCell>
                        <TableCell>{row.customer}</TableCell>
                        {/* <TableCell>{row.status}</TableCell> */}
                        {/* <TableCell align="right">
                          <IconButton aria-label="delete" size="large">
                            <ArchiveIcon />
                          </IconButton>
                          <IconButton
                            aria-label="details"
                            component={NavLink}
                              to="/invoices/detail"
                            size="large"
                          >
                            <EditIcon />
                          </IconButton>
                        </TableCell> */}
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={7} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <Box m={1} pt={3} style={{ margin: "0" }}>
          <Grid container spacing={6}>
            <Grid item xs={12} lg={6} xl={3}>
              <Project
                title="Anna Walley"
                description="Nam pretium turpis et arcu. Duis arcu tortor, suscipit eget, imperdiet nec, imperdiet iaculis, ipsum. Sed aliquam ultrices mauris."
                chip={<Chip label="Inactive" color="warning" />}
                image="/static/img/avatars/avatar-1.jpg"
              />
            </Grid>
            <Grid item xs={12} lg={6} xl={3}>
              <Project
                title="Anna Walley"
                description="Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum."
                chip={<Chip label="Active" color="success" />}
                image="/static/img/avatars/avatar-1.jpg"
              />
            </Grid>
            <Grid item xs={12} lg={6} xl={3}>
              <Project
                title="Anna Walley"
                description="Nam pretium turpis et arcu. Duis arcu tortor, suscipit eget, imperdiet nec, imperdiet iaculis, ipsum. Sed aliquam ultrices mauris."
                chip={<Chip label="Active" color="success" />}
                image="/static/img/avatars/avatar-1.jpg"
              />
            </Grid>
            <Grid item xs={12} lg={6} xl={3}>
              <Project
                title="Anna Walley"
                description="Curabitur ligula sapien, tincidunt non, euismod vitae, posuere imperdiet, leo. Maecenas malesuada. Praesent congue erat at massa."
                chip={<Chip label="Active" color="success" />}
                image="/static/img/avatars/avatar-1.jpg"
              />
            </Grid>
          </Grid>
        </Box>
      )}
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}

const AvatarGroup = styled(MuiAvatarGroup)`
  margin-left: ${(props) => props.theme.spacing(2)};
`;

const Project = ({ image, title, description, chip }) => {
  return (
    <Paper>
      <Card>
        <CardContent>
          <Typography gutterBottom variant="h4" component="h2">
            Group A
          </Typography>
          <Typography mb={4} color="textSecondary" component="p">
            Niaz Khan
          </Typography>
          {/* <AvatarGroup max={3}>
          <Avatar alt="Avatar" src="/static/img/avatars/avatar-1.jpg" />
          <Avatar alt="Avatar" src="/static/img/avatars/avatar-2.jpg" />
          <Avatar alt="Avatar" src="/static/img/avatars/avatar-3.jpg" />
        </AvatarGroup> */}
        </CardContent>
        {/* <CardActions>
        <Button size="small" color="primary">
          Share
        </Button>
        <Button size="small" color="primary">
          Learn More
        </Button>
      </CardActions> */}
      </Card>
    </Paper>
  );
};

const TaskTitle = styled(Typography)`
  font-weight: 600;
  font-size: 15px;
  margin-right: ${(props) => props.theme.spacing(10)};
`;

function TableRows1({ rows, tableRowRemove, onValUpdate }) {
  return rows.map((rowsData, index) => {
    var opt = "Email";
    const { name, email, profile } = rowsData;
    return (
      <React.Fragment>
        <Divider spacing={2} style={{ marginTop: "10px" }}>
          AND
        </Divider>
        <tr key={index}>
          <td>
            <Grid container>
              <Grid
                item
                md={3}
                style={{ marginTop: "10px", paddingRight: "15px" }}
              >
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    autoFocus
                    value="Custom Page"
                    inputProps={{
                      name: "max-width",
                      id: "max-width",
                    }}
                  >
                    <MenuItem value="Custom Page">Country</MenuItem>
                    <MenuItem value="External">Email</MenuItem>
                    <MenuItem value="Event Redirect">IP Address</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                md={3}
                style={{ marginTop: "10px", paddingRight: "15px" }}
              >
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    autoFocus
                    value="Custom Page"
                    inputProps={{
                      name: "max-width",
                      id: "max-width",
                    }}
                  >
                    <MenuItem value="Custom Page">Contains</MenuItem>
                    <MenuItem value="External Redirect">
                      Does Not Contains
                    </MenuItem>
                    <MenuItem value="Event Redirect">
                      Does Not End With
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={5}>
                <FormControl sx={{ width: "100%" }}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    // label="First Name"
                    placeholder=""
                    type="text"
                    fullWidth
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                md={1}
                style={{
                  marginTop: "10px",
                  textAlign: "right",
                }}
              >
                <DeleteIcon
                  onClick={() => tableRowRemove(index)}
                  style={{ position: "relative", top: "15px" }}
                />
              </Grid>
            </Grid>
          </td>
        </tr>
      </React.Fragment>
    );
  });
}
var noOfRows = 0;
function TableRows({ rows, tableRowRemove, onValUpdate }) {
  const customString =
    "Use field identifiers as variables for your custom event redirect (e.g. {First Name})";
  const [fallbackRouteType, setFallbackRouteType] = useState("Custom Page");
  const [eventRedirectValueType, setEventRedirectValueType] = useState("");

  const handleChange = (event) => {
    setFallbackRouteType(event.target.value);
  };

  const handleChange1 = (event) => {
    setEventRedirectValueType(event.target.value);
  };

  const [showAddGuest, setShowAddGuest] = useState("false");

  const [rows1, initRow] = useState([]);
  const addRowTable = () => {
    if (showAddGuest == "true") {
      const data = {
        name: "",
      };
      initRow([...rows1, data]);
    }
    setShowAddGuest("true");
    noOfRows++;
  };
  const tableRowRemove1 = (index) => {
    const dataRow = [...rows1];
    dataRow.splice(index, 1);
    initRow(dataRow);
    noOfRows--;
  };
  const onValUpdate1 = (i, event) => {
    const { name, value } = event.target;
    const data = [...rows1];
    data[i][name] = value;
    initRow(data);
  };

  return rows.map((rowsData, index) => {
    var opt = "Option " + (index + 1);
    const { name, email, profile } = rowsData;
    return (
      <tr key={index}>
        <td>
          <TaskWrapper mb={4}>
            <TaskWrapperContent>
              <TaskTitle
                variant="body1"
                gutterBottom
                style={{ marginBottom: "1em" }}
              >
                {/* Route {index + 1} */}
                <FormControl fullWidth my={2} variant="outlined">
                  <InputLabel htmlFor="max-width">
                    Apply To Rule Group
                  </InputLabel>
                  <Select autoFocus label="Bookable Appointments">
                    <MenuItem value="d">
                      All of the following conditions
                    </MenuItem>
                    <MenuItem value="xs">Any of the following rules</MenuItem>
                  </Select>
                </FormControl>
              </TaskTitle>
              <Divider />
              <Grid container>
                <Grid
                  item
                  md={3}
                  style={{ marginTop: "10px", paddingRight: "15px" }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      autoFocus
                      value="Custom Page"
                      inputProps={{
                        name: "max-width",
                        id: "max-width",
                      }}
                    >
                      <MenuItem value="Custom Page">Country</MenuItem>
                      <MenuItem value="External">Email</MenuItem>
                      <MenuItem value="Event Redirect">IP Address</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  md={3}
                  style={{ marginTop: "10px", paddingRight: "15px" }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      autoFocus
                      value={fallbackRouteType}
                      inputProps={{
                        name: "max-width",
                        id: "max-width",
                      }}
                      onChange={handleChange}
                    >
                      <MenuItem value="Custom Page">Contains</MenuItem>
                      <MenuItem value="External Redirect">
                        Does Not Contains
                      </MenuItem>
                      <MenuItem value="Event Redirect">
                        Does Not End With
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={5}>
                  <FormControl sx={{ width: "100%" }}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="name"
                      // label="First Name"
                      placeholder=""
                      type="text"
                      fullWidth
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  md={1}
                  style={{
                    marginTop: "10px",
                    textAlign: "right",
                  }}
                >
                  <DeleteIcon
                    onClick={() => tableRowRemove(index)}
                    style={{ position: "relative", top: "15px" }}
                  />
                </Grid>
              </Grid>
              {/* <Divider spacing={2} style={{ marginTop: "10px" }}>
                AND
              </Divider> */}
              {showAddGuest == "true" ? (
                <React.Fragment>
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <TableRows1
                        rows={rows1}
                        tableRowRemove={tableRowRemove1}
                        onValUpdate={onValUpdate1}
                      />
                    </tbody>
                  </table>
                </React.Fragment>
              ) : (
                <></>
              )}
              <Grid container>
                <Grid
                  item
                  md={2}
                  style={{ marginTop: "10px", marginRight: "10px" }}
                >
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={(e) => {
                      addRowTable();
                      setShowAddGuest("true");
                    }}
                    style={{ marginBottom: "20px" }}
                  >
                    <AddIcon />
                    AND
                  </Button>
                </Grid>
                <Grid
                  item
                  md={2}
                  style={{ marginTop: "10px", marginRight: "10px" }}
                >
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={(e) => {
                      addRowTable();
                      setShowAddGuest("true");
                    }}
                    style={{ marginBottom: "20px" }}
                  >
                    <AddIcon />
                    OR
                  </Button>
                </Grid>
              </Grid>
              <TaskNotifications style={{ top: "16px" }}>
                <DeleteIcon onClick={() => tableRowRemove(index)} />
              </TaskNotifications>
            </TaskWrapperContent>
          </TaskWrapper>
        </td>
      </tr>
    );
  });
}
var i = false;
function Table1() {
  const customString =
    "Use field identifiers as variables for your custom event redirect (e.g. {First Name})";
  const [fallbackRouteType, setFallbackRouteType] = useState("Custom Page");
  const [eventRedirectValueType, setEventRedirectValueType] = useState("");
  const [rows, initRow] = useState([]);
  const addRowTable = () => {
    const data = {
      name: "",
    };
    initRow([...rows, data]);
  };
  const tableRowRemove = (index) => {
    const dataRow = [...rows];
    dataRow.splice(index, 1);
    initRow(dataRow);
  };
  const onValUpdate = (i, event) => {
    const { name, value } = event.target;
    const data = [...rows];
    data[i][name] = value;
    initRow(data);
  };

  if (i == false) {
    addRowTable();
    i = true;
  }

  const handleChange = (event) => {
    setFallbackRouteType(event.target.value);
  };

  const handleChange1 = (event) => {
    setEventRedirectValueType(event.target.value);
  };

  return (
    <>
      <Grid item xs={12} lg={12} xl={12}>
        <Card
          mb={6}
          style={{
            backgroundColor: "transparent",
            padding: "0px !important",
            boxShadow: "none",
          }}
        >
          <CardContent pb={0} style={{ paddingLeft: "0px", paddingRight: "0" }}>
            <table style={{ width: "100%" }}>
              <tbody>
                <TableRows
                  rows={rows}
                  tableRowRemove={tableRowRemove}
                  onValUpdate={onValUpdate}
                />
              </tbody>
            </table>
            <Button
              color="primary"
              variant="outlined"
              fullWidth
              onClick={addRowTable}
              style={{ marginBottom: "20px" }}
            >
              <AddIcon />
              AND
            </Button>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}

function EventEntryTab() {
  return (
    <Grid justifyContent="space-between" container spacing={10}>
      <Grid item md={12}>
        <TextField
          margin="dense"
          id="name"
          label="Name"
          type="text"
          fullWidth
        />
      </Grid>
      <Grid item md={12} style={{ paddingTop: "20px" }}>
        <Typography variant="h6" gutterBottom>
          Add visitors to this segment
        </Typography>
      </Grid>
      <Grid item md={12} style={{ paddingTop: "20px" }}>
        <FormControl fullWidth my={2} variant="outlined">
          <InputLabel htmlFor="max-width">Apply To Rule Group</InputLabel>
          <Select autoFocus label="Bookable Appointments">
            <MenuItem value="d">All of the following conditions</MenuItem>
            <MenuItem value="xs">Any of the following rules</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={12} style={{ paddingTop: "20px" }}>
        <Box style={{ marginBottom: "15px" }}>
          <Grid container spacing={6}>
            <Table1 />
          </Grid>
        </Box>
        <Button variant="contained" color="primary">
          Save
        </Button>
      </Grid>
    </Grid>
  );
}

function Segment() {
  const [open, setOpen] = useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");

  return (
    <React.Fragment>
      {/* <Helmet title="User List" /> */}

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          {/* <Typography variant="h3" gutterBottom display="inline">
            User List
          </Typography> */}

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            {/* <Link component={NavLink} to="/spaces/space-apps">
              Dashboard
            </Link>
            <Link component={NavLink} to="/spaces/space-apps">
              Settings
            </Link>
            <Typography>User List</Typography> */}
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            {/* <Button
              variant="contained"
              color="inherit"
              sx={{ mx: "0.5rem" }}
              component={Link}
              to="/dashboard"
            >
              <ArrowBackIcon />
              Back
            </Button> */}
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpen(true)}
            >
              <AddIcon />
              New Segment
            </Button>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
      <Paper mt={4}>
        <div>
          <Dialog
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">New Segment</DialogTitle>
            <DialogContent>
              {/* <DialogContentText>
                To subscribe to this website, please enter your email address
                here. We will send updates occasionally.
              </DialogContentText> */}
              <EventEntryTab />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen(false)} color="primary">
                Cancel
              </Button>
              <Button onClick={() => setOpen(false)} color="primary">
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Paper>
    </React.Fragment>
  );
}

export default Segment;
