import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { Link, NavLink } from "react-router-dom";

import {
  Avatar,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Divider as MuiDivider,
  Grid,
  Tooltip,
  IconButton,
  TextField,
  Button,
} from "@mui/material";

import { ReactComponent as Logo } from "../../../vendor/logo.svg";
// import SignInComponent from "../../components/auth/SignIn";
import { tableCellClasses } from "@mui/material/TableCell";
import { spacing } from "@mui/system";
import { Edit } from "react-feather";
import { Google } from "@mui/icons-material";
const Divider = styled(MuiDivider)(spacing);

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
  to: "/";
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: ${(props) => props.theme.palette.common.black};
    color: ${(props) => props.theme.palette.common.white};
  }
  &.${tableCellClasses.body} {
    font-size: 14px;
  }
`;

const CustomTableRow = styled(TableRow)`
  // &:nth-of-type(odd) {
  //   background-color: rgba(0, 0, 0, 0.025);
  // }
`;

function BookingConfirm() {
  const [isCancel, setIscancel] = useState("false");
  return (
    <React.Fragment>
      <a href="/">
        <Brand />
      </a>

      <Wrapper>
        <Helmet title="Sign In" />
        {/* <BigAvatar alt="Lucy" src="/static/img/avatars/avatar-1.jpg" /> */}
        <Typography component="h1" variant="h2" align="center" gutterBottom>
          This meeting is scheduled
        </Typography>
        <Typography component="h2" variant="h5" align="center">
          We sent an email with a calendar invitation with the details to <br />
          everyone.
        </Typography>
        <br />
        <Divider my={6} />
        <Table>
          <TableBody>
            <CustomTableRow>
              <CustomTableCell
                component="th"
                scope="row"
                style={{ borderBottom: "0" }}
              >
                What
              </CustomTableCell>
              <CustomTableCell
                component="th"
                scope="row"
                style={{ borderBottom: "0" }}
              >
                Secret Meeting between Insta Cal and Insta Cal
              </CustomTableCell>
            </CustomTableRow>
            <CustomTableRow>
              <CustomTableCell
                component="th"
                scope="row"
                style={{ borderBottom: "0" }}
              >
                When
              </CustomTableCell>
              <CustomTableCell
                component="th"
                scope="row"
                style={{ borderBottom: "0" }}
              >
                Friday, March 22, 2024
                <br />
                9:00 AM - 9:15 AM (Eastern Daylight Time)
              </CustomTableCell>
            </CustomTableRow>
            <CustomTableRow>
              <CustomTableCell
                component="th"
                scope="row"
                style={{ borderBottom: "0" }}
              >
                Who
              </CustomTableCell>
              <CustomTableCell
                component="th"
                scope="row"
                style={{ borderBottom: "0" }}
              >
                Insta CalHost <br />
                shabbirbakh@gmail.com
                <br />
                Insta Cal
                <br />
                amit@yopmail.com
              </CustomTableCell>
            </CustomTableRow>
            <CustomTableRow>
              <CustomTableCell
                component="th"
                scope="row"
                style={{ borderBottom: "0" }}
              >
                What
              </CustomTableCell>
              <CustomTableCell
                component="th"
                scope="row"
                style={{ borderBottom: "0" }}
              >
                Secret Meeting between Insta Cal and Insta Cal
              </CustomTableCell>
            </CustomTableRow>
          </TableBody>
        </Table>
        <Divider my={6} />
        {isCancel === "false" ? (
          <Grid container>
            <Grid md={8}>
              <Typography variant="h6" gutterBottom display="inline">
                Need to make a change?
              </Typography>
            </Grid>
            <Grid md={4}>
              <Link
                variant="contained"
                color="primary"
                style={{
                  fontWeight: "bold",
                  paddingRight: "15px",
                  fontSize: "0.9rem",
                }}
                component={NavLink}
                to="/book"
              >
                Reschedule
              </Link>
              <Link
                variant="contained"
                color="primary"
                style={{
                  fontWeight: "bold",
                  fontSize: "0.9rem",
                }}
                onClick={(e) => {
                  setIscancel("true");
                }}
              >
                Cancel
              </Link>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={6}>
            <Grid item md={12}>
              <Typography variant="h6" gutterBottom display="inline">
                Reason for cancellation (optional)
              </Typography>
              <TextField
                id="username"
                label="Description"
                defaultValue=""
                placeholder=""
                variant="outlined"
                fullWidth
                my={2}
                multiline
                rows={4}
                maxRows={6}
              />
            </Grid>
            <Grid item md={12}>
              <Button onClick={() => setIscancel("false")} color="primary">
                Cancel
              </Button>
              <Button color="primary"> Submit </Button>
            </Grid>
          </Grid>
        )}
        <Divider my={6} />
        <Grid container style={{ textAlign: "center" }}>
          <Grid md={12}>
            <Typography variant="h6" gutterBottom display="inline">
              Add to calendar
              <Tooltip title="Google">
                <IconButton
                  aria-label="Google"
                  size="large"
                  component={NavLink}
                  to="/events/view-event"
                >
                  <Google />
                </IconButton>
              </Tooltip>
              <Tooltip title="Microsoft Outlook">
                <IconButton
                  aria-label="Microsoft Outlook"
                  size="large"
                  component={NavLink}
                  to="/events/view-event"
                >
                  <Google />
                </IconButton>
              </Tooltip>
              <Tooltip title="Microsoft Office">
                <IconButton
                  aria-label="Microsoft Office"
                  size="large"
                  component={NavLink}
                  to="/events/view-event"
                >
                  <Google />
                </IconButton>
              </Tooltip>
              <Tooltip title="Other">
                <IconButton
                  aria-label="Other"
                  size="large"
                  component={NavLink}
                  to="/events/view-event"
                >
                  <Google />
                </IconButton>
              </Tooltip>
            </Typography>
          </Grid>
        </Grid>
      </Wrapper>
    </React.Fragment>
  );
}

export default BookingConfirm;
