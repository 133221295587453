import {
  Dashboard,
  Event,
  Videocam,
  People,
  IntegrationInstructions,
  Analytics,
  Groups,
} from "@mui/icons-material";

const pagesSection = [
  {
    href: "/events/dashboard",
    icon: Dashboard,
    title: "Dashboard",
  },
  {
    href: "/events/events",
    icon: Event,
    title: "Events",
  },
  {
    href: "/events",
    icon: Videocam,
    title: "Video Library",
  },
  {
    href: "/events",
    icon: People,
    title: "Team",
  },
  {
    href: "/events",
    icon: IntegrationInstructions,
    title: "Integrations",
  },
  {
    href: "/events",
    icon: Analytics,
    title: "Analytics",
  },
  {
    href: "/events",
    icon: Groups,
    title: "Audience",
  },
];

const eventsItems = [
  {
    title: "Events",
    pages: pagesSection,
  },
  // {
  //   title: "Elements",
  //   pages: elementsSection,
  // },
  // {
  //   title: "Mira Pro",
  //   pages: docsSection,
  // },
];

export default eventsItems;
