import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import axios from "axios";

import {
  Alert as MuiAlert,
  Checkbox,
  FormControlLabel,
  Button,
  TextField as MuiTextField,
  Switch,
} from "@mui/material";
import { spacing } from "@mui/system";

import useAuth from "../../hooks/useAuth";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function SignIn() {
  const navigate = useNavigate();
  const [isCorporate, setIsCorporate] = useState("");
  const [email, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");

  // const handleSubmit = async (e) => {
  //   //e.preventDefault();
  //   if (email === "" || password === "") {
  //     setErrors({ submit: '"Both Email Id and Password is required !!"' });
  //     return;
  //   }

  //   if (isCorporate == true) {
  //     if (
  //       email.includes("zoho") ||
  //       email.includes("aol") ||
  //       email.includes("gmail") ||
  //       email.includes("gmx") ||
  //       email.includes("icloud") ||
  //       email.includes("proton") ||
  //       email.includes("yahoo") ||
  //       email.includes("tutanota") ||
  //       email.includes("yandex") ||
  //       email.includes("outlook") ||
  //       email.includes("mailfence") ||
  //       email.includes("yahoo")
  //     ) {
  //       setMessage("Public email domains are not allowed in corporate login");
  //       return;
  //     }
  //   }

  //   var hh = isCorporate;

  //   const data = {
  //     email: email,
  //     pwd: password,
  //   };

  //   await axios
  //     .post("http://localhost:4001/api/login", data, {
  //       headers: { "Content-Type": "application/json" },
  //     })
  //     .then((response) => {
  //       if (response.data == "User does not exists or Password Error") {
  //         setMessage(response.data);
  //       } else {
  //         navigate("/dashboard/analytics");
  //       }
  //     })
  //     .catch((error) => {
  //       alert(error);
  //     });
  // };

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        password: Yup.string().max(255).required("Password is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          //await signIn(values.email, values.password);

          //navigate("/dashboard/default");
          navigate("/spaces/space-apps");
        } catch (error) {
          const message = error.message || "Something went wrong";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {/* <Alert mt={3} mb={3} severity="info">
            Use <strong>demo@bootlab.io</strong> and{" "}
            <strong>unsafepassword</strong> to sign in
          </Alert> */}
          {errors.submit && (
            <Alert mt={2} mb={3} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <FormControlLabel
            control={
              <Switch
                name="gilad"
                onChange={(event) => setIsCorporate(event.target.checked)}
              />
            }
            label="I'm a Corporate"
          />
          <TextField
            type="email"
            name="email"
            label="Email Address"
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
          <TextField
            type="password"
            name="password"
            label="Password"
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Sign in
          </Button>
          <Button
            component={Link}
            to="/auth/reset-password"
            fullWidth
            color="primary"
          >
            Forgot password
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default SignIn;
