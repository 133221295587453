import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import "../../../../Styles/Custom.css";
import Switch from "@mui/material/Switch";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { faker } from "@faker-js/faker";
import Drawer from "@mui/material/Drawer";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import InputAdornment from "@mui/material/InputAdornment";

import {
  AvatarGroup as MuiAvatarGroup,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  Paper as MuiPaper,
  TableCell,
  TableRow,
  Typography,
  MenuItem,
  DialogActions,
  TextField,
  CardContent,
  Card as MuiCard,
  CardMedia as MuiCardMedia,
  FormControlLabel,
} from "@mui/material";
import { green, orange, blue } from "@mui/material/colors";
import {
  Add as AddIcon,
  ArrowBack as ArrowBackIcon,
} from "@mui/icons-material";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Chip = styled(MuiChip)`
  ${spacing};
  background: "#eee";
`;

// Data
let id = 0;
function createData(name, calories, fat, carbs, protein) {
  id += 1;
  return { id, name, calories, fat, carbs, protein };
}

const TaskWrapper = styled(Card)`
  border: 1px solid ${(props) => props.theme.palette.grey[300]};
  margin-bottom: ${(props) => props.theme.spacing(4)};
  cursor: grab;

  &:hover {
    background: ${(props) => props.theme.palette.background.default};
  }
`;

const TaskWrapperContent = styled(CardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)};
  }
`;

const TaskNotifications = styled.div`
  display: flex;
  position: absolute;
  bottom: ${(props) => props.theme.spacing(4)};
  right: ${(props) => props.theme.spacing(4)};
`;

const TaskNotificationsAmount = styled.div`
  color: ${(props) => props.theme.palette.grey[500]};
  font-weight: 600;
  margin-right: ${(props) => props.theme.spacing(1)};
  line-height: 1.75;
`;

const mockItems1 = [
  {
    id: faker.datatype.uuid(),
    title: "Redesign the homepage",
    badges: [green[600], orange[600]],
    notifications: 2,
    avatars: [1, 2, 3, 4],
  },
  {
    id: faker.datatype.uuid(),
    title: "Upgrade dependencies to latest versions",
    badges: [green[600]],
    notifications: 1,
    avatars: [2],
  },
  {
    id: faker.datatype.uuid(),
    title: "Google Adwords best practices",
    badges: [],
    notifications: 0,
    avatars: [2, 3],
  },
  {
    id: faker.datatype.uuid(),
    title: "Improve site speed",
    badges: [green[600]],
    notifications: 3,
    avatars: [],
  },
  {
    id: faker.datatype.uuid(),
    title: "Stripe payment integration",
    badges: [blue[600]],
    notifications: 0,
    avatars: [],
  },
];

const mockColumns = {
  [faker.datatype.uuid()]: {
    // title: "Fields",
    // description: "Customize the questions asked on the booking page",
    items: mockItems1,
  },
};

const onDragEnd = (result, columns, setColumns) => {
  if (!result.destination) return;
  const { source, destination } = result;

  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems,
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems,
      },
    });
  } else {
    const column = columns[source.droppableId];
    const copiedItems = [...column.items];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...column,
        items: copiedItems,
      },
    });
  }
};

const Lane = ({ column, children }) => {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open, oo) => (event) => {
    i = false;
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      style={{ width: "350px" }}
    >
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item style={{ margin: "15px", paddingBottom: "10px" }}>
          <Typography variant="h3" gutterBottom display="inline">
            Add Question
          </Typography>
          <Typography>
            Customize the questions asked on the booking page
          </Typography>
        </Grid>
      </Grid>
      <Grid item style={{ margin: "15px", paddingBottom: "10px" }}>
        <Grid container spacing={6}>
          <Grid item md={12}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel htmlFor="max-width">Before Event</InputLabel>
              <Select
                autoFocus
                value="xs"
                label="Input Type"
                inputProps={{
                  name: "max-width",
                  id: "max-width",
                }}
              >
                <MenuItem value="xs">Email</MenuItem>
                <MenuItem value="sm">Phone</MenuItem>
                <MenuItem value="md">Address</MenuItem>
                <MenuItem value="lg">Short Text</MenuItem>
                <MenuItem value="xl">Number</MenuItem>
                <MenuItem value="xl">Long Text</MenuItem>
                <MenuItem value="xl">Select</MenuItem>
                <MenuItem value="xl">Multi Select</MenuItem>
                <MenuItem value="xl">Multiple Emails</MenuItem>
                <MenuItem value="xl">Checkbox Group</MenuItem>
                <MenuItem value="xl">Radio Group</MenuItem>
                <MenuItem value="xl">Checkbox</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={12}>
            <TextField
              id="username"
              label="Identifier"
              defaultValue=""
              variant="outlined"
              fullWidth
              my={2}
              rows={4}
              maxRows={6}
            />
          </Grid>
          <Grid item md={12}>
            <TextField
              id="username"
              label="Label"
              defaultValue=""
              variant="outlined"
              fullWidth
              my={2}
              rows={4}
              maxRows={6}
            />
          </Grid>
          <Grid item md={12}>
            <TextField
              id="username"
              label="Placeholder"
              defaultValue=""
              variant="outlined"
              fullWidth
              my={2}
              rows={4}
              maxRows={6}
            />
          </Grid>
          <Grid item md={12}>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
                Required
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="yes"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <DialogActions>
        <Button onClick={toggleDrawer("right", false)} color="primary">
          Cancel
        </Button>
        <Button onClick={toggleDrawer("right", false)} color="primary">
          Done
        </Button>
      </DialogActions>
    </Box>
  );
  return (
    <Grid item xs={12} lg={12} xl={12}>
      <Card
        mb={6}
        style={{ backgroundColor: "transparent", padding: "0px !important" }}
      >
        <CardContent pb={0} style={{ paddingLeft: "0px" }}>
          <Typography variant="h6" gutterBottom>
            {column.title}
          </Typography>
          <Typography variant="body2" mb={4}>
            {column.description}
          </Typography>
          {children}
          <Button
            color="primary"
            variant="outlined"
            fullWidth
            onClick={toggleDrawer("right", true)}
          >
            <AddIcon />
            Add New Field
          </Button>
          <Drawer
            anchor={"right"}
            open={state["right"]}
            onClose={toggleDrawer("right", false)}
          >
            {list("right")}
          </Drawer>
        </CardContent>
      </Card>
    </Grid>
  );
};

const Task = ({ item }) => {
  return (
    <TaskWrapper mb={4}>
      <TaskWrapperContent>
        {/* {item.badges &&
          item.badges.map((color, i) => <TaskBadge color={color} key={i} />)} */}

        <TaskTitle variant="body1"> Field </TaskTitle>
        <Divider style={{ marginBottom: "15px", marginTop: "15px" }} />
        <Grid justifyContent="space-between" container spacing={10}>
          <Grid item md={12}>
            <Grid container spacing={6}>
              <Grid item md={12}>
                <TextField
                  id="username"
                  label="Label"
                  defaultValue=""
                  variant="outlined"
                  fullWidth
                  my={2}
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  id="username"
                  label="Identifier"
                  defaultValue=""
                  variant="outlined"
                  fullWidth
                  my={2}
                />
              </Grid>
              <Grid item md={12}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel htmlFor="Type">Type</InputLabel>
                  <Select
                    autoFocus
                    label="Type"
                    inputProps={{
                      name: "max-width",
                      id: "max-width",
                    }}
                  >
                    <MenuItem value="xs">Short Text</MenuItem>
                    <MenuItem value="sm">Number</MenuItem>
                    <MenuItem value="md">Long Text</MenuItem>
                    <MenuItem value="lg">Single Selection</MenuItem>
                    <MenuItem value="xl">Multiple Selection</MenuItem>
                    <MenuItem value="xl">Phone</MenuItem>
                    <MenuItem value="xl">Email</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={12}>
                <FormGroup>
                  <FormControlLabel
                    required
                    control={<Switch />}
                    label="Required"
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <TaskNotifications style={{ top: "16px" }}>
          <DeleteIcon />
        </TaskNotifications>
      </TaskWrapperContent>
    </TaskWrapper>
  );
};

const TaskTitle = styled(Typography)`
  font-weight: 600;
  font-size: 15px;
  margin-right: ${(props) => props.theme.spacing(10)};
`;

function TableRows1({ rows, tableRowRemove, onValUpdate }) {
  return rows.map((rowsData, index) => {
    var opt = "Email";
    const { name, email, profile } = rowsData;
    return (
      <tr key={index}>
        <td>
          <Grid container item>
            <Grid
              item
              md={2}
              style={{ marginTop: "10px", marginRight: "10px" }}
            >
              <FormControl sx={{ width: "100%" }}>
                {/* <InputLabel htmlFor="max-width">Before Event</InputLabel> */}
                <Select
                  autoFocus
                  value="false"
                  // label="maxWidth"
                  inputProps={{
                    name: "max-width",
                    id: "max-width",
                  }}
                >
                  <MenuItem value={false}>First Name</MenuItem>
                  <MenuItem value="xs">Last Name</MenuItem>
                  <MenuItem value="sm">Email Id</MenuItem>
                  <MenuItem value="md">Phone No</MenuItem>
                  <MenuItem value="lg">Country</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              md={2}
              style={{ marginTop: "10px", marginRight: "10px" }}
            >
              <FormControl sx={{ width: "100%" }}>
                {/* <InputLabel htmlFor="max-width">Before Event</InputLabel> */}
                <Select
                  autoFocus
                  value="false"
                  // label="maxWidth"
                  inputProps={{
                    name: "max-width",
                    id: "max-width",
                  }}
                >
                  <MenuItem value={false}>Equals</MenuItem>
                  <MenuItem value="xs">Does Not Equal</MenuItem>
                  <MenuItem value="sm">Contains</MenuItem>
                  <MenuItem value="md">Not Contains</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={2}>
              <FormControl sx={{ width: "100%" }}>
                {/* <InputLabel htmlFor="max-width">Before Event</InputLabel> */}
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  // label="First Name"
                  placeholder="Value"
                  type="text"
                  fullWidth
                />
              </FormControl>
            </Grid>
            <Grid
              item
              md={1}
              style={{
                marginTop: "10px",
                textAlign: "center",
              }}
            >
              <DeleteIcon
                onClick={() => tableRowRemove(index)}
                style={{ position: "relative", top: "15px" }}
              />
            </Grid>
          </Grid>
        </td>
      </tr>
    );
  });
}
var noOfRows = 0;
function TableRows({ rows, tableRowRemove, onValUpdate }) {
  const customString =
    "Use field identifiers as variables for your custom event redirect (e.g. {First Name})";
  const [fallbackRouteType, setFallbackRouteType] = useState("Custom Page");
  const [eventRedirectValueType, setEventRedirectValueType] = useState("");

  const handleChange = (event) => {
    setFallbackRouteType(event.target.value);
  };

  const handleChange1 = (event) => {
    setEventRedirectValueType(event.target.value);
  };

  const [showAddGuest, setShowAddGuest] = useState("false");

  const [rows1, initRow] = useState([]);
  const addRowTable = () => {
    if (showAddGuest == "true") {
      const data = {
        name: "",
      };
      initRow([...rows1, data]);
    }
    setShowAddGuest("true");
    noOfRows++;
  };
  const tableRowRemove1 = (index) => {
    const dataRow = [...rows1];
    dataRow.splice(index, 1);
    initRow(dataRow);
    noOfRows--;
  };
  const onValUpdate1 = (i, event) => {
    const { name, value } = event.target;
    const data = [...rows1];
    data[i][name] = value;
    initRow(data);
  };

  return rows.map((rowsData, index) => {
    var opt = "Option " + (index + 1);
    const { name, email, profile } = rowsData;
    return (
      <tr key={index}>
        <td>
          <TaskWrapper mb={4}>
            <TaskWrapperContent>
              <TaskTitle variant="body1" gutterBottom>
                Route {index + 1}
              </TaskTitle>
              <Divider />
              <Grid container>
                <Grid
                  item
                  md={2}
                  style={{ marginTop: "10px", marginRight: "10px" }}
                >
                  <Typography
                    variant="h7"
                    style={{ position: "relative", top: "15px" }}
                  >
                    Send Booker to
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={4}
                  style={{ marginTop: "10px", marginRight: "10px" }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      autoFocus
                      value={fallbackRouteType}
                      inputProps={{
                        name: "max-width",
                        id: "max-width",
                      }}
                      onChange={handleChange}
                    >
                      <MenuItem value="Custom Page">Custom Page</MenuItem>
                      <MenuItem value="External Redirect">
                        External Redirect
                      </MenuItem>
                      <MenuItem value="Event Redirect">Event Redirect</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {fallbackRouteType == "Custom Page" ? (
                  <Grid item md={4} style={{ marginRight: "10px" }}>
                    <FormControl sx={{ width: "100%" }}>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        defaultValue="We are not ready for you yet :("
                        placeholder="Value"
                        type="text"
                        fullWidth
                        multiline={true}
                        maxRowsrows={3}
                        maxRows={4}
                      />
                    </FormControl>
                  </Grid>
                ) : fallbackRouteType == "External Redirect" ? (
                  <Grid item md={4} style={{ marginRight: "10px" }}>
                    <FormControl sx={{ width: "100%" }}>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        // label="First Name"
                        placeholder="https://example.com"
                        type="text"
                        fullWidth
                      />
                    </FormControl>
                  </Grid>
                ) : (
                  <Grid
                    item
                    md={4}
                    style={{ marginTop: "10px", marginRight: "10px" }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        autoFocus
                        value={eventRedirectValueType}
                        inputProps={{
                          name: "max-width",
                          id: "max-width",
                        }}
                        onChange={handleChange1}
                      >
                        <MenuItem value="Custom">Custom</MenuItem>
                        <MenuItem value="xs">
                          team/instaconnect/managed-event1
                        </MenuItem>
                        <MenuItem value="sm">
                          team/instaconnect/collective1
                        </MenuItem>
                        <MenuItem value="sm">
                          team/instaconnect/roundrobin1
                        </MenuItem>
                      </Select>
                      {eventRedirectValueType == "Custom" ? (
                        <>
                          <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            // label="First Name"
                            placeholder="event url"
                            type="text"
                            fullWidth
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Button disabled>team/instaconnect/</Button>
                                </InputAdornment>
                              ),
                            }}
                          />
                          <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                          >
                            {customString}
                          </Typography>
                        </>
                      ) : (
                        <></>
                      )}
                    </FormControl>
                  </Grid>
                )}
              </Grid>
              <Divider style={{ marginTop: "10px" }} />
              <Grid container>
                <Grid
                  item
                  md={2}
                  style={{ marginTop: "10px", marginRight: "10px" }}
                >
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={(e) => {
                      addRowTable();
                      setShowAddGuest("true");
                    }}
                    style={{ marginBottom: "20px" }}
                  >
                    <AddIcon />
                    Add Rule
                  </Button>
                </Grid>
              </Grid>
              {noOfRows > 2 ? (
                <Grid container>
                  <Grid
                    item
                    md={2}
                    style={{ marginTop: "10px", marginRight: "10px" }}
                  >
                    <Typography
                      variant="h7"
                      style={{ position: "relative", top: "15px" }}
                    >
                      Query where
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={2}
                    style={{ marginTop: "10px", marginRight: "10px" }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        autoFocus
                        value="false"
                        inputProps={{
                          name: "max-width",
                          id: "max-width",
                        }}
                      >
                        <MenuItem value={false}>All</MenuItem>
                        <MenuItem value="xs">Any</MenuItem>
                        <MenuItem value="sm">None</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={2} style={{ marginTop: "10px" }}>
                    <Typography
                      variant="h7"
                      style={{ position: "relative", top: "15px" }}
                    >
                      match
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <></>
              )}
              {showAddGuest == "true" ? (
                <table style={{ width: "100%" }}>
                  <tbody>
                    <TableRows1
                      rows={rows1}
                      tableRowRemove={tableRowRemove1}
                      onValUpdate={onValUpdate1}
                    />
                  </tbody>
                </table>
              ) : (
                <></>
              )}
              <TaskNotifications style={{ top: "16px" }}>
                <DeleteIcon onClick={() => tableRowRemove(index)} />
              </TaskNotifications>
            </TaskWrapperContent>
          </TaskWrapper>
        </td>
      </tr>
    );
  });
}
var i = false;
function Table1() {
  const customString =
    "Use field identifiers as variables for your custom event redirect (e.g. {First Name})";
  const [fallbackRouteType, setFallbackRouteType] = useState("Custom Page");
  const [eventRedirectValueType, setEventRedirectValueType] = useState("");
  const [rows, initRow] = useState([]);
  const addRowTable = () => {
    const data = {
      name: "",
    };
    initRow([...rows, data]);
  };
  const tableRowRemove = (index) => {
    const dataRow = [...rows];
    dataRow.splice(index, 1);
    initRow(dataRow);
  };
  const onValUpdate = (i, event) => {
    const { name, value } = event.target;
    const data = [...rows];
    data[i][name] = value;
    initRow(data);
  };

  //   if (i == false) {
  //     addRowTable();
  //     i = true;
  //   }

  const handleChange = (event) => {
    setFallbackRouteType(event.target.value);
  };

  const handleChange1 = (event) => {
    setEventRedirectValueType(event.target.value);
  };

  return (
    <>
      <Grid item xs={12} lg={12} xl={12}>
        <Card
          mb={6}
          style={{
            backgroundColor: "transparent",
            padding: "0px !important",
            boxShadow: "none",
          }}
        >
          <CardContent pb={0} style={{ paddingLeft: "0px" }}>
            <table style={{ width: "100%" }}>
              <tbody>
                <TableRows
                  rows={rows}
                  tableRowRemove={tableRowRemove}
                  onValUpdate={onValUpdate}
                />
              </tbody>
            </table>
            <Button
              color="primary"
              variant="outlined"
              fullWidth
              onClick={addRowTable}
              style={{ marginBottom: "20px" }}
            >
              <AddIcon />
              Add a new Route
            </Button>
            <TaskWrapper mb={4}>
              <TaskWrapperContent>
                <TaskTitle variant="body1" gutterBottom>
                  Fallback Route
                </TaskTitle>
                <Divider />
                <Grid container>
                  <Grid
                    item
                    md={2}
                    style={{ marginTop: "10px", marginRight: "10px" }}
                  >
                    <Typography
                      variant="h7"
                      style={{ position: "relative", top: "15px" }}
                    >
                      Send Booker to
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    style={{ marginTop: "10px", marginRight: "10px" }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        autoFocus
                        value={fallbackRouteType}
                        inputProps={{
                          name: "max-width",
                          id: "max-width",
                        }}
                        onChange={handleChange}
                      >
                        <MenuItem value="Custom Page">Custom Page</MenuItem>
                        <MenuItem value="External Redirect">
                          External Redirect
                        </MenuItem>
                        <MenuItem value="Event Redirect">
                          Event Redirect
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {fallbackRouteType == "Custom Page" ? (
                    <Grid item md={4} style={{ marginRight: "10px" }}>
                      <FormControl sx={{ width: "100%" }}>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="name"
                          defaultValue="We are not ready for you yet :("
                          placeholder="Value"
                          type="text"
                          fullWidth
                          multiline={true}
                          maxRowsrows={3}
                          maxRows={4}
                        />
                      </FormControl>
                    </Grid>
                  ) : fallbackRouteType == "External Redirect" ? (
                    <Grid item md={4} style={{ marginRight: "10px" }}>
                      <FormControl sx={{ width: "100%" }}>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="name"
                          // label="First Name"
                          placeholder="https://example.com"
                          type="text"
                          fullWidth
                        />
                      </FormControl>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      md={4}
                      style={{ marginTop: "10px", marginRight: "10px" }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <Select
                          autoFocus
                          value={eventRedirectValueType}
                          inputProps={{
                            name: "max-width",
                            id: "max-width",
                          }}
                          onChange={handleChange1}
                        >
                          <MenuItem value="Custom">Custom</MenuItem>
                          <MenuItem value="xs">
                            team/instaconnect/managed-event1
                          </MenuItem>
                          <MenuItem value="sm">
                            team/instaconnect/collective1
                          </MenuItem>
                          <MenuItem value="sm">
                            team/instaconnect/roundrobin1
                          </MenuItem>
                        </Select>
                        {eventRedirectValueType == "Custom" ? (
                          <>
                            <TextField
                              autoFocus
                              margin="dense"
                              id="name"
                              // label="First Name"
                              placeholder="event url"
                              type="text"
                              fullWidth
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <Button disabled>team/instaconnect/</Button>
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <Typography
                              variant="caption"
                              display="block"
                              gutterBottom
                            >
                              {customString}
                            </Typography>
                          </>
                        ) : (
                          <></>
                        )}
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
              </TaskWrapperContent>
            </TaskWrapper>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}

function EventEntryTab() {
  return (
    <Grid justifyContent="space-between" container spacing={10}>
      <Grid item md={12}>
        <Typography variant="h3" gutterBottom display="inline">
          Fields
        </Typography>
        <Typography>
          Configure what information should be captured during registration
        </Typography>
      </Grid>
      <Grid item md={12} style={{ padding: "20px 40px" }}>
        {/* <Typography variant="h6" gutterBottom>
            Basic Information
          </Typography> */}
        <Box style={{ marginBottom: "15px" }}>
          <Grid container spacing={6}>
            <Table1 />
          </Grid>
        </Box>
        <Button variant="contained" color="primary">
          Save
        </Button>
      </Grid>
    </Grid>
  );
}

function Routing() {
  return (
    <React.Fragment>
      <EventEntryTab />
    </React.Fragment>
  );
}

export default Routing;
