import React, { useState } from "react";
import styled from "@emotion/styled";
import { NavLink, json } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Copy, Search as SearchIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import { darken } from "polished";
import { Link } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import "../../../../Styles/Custom.css";
import Switch from "@mui/material/Switch";
import ReactQuill from "react-quill";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Checkbox,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  Menu,
  MenuItem,
  InputBase,
  DialogActions,
  TextField,
  CardContent,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card as MuiCard,
  CardMedia as MuiCardMedia,
  CardActions,
  AvatarGroup as MuiAvatarGroup,
  FormControlLabel,
} from "@mui/material";

import { green, orange } from "@mui/material/colors";

import {
  Add as AddIcon,
  ArrowBack as ArrowBackIcon,
  FilterList,
  Download,
  Mail,
  ContentCopy,
  Visibility,
} from "@mui/icons-material";

import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import { tableCellClasses } from "@mui/material/TableCell";
import { spacing } from "@mui/system";

import Code from "../../../../components/Code";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const CustomTableRow = styled(TableRow)`
  // &:nth-of-type(odd) {
  //   background-color: rgba(0, 0, 0, 0.025);
  // }
`;
const QuillWrapper = styled.div`
  .ql-editor {
    min-height: 200px;
  }
`;
const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: ${(props) => props.theme.palette.common.black};
    color: ${(props) => props.theme.palette.common.white};
  }
  &.${tableCellClasses.body} {
    font-size: 14px;
  }
`;
// Data
let id = 0;
function createData(name, calories, fat, carbs, protein) {
  id += 1;
  return { id, name, calories, fat, carbs, protein };
}

function EventEntryTab() {
  return (
    <Grid justifyContent="space-between" container>
      {/* <Grid item md={12}>
        <Typography variant="h3" gutterBottom display="inline">
          Event Setup
        </Typography>
        <Typography>
          Configure what information should be captured during registration
        </Typography>
      </Grid> */}
      <Grid item md={12}>
        {/* <Typography variant="h6" gutterBottom>
            Basic Information
          </Typography> */}

        <Grid container spacing={6}>
          <Grid item md={6} style={{ marginBottom: "15px" }}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel htmlFor="max-width">
                What are you using the widget for?*
              </InputLabel>
              <Select
                autoFocus
                value="Sales"
                label="max-width"
                inputProps={{
                  name: "max-width",
                  id: "max-width",
                }}
              >
                <MenuItem value="Sales">Sales</MenuItem>
                <MenuItem value="Support">Support</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item md={6} style={{ marginBottom: "15px" }}>
            <TextField
              id="username"
              label="Give your widget a name"
              defaultValue=""
              placeholder="Type your unique url"
              variant="outlined"
              fullWidth
              my={2}
            />
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item md={6} style={{ marginBottom: "15px" }}>
            <TextField
              id="username"
              label="Set display company name"
              defaultValue=""
              placeholder="Type your unique url"
              variant="outlined"
              fullWidth
              my={2}
            />
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item md={12} style={{ marginBottom: "15px" }}>
            <InputLabel htmlFor="max-width">
              Test your widget on our test website before embedding it on your
              website.
            </InputLabel>
            <div style={{ marginTop: "10px" }}>
              <Button
                variant="outlined"
                color="primary"
                style={{ marginRight: "15px" }}
              >
                Test Your Widget
              </Button>
              <Button variant="outlined" color="primary">
                Copy Test Website URL
              </Button>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item md={6} style={{ marginBottom: "15px" }}>
            <Typography variant="h3" gutterBottom>
              Website Integration
            </Typography>
            <Box mb={4}>
              <InputLabel>
                1. Integrate the code into the footer of your website
              </InputLabel>
              <Typography variant="subtitle1" gutterBottom my={4}>
                <Code>{`<script type="text/javascript" async>
(function(d,s){
var l=d.createElement(s),e=d.getElementsByTagName(s)[0];
l.async=true;l.type='text/javascript';
l.src='https://app.letsconnect.at/embed.js';
l.id='VG8Vduza3Ijsw15154zV';
e.parentNode.insertBefore(l,e);})(document,'script');</script>`}</Code>
              </Typography>
              <Button variant="outlined" color="primary">
                Copy
              </Button>
            </Box>
            <Box mb={4}>
              <InputLabel>
                2. First open your website and then press the test button
              </InputLabel>
              <Button
                variant="outlined"
                color="primary"
                style={{ marginTop: "10px" }}
              >
                Test Your Integration
              </Button>
            </Box>
            <Box mb={4}>
              <InputLabel>
                3. Do not forget to complete your privacy police on your website
              </InputLabel>
              <Button
                variant="outlined"
                color="primary"
                style={{ marginTop: "10px" }}
              >
                Data Protection Text For Your Website
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

function PrepareWebsite() {
  return (
    <React.Fragment>
      <EventEntryTab />
    </React.Fragment>
  );
}

export default PrepareWebsite;
