import React, { useState } from "react";
import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link, NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import CardMedia from "@mui/material/CardMedia";
import "../../../../Styles/Custom.css";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { Search as SearchIcon } from "react-feather";
import { darken } from "polished";
import { useTranslation } from "react-i18next";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";

import {
  Avatar,
  Grid,
  CardActions,
  CardContent,
  Card as MuiCard,
  CardHeader as MuiCardHeader,
  Breadcrumbs as MuiBreadcrumbs,
  Paper as MuiPaper,
  Divider as MuiDivider,
  TextField as MuiTextField,
  DialogActions,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  InputBase,
  FormControlLabel,
} from "@mui/material";

import { tableCellClasses } from "@mui/material/TableCell";

import {
  Add as AddIcon,
  ArrowBack as ArrowBackIcon,
  Padding,
  StarBorder as StarIcon,
} from "@mui/icons-material";

import { color, spacing } from "@mui/system";
import { padding } from "polished";
import { TimePicker } from "@mui/x-date-pickers";

const Header = styled.div`
  padding: ${(props) => props.theme.spacing(6)} 0;
`;

const stepsHorizontal = [
  "Select campaign settings",
  "Create an ad group",
  "Create an ad",
];

const Card = styled(MuiCard)(spacing);

const CardHeader = styled(MuiCardHeader)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Price = styled.div`
  text-align: center;
  padding-bottom: ${(props) => props.theme.spacing(3)};
`;

const CenteredContent = styled.div`
  text-align: center;
`;

const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 0 auto ${(props) => props.theme.spacing(2)};
`;

const QuillWrapper = styled.div`
  .ql-editor {
    min-height: 200px;
  }
`;

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: ${(props) => props.theme.palette.common.black};
    color: ${(props) => props.theme.palette.common.white};
  }
  &.${tableCellClasses.body} {
    font-size: 14px;
  }
`;

const CustomTableRow = styled(TableRow)`
  // &:nth-of-type(odd) {
  //   background-color: rgba(0, 0, 0, 0.025);
  // }
`;

// Data
let id = 0;
function createData(name, calories, fat, carbs, protein) {
  id += 1;
  return { id, name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

const EventType = (props) => {
  const { doChangeProps } = props;
  return (
    <div>
      <Header>
        <Typography variant="h3" gutterBottom align="center">
          What kind of event type is it?
        </Typography>
        <Typography variant="subtitle1" gutterBottom align="center">
          Choose the type of the event type you would like to create.
        </Typography>
      </Header>

      <Grid container justifyContent="center">
        <Grid item xs={12} lg={10}>
          <Grid container spacing={6} alignItems="flex-end">
            <Grid item xs={12} md={4}>
              <Card p={5}>
                <CardMedia
                  component="img"
                  height="140"
                  image="https://d2xqcdy5rl17k2.cloudfront.net/images/dashboard/event-types/modules/il-event-type-MEETUP-light.png"
                  alt="green iguana"
                />
                <CardHeader
                  title="One To One"
                  titleTypographyProps={{ align: "center" }}
                  pb={0}
                  pt={2}
                />
                <CardContent>
                  <Typography variant="subtitle1" align="center">
                    Host a talk or presentation with speakers to educate and
                    engage your audience.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="primary"
                    onClick={(e) => {
                      doChangeProps("1");
                    }}
                  >
                    Proceed
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card p={5}>
                <CardMedia
                  component="img"
                  height="140"
                  image="https://d2xqcdy5rl17k2.cloudfront.net/images/dashboard/event-types/modules/il-event-type-MEETUP-light.png"
                  alt="green iguana"
                />
                <CardHeader
                  title="One To Many"
                  titleTypographyProps={{ align: "center" }}
                  pb={0}
                  pt={2}
                />
                <CardContent>
                  <Typography variant="subtitle1" align="center">
                    Host a talk or presentation with speakers to educate and
                    engage your audience.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="primary"
                    onClick={(e) => {
                      doChangeProps("1");
                    }}
                  >
                    Proceed
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card p={5}>
                <CardMedia
                  component="img"
                  height="140"
                  image="https://d2xqcdy5rl17k2.cloudfront.net/images/dashboard/event-types/modules/il-event-type-MEETUP-light.png"
                  alt="green iguana"
                />
                <CardHeader
                  title="Many To One"
                  titleTypographyProps={{ align: "center" }}
                  pb={0}
                  pt={2}
                />
                <CardContent>
                  <Typography variant="subtitle1" align="center">
                    Host a talk or presentation with speakers to educate and
                    engage your audience.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="primary"
                    onClick={(e) => {
                      doChangeProps("1");
                    }}
                  >
                    Proceed
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card p={5}>
                <CardMedia
                  component="img"
                  height="140"
                  image="https://d2xqcdy5rl17k2.cloudfront.net/images/dashboard/event-types/modules/il-event-type-MEETUP-light.png"
                  alt="green iguana"
                />
                <CardHeader
                  title="Many To Many"
                  titleTypographyProps={{ align: "center" }}
                  pb={0}
                  pt={2}
                />
                <CardContent>
                  <Typography variant="subtitle1" align="center">
                    Host a talk or presentation with speakers to educate and
                    engage your audience.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="primary"
                    onClick={(e) => {
                      doChangeProps("1");
                    }}
                  >
                    Proceed
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card p={5}>
                <CardMedia
                  component="img"
                  height="140"
                  image="https://d2xqcdy5rl17k2.cloudfront.net/images/dashboard/event-types/modules/il-event-type-MEETUP-light.png"
                  alt="green iguana"
                />
                <CardHeader
                  title="Hybrid"
                  titleTypographyProps={{ align: "center" }}
                  pb={0}
                  pt={2}
                />
                <CardContent>
                  <Typography variant="subtitle1" align="center">
                    Host a talk or presentation with speakers to educate and
                    engage your audience.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="primary"
                    onClick={(e) => {
                      doChangeProps("1");
                    }}
                  >
                    Proceed
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const EventSubType = (props) => {
  const { doChangeProps } = props;
  return (
    <div>
      <Header>
        <Typography variant="h3" gutterBottom align="center">
          Start building your event type
        </Typography>
        <Typography variant="subtitle1" gutterBottom align="center">
          Start from scratch or pick a template to set up your event faster.
        </Typography>
      </Header>

      <Grid container justifyContent="center">
        <Grid item xs={12} lg={12}>
          <Grid container spacing={6} alignItems="flex-end">
            <Grid item xs={12} md={6}>
              <Card p={5}>
                <Grid container>
                  <Grid md={4}>
                    <CardMedia
                      component="img"
                      height="140"
                      image="https://assets-versions-cdn.static.events.ringcentral.com/assets/packs/media/react/features/event-creation-wizard/assets/templates/blank-f8f34e8a582e7b3b073f04f1220db88e.png"
                      alt="green iguana"
                    />
                  </Grid>
                  <Grid md={8}>
                    <CardHeader
                      title="Start with a blank event"
                      pb={0}
                      pt={2}
                    />
                    <CardContent>
                      <Typography variant="subtitle1">
                        Host a talk or presentation with speakers to educate and
                        engage your audience.
                      </Typography>
                    </CardContent>
                    <CardActions>
                      {/* <Button size="small" variant="outlined">
                        Learn More Webinar
                      </Button> */}
                      <Button
                        size="small"
                        variant="contained"
                        onClick={(e) => {
                          doChangeProps("2");
                        }}
                      >
                        Start
                      </Button>
                    </CardActions>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card p={5}>
                <Grid container>
                  <Grid md={4}>
                    <CardMedia
                      component="img"
                      height="140"
                      image="https://assets-versions-cdn.static.events.ringcentral.com/assets/packs/media/react/features/event-creation-wizard/assets/templates/expo-887d82b4e95ad9c41975bcb541c831f2.png"
                      alt="green iguana"
                    />
                  </Grid>
                  <Grid md={8}>
                    <CardHeader title="Expos and tradeshows" pb={0} pt={2} />
                    <CardContent>
                      <Typography variant="subtitle1">
                        Host a talk or presentation with speakers to educate and
                        engage your audience.
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button size="small" variant="outlined">
                        Learn More
                      </Button>
                      <Button
                        size="small"
                        variant="contained"
                        onClick={(e) => {
                          doChangeProps("2");
                        }}
                      >
                        Start
                      </Button>
                    </CardActions>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card p={5}>
                <Grid container>
                  <Grid md={4}>
                    <CardMedia
                      component="img"
                      height="140"
                      image="https://assets-versions-cdn.static.events.ringcentral.com/assets/packs/media/react/features/event-creation-wizard/assets/templates/webinar-6a4efb0e86ef06e800cbdafc1d623498.png"
                      alt="green iguana"
                    />
                  </Grid>
                  <Grid md={8}>
                    <CardHeader title="Webinars" pb={0} pt={2} />
                    <CardContent>
                      <Typography variant="subtitle1">
                        Host a talk or presentation with speakers to educate and
                        engage your audience.
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button size="small" variant="outlined">
                        Learn More
                      </Button>
                      <Button
                        size="small"
                        variant="contained"
                        onClick={(e) => {
                          doChangeProps("2");
                        }}
                      >
                        Start
                      </Button>
                    </CardActions>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card p={5}>
                <Grid container>
                  <Grid md={4}>
                    <CardMedia
                      component="img"
                      height="140"
                      image="https://assets-versions-cdn.static.events.ringcentral.com/assets/packs/media/react/features/event-creation-wizard/assets/templates/workshop-2f2fe16a2e9ca8f855b3fb92845d4a99.png"
                      alt="green iguana"
                    />
                  </Grid>
                  <Grid md={8}>
                    <CardHeader title="Courses and workshops" pb={0} pt={2} />
                    <CardContent>
                      <Typography variant="subtitle1">
                        Host a talk or presentation with speakers to educate and
                        engage your audience.
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button size="small" variant="outlined">
                        Learn More
                      </Button>
                      <Button
                        size="small"
                        variant="contained"
                        onClick={(e) => {
                          doChangeProps("2");
                        }}
                      >
                        Start
                      </Button>
                    </CardActions>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card p={5}>
                <Grid container>
                  <Grid md={4}>
                    <CardMedia
                      component="img"
                      height="140"
                      image="https://assets-versions-cdn.static.events.ringcentral.com/assets/packs/media/react/features/event-creation-wizard/assets/templates/conference-55d0d60b1a4529ac04c79cf95115f1b4.png"
                      alt="green iguana"
                    />
                  </Grid>
                  <Grid md={8}>
                    <CardHeader title="Conferences and summits" pb={0} pt={2} />
                    <CardContent>
                      <Typography variant="subtitle1">
                        Host a talk or presentation with speakers to educate and
                        engage your audience.
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button size="small" variant="outlined">
                        Learn More
                      </Button>
                      <Button
                        size="small"
                        variant="contained"
                        onClick={(e) => {
                          doChangeProps("2");
                        }}
                      >
                        Start
                      </Button>
                    </CardActions>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card p={5}>
                <Grid container>
                  <Grid md={4}>
                    <CardMedia
                      component="img"
                      height="140"
                      image="https://assets-versions-cdn.static.events.ringcentral.com/assets/packs/media/react/features/event-creation-wizard/assets/templates/meetup-99b31a509cadb02a08e0771dfa9c812b.png"
                      alt="green iguana"
                    />
                  </Grid>
                  <Grid md={8}>
                    <CardHeader title="Meet ups" pb={0} pt={2} />
                    <CardContent>
                      <Typography variant="subtitle1">
                        Host a talk or presentation with speakers to educate and
                        engage your audience.
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button size="small" variant="outlined">
                        Learn More
                      </Button>
                      <Button
                        size="small"
                        variant="contained"
                        onClick={(e) => {
                          doChangeProps("2");
                        }}
                      >
                        Start
                      </Button>
                    </CardActions>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card p={5}>
                <Grid container>
                  <Grid md={4}>
                    <CardMedia
                      component="img"
                      height="140"
                      image="https://assets-versions-cdn.static.events.ringcentral.com/assets/packs/media/react/features/event-creation-wizard/assets/templates/all-hands-089eb3be05d99d9348ad1f1157bc6e66.png"
                      alt="green iguana"
                    />
                  </Grid>
                  <Grid md={8}>
                    <CardHeader title="Internal company event" pb={0} pt={2} />
                    <CardContent>
                      <Typography variant="subtitle1">
                        Host a talk or presentation with speakers to educate and
                        engage your audience.
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button size="small" variant="outlined">
                        Learn More
                      </Button>
                      <Button
                        size="small"
                        variant="contained"
                        onClick={(e) => {
                          doChangeProps("2");
                        }}
                      >
                        Start
                      </Button>
                    </CardActions>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card p={5}>
                <Grid container>
                  <Grid md={4}>
                    <CardMedia
                      component="img"
                      height="140"
                      image="https://assets-versions-cdn.static.events.ringcentral.com/assets/packs/media/react/features/event-creation-wizard/assets/templates/recruitment-b69d68ef95037ccb071a77071479178d.png"
                      alt="green iguana"
                    />
                  </Grid>
                  <Grid md={8}>
                    <CardHeader title="Recruitment fair" pb={0} pt={2} />
                    <CardContent>
                      <Typography variant="subtitle1">
                        Host a talk or presentation with speakers to educate and
                        engage your audience.
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button size="small" variant="outlined">
                        Learn More
                      </Button>
                      <Button
                        size="small"
                        variant="contained"
                        onClick={(e) => {
                          doChangeProps("2");
                        }}
                      >
                        Start
                      </Button>
                    </CardActions>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const stepsVertical = [
  {
    label: "Select campaign settings",
    description: `For each ad campaign that you create, you can control how much
                you're willing to spend on clicks and conversions, which networks
                and geographical locations you want your ads to show on, and more.`,
  },
  {
    label: "Create an ad group",
    description:
      "An ad group contains one or more ads which target a shared set of keywords.",
  },
  {
    label: "Create an ad",
    description: `Try out different ad text to see what brings in the most customers,
                and learn how to enhance your ads using features like ad extensions.
                If you run into any problems with your ads, find out how to tell if
                they're running and how to resolve approval issues.`,
  },
];

const HoriziontalStepper = (props) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep}>
        {stepsHorizontal.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === stepsHorizontal.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )}

            <Button onClick={handleNext}>
              {activeStep === stepsHorizontal.length - 1 ? "Finish" : "Next"}
            </Button>
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
};

function CustomizedTableDemo() {
  return (
    <Card mb={6} mt={6}>
      <Paper>
        <Table>
          <TableBody>
            {rows.map((row) => (
              <CustomTableRow key={row.id}>
                <CustomTableCell component="th" scope="row">
                  UTC -12:00
                </CustomTableCell>
                <CustomTableCell align="right">
                  Canada/Yukon Time
                  <br />
                  <small style={{ color: "rgba(0, 0, 0, 0.6)" }}>
                    (UTC-07:00) Yukon
                  </small>
                </CustomTableCell>
              </CustomTableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Card>
  );
}

const EventEntryForm = (props) => {
  const { doChangeProps } = props;
  const [value, setValue] = useState("");
  const [open, setOpen] = useState(false);
  const Search = styled.div`
    border-radius: 2px;
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
    position: relative;
    width: 100%;

    &:hover {
      background-color: ${(props) =>
        darken(0.05, props.theme.header.background)};
    }
  `;
  const SearchIconWrapper = styled.div`
    width: 50px;
    height: 100%;
    position: absolute;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 22px;
      height: 22px;
    }
  `;
  const Input = styled(InputBase)`
    color: inherit;
    width: 100%;
    > input {
      color: ${(props) => props.theme.header.search.color};
      padding-top: ${(props) => props.theme.spacing(2.5)};
      padding-right: ${(props) => props.theme.spacing(2.5)};
      padding-bottom: ${(props) => props.theme.spacing(2.5)};
      padding-left: ${(props) => props.theme.spacing(12)};
    }
  `;

  const { t } = useTranslation();
  return (
    <Card mb={6}>
      <CardContent>
        {/* <Typography variant="h6" gutterBottom>
          Basic Information
        </Typography> */}

        <Grid container spacing={6}>
          <Grid item md={6}>
            <TextField
              id="username"
              label="Title"
              defaultValue=""
              placeholder="Give your event an exciting name"
              variant="outlined"
              fullWidth
              my={2}
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              id="username"
              label="Url"
              defaultValue=""
              placeholder="Type your unique url"
              variant="outlined"
              fullWidth
              my={2}
            />
          </Grid>
        </Grid>
        <Grid item md={12}>
          {/* <Typography variant="body2" gutterBottom>
            Description
          </Typography> */}
          <FormLabel id="demo-radio-buttons-group-label">Description</FormLabel>
          <QuillWrapper className="editor">
            <ReactQuill
              theme="snow"
              value={value}
              onChange={setValue}
              placeholder="Type something.."
            />
          </QuillWrapper>
          <TextField
            id="username"
            label="Duration"
            defaultValue=""
            placeholder="In Minutes"
            variant="outlined"
            fullWidth
            my={2}
          />
        </Grid>
        <Grid item md={6}>
          {/* <Typography variant="body2" gutterBottom>
            Assignment
          </Typography> */}
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              Assignment
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="female"
                control={<Radio />}
                label="Collective"
              />
              <FormControlLabel
                value="male"
                control={<Radio />}
                label="Round Robin"
              />
              <FormControlLabel
                value="male"
                control={<Radio />}
                label="Managed Event"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Paper mt={4}>
          <div>
            <Dialog
              open={open}
              onClose={() => setOpen(false)}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">
                Timezone
                <br />
                <small style={{ color: "rgba(0, 0, 0, 0.6)" }}>
                  Set the timezone in which your event is occuring
                </small>
              </DialogTitle>
              <DialogContent>
                <Typography>
                  <Search className="searchBar">
                    <SearchIconWrapper>
                      <SearchIcon />
                    </SearchIconWrapper>
                    <Input
                      placeholder={t("Search for a country or a timezone")}
                    />
                  </Search>
                </Typography>
                <CustomizedTableDemo />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpen(false)} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </Paper>

        <Button variant="contained" color="primary">
          Create Event
        </Button>
      </CardContent>
    </Card>
  );
};

function EventTypeAdd() {
  const [eventType, setEventType] = useState("0");
  return (
    <React.Fragment>
      <Helmet title="Add New Event Type" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Add New Event Type
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/calendar/dashboard">
              Dashboard
            </Link>
            <Link component={NavLink} to="/calendar/event-types">
              Events Type List
            </Link>
            <Typography>Add New Event Type</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            <Button
              variant="contained"
              color="inherit"
              sx={{ mx: "0.5rem" }}
              component={Link}
              to="/calendar/event-types"
            >
              <ArrowBackIcon />
              Back
            </Button>
          </div>
        </Grid>
      </Grid>
      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          {eventType == "0" ? (
            <EventType doChangeProps={setEventType} />
          ) : eventType == "1" ? (
            <EventSubType doChangeProps={setEventType} />
          ) : (
            <EventEntryForm />
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default EventTypeAdd;
