import React, { useState } from "react";
import styled from "@emotion/styled";
import ListItem from "@mui/material/ListItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import "../../../../Styles/Custom.css";
import Switch from "@mui/material/Switch";
import InputAdornment from "@mui/material/InputAdornment";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FormGroup from "@mui/material/FormGroup";

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Checkbox,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  Menu,
  MenuItem,
  InputBase,
  DialogActions,
  TextField,
  CardContent,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card as MuiCard,
  CardMedia as MuiCardMedia,
  CardActions,
  AvatarGroup as MuiAvatarGroup,
  FormControlLabel,
} from "@mui/material";
import { green, orange } from "@mui/material/colors";
import {
  Add as AddIcon,
  ArrowBack as ArrowBackIcon,
  FilterList,
  Download,
  Mail,
  ContentCopy,
  Visibility,
} from "@mui/icons-material";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import LanguageIcon from "@mui/icons-material/Language";
import { tableCellClasses } from "@mui/material/TableCell";
import { spacing } from "@mui/system";
const Divider = styled(MuiDivider)(spacing);

function EventEntryTab() {
  const [schedulingType, setSchedulingType] = useState("Collective");
  const handleSchedulingTypeChange = (event) => {
    setSchedulingType(event.target.value);
  };

  const [limitFutureBookings, setlimitFutureBookings] = useState("false");
  const handleLimitFutureBookingsChange = (event) => {
    setlimitFutureBookings(JSON.stringify(event.target.checked));
  };

  const [roundRobinHost, setRoundRobinHost] = useState("false");
  const handleRoundRobinHostChange = (event) => {
    setRoundRobinHost(JSON.stringify(event.target.checked));
  };

  return (
    <Grid justifyContent="space-between" container spacing={10}>
      <Grid item md={12}>
        <Typography variant="h3" gutterBottom display="inline">
          Assignment
        </Typography>
        <Typography>
          Schedule meetings when everyone is available or rotate through members
          on your team
        </Typography>
      </Grid>
      <Grid item md={12}>
        {/* <Typography variant="h6" gutterBottom>
            Basic Information
          </Typography> */}
        <Box style={{ marginBottom: "15px" }}>
          <Grid container spacing={6}>
            <Grid item md={12} style={{ marginBottom: "15px" }}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-helper-label">
                  Scheduling Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  autoFocus
                  value={schedulingType}
                  onChange={handleSchedulingTypeChange}
                  label="Scheduling Type"
                  inputProps={{
                    name: "max-width",
                    id: "max-width",
                  }}
                >
                  <MenuItem value="Collective">Collective</MenuItem>
                  <MenuItem value="Round Robin">Round Robin</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        {schedulingType == "Collective" ? (
          <>
            <Divider />
            <Box style={{ marginTop: "15px", marginBottom: "15px" }}>
              <Grid item md={12}>
                <ListItem alignItems="flex-start" disablePadding>
                  <Grid container>
                    <Grid md={6}>
                      <Typography variant="subtitle1">
                        Fixed Hosts
                        <Typography
                          color="success"
                          style={{ paddingLeft: "12px !important" }}
                        >
                          <small
                            style={{
                              position: "relative",
                              bottom: "4px",
                              paddingLeft: "2px",
                            }}
                          >
                            Add anyone who needs to attend the event
                          </small>
                        </Typography>
                      </Typography>
                    </Grid>
                    <Grid md={12}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              onChange={handleLimitFutureBookingsChange}
                            />
                          }
                          label="Add all team members, including future members"
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                </ListItem>
                {/* <Switch inputProps={{ "aria-label": "controlled" }} />
                <ListItemText primary="Allow booker to select duration" /> */}
              </Grid>
              {limitFutureBookings == "true" ? (
                <Grid container spacing={6} style={{ marginTop: "0px" }}>
                  <Grid item md={12}>
                    <Grid container spacing={6}>
                      <Grid item md={12}>
                        <Select
                          style={{ width: "100%" }}
                          autoFocus
                          value={setSchedulingType}
                          inputProps={{
                            name: "max-width",
                            id: "max-width",
                          }}
                        >
                          <MenuItem value="xsd">Shabbir Bakh</MenuItem>
                          <MenuItem value="ssm">James</MenuItem>
                          <MenuItem value="l">Niaz Khan</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item md={12}>
                        <Box>
                          <Grid
                            item
                            md={12}
                            style={{
                              padding: "15px",
                              border: "1px solid #c4c4c4",
                              marginBottom: "15px",
                            }}
                          >
                            <ListItem alignItems="flex-start" disablePadding>
                              <Grid container>
                                <Grid md={10}>
                                  <Typography variant="subtitle1">
                                    Niaz Khan
                                  </Typography>
                                </Grid>
                                <Grid md={2}>
                                  <DeleteIcon style={{ float: "right" }} />
                                </Grid>
                              </Grid>
                            </ListItem>
                          </Grid>
                        </Box>
                        <Box>
                          <Grid
                            item
                            md={12}
                            style={{
                              padding: "15px",
                              border: "1px solid #c4c4c4",
                              marginBottom: "15px",
                            }}
                          >
                            <ListItem alignItems="flex-start" disablePadding>
                              <Grid container>
                                <Grid md={10}>
                                  <Typography variant="subtitle1">
                                    James
                                  </Typography>
                                </Grid>
                                <Grid md={2}>
                                  <DeleteIcon style={{ float: "right" }} />
                                </Grid>
                              </Grid>
                            </ListItem>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <></>
              )}
            </Box>
          </>
        ) : (
          <>
            <Divider />
            <Box style={{ marginTop: "15px", marginBottom: "15px" }}>
              <Grid item md={12}>
                <ListItem alignItems="flex-start" disablePadding>
                  <Grid container>
                    <Grid md={6}>
                      <Typography variant="subtitle1">
                        Fixed Hosts
                        <Typography
                          color="success"
                          style={{ paddingLeft: "12px !important" }}
                        >
                          <small
                            style={{
                              position: "relative",
                              bottom: "4px",
                              paddingLeft: "2px",
                            }}
                          >
                            Add anyone who needs to attend the event
                          </small>
                        </Typography>
                      </Typography>
                    </Grid>
                    <Grid md={6} style={{ textAlign: "right" }}>
                      <Switch
                        inputProps={{ "aria-label": "controlled" }}
                        onChange={handleLimitFutureBookingsChange}
                      />
                    </Grid>
                  </Grid>
                </ListItem>
              </Grid>
              {limitFutureBookings == "true" ? (
                <Grid container spacing={6} style={{ marginTop: "0px" }}>
                  <Grid item md={12}>
                    <Grid container spacing={6}>
                      <Grid item md={12}>
                        <Select
                          style={{ width: "100%" }}
                          autoFocus
                          value={schedulingType}
                          inputProps={{
                            name: "max-width",
                            id: "max-width",
                          }}
                        >
                          <MenuItem value="xsd">Shabbir Bakh</MenuItem>
                          <MenuItem value="ssm">James</MenuItem>
                          <MenuItem value="l">Niaz Khan</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item md={12}>
                        <Box>
                          <Grid
                            item
                            md={12}
                            style={{
                              padding: "15px",
                              border: "1px solid #c4c4c4",
                              marginBottom: "15px",
                            }}
                          >
                            <ListItem alignItems="flex-start" disablePadding>
                              <Grid container>
                                <Grid md={10}>
                                  <Typography variant="subtitle1">
                                    Niaz Khan
                                  </Typography>
                                </Grid>
                                <Grid md={2}>
                                  <DeleteIcon style={{ float: "right" }} />
                                </Grid>
                              </Grid>
                            </ListItem>
                          </Grid>
                        </Box>
                        <Box>
                          <Grid
                            item
                            md={12}
                            style={{
                              padding: "15px",
                              border: "1px solid #c4c4c4",
                              marginBottom: "15px",
                            }}
                          >
                            <ListItem alignItems="flex-start" disablePadding>
                              <Grid container>
                                <Grid md={10}>
                                  <Typography variant="subtitle1">
                                    James
                                  </Typography>
                                </Grid>
                                <Grid md={2}>
                                  <DeleteIcon style={{ float: "right" }} />
                                </Grid>
                              </Grid>
                            </ListItem>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <></>
              )}
            </Box>
            <Divider />
            <Box style={{ marginTop: "15px", marginBottom: "15px" }}>
              <Grid item md={12}>
                <ListItem alignItems="flex-start" disablePadding>
                  <Grid container>
                    <Grid md={6}>
                      <Typography variant="subtitle1">
                        Round-Robin Hosts
                        <Typography
                          color="success"
                          style={{ paddingLeft: "12px !important" }}
                        >
                          <small
                            style={{
                              position: "relative",
                              bottom: "4px",
                              paddingLeft: "2px",
                            }}
                          >
                            People in the group take turns and only one person
                            will show up for the event
                          </small>
                        </Typography>
                      </Typography>
                    </Grid>
                    <Grid md={12}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch onChange={handleRoundRobinHostChange} />
                          }
                          label="Add all team members, including future members"
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                </ListItem>
              </Grid>
              {roundRobinHost == "true" ? (
                <Grid container spacing={6} style={{ marginTop: "0px" }}>
                  <Grid item md={12}>
                    <Grid container spacing={6}>
                      <Grid item md={12}>
                        <Select
                          style={{ width: "100%" }}
                          autoFocus
                          value={schedulingType}
                          inputProps={{
                            name: "max-width",
                            id: "max-width",
                          }}
                        >
                          <MenuItem value="xsd">Shabbir Bakh</MenuItem>
                          <MenuItem value="ssm">James</MenuItem>
                          <MenuItem value="l">Niaz Khan</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item md={12}>
                        <Box>
                          <Grid
                            item
                            md={12}
                            style={{
                              padding: "15px",
                              border: "1px solid #c4c4c4",
                              marginBottom: "15px",
                            }}
                          >
                            <ListItem alignItems="flex-start" disablePadding>
                              <Grid container>
                                <Grid md={10}>
                                  <Typography variant="subtitle1">
                                    Niaz Khan
                                  </Typography>
                                </Grid>
                                <Grid md={2}>
                                  <DeleteIcon style={{ float: "right" }} />
                                </Grid>
                              </Grid>
                            </ListItem>
                          </Grid>
                        </Box>
                        <Box>
                          <Grid
                            item
                            md={12}
                            style={{
                              padding: "15px",
                              border: "1px solid #c4c4c4",
                              marginBottom: "15px",
                            }}
                          >
                            <ListItem alignItems="flex-start" disablePadding>
                              <Grid container>
                                <Grid md={10}>
                                  <Typography variant="subtitle1">
                                    James
                                  </Typography>
                                </Grid>
                                <Grid md={2}>
                                  <DeleteIcon style={{ float: "right" }} />
                                </Grid>
                              </Grid>
                            </ListItem>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <></>
              )}
            </Box>
          </>
        )}
        ;
        <Button variant="contained" color="primary">
          Save
        </Button>
      </Grid>
    </Grid>
  );
}

function Assignment() {
  return (
    <React.Fragment>
      <EventEntryTab />
    </React.Fragment>
  );
}

export default Assignment;
