import React from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";

import { Badge, Grid, Avatar, Typography } from "@mui/material";

import useAuth from "../../hooks/useAuth";
import { Settings } from "react-feather";

const Footer = styled.div`
  background-color: ${(props) =>
    props.theme.sidebar.footer.background} !important;
  padding: ${(props) => props.theme.spacing(2.75)}
    ${(props) => props.theme.spacing(4)};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const FooterText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
`;

const FooterSubText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
  font-size: 0.7rem;
  display: block;
  padding: 1px;
`;

const FooterBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)};
  span {
    background-color: ${(props) =>
      props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

const SidebarFooter = ({ ...rest }) => {
  const { user } = useAuth();

  return (
    <Footer {...rest}>
      <Grid container spacing={2} style={{ marginTop: "0px" }}>
        <Grid md={4}>
          <Grid item>
            <FooterBadge
              overlap="circular"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              variant="dot"
            >
              {!!user && <Avatar alt={user.displayName} src={user.avatar} />}
              {/* Demo data */}
              {!user && (
                <Avatar
                  alt="Lucy Lavender"
                  src="/static/img/avatars/avatar-1.jpg"
                />
              )}
            </FooterBadge>
          </Grid>
        </Grid>
        <Grid md={6}>
          <Grid item>
            {!!user && (
              <FooterText variant="body2">{user.displayName}</FooterText>
            )}
            {/* Demo data */}
            {!user && <FooterText variant="body2">Lucy Lavender</FooterText>}
            <FooterSubText variant="caption">UX Designer</FooterSubText>
          </Grid>
        </Grid>
        <Grid md={2}>
          {/* <Settings
            style={{
              position: "absolute",
              bottom: "18px",
              cursor: "pointer",
              color: "#fff",
            }}
            component={Link}
            to="/settings/business-settings"
          /> */}
          <Link
            component={NavLink}
            color="primary"
            to="/settings/business-settings"
          >
            <Settings
              style={{
                position: "absolute",
                bottom: "18px",
                cursor: "pointer",
                color: "#fff",
              }}
            />
          </Link>
        </Grid>
      </Grid>
    </Footer>
  );
};

export default SidebarFooter;
