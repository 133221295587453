import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import ReactPerfectScrollbar from "react-perfect-scrollbar";
import { List } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "react-router-dom";
import { rgba, darken } from "polished";

import SidebarNavSection from "./SidebarNavSection";
import { Button, ListItemButton, ListItemText } from "@mui/material";
import {
  ArrowCircleLeftOutlined,
  KeyboardArrowRight,
  Workspaces,
} from "@mui/icons-material";

import "../../../vendor/perfect-scrollbar.css";
import { Grid } from "react-feather";

const baseScrollbar = (props) => css`
  background-color: ${props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  flex-grow: 1;
`;

const Scrollbar = styled.div`
  ${baseScrollbar}
`;

const PerfectScrollbar = styled(ReactPerfectScrollbar)`
  ${baseScrollbar}
`;

const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)};
  padding-bottom: ${(props) => props.theme.spacing(2.5)};
`;

const Item = styled(ListItemButton)`
  padding-top: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 2 : 3)};
  padding-bottom: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 2 : 3)};
  padding-left: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 14 : 8)};
  padding-right: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 4 : 7)};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.08);
    color: ${(props) => props.theme.sidebar.color};
  }
  &.${(props) => props.activeclassname} {
    background-color: ${(props) =>
      darken(0.03, props.theme.sidebar.background)};
    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const Title = styled(ListItemText)`
  margin: 0;
  span {
    color: ${(props) =>
      rgba(
        props.theme.sidebar.color,
        props.depth && props.depth > 0 ? 0.7 : 1
      )};
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    padding: 0 ${(props) => props.theme.spacing(4)};
  }
`;

const ArrowForwardIcon = styled(KeyboardArrowRight)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const SidebarNav = ({ items }) => {
  const [spaceType, setSpaceType] = React.useState("all");
  const [title, setTitle] = React.useState("Space A");
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const ScrollbarComponent = matches ? PerfectScrollbar : Scrollbar;

  return (
    <ScrollbarComponent>
      <List disablePadding>
        {spaceType === "all" ? (
          <Items>
            <Item>
              <ArrowCircleLeftOutlined
                onClick={(e) => {
                  setSpaceType("single");
                }}
              />
              <Title>Space List</Title>
            </Item>
            {items &&
              items.map((item) => (
                <SidebarNavSection
                  component="div"
                  key={item.title}
                  pages={item.pages}
                  title={item.title}
                  onClick={(e) => {
                    setSpaceType("single");
                  }}
                />
              ))}
          </Items>
        ) : (
          <Items>
            <Item
              depth="0"
              onClick={(e) => {
                setSpaceType("all");
              }}
              activeclassname="active"
            >
              <Workspaces />
              <Title depth="0"> {title} </Title>
              <ArrowForwardIcon fontSize="small" />
            </Item>
          </Items>
        )}
      </List>
    </ScrollbarComponent>
  );
};

export default SidebarNav;
