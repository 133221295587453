import React, { useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Copy, Search as SearchIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import { darken } from "polished";
import { Link } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import "../../../Styles/Custom.css";
import Switch from "@mui/material/Switch";

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Checkbox,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  Menu,
  MenuItem,
  InputBase,
  DialogActions,
  TextField,
  CardContent,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card as MuiCard,
  CardMedia as MuiCardMedia,
  CardActions,
  AvatarGroup as MuiAvatarGroup,
  FormControlLabel,
} from "@mui/material";
import { green, orange } from "@mui/material/colors";
import {
  Add as AddIcon,
  ArrowBack as ArrowBackIcon,
  FilterList,
  Download,
  Mail,
  ContentCopy,
  Visibility,
} from "@mui/icons-material";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

function EventEntryTab() {
  const [open, setOpen] = useState(false);
  const [age, setAge] = React.useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open, oo) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      style={{ width: "550px" }}
    >
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item style={{ margin: "15px", paddingBottom: "10px" }}>
          <Typography variant="h3" gutterBottom display="inline">
            Enable Early Access
          </Typography>
          <Typography>
            Participants who have registered for the event can now enter before
            the event begins. You can enable your attendees to build their
            agenda and explore networking opportunities in advance.
          </Typography>
        </Grid>
      </Grid>
      <List>
        <ListItem>
          <Grid container spacing={6}>
            <Grid item md={12} className="checkboxPadding">
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Send an email to the already registered participants to inform them about the early access"
                />
              </FormGroup>
            </Grid>
          </Grid>
        </ListItem>
      </List>
      <List>
        <ListItem>
          <Grid container spacing={6}>
            <Grid item md={12} className="checkboxPadding">
              <FormLabel id="demo-radio-buttons-group-label">
                Enable/disable activities available in early access
              </FormLabel>
              <List
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                }}
              >
                <Divider />
                <ListItem alignItems="flex-start">
                  <Grid container>
                    <Grid md={8}>
                      <ListItemText
                        primary="Messages"
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              Attendees will be able to send messages to other
                              participants
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </Grid>
                    <Grid md={4}>
                      <Switch
                        onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </Grid>
                  </Grid>
                </ListItem>
                <Divider />
                <ListItem alignItems="flex-start">
                  <Grid container>
                    <Grid md={8}>
                      <ListItemText
                        primary="Schedule meetings"
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              Attendees will be able to schedule meetings
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </Grid>
                    <Grid md={4}>
                      <Switch
                        onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </Grid>
                  </Grid>
                </ListItem>
                <Divider />
                <ListItem alignItems="flex-start">
                  <Grid container>
                    <Grid md={8}>
                      <ListItemText
                        primary="Booths"
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              Attendees will be able to enter the booths and
                              connect with the exhibitors.
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </Grid>
                    <Grid md={4}>
                      <Switch
                        onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </Grid>
                  </Grid>
                </ListItem>
                <Divider />
                <ListItem alignItems="flex-start">
                  <Grid container>
                    <Grid md={8}>
                      <ListItemText
                        primary="Feed"
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              Attendees will receive feed
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </Grid>
                    <Grid md={4}>
                      <Switch
                        onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </Grid>
                  </Grid>
                </ListItem>
                <Divider />
                <ListItem alignItems="flex-start">
                  <Grid container>
                    <Grid md={8}>
                      <ListItemText
                        primary="Lounge"
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              Attendees will be able to enter the lounge and
                              join the tables to interact with others.
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </Grid>
                    <Grid md={4}>
                      <Switch
                        onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </Grid>
                  </Grid>
                </ListItem>
                <Divider />
              </List>
            </Grid>
          </Grid>
        </ListItem>
      </List>
      <DialogActions>
        <Button onClick={toggleDrawer("right", false)} color="primary">
          Cancel
        </Button>
        <Button onClick={toggleDrawer("right", false)} color="primary">
          Done
        </Button>
      </DialogActions>
    </Box>
  );
  return (
    <Grid justifyContent="space-between" container spacing={10}>
      <Grid item md={12}>
        <Typography variant="h3" gutterBottom display="inline">
          Event Entry
        </Typography>
        <Typography>
          Configure what information should be captured during registration
        </Typography>
        <br />
        <Typography variant="h5" gutterBottom display="inline">
          Who can enter this event when it's live?
        </Typography>
        <Typography>
          Choose who can enter the event and how they register.
        </Typography>
        <br />
        <Grid container>
          <Grid md={8}>
            <Typography variant="h5" gutterBottom display="inline">
              <AddIcon style={{ position: "relative", top: "5px" }} />
              Anyone can enter via their unique link
            </Typography>
          </Grid>
          <Grid md={4}>
            <Link
              variant="contained"
              color="primary"
              onClick={toggleDrawer("right", true)}
              style={{
                textDecoration: "none",
                fontWeight: "bold",
                paddingRight: "15px",
                fontSize: "0.9rem",
              }}
            >
              {/* <EditIcon style={{ position: "relative", top: "5px" }} /> */}
              Edit
            </Link>
            <Drawer
              anchor={"right"}
              open={state["right"]}
              onClose={toggleDrawer("right", false)}
            >
              {list("right")}
            </Drawer>
            <Link
              variant="contained"
              color="primary"
              onClick={() => setOpen(true)}
              style={{
                textDecoration: "none",
                fontWeight: "bold",
                fontSize: "0.9rem",
              }}
            >
              {/* <AddIcon style={{ position: "relative", top: "5px" }} /> */}
              New Attendee
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <Paper mt={4}>
        <div>
          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title" mb={0}>
              Add New Attendee
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Add attendees to the attendee list. For a seamless one-click
                registration/entry for your attendees, please fill all mandatory
                form fields.
              </DialogContentText>
              <br />
              <Grid container spacing={6}>
                <Grid item md={6}>
                  <TextField
                    id="username"
                    label="First Name"
                    defaultValue=""
                    variant="outlined"
                    fullWidth
                    my={2}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    id="username"
                    label="Last Name"
                    defaultValue=""
                    variant="outlined"
                    fullWidth
                    my={2}
                  />
                </Grid>
                <Grid item md={12}>
                  <TextField
                    id="username"
                    label="Email Id"
                    defaultValue=""
                    variant="outlined"
                    fullWidth
                    my={2}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    id="username"
                    label="Job Title"
                    defaultValue=""
                    variant="outlined"
                    fullWidth
                    my={2}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    id="username"
                    label="Organization"
                    defaultValue=""
                    variant="outlined"
                    fullWidth
                    my={2}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    id="username"
                    label="City"
                    defaultValue=""
                    variant="outlined"
                    fullWidth
                    my={2}
                  />
                </Grid>
                <Grid item md={6}>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        country
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={age}
                        label="Age"
                        onChange={handleChange}
                      >
                        <MenuItem value={10}>Canada</MenuItem>
                        <MenuItem value={20}>Usa</MenuItem>
                        <MenuItem value={30}>India</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <FormControl>
                    {/* <FormLabel id="demo-radio-buttons-group-label">
                      Gender
                    </FormLabel> */}
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      name="radio-buttons-group"
                    >
                      <FormControlLabel
                        value="female"
                        control={<Radio />}
                        label="Register attendee"
                      />
                      <FormControlLabel
                        value="male"
                        control={<Radio />}
                        label="Invite Attendee"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item md={12} className="checkboxPadding">
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Send invitation email with unique access link"
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen(false)} color="primary">
                Cancel
              </Button>
              <Button onClick={() => setOpen(false)} color="primary">
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Paper>
    </Grid>
  );
}

function PreEventEntryTab() {
  const [open, setOpen] = useState(false);
  const [age, setAge] = React.useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open, oo) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      style={{ width: "350px" }}
    >
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item style={{ margin: "15px", paddingBottom: "10px" }}>
          <Typography variant="h3" gutterBottom display="inline">
            Edit event entry
          </Typography>
        </Grid>
      </Grid>
      <Grid item style={{ margin: "15px", paddingBottom: "10px" }}>
        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">
            Choose who can enter this event
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
          >
            <FormControlLabel
              value="female"
              control={<Radio />}
              label="Anyone can enter via their unique link (Recommended for public events)"
            />
            <FormControlLabel
              value="female1"
              control={<Radio />}
              label="Only invited people can enter"
            />
            <FormControlLabel
              value="female2"
              control={<Radio />}
              label="Anyone can enter after sign in              "
            />
            <FormControlLabel
              value="female3"
              control={<Radio />}
              label="Anyone can enter without sign in              "
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <DialogActions>
        <Button onClick={toggleDrawer("right", false)} color="primary">
          Cancel
        </Button>
        <Button onClick={toggleDrawer("right", false)} color="primary">
          Done
        </Button>
      </DialogActions>
    </Box>
  );
  return (
    <Grid item md={12}>
      <Typography variant="h3" gutterBottom display="inline">
        Pre & Post Event Entry
      </Typography>
      <br />
      <br />
      <Grid container>
        <Grid md={8}>
          <Typography variant="h5" gutterBottom display="inline">
            Pre-event Entry
          </Typography>
          <Typography>
            Allow people to enter the event before it opens so they can network
            and schedule meetings
          </Typography>
        </Grid>
        <Grid md={4}>
          <Link
            variant="contained"
            color="primary"
            onClick={toggleDrawer("right", true)}
            style={{
              textDecoration: "none",
              fontWeight: "bold",
              paddingRight: "15px",
              fontSize: "0.9rem",
            }}
          >
            {/* <EditIcon style={{ position: "relative", top: "5px" }} /> */}
            Edit
          </Link>
          <Drawer
            anchor={"right"}
            open={state["right"]}
            onClose={toggleDrawer("right", false)}
          >
            {list("right")}
          </Drawer>
          <Link
            variant="contained"
            color="error"
            style={{
              textDecoration: "none",
              fontWeight: "bold",
              fontSize: "0.9rem",
              color: "rgb(211, 47, 47)",
            }}
          >
            {/* <AddIcon style={{ position: "relative", top: "5px" }} /> */}
            Disable
          </Link>
        </Grid>
      </Grid>
      <br />
      <Grid container>
        <Grid md={8}>
          <Typography variant="h5" gutterBottom display="inline">
            Event Replay - Video Hosting
          </Typography>
          <Typography>
            Attendees can experience the event even after the event is
            completed. Event will be available in ‘Replay’ mode for attendees to
            watch the recordings. Interactive features like chats, polls, Q&A,
            etc will be disabled.
          </Typography>
        </Grid>
        <Grid md={4}>
          <Link
            variant="contained"
            color="primary"
            onClick={() => setOpen(true)}
            style={{
              textDecoration: "none",
              fontWeight: "bold",
              paddingRight: "15px",
              fontSize: "0.9rem",
            }}
          >
            {/* <EditIcon style={{ position: "relative", top: "5px" }} /> */}
            Learn More
          </Link>
          <Link
            variant="contained"
            color="primary"
            onClick={() => setOpen(true)}
            style={{
              textDecoration: "none",
              fontWeight: "bold",
              fontSize: "0.9rem",
            }}
          >
            {/* <AddIcon style={{ position: "relative", top: "5px" }} /> */}
            Upgrade Plan
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
}

function EventEntry() {
  return (
    <React.Fragment>
      <EventEntryTab />
      <Divider my={6} />
      <PreEventEntryTab />
    </React.Fragment>
  );
}

export default EventEntry;
