import React, { useState, useRef, useEffect } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Tabs from "@mui/material/Tabs";
import FormControl from "@mui/material/FormControl";
import {
  ChevronLeft,
  ChevronRight,
  Search as SearchIcon,
  User,
} from "react-feather";
import { darken } from "polished";
import { useTranslation } from "react-i18next";
import "../../../App.css";
import { Link, NavLink } from "react-router-dom";
import MenuList from "@mui/material/MenuList";
import {
  ScheduleComponent,
  Day,
  Week,
  WorkWeek,
  Month,
  Agenda,
  Inject,
  Resize,
  DragAndDrop,
} from "@syncfusion/ej2-react-schedule";

import {
  Badge,
  Paper,
  Typography,
  Divider as MuiDivider,
  List,
  ListItem,
  ListItemText,
  Grid,
  Card,
  IconButton,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableRow,
  DialogContentText,
  TextField,
  MenuItem,
  Avatar as MuiAvatar,
} from "@mui/material";

import { Group, PeopleAlt, PersonAdd } from "@mui/icons-material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import { tableCellClasses } from "@mui/material/TableCell";

import {
  AccessTime,
  CalendarMonth,
  Language,
  Videocam,
  ViewColumn,
  CalendarViewMonth,
  ModeEditOutline,
  Add as AddIcon,
} from "@mui/icons-material";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
const Divider = styled(MuiDivider)(spacing);

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = [
  { title: "The Shawshank Redemption", year: 1994 },
  { title: "The Godfather", year: 1972 },
  { title: "The Godfather: Part II", year: 1974 },
  { title: "The Dark Knight", year: 2008 },
  { title: "12 Angry Men", year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: "Pulp Fiction", year: 1994 },
  {
    title: "The Lord of the Rings: The Return of the King",
    year: 2003,
  },
];

const CenteredContent = styled.div`
  text-align: center;
`;

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;

const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 0 auto ${(props) => props.theme.spacing(2)};
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function Form() {
  return (
    <React.Fragment>
      <Wrapper style={{ width: "60%" }}>
        <Helmet title="Form" />
        <Grid justifyContent="space-between" container spacing={10}>
          <Grid container item>
            <FormControl>
              <Typography id="form-dialog-title" variant="h4">
                Form1
                <br />
                <DialogContentText>
                  This is a test description
                </DialogContentText>
              </Typography>
              <Paper>
                {/* <DialogContentText>This is a test description</DialogContentText> */}
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="First Name"
                  type="text"
                  fullWidth
                />
                <TextField
                  margin="dense"
                  id="name"
                  label="Last Name"
                  type="text"
                  fullWidth
                />
                <TextField
                  margin="dense"
                  id="name"
                  label="Email Address"
                  type="email"
                  fullWidth
                />
                <TextField
                  margin="dense"
                  id="name"
                  label="Mobile No"
                  type="number"
                  fullWidth
                />
              </Paper>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: "10px" }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Wrapper>
    </React.Fragment>
  );
}

export default Form;
