import { grid } from "@mui/system";
import { Code, Users, Settings, Grid } from "react-feather";
import WorkspacesIcon from "@mui/icons-material/Workspaces";

const gettingStartedSection = [
  {
    href: "/spaces/space-apps",
    icon: WorkspacesIcon,
    title: "Space A",
  },
  {
    href: "/spaces/space-apps1",
    icon: WorkspacesIcon,
    title: "Space B",
  },
  // {
  //   href: "/spaces",
  //   icon: WorkspacesIcon,
  //   title: "Space List",
  //   children: [
  //     {
  //       href: "/spaces/space-apps",
  //       title: "Space A",
  //     },
  //     {
  //       href: "/spaces/space-apps1",
  //       title: "Space B",
  //     },
  //   ],
  // },
];

const spaceItems = [
  {
    // title: "Getting started",
    pages: gettingStartedSection,
  },
  // {
  //   title: "Development",
  //   pages: developmentSection,
  // },
  // {
  //   title: "Help",
  //   pages: supportSection,
  // },
];

export default spaceItems;
