import {
  Dashboard,
  DesktopWindows,
  RingVolume,
  Timeline,
  Code,
  QrCode,
  Language,
  StarBorder,
  Settings,
  SmartToy,
  ArrowOutward,
  ContactSupport,
} from "@mui/icons-material";
const pagesSection = [
  {
    href: "/widget/dashboard",
    icon: Dashboard,
    title: "Dashboard",
  },
  {
    href: "/widget/visitors",
    icon: DesktopWindows,
    title: "Visitors",
  },
  {
    href: "/widget/incoming-requests",
    icon: RingVolume,
    title: "Incoming Requests",
  },
  {
    href: "/widget/lead-overview",
    icon: Timeline,
    title: "Lead Overview",
  },
  // {
  //   href: "",
  //   icon: ArrowOutward,
  //   title: "Online Meeting",
  // },
  {
    href: "/widget/channel-connect",
    icon: Code,
    title: "Channel Connect",
  },
  {
    href: "/widget/qr-connect",
    icon: QrCode,
    title: "QR Connect",
  },
  {
    href: "/widget/support-tickets",
    icon: ContactSupport,
    title: "Support Tickets",
  },
  // {
  //   href: "",
  //   icon: Language,
  //   title: "Translations",
  // },
  {
    href: "",
    icon: StarBorder,
    title: "Vega",
  },
  {
    href: "",
    icon: SmartToy,
    title: "GPT Connect",
  },
  {
    href: "/widget/settings",
    icon: Settings,
    title: "Settings",
  },
];
const widgetItems = [
  {
    title: "Widget",
    pages: pagesSection,
  },
  // {
  //   title: "Elements",
  //   pages: elementsSection,
  // },
  // {
  //   title: "Mira Pro",
  //   pages: docsSection,
  // },
];

export default widgetItems;
