import React, { useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Copy, Search as SearchIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import { darken } from "polished";
import { Link } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import "../../../Styles/Custom.css";
import Switch from "@mui/material/Switch";
import Break from "./Break";
import Session from "./Session";
import Track from "./Track";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import HotelIcon from "@mui/icons-material/Hotel";
import RepeatIcon from "@mui/icons-material/Repeat";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Checkbox,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  Menu,
  MenuItem,
  InputBase,
  DialogActions,
  TextField,
  CardContent,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card as MuiCard,
  CardMedia as MuiCardMedia,
  CardActions,
  AvatarGroup as MuiAvatarGroup,
  FormControlLabel,
} from "@mui/material";
import { green, orange } from "@mui/material/colors";
import {
  Add as AddIcon,
  ArrowBack as ArrowBackIcon,
  FilterList,
  Download,
  Mail,
  ContentCopy,
  Visibility,
} from "@mui/icons-material";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Chip = styled(MuiChip)`
  ${spacing};

  background: ${(props) => props.paid && green[500]};
  background: ${(props) => props.sent && orange[700]};
  color: ${(props) =>
    (props.paid || props.sent) && props.theme.palette.common.white};
`;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const ToolbarTitle = styled.div`
  min-width: 200px;
`;

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;

const checkboxStyle = `
  position:absolute;
  bottom:0;
`;

const Customer = styled.div`
  display: flex;
  align-items: center;
`;

function CustomizedTimeline() {
  return (
    <Timeline
      sx={{
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex: 0.1,
        },
      }}
    >
      <TimelineItem>
        <TimelineOppositeContent color="textSecondary">
          09:30 am 30 mins
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          Welcome Session 1
          <EditIcon
            style={{
              position: "relative",
              top: "5px",
              width: "1rem",
              marginLeft: "10px",
            }}
          />
          <DeleteIcon
            style={{
              position: "relative",
              top: "5px",
              color: "red",
              width: "1rem",
            }}
          />
          <Typography>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s
          </Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent color="textSecondary">
          10:00 am 30 mins
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          Break 1
          <EditIcon
            style={{
              position: "relative",
              top: "5px",
              width: "1rem",
              marginLeft: "10px",
            }}
          />
          <DeleteIcon
            style={{
              position: "relative",
              top: "5px",
              color: "red",
              width: "1rem",
            }}
          />
          <Typography>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s
          </Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent color="textSecondary">
          10:30 am 30 mins
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          Welcome Session 2
          <EditIcon
            style={{
              position: "relative",
              top: "5px",
              width: "1rem",
              marginLeft: "10px",
            }}
          />
          <DeleteIcon
            style={{
              position: "relative",
              top: "5px",
              color: "red",
              width: "1rem",
            }}
          />
          <Typography>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s
          </Typography>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
}

function Sessions() {
  const [open, setOpen] = useState(false);
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const toggleDrawer = (anchor, open, oo) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      style={{ width: "400px" }}
    >
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item style={{ margin: "15px", paddingBottom: "10px" }}>
          <Typography variant="h3" gutterBottom display="inline">
            Add Tracks
          </Typography>
          <Typography>
            Turn this ON if you want to host parallel sessions targeted at
            different audience segments
          </Typography>
        </Grid>
      </Grid>
      <List>
        <ListItem>
          <Grid container spacing={6}>
            <Grid item md={12} className="checkboxPadding">
              <List
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                }}
              >
                <Divider />
                <ListItem alignItems="flex-start">
                  <Grid container>
                    <Grid md={8}>
                      <ListItemText
                        primary="Is this a multi track event?"
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              Multi track events have specially curated sessions
                              organized by tracks. Each track has many sessions
                              targeted at a particular audience group.
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </Grid>
                    <Grid md={4}>
                      <Switch
                        onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </Grid>
                  </Grid>
                </ListItem>
                <Divider />
              </List>
              <List>
                <ListItem>
                  <Grid container spacing={6}>
                    <Grid item md={12} className="checkboxPadding">
                      <FormLabel id="demo-radio-buttons-group-label">
                        Create a track to group sessions curated for a specific
                        audience group
                      </FormLabel>
                      <List
                        sx={{
                          width: "100%",
                          bgcolor: "background.paper",
                        }}
                      >
                        <Divider />
                        <ListItem alignItems="flex-start">
                          <Grid container>
                            <Grid md={6}></Grid>
                            <Grid md={6}>
                              <Track />
                            </Grid>
                          </Grid>
                        </ListItem>
                        <Divider />
                        <ListItem alignItems="flex-start">
                          <Grid container>
                            <Grid md={8}>
                              <ListItemText
                                primary="Track A"
                                secondary={
                                  <React.Fragment>
                                    <Typography
                                      sx={{ display: "inline" }}
                                      component="span"
                                      variant="body2"
                                      color="text.primary"
                                    >
                                      This is a demo
                                    </Typography>
                                  </React.Fragment>
                                }
                              />
                            </Grid>
                            <Grid md={4}>
                              <EditIcon
                                style={{ position: "relative", top: "5px" }}
                              />
                              <DeleteIcon
                                style={{
                                  position: "relative",
                                  top: "5px",
                                  color: "red",
                                }}
                              />
                            </Grid>
                          </Grid>
                        </ListItem>
                        <Divider />
                        <ListItem alignItems="flex-start">
                          <Grid container>
                            <Grid md={8}>
                              <ListItemText
                                primary="Track B"
                                secondary={
                                  <React.Fragment>
                                    <Typography
                                      sx={{ display: "inline" }}
                                      component="span"
                                      variant="body2"
                                      color="text.primary"
                                    >
                                      This is a demo
                                    </Typography>
                                  </React.Fragment>
                                }
                              />
                            </Grid>
                            <Grid md={4}>
                              <EditIcon
                                style={{ position: "relative", top: "5px" }}
                              />
                              <DeleteIcon
                                style={{
                                  position: "relative",
                                  top: "5px",
                                  color: "red",
                                }}
                              />
                            </Grid>
                          </Grid>
                        </ListItem>
                        <Divider />
                        <ListItem alignItems="flex-start">
                          <Grid container>
                            <Grid md={8}>
                              <ListItemText
                                primary="Track C"
                                secondary={
                                  <React.Fragment>
                                    <Typography
                                      sx={{ display: "inline" }}
                                      component="span"
                                      variant="body2"
                                      color="text.primary"
                                    >
                                      This is a demo
                                    </Typography>
                                  </React.Fragment>
                                }
                              />
                            </Grid>
                            <Grid md={4}>
                              <EditIcon
                                style={{ position: "relative", top: "5px" }}
                              />
                              <DeleteIcon
                                style={{
                                  position: "relative",
                                  top: "5px",
                                  color: "red",
                                }}
                              />
                            </Grid>
                          </Grid>
                        </ListItem>
                        <Divider />
                      </List>
                    </Grid>
                  </Grid>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </ListItem>
      </List>
    </Box>
  );
  return (
    <React.Fragment>
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Sessions
          </Typography>
          <Typography>
            Choose from the different types of sessions to add to your event
            schedule
          </Typography>
        </Grid>
        <Grid item>
          <div>
            <Link
              variant="contained"
              color="primary"
              onClick={toggleDrawer("right", true)}
              style={{
                textDecoration: "none",
                fontWeight: "bold",
                fontSize: "0.9rem",
                marginRight: "1rem",
              }}
            >
              {/* <AddIcon style={{ position: "relative", top: "5px" }} /> */}
              Add Tracks
            </Link>
            <Drawer
              anchor={"right"}
              open={state["right"]}
              onClose={toggleDrawer("right", false)}
            >
              {list("right")}
            </Drawer>
            <Break />
            <Session />
          </div>
        </Grid>
      </Grid>
      <Divider my={6} />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item md={12}>
          <Grid md={8}>
            <span style={{ marginRight: "10px" }}> Tracks </span>
            <Button
              variant="contained"
              disabled
              sx={{ mr: 2, display: { xs: "none", md: "inline" } }}
              style={{ borderRadius: "30px" }}
            >
              All Tracks
            </Button>
            <Button
              variant="outlined"
              sx={{ mr: 2, display: { xs: "none", md: "inline" } }}
              style={{ borderRadius: "30px" }}
            >
              Track A
            </Button>
            <Button
              variant="outlined"
              sx={{ mr: 2, display: { xs: "none", md: "inline" } }}
              style={{ borderRadius: "30px" }}
            >
              Track B
            </Button>
            <Button
              variant="outlined"
              sx={{ mr: 2, display: { xs: "none", md: "inline" } }}
              style={{ borderRadius: "30px" }}
            >
              Track C
            </Button>
            <Button
              variant="outlined"
              sx={{ mr: 10, display: { xs: "none", md: "inline" } }}
              style={{ borderRadius: "30px" }}
            >
              Track D
            </Button>
          </Grid>
          {/* <Grid md={4}>

          </Grid> */}
          <Grid md={12} style={{ marginTop: "20px" }}>
            <Button
              variant="contained"
              disabled
              sx={{ mr: 2, display: { xs: "none", md: "inline" } }}
            >
              Dec 25 <br /> Mon
            </Button>
            <Button
              variant="contained"
              sx={{ mr: 2, display: { xs: "none", md: "inline" } }}
            >
              Dec 26 <br /> Tue
            </Button>
            <Button
              variant="contained"
              sx={{ mr: 2, display: { xs: "none", md: "inline" } }}
            >
              Dec 27 <br /> Wed
            </Button>
            <Button
              variant="contained"
              sx={{ mr: 2, display: { xs: "none", md: "inline" } }}
            >
              Dec 28 <br /> Thur
            </Button>
            <Button
              variant="contained"
              sx={{ mr: 10, display: { xs: "none", md: "inline" } }}
            >
              Dec 29 <br /> Fri
            </Button>
          </Grid>
          <CustomizedTimeline />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Sessions;
