import React, { useState, useRef, useEffect } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Tabs from "@mui/material/Tabs";
import { ChevronLeft, ChevronRight, Search as SearchIcon } from "react-feather";
import { darken } from "polished";
import { useTranslation } from "react-i18next";
// import "../../../App.css";
import { Link, NavLink } from "react-router-dom";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Switch from "@mui/material/Switch";

import {
  ScheduleComponent,
  Day,
  Week,
  WorkWeek,
  Month,
  Agenda,
  MonthAgenda,
  TimelineViews,
  TimelineMonth,
  Inject,
  Resize,
  DragAndDrop,
  ViewsDirective,
  ViewDirective,
  ResourcesDirective,
  ResourceDirective,
} from "@syncfusion/ej2-react-schedule";

import {
  Badge,
  Paper,
  Typography,
  Divider as MuiDivider,
  List,
  ListItem,
  ListItemText,
  Grid,
  Card,
  IconButton,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableRow,
  DialogContentText,
  TextField,
  Breadcrumbs as MuiBreadcrumbs,
  ToggleButtonGroup,
  ToggleButton,
  MenuItem,
  Checkbox,
  TableContainer,
  Chip as MuiChip,
} from "@mui/material";

import { PersonAdd } from "@mui/icons-material";

import { tableCellClasses } from "@mui/material/TableCell";

import {
  AccessTime,
  CalendarMonth,
  Language,
  Videocam,
  ViewColumn,
  CalendarViewMonth,
  ModeEditOutline,
  Add as AddIcon,
} from "@mui/icons-material";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const ChatContainer = styled(Grid)`
  width: 100%;
  height: 50vh;
  height: 100%;
`;

const ChatSidebar = styled(Grid)`
  border-right: 1px solid ${(props) => props.theme.palette.divider};
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: ${(props) => props.theme.palette.common.black};
    color: ${(props) => props.theme.palette.common.white};
  }
  &.${tableCellClasses.body} {
    font-size: 14px;
  }
`;

const CustomTableRow = styled(TableRow)`
  // &:nth-of-type(odd) {
  //   background-color: rgba(0, 0, 0, 0.025);
  // }
`;

const Chip = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 85%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
  margin-bottom: ${(props) => props.theme.spacing(4)};
`;

// Data
let id = 0;
function createData(name, calories, fat, carbs, protein) {
  id += 1;
  return { id, name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

function TableRows({ rows, tableRowRemove, onValUpdate }) {
  return rows.map((rowsData, index) => {
    var opt = "Email";
    const { name, email, profile } = rowsData;
    return (
      <tr key={index}>
        <td>
          <TextField
            id="username"
            label={opt}
            defaultValue={name}
            variant="outlined"
            fullWidth
            my={2}
            rows={4}
            maxRows={6}
            name="name"
            onChange={(event) => onValUpdate(index, event)}
            style={{ marginBottom: "10px" }}
          />
        </td>
        <td style={{ textAlign: "center" }}>
          <DeleteIcon onClick={() => tableRowRemove(index)} />
        </td>
      </tr>
    );
  });
}
var i = false;

function Table1() {
  const [rows, initRow] = useState([]);
  const addRowTable = () => {
    const data = {
      name: "",
    };
    initRow([...rows, data]);
  };
  const tableRowRemove = (index) => {
    const dataRow = [...rows];
    dataRow.splice(index, 1);
    initRow(dataRow);
  };
  const onValUpdate = (i, event) => {
    const { name, value } = event.target;
    const data = [...rows];
    data[i][name] = value;
    initRow(data);
  };

  if (i == false) {
    addRowTable();
    i = true;
  }
  return (
    <>
      <Typography variant="body2" gutterBottom>
        <button
          onClick={addRowTable}
          style={{
            border: "0",
            backgroundColor: "transparent",
            fontWeight: "bold",
            color: "#376fd1",
            width: "100%",
            textAlign: "right",
          }}
          color="primary"
        >
          Add
          <AddIcon style={{ position: "relative", top: "7px" }} />
        </button>
      </Typography>
      <table style={{ width: "100%" }}>
        <tbody>
          <TableRows
            rows={rows}
            tableRowRemove={tableRowRemove}
            onValUpdate={onValUpdate}
          />
        </tbody>
      </table>
    </>
  );
}

const WeekApp = () => {
  const [openModal, setOpenModal] = useState(false);
  const [showAddGuest, setShowAddGuest] = useState(false);

  const initialLoad = useRef(true);
  const scheduleRef = useRef(null);

  useEffect(() => {
    if (initialLoad.current) {
      const allDayAppointmentSection =
        scheduleRef.current.element.querySelector(
          ".e-all-day-appointment-section"
        );
      if (allDayAppointmentSection) {
        allDayAppointmentSection.click();
      }
      initialLoad.current = false;
    }
  }, []);

  let data = [
    {
      Id: 1,
      Subject: "James",
      StartTime: new Date(2018, 1, 11, 9, 30),
      EndTime: new Date(2018, 1, 11, 10, 0),
      CategoryColor: "#ef5350",
    },
    {
      Id: 111,
      Subject: "Niaz",
      StartTime: new Date(2018, 1, 11, 9, 30),
      EndTime: new Date(2018, 1, 11, 10, 0),
      CategoryColor: "#ef5350",
    },
    {
      Id: 1113,
      Subject: "Shabbir",
      StartTime: new Date(2018, 1, 11, 9, 30),
      EndTime: new Date(2018, 1, 11, 10, 0),
      CategoryColor: "#4caf50",
    },
    {
      Id: 1112,
      Subject: "Amit",
      StartTime: new Date(2018, 1, 11, 9, 30),
      EndTime: new Date(2018, 1, 11, 10, 0),
      CategoryColor: "#4caf50",
    },
    {
      Id: 1116,
      Subject: "James",
      StartTime: new Date(2018, 1, 11, 9, 30),
      EndTime: new Date(2018, 1, 11, 10, 0),
      CategoryColor: "#4caf50",
    },
    {
      Id: 12,
      Subject: "Shabbir",
      StartTime: new Date(2018, 1, 11, 9, 30),
      EndTime: new Date(2018, 1, 11, 10, 0),
      CategoryColor: "#4caf50",
    },
    {
      Id: 2,
      Subject: "Niaz",
      StartTime: new Date(2018, 1, 11, 9, 30),
      EndTime: new Date(2018, 1, 11, 10, 0),
      CategoryColor: "#4caf50",
    },
    {
      Id: 3,
      Subject: "Amit",
      StartTime: new Date(2018, 1, 13, 9, 30),
      EndTime: new Date(2018, 1, 13, 11, 0),
      CategoryColor: "#4caf50",
    },
    {
      Id: 4,
      Subject: "Shabbir",
      StartTime: new Date(2018, 1, 14, 13, 0),
      EndTime: new Date(2018, 1, 14, 14, 30),
      CategoryColor: "#4caf50",
    },
    {
      Id: 5,
      Subject: "Niaz",
      StartTime: new Date(2018, 1, 15, 12, 0),
      EndTime: new Date(2018, 1, 15, 14, 0),
      CategoryColor: "#4caf50",
    },
    {
      Id: 6,
      Subject: "James",
      StartTime: new Date(2018, 1, 15, 9, 30),
      EndTime: new Date(2018, 1, 15, 11, 0),
      CategoryColor: "#4caf50",
    },
    {
      Id: 7,
      Subject: "Amit",
      StartTime: new Date(2018, 1, 16, 11, 0),
      EndTime: new Date(2018, 1, 16, 12, 30),
      CategoryColor: "#4caf50",
    },
    {
      Id: 8,
      Subject: "Shabbir",
      StartTime: new Date(2018, 1, 17, 9, 0),
      EndTime: new Date(2018, 1, 17, 10, 0),
      CategoryColor: "#4caf50",
    },
    {
      Id: 9,
      Subject: "Amit",
      StartTime: new Date(2018, 1, 19, 11, 0),
      EndTime: new Date(2018, 1, 19, 13, 0),
      CategoryColor: "#4caf50",
    },
    {
      Id: 10,
      Subject: "James",
      StartTime: new Date(2018, 1, 21, 11, 0),
      EndTime: new Date(2018, 1, 21, 13, 0),
      CategoryColor: "#4caf50",
    },
    {
      Id: 11,
      Subject: "Shabbir",
      StartTime: new Date(2018, 1, 22, 9, 30),
      EndTime: new Date(2018, 1, 22, 11, 0),
      CategoryColor: "#4caf50",
    },
    {
      Id: 12,
      Subject: "Niaz",
      StartTime: new Date(2018, 1, 9, 10, 0),
      EndTime: new Date(2018, 1, 9, 11, 30),
      CategoryColor: "#4caf50",
    },
    {
      Id: 13,
      Subject: "Shabbir",
      StartTime: new Date(2018, 1, 7, 10, 30),
      EndTime: new Date(2018, 1, 7, 12, 30),
      CategoryColor: "#4caf50",
    },
    {
      Id: 14,
      Subject: "James",
      StartTime: new Date(2018, 1, 5, 10, 0),
      EndTime: new Date(2018, 1, 5, 11, 30),
      CategoryColor: "#4caf50",
    },
    {
      Id: 15,
      Subject: "Niaz",
      StartTime: new Date(2018, 1, 20, 9, 30),
      EndTime: new Date(2018, 1, 20, 11, 0),
      CategoryColor: "#4caf50",
    },
    {
      Id: 16,
      Subject: "Sky Gazers",
      StartTime: new Date(2018, 1, 23, 11, 0),
      EndTime: new Date(2018, 1, 23, 13, 0),
      CategoryColor: "#4caf50",
    },
    {
      Id: 17,
      Subject: "Shabbir",
      StartTime: new Date(2018, 1, 12, 5, 30),
      EndTime: new Date(2018, 1, 12, 7, 30),
      CategoryColor: "#4caf50",
    },
    {
      Id: 18,
      Subject: "Niaz",
      StartTime: new Date(2018, 1, 12, 17, 0),
      EndTime: new Date(2018, 1, 12, 18, 30),
      CategoryColor: "#4caf50",
    },
    {
      Id: 19,
      Subject: "James",
      StartTime: new Date(2018, 1, 15, 6, 0),
      EndTime: new Date(2018, 1, 15, 7, 30),
      CategoryColor: "#4caf50",
    },
    {
      Id: 20,
      Subject: "Niaz",
      StartTime: new Date(2018, 1, 15, 16, 0),
      EndTime: new Date(2018, 1, 15, 18, 0),
      CategoryColor: "#4caf50",
    },
  ];
  const eventSettings = { dataSource: data };

  const eventRendered = (args) => {
    const appointment = args.data;
    const categoryColor = appointment.CategoryColor;
    //args.element.style.backgroundColor = categoryColor;
    args.element.children[0].style.borderLeft = "4px solid " + categoryColor;
    console.log(args.element.children[0]);
  };

  return (
    <>
      <ScheduleComponent
        height="550px"
        ref={scheduleRef}
        selectedDate={new Date(2018, 1, 15)}
        eventSettings={eventSettings}
        views={["MonthAgenda"]}
        eventRendered={eventRendered}
      >
        <Inject services={[MonthAgenda]} />
      </ScheduleComponent>
    </>
  );
};

function WeekView() {
  const [maxWidth, setMaxWidth] = useState("sm");
  const handleMaxWidthChange = (event) => {
    setMaxWidth(event.target.value);
  };

  const [showTeamA, setShowTeamA] = useState("true");
  const handleShowTeamAChange = (event) => {
    setShowTeamA(JSON.stringify(event.target.checked));
  };

  const [showTeamB, setShowTeamB] = useState("false");
  const handleShowTeamBChange = (event) => {
    setShowTeamB(JSON.stringify(event.target.checked));
  };

  return (
    <ChatContainer container component={Card}>
      <ChatSidebar item xs={12} md={3} lg={3}>
        {/* <Divider my={6} /> */}
        <ListItem alignItems="flex-start" style={{ paddingBottom: "0" }}>
          <ListItemText>
            <Box>
              <Grid item md={12}>
                <ListItem alignItems="flex-start" disablePadding>
                  <Grid container>
                    <TableContainer>
                      <Table
                        aria-labelledby="tableTitle"
                        size={"medium"}
                        aria-label="enhanced table"
                      >
                        <TableBody>
                          <TableRow>
                            <TableCell
                              style={{ borderBottom: "0", textAlign: "center" }}
                            >
                              <Chip
                                label="Available"
                                style={{ background: "#4caf50" }}
                              />
                              <Chip
                                label="Booked"
                                style={{
                                  background: "#ef5350",
                                  marginLeft: "10px",
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </ListItem>
              </Grid>
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem alignItems="flex-start" style={{ paddingBottom: "0" }}>
          <ListItemText>
            <Box>
              <Grid item md={12}>
                <ListItem alignItems="flex-start" disablePadding>
                  <Grid container>
                    <Grid md={6}>
                      <Typography
                        variant="subtitle1"
                        style={{ fontWeight: "bold" }}
                      >
                        Team A
                        <Typography
                          color="success"
                          style={{ paddingLeft: "12px !important" }}
                        >
                          {/* <small
                            style={{
                              position: "relative",
                              bottom: "4px",
                              paddingLeft: "2px",
                            }}
                          >
                            Limit how many times this event can be booked
                          </small> */}
                        </Typography>
                      </Typography>
                    </Grid>
                    <Grid md={6} style={{ textAlign: "right" }}>
                      <Switch
                        inputProps={{ "aria-label": "controlled" }}
                        onChange={handleShowTeamAChange}
                        defaultChecked
                      />
                    </Grid>
                  </Grid>
                </ListItem>
              </Grid>
              {showTeamA == "true" ? (
                <div>
                  <TableContainer>
                    <Table
                      aria-labelledby="tableTitle"
                      size={"medium"}
                      aria-label="enhanced table"
                    >
                      <TableBody>
                        <TableRow>
                          <TableCell style={{ borderBottom: "0" }}>
                            <Typography
                              variant="h7"
                              gutterBottom
                              style={{ fontWeight: "bold" }}
                            >
                              Shabbir
                            </Typography>
                          </TableCell>
                          <TableCell style={{ borderBottom: "0" }}>
                            America/New York
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ borderBottom: "0" }}>
                            <Typography
                              variant="h7"
                              gutterBottom
                              style={{ fontWeight: "bold" }}
                            >
                              Niaz
                            </Typography>
                          </TableCell>
                          <TableCell style={{ borderBottom: "0" }}>
                            America/New York
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ borderBottom: "0" }}>
                            <Typography
                              variant="h7"
                              gutterBottom
                              style={{ fontWeight: "bold" }}
                            >
                              James
                            </Typography>
                          </TableCell>
                          <TableCell style={{ borderBottom: "0" }}>
                            Asia/Calcutta
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              ) : (
                <></>
              )}
            </Box>
          </ListItemText>
        </ListItem>
        <ListItem alignItems="flex-start" style={{ paddingBottom: "0" }}>
          <ListItemText>
            <Box>
              <Grid item md={12}>
                <ListItem alignItems="flex-start" disablePadding>
                  <Grid container>
                    <Grid md={6}>
                      <Typography
                        variant="subtitle1"
                        style={{ fontWeight: "bold" }}
                      >
                        Team B
                        <Typography
                          color="success"
                          style={{ paddingLeft: "12px !important" }}
                        >
                          {/* <small
                            style={{
                              position: "relative",
                              bottom: "4px",
                              paddingLeft: "2px",
                            }}
                          >
                            Limit how many times this event can be booked
                          </small> */}
                        </Typography>
                      </Typography>
                    </Grid>
                    <Grid md={6} style={{ textAlign: "right" }}>
                      <Switch
                        inputProps={{ "aria-label": "controlled" }}
                        onChange={handleShowTeamBChange}
                      />
                    </Grid>
                  </Grid>
                </ListItem>
              </Grid>
              {showTeamB == "true" ? (
                <div>
                  <TableContainer>
                    <Table
                      aria-labelledby="tableTitle"
                      size={"medium"}
                      aria-label="enhanced table"
                    >
                      <TableBody>
                        <TableRow>
                          <TableCell style={{ borderBottom: "0" }}>
                            <Typography
                              variant="h7"
                              gutterBottom
                              style={{ fontWeight: "bold" }}
                            >
                              Shabbir
                            </Typography>
                          </TableCell>
                          <TableCell style={{ borderBottom: "0" }}>
                            America/New York
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ borderBottom: "0" }}>
                            <Typography
                              variant="h7"
                              gutterBottom
                              style={{ fontWeight: "bold" }}
                            >
                              Niaz
                            </Typography>
                          </TableCell>
                          <TableCell style={{ borderBottom: "0" }}>
                            America/New York
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ borderBottom: "0" }}>
                            <Typography
                              variant="h7"
                              gutterBottom
                              style={{ fontWeight: "bold" }}
                            >
                              James
                            </Typography>
                          </TableCell>
                          <TableCell style={{ borderBottom: "0" }}>
                            Asia/Calcutta
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ borderBottom: "0" }}>
                            <Typography
                              variant="h7"
                              gutterBottom
                              style={{ fontWeight: "bold" }}
                            >
                              Amit
                            </Typography>
                          </TableCell>
                          <TableCell style={{ borderBottom: "0" }}>
                            Asia/Calcutta
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              ) : (
                <></>
              )}
            </Box>
          </ListItemText>
        </ListItem>
      </ChatSidebar>
      <ChatSidebar item xs={12} md={9} lg={9} style={{ border: "0" }}>
        {/* <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          <ListItem alignItems="flex-start">
            <Grid justifyContent="space-between" container spacing={10}>
              <Grid item style={{ paddingTop: "30px" }}>
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                  style={{ fontSize: "18px", fontWeight: "bold" }}
                >
                  Mar 13- 18, 2024
                </Typography>
                <ChevronLeft style={{ position: "relative", top: "5px" }} />
                <ChevronRight style={{ position: "relative", top: "5px" }} />
                <Button
                  variant="outlined"
                  color="inherit"
                  size="small"
                  style={{
                    padding: "8px",
                    position: "absolute",
                    top: "-4px",
                  }}
                >
                  Today
                </Button>
              </Grid>
              <Grid item style={{ paddingTop: "20px" }}>
                <div>
                  <TabContext value={tabValue}>
                    <Box
                      sx={{ borderBottom: 1, borderColor: "divider" }}
                      style={{ position: "relative", bottom: "15px" }}
                    >
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                      >
                        <Tab label="12h" value="1" />
                        <Tab label="24h" value="2" />
                      </TabList>
                    </Box>
                  </TabContext>
                </div>
              </Grid>
            </Grid>
          </ListItem>
        </List> */}
        <Grid item md={12}>
          <Grid container>
            <WeekApp />
          </Grid>
        </Grid>
      </ChatSidebar>
    </ChatContainer>
  );
}

function TeamAvailability() {
  const [open, setOpen] = useState(false);
  const [alignment, setAlignment] = React.useState("team-availability");

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  return (
    <React.Fragment>
      <Helmet title="Team Availability" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Team Availability
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/calendar/dashboard">
              Dashboard
            </Link>
            <Typography>Team Availability</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            <ToggleButtonGroup
              color="primary"
              value={alignment}
              exclusive
              onChange={handleChange}
              aria-label="Platform"
              style={{ paddingRight: "10px" }}
            >
              <ToggleButton
                value="my-availability"
                style={{ padding: "6px" }}
                component={NavLink}
                to="/calendar/my-availability"
              >
                My Availability
              </ToggleButton>
              <ToggleButton
                value="team-availability"
                style={{ padding: "6px" }}
              >
                Team Availability
              </ToggleButton>
            </ToggleButtonGroup>
            <Button
              variant="contained"
              color="primary"
              component={NavLink}
              to="/calendar/add-my-availability"
            >
              <AddIcon />
              New Availability
            </Button>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <WeekView />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default TeamAvailability;
