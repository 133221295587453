import React, { useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Search as SearchIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import { darken } from "polished";
import { Link } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
// import { ArrowBackIcon as BackIcon } from "@mui/icons-material/ArrowBack";
import Switch from "@mui/material/Switch";
import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Checkbox,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  Menu,
  MenuItem,
  InputBase,
  DialogActions,
  TextField,
  CardContent,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card as MuiCard,
  CardMedia as MuiCardMedia,
  CardActions,
  AvatarGroup as MuiAvatarGroup,
  FormControlLabel,
} from "@mui/material";
import { green, orange } from "@mui/material/colors";
import {
  Add as AddIcon,
  ArrowBack as ArrowBackIcon,
} from "@mui/icons-material";
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  CloudUpload as MuiCloudUpload,
} from "@mui/icons-material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Chip = styled(MuiChip)`
  ${spacing};

  background: ${(props) => props.paid && green[500]};
  background: ${(props) => props.sent && orange[700]};
  color: ${(props) =>
    (props.paid || props.sent) && props.theme.palette.common.white};
`;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const ToolbarTitle = styled.div`
  min-width: 200px;
`;

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;

const checkboxStyle = `
  position:absolute;
  bottom:0;
`;

const Customer = styled.div`
  display: flex;
  align-items: center;
`;

const CardMedia = styled(MuiCardMedia)`
  height: 220px;
`;

const CloudUpload = styled(MuiCloudUpload)(spacing);

const CenteredContent = styled.div`
  text-align: center;
`;

const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 0 auto ${(props) => props.theme.spacing(2)};
`;

function createData(
  customer,
  customerEmail,
  customerAvatar,
  status,
  amount,
  id,
  date
) {
  return { customer, customerEmail, customerAvatar, status, amount, id, date };
}

const rows = [
  createData(
    "Group A",
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    "A",
    0,
    "$530,00",
    "000112",
    "2023-01-02"
  ),
  createData(
    "Group B",
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    "D",
    2,
    "$209,00",
    "000114",
    "2023-02-13"
  ),
  createData(
    "Group C",
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    "E",
    2,
    "$535,00",
    "000117",
    "2023-03-04"
  ),
  createData(
    "Group D",
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    "E",
    2,
    "$678,00",
    "000115",
    "2023-02-17"
  ),
  createData(
    "Group E",
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    "G",
    0,
    "$314,00",
    "000119",
    "2023-03-28"
  ),
  createData(
    "Group F",
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    "J",
    0,
    "$864,00",
    "000113",
    "2023-01-23"
  ),
  createData(
    "Group G",
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    "L",
    2,
    "$341,00",
    "000118",
    "2023-03-14"
  ),
  createData(
    "Group H",
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    "M",
    2,
    "$781,00",
    "000116",
    "2023-02-22"
  ),
  createData(
    "Group I",
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    "M",
    0,
    "$273,00",
    "000121",
    "2023-05-31"
  ),
  createData(
    "Group J",
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    "S",
    0,
    "$713,00",
    "000120",
    "2023-04-25"
  ),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  { id: "customer", alignment: "left", label: "Group Name" },
  { id: "status", alignment: "left", label: "Status" },
  //{ id: "id", alignment: "right", label: "User ID" },
  //   { id: "amount", alignment: "right", label: "Amount" },
  { id: "date", alignment: "left", label: "Creation Date" },
  //   { id: "actions", alignment: "right", label: "Actions" },
];

const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const EnhancedTableToolbar = (props) => {
  const [viewStyle, setViewStyle] = React.useState("List");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { numSelected } = props;

  const Input = styled(InputBase)`
    color: inherit;
    width: 100%;

    > input {
      color: ${(props) => props.theme.header.search.color};
      padding-top: ${(props) => props.theme.spacing(2.5)};
      padding-right: ${(props) => props.theme.spacing(2.5)};
      padding-bottom: ${(props) => props.theme.spacing(2.5)};
      padding-left: ${(props) => props.theme.spacing(12)};
      width: 160px;
    }
  `;

  const { t } = useTranslation();

  const Search = styled.div`
    border-radius: 2px;
    background-color: ${(props) => props.theme.header.background};
    display: none;
    position: relative;
    width: 100%;

    &:hover {
      background-color: ${(props) =>
        darken(0.05, props.theme.header.background)};
    }

    ${(props) => props.theme.breakpoints.up("md")} {
      display: block;
    }
  `;

  const SearchIconWrapper = styled.div`
    width: 50px;
    height: 100%;
    position: absolute;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 22px;
      height: 22px;
    }
  `;

  const { doChangeProps } = props;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    setAnchorEl(null);
  };

  return (
    <Toolbar style={{ paddingLeft: "0" }}>
      <ToolbarTitle>
        {numSelected > 0 ? (
          <Typography
            color="inherit"
            variant="subtitle1"
            style={{ paddingLeft: "12px" }}
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Grid container>
            {viewStyle === "Card" ? (
              <Grid md={2}>
                <Checkbox />
              </Grid>
            ) : (
              <div></div>
            )}
            <Grid md={10}>
              <Typography>
                <Search>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <Input placeholder={t("Search")} />
                </Search>
              </Typography>
            </Grid>
          </Grid>
        )}
      </ToolbarTitle>
      <Spacer />
      <Grid container display="block" textAlign="right">
        {numSelected === 1 ? (
          <Grid>
            <Tooltip title="Edit">
              <IconButton aria-label="Edit" size="large">
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton aria-label="Delete" size="large">
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        ) : numSelected > 1 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete" size="large">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Paper>
            <Button
              aria-owns={anchorEl ? "simple-menu" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              variant="contained"
              color="secondary"
            >
              View
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={(e) => {
                  doChangeProps("List");
                  setViewStyle("List");
                  setAnchorEl(null);
                }}
              >
                List
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  doChangeProps("Card");
                  setViewStyle("Card");
                  setAnchorEl(null);
                }}
              >
                Card
              </MenuItem>
            </Menu>
          </Paper>
        )}
      </Grid>
    </Toolbar>
  );
};

function EnhancedTable() {
  const [viewStyle, setViewStyle] = useState("List");
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("customer");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      // setViewStyle("Card");
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div>
      <Paper>
        <EnhancedTableToolbar
          numSelected={selected.length}
          doChangeProps={setViewStyle}
        />
      </Paper>
      {/* <ViewMenu doChangeProps={setViewStyle} /> */}
      {viewStyle === "List" ? (
        <Paper>
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size={"medium"}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={`${row.id}-${index}`}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                            onClick={(event) => handleClick(event, row.id)}
                          />
                        </TableCell>
                        <TableCell component="th" id={labelId} scope="row">
                          <Customer>
                            <Avatar>{row.customerAvatar}</Avatar>
                            <Box ml={3}>
                              {row.customer}
                              <br />
                              {row.customerEmail}
                            </Box>
                          </Customer>
                        </TableCell>
                        <TableCell>
                          {row.status === 0 && (
                            <Chip
                              size="small"
                              mr={1}
                              mb={1}
                              label="Inactive"
                              sent={+true}
                            />
                          )}
                          {/* {row.status === 1 && (
                            <Chip size="small" mr={1} mb={1} label="Void" />
                          )} */}
                          {row.status === 2 && (
                            <Chip
                              size="small"
                              mr={1}
                              mb={1}
                              label="Active"
                              paid={+true}
                            />
                          )}
                        </TableCell>
                        {/* <TableCell align="right">#{row.id}</TableCell> */}
                        {/* <TableCell align="right">{row.amount}</TableCell> */}
                        <TableCell>{row.date}</TableCell>
                        {/* <TableCell align="right">
                          <IconButton aria-label="delete" size="large">
                            <ArchiveIcon />
                          </IconButton>
                          <IconButton
                            aria-label="details"
                            component={NavLink}
                              to="/invoices/detail"
                            size="large"
                          >
                            <EditIcon />
                          </IconButton>
                        </TableCell> */}
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={7} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <Box m={1} pt={3} style={{ margin: "0" }}>
          <Grid container spacing={6}>
            <Grid item xs={12} lg={6} xl={3}>
              <Project
                title="Group A"
                description="Lorem Ipsum is simply dummy text of the printing and typesetting industry"
                chip={<Chip label="Inactive" color="warning" />}
                image="/static/img/avatars/avatar-1.jpg"
              />
            </Grid>
            <Grid item xs={12} lg={6} xl={3}>
              <Project
                title="Group B"
                description="Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum."
                chip={<Chip label="Active" color="success" />}
                image="/static/img/avatars/avatar-1.jpg"
              />
            </Grid>
            <Grid item xs={12} lg={6} xl={3}>
              <Project
                title="Group C"
                description="Nam pretium turpis et arcu. Duis arcu tortor, suscipit eget, imperdiet nec, imperdiet iaculis, ipsum. Sed aliquam ultrices mauris."
                chip={<Chip label="Active" color="success" />}
                image="/static/img/avatars/avatar-1.jpg"
              />
            </Grid>
            <Grid item xs={12} lg={6} xl={3}>
              <Project
                title="Group D"
                description="Curabitur ligula sapien, tincidunt non, euismod vitae, posuere imperdiet, leo. Maecenas malesuada. Praesent congue erat at massa."
                chip={<Chip label="Active" color="success" />}
                image="/static/img/avatars/avatar-1.jpg"
              />
            </Grid>
          </Grid>
        </Box>
      )}
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}

const AvatarGroup = styled(MuiAvatarGroup)`
  margin-left: ${(props) => props.theme.spacing(2)};
`;

const Project = ({ image, title, description, chip }) => {
  return (
    <Paper>
      <Card>
        {image ? (
          <div
            style={{
              position: "relative",
            }}
          >
            <CardMedia image={image} title="Contemplative Reptile" />
            <Checkbox
              value="remember"
              color="primary"
              style={{ position: "absolute", top: "0px", left: "0px" }}
            />
          </div>
        ) : null}
        <CardContent>
          <Typography gutterBottom variant="h4" component="h2">
            {title}
          </Typography>
          <Typography mb={4} color="textSecondary" component="p">
            2023-03-01
            <br />
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry
          </Typography>
          {chip}
          {/* <AvatarGroup max={3}>
          <Avatar alt="Avatar" src="/static/img/avatars/avatar-1.jpg" />
          <Avatar alt="Avatar" src="/static/img/avatars/avatar-2.jpg" />
          <Avatar alt="Avatar" src="/static/img/avatars/avatar-3.jpg" />
        </AvatarGroup> */}
        </CardContent>
        {/* <CardActions>
        <Button size="small" color="primary">
          Share
        </Button>
        <Button size="small" color="primary">
          Learn More
        </Button>
      </CardActions> */}
      </Card>
    </Paper>
  );
};

function Groups() {
  const [open, setOpen] = useState(false);
  const [age, setAge] = React.useState("");
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const toggleDrawer = (anchor, open, oo) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      style={{ width: "350px" }}
    >
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item style={{ margin: "15px", paddingBottom: "10px" }}>
          <Typography variant="h3" gutterBottom display="inline">
            Add Group
          </Typography>
        </Grid>
      </Grid>
      <Grid item style={{ margin: "15px", paddingBottom: "10px" }}>
        <Grid container spacing={6}>
          <Grid item md={12}>
            <CenteredContent>
              <BigAvatar
                alt="Remy Sharp"
                src="/static/img/avatars/avatar-1.jpg"
              />
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="raised-button-file"
                multiple
                type="file"
              />
              <label htmlFor="raised-button-file">
                <Button variant="contained" color="primary" component="span">
                  <CloudUpload mr={2} /> Upload Group Avatar
                </Button>

                <Typography variant="caption" display="block" gutterBottom>
                  For best results, use an image at least 128px by 128px in .jpg
                  format
                </Typography>
              </label>
            </CenteredContent>
          </Grid>
          <Grid item md={12}>
            <TextField
              id="username"
              label="Group Name"
              defaultValue=""
              variant="outlined"
              fullWidth
              my={2}
            />
          </Grid>
          <Grid item md={12}>
            <TextField
              id="username"
              label="Description"
              defaultValue=""
              variant="outlined"
              fullWidth
              my={2}
              multiline
              rows={4}
              maxRows={6}
            />
          </Grid>
          <Grid item md={12} style={{ paddingTop: "15px" }}>
            <Grid container>
              <Grid md={8}>
                <ListItemText
                  primary="Widget"
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      ></Typography>
                    </React.Fragment>
                  }
                />
              </Grid>
              <Grid md={4}>
                <Switch
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12} style={{ paddingTop: "15px" }}>
            <Grid container>
              <Grid md={8}>
                <ListItemText
                  primary="Calendar"
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      ></Typography>
                    </React.Fragment>
                  }
                />
              </Grid>
              <Grid md={4}>
                <Switch
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12} style={{ paddingTop: "15px" }}>
            <Grid container>
              <Grid md={8}>
                <ListItemText
                  primary="Events"
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      ></Typography>
                    </React.Fragment>
                  }
                />
              </Grid>
              <Grid md={4}>
                <Switch
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DialogActions>
        <Button onClick={toggleDrawer("right", false)} color="primary">
          Cancel
        </Button>
        <Button onClick={toggleDrawer("right", false)} color="primary">
          Done
        </Button>
      </DialogActions>
    </Box>
  );
  return (
    <React.Fragment>
      <Helmet title="Groups" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Groups
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/spaces/space-apps">
              Dashboard
            </Link>
            <Link component={NavLink} to="/spaces/space-apps">
              Settings
            </Link>
            <Typography>Groups</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            <Button
              variant="contained"
              color="inherit"
              sx={{ mx: "0.5rem" }}
              component={Link}
              to="/dashboard"
            >
              <ArrowBackIcon />
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={toggleDrawer("right", true)}
            >
              <AddIcon />
              New Group
            </Button>
            <Drawer
              anchor={"right"}
              open={state["right"]}
              onClose={toggleDrawer("right", false)}
            >
              {list("right")}
            </Drawer>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
      <Paper mt={4}>
        <div>
          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Add New Space</DialogTitle>
            <DialogContent>
              <DialogContentText>
                To subscribe to this website, please enter your email address
                here. We will send updates occasionally.
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="First Name"
                type="text"
                fullWidth
              />
              <TextField
                margin="dense"
                id="name"
                label="Last Name"
                type="text"
                fullWidth
              />
              <TextField
                margin="dense"
                id="name"
                label="Email Address"
                type="email"
                fullWidth
              />
              <TextField
                margin="dense"
                id="name"
                label="Mobile No"
                type="number"
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen(false)} color="primary">
                Cancel
              </Button>
              <Button onClick={() => setOpen(false)} color="primary">
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Paper>
    </React.Fragment>
  );
}

export default Groups;
