import React, { useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Copy, Search as SearchIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import { darken } from "polished";
import { Link } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import "../../../../Styles/Custom.css";
import Switch from "@mui/material/Switch";
import MenuList from "@mui/material/MenuList";
import Profile from "../../../pages/Calendar/Teams/Profile";
import Members from "../../../pages/Calendar/Teams/Members";
import Appearance from "../../../pages/Calendar/Teams/Appearance";

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Checkbox,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  Menu,
  MenuItem,
  InputBase,
  DialogActions,
  TextField,
  CardContent,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card as MuiCard,
  CardMedia as MuiCardMedia,
  CardActions,
  AvatarGroup as MuiAvatarGroup,
  FormControlLabel,
} from "@mui/material";
import { green, orange } from "@mui/material/colors";
import {
  Add as AddIcon,
  ArrowBack as ArrowBackIcon,
  FilterList,
  Download,
  Mail,
  ContentCopy,
  Visibility,
  Code as CodeIcon,
  Share,
} from "@mui/icons-material";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import LinkIcon from "@mui/icons-material/Link";
import CommentIcon from "@mui/icons-material/Comment";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function RegistrationTab() {
  const [RTab, setRTab] = React.useState("Profile");

  return (
    <Grid container>
      <Grid md={3}>
        <Paper>
          <MenuList style={{ height: "550px" }}>
            {RTab == "Profile" ? (
              <MenuItem
                onClick={(e) => {
                  setRTab("Profile");
                }}
                style={{
                  borderLeft: "3px solid",
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                  minHeight: "50px",
                }}
              >
                <ListItem
                  disableGutters
                  secondaryAction={
                    <IconButton aria-label="comment">
                      <KeyboardArrowRightIcon />
                    </IconButton>
                  }
                >
                  <ListItemText
                    primary="Profile"
                    // secondary={
                    //   <React.Fragment>
                    //     <Typography
                    //       sx={{ display: "inline" }}
                    //       component="span"
                    //       variant="body2"
                    //       color="text.primary"
                    //     >
                    //       30 mins
                    //     </Typography>
                    //   </React.Fragment>
                    // }
                  />
                </ListItem>
              </MenuItem>
            ) : (
              <MenuItem
                onClick={(e) => {
                  setRTab("Profile");
                }}
                style={{ minHeight: "50px" }}
              >
                <ListItem disableGutters>
                  <ListItemText
                    primary="Profile"
                    // secondary={
                    //   <React.Fragment>
                    //     <Typography
                    //       sx={{ display: "inline" }}
                    //       component="span"
                    //       variant="body2"
                    //       color="text.primary"
                    //     >
                    //       30 mins
                    //     </Typography>
                    //   </React.Fragment>
                    // }
                  />
                </ListItem>
              </MenuItem>
            )}
            {RTab == "Members" ? (
              <MenuItem
                onClick={(e) => {
                  setRTab("Members");
                }}
                style={{
                  borderLeft: "3px solid",
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                  minHeight: "50px",
                }}
              >
                <ListItem
                  disableGutters
                  secondaryAction={
                    <IconButton aria-label="comment">
                      <KeyboardArrowRightIcon />
                    </IconButton>
                  }
                >
                  <ListItemText
                    primary="Members"
                    // secondary={
                    //   <React.Fragment>
                    //     <Typography
                    //       sx={{ display: "inline" }}
                    //       component="span"
                    //       variant="body2"
                    //       color="text.primary"
                    //     >
                    //       Working Hours
                    //     </Typography>
                    //   </React.Fragment>
                    // }
                  />
                </ListItem>
              </MenuItem>
            ) : (
              <MenuItem
                onClick={(e) => {
                  setRTab("Members");
                }}
                style={{ minHeight: "50px" }}
              >
                <ListItem disableGutters>
                  <ListItemText
                    primary="Members"
                    // secondary={
                    //   <React.Fragment>
                    //     <Typography
                    //       sx={{ display: "inline" }}
                    //       component="span"
                    //       variant="body2"
                    //       color="text.primary"
                    //     >
                    //       Working Hours
                    //     </Typography>
                    //   </React.Fragment>
                    // }
                  />
                </ListItem>
              </MenuItem>
            )}
            {RTab == "Appearance" ? (
              <MenuItem
                onClick={(e) => {
                  setRTab("Appearance");
                }}
                style={{
                  borderLeft: "3px solid",
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                  minHeight: "50px",
                }}
              >
                <ListItem
                  disableGutters
                  secondaryAction={
                    <IconButton aria-label="comment">
                      <KeyboardArrowRightIcon />
                    </IconButton>
                  }
                >
                  <ListItemText
                    primary="Appearance"
                    // secondary={
                    //   <React.Fragment>
                    //     <Typography
                    //       sx={{ display: "inline" }}
                    //       component="span"
                    //       variant="body2"
                    //       color="text.primary"
                    //     >
                    //       Collective
                    //     </Typography>
                    //   </React.Fragment>
                    // }
                  />
                </ListItem>
              </MenuItem>
            ) : (
              <MenuItem
                onClick={(e) => {
                  setRTab("Appearance");
                }}
                style={{ minHeight: "50px" }}
              >
                <ListItem disableGutters>
                  <ListItemText
                    primary="Appearance"
                    // secondary={
                    //   <React.Fragment>
                    //     <Typography
                    //       sx={{ display: "inline" }}
                    //       component="span"
                    //       variant="body2"
                    //       color="text.primary"
                    //     >
                    //       Collective
                    //     </Typography>
                    //   </React.Fragment>
                    // }
                  />
                </ListItem>
              </MenuItem>
            )}
          </MenuList>
        </Paper>
      </Grid>
      <Grid md={9} sx={{ paddingLeft: "25px" }}>
        {RTab == "Profile" ? (
          <Profile />
        ) : RTab == "Members" ? (
          <Members />
        ) : (
          <Appearance />
        )}
      </Grid>
    </Grid>
  );
}

function TeamEdit() {
  return (
    <React.Fragment>
      <Helmet title="Edit Team" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Edit Team
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/calendar/dashboard">
              Dashboard
            </Link>
            <Link component={NavLink} to="/calendar/teams">
              Teams List
            </Link>
            <Typography>Edit Team</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            <Button
              variant="contained"
              color="inherit"
              sx={{ mx: "0.5rem" }}
              component={Link}
              to="/calendar/teams"
            >
              <ArrowBackIcon />
              Back
            </Button>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <RegistrationTab />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default TeamEdit;
