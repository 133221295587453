import React, { useState } from "react";
import styled from "@emotion/styled";
import { NavLink, json } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Copy, Image, Search as SearchIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import { darken } from "polished";
import { Link } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import "../../../../Styles/Custom.css";
import Switch from "@mui/material/Switch";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import Avatar from "@mui/material/Avatar";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { red } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import CardActions from "@mui/material/CardActions";
import PropTypes from "prop-types";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import PersonIcon from "@mui/icons-material/Person";
import { blue } from "@mui/material/colors";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import ReactQuill from "react-quill";
import { MuiColorInput } from "mui-color-input";
import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  Paper as MuiPaper,
  TableCell,
  TableRow,
  Typography,
  Card as MuiCard,
  CardMedia as MuiCardMedia,
  AvatarGroup as MuiAvatarGroup,
  FormControlLabel,
  Alert as MuiAlert,
} from "@mui/material";

import { green, orange } from "@mui/material/colors";

import {
  Add as AddIcon,
  ArrowBack as ArrowBackIcon,
  FilterList,
  Download,
  Mail,
  ContentCopy,
  Visibility,
  CloudUpload as MuiCloudUpload,
  FileUpload,
  Videocam,
  InsertPhoto,
} from "@mui/icons-material";

import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import { tableCellClasses } from "@mui/material/TableCell";
import { spacing } from "@mui/system";

import Code from "../../../../components/Code";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);
const Alert = styled(MuiAlert)(spacing);
const CustomTableRow = styled(TableRow)`
  // &:nth-of-type(odd) {
  //   background-color: rgba(0, 0, 0, 0.025);
  // }
`;
const CloudUpload = styled(MuiCloudUpload)(spacing);
const QuillWrapper = styled.div`
  .ql-editor {
    min-height: 200px;
  }
`;
const CenteredContent = styled.div`
  text-align: center;
`;
const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 0 auto ${(props) => props.theme.spacing(2)};
`;
const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: ${(props) => props.theme.palette.common.black};
    color: ${(props) => props.theme.palette.common.white};
  }
  &.${tableCellClasses.body} {
    font-size: 14px;
  }
`;
// Data
let id = 0;
function createData(name, calories, fat, carbs, protein) {
  id += 1;
  return { id, name, calories, fat, carbs, protein };
}

function Text_Colors({ handleChildData }) {
  const [ButtonStyle, setButtonStyle] = React.useState("Button");
  const [Mode, setMode] = React.useState("Standard");
  const sendDataToParent = (d) => {
    handleChildData(d);
  };

  const [IsCustomizeableButton, setIsCustomizeableButton] = useState("false");
  const handleCustomizeableButtonChange = (event) => {
    setIsCustomizeableButton(JSON.stringify(event.target.checked));
  };
  const [value, setValue] = React.useState("#ffffff");

  const handleChange = (newValue) => {
    setValue(newValue);
  };
  return (
    <Grid justifyContent="space-between" container>
      <Grid item md={12}>
        <Typography variant="h3" gutterBottom>
          Customise Your Widget
        </Typography>
        <Alert mt={3} mb={3} severity="info">
          Please create all your texts in English or leave the default texts.
          You can manage the translations here Translations. We always show the
          widget in the browser language the user has chosen. If this is not
          available, English will be default
        </Alert>
        <Grid container spacing={6}>
          <Grid item md={5} style={{ marginBottom: "15px" }}>
            <Typography variant="h6" gutterBottom>
              1. Button + Text
            </Typography>
            <Box mb={4} mt={4}>
              <Grid item md={12} mb={4}>
                <FormControl fullWidth my={2} variant="outlined">
                  <TextField
                    label="Speech Bubble Text"
                    id="biography"
                    multiline={true}
                    rows={3}
                    maxRows={4}
                    variant="outlined"
                    defaultValue="May I help you further? I would be happy to support you personally and immediately."
                  />
                </FormControl>
              </Grid>
              <Grid item md={12} mb={4}>
                <FormControl fullWidth my={2} variant="outlined">
                  <TextField
                    label="Call To Action Button"
                    id="biography"
                    variant="outlined"
                    defaultValue="Yes gladly"
                  />
                </FormControl>
              </Grid>
              <Grid item md={12} mb={4}>
                <FormControl fullWidth my={2} variant="outlined">
                  <TextField
                    label="Slogan"
                    id="biography"
                    variant="outlined"
                    defaultValue="Let's Conn777"
                  />
                </FormControl>
              </Grid>
            </Box>
            <Typography variant="h6" gutterBottom>
              2. Welcome Screen
            </Typography>
            <Box mb={4} mt={4}>
              <Grid item md={12}>
                <InputLabel>
                  Activate font shadow
                  <FormControlLabel
                    label=""
                    control={<Switch defaultChecked />}
                    style={{ marginLeft: "0px" }}
                  />
                </InputLabel>
              </Grid>
              <Grid item md={12}>
                {/* <Typography variant="body2" gutterBottom>
                  Description
                </Typography> */}
                <QuillWrapper className="editor">
                  <ReactQuill
                    theme="snow"
                    value="You have questions about our products?

                    We are happy to help you!
                    
                    
                    
                    How would you prefer to communicate with us?oo"
                  />
                </QuillWrapper>
                {/* <FormControl fullWidth my={2} variant="outlined">
                  <TextField
                    label=""
                    id="biography"
                    multiline={true}
                    rows={3}
                    maxRows={4}
                    variant="outlined"
                    defaultValue="You have questions about our products? We are happy to help you! How would you prefer to communicate with us?oo"
                  />
                </FormControl> */}
              </Grid>
              <Grid item md={12}>
                <InputLabel>
                  Customizeable button
                  <FormControlLabel
                    label=""
                    control={
                      <Switch onChange={handleCustomizeableButtonChange} />
                    }
                    style={{ marginLeft: "0px" }}
                  />
                </InputLabel>
              </Grid>
              {IsCustomizeableButton == "true" ? (
                <React.Fragment>
                  <Grid item md={12} mb={4}>
                    <FormControl fullWidth my={2} variant="outlined">
                      <TextField
                        label="Button Text"
                        id="biography"
                        variant="outlined"
                        defaultValue="More information"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={12} mb={4}>
                    <FormControl fullWidth my={2} variant="outlined">
                      <TextField
                        label="Link"
                        id="biography"
                        variant="outlined"
                        defaultValue=""
                      />
                    </FormControl>
                  </Grid>
                </React.Fragment>
              ) : (
                <React.Fragment></React.Fragment>
              )}
              <Grid item md={12} mb={4}>
                <FormControl fullWidth my={2} variant="outlined">
                  <MuiColorInput
                    format="hex"
                    value={value}
                    onChange={handleChange}
                    label="Button Color"
                    id="biography"
                    variant="outlined"
                    defaultValue=""
                  />
                </FormControl>
              </Grid>
              <Grid item md={12} mb={4}>
                <FormControl fullWidth my={2} variant="outlined">
                  <MuiColorInput
                    format="hex"
                    value={value}
                    onChange={handleChange}
                    label="Font Color"
                    id="biography"
                    variant="outlined"
                    defaultValue=""
                  />
                </FormControl>
              </Grid>
              <Grid item md={12} mb={4}>
                <FormControl fullWidth my={2} variant="outlined">
                  <MuiColorInput
                    format="hex"
                    value={value}
                    onChange={handleChange}
                    label="Icon Color"
                    id="biography"
                    variant="outlined"
                    defaultValue=""
                  />
                </FormControl>
              </Grid>
            </Box>
            <Typography variant="h6" gutterBottom>
              3. Welcome Message
            </Typography>
            <Box mb={4} mt={4}>
              <Grid item md={12}>
                <QuillWrapper className="editor">
                  <ReactQuill
                    theme="snow"
                    value="Great to have you here!

                    You are interested in one of our products? Just let us know how we can help you and you will be connected directly with one of our consultants. 
                    
                    
                    
                    Thank you for your patience!"
                  />
                </QuillWrapper>
                {/* <FormControl fullWidth my={2} variant="outlined">
                  <TextField
                    label=""
                    id="biography"
                    multiline={true}
                    rows={3}
                    maxRows={4}
                    variant="outlined"
                    defaultValue="Great to have you here! You are interested in one of our products? Just let us know how we can help you and you will be connected directly with one of our consultants. Thank you for your patience!"
                  />
                </FormControl> */}
              </Grid>
            </Box>
          </Grid>
          <Grid item md={3} style={{ marginBottom: "15px" }}>
            <Typography variant="h6" gutterBottom>
              Preview
            </Typography>
            <CardMedia
              component="img"
              image="/static/img/others/Text_Color_Step1.png"
              alt="Paella dish"
              style={{
                objectFit: "contain",
                backgroundPosition: "center",
                width: "100%",
              }}
            />
            <CardMedia
              component="img"
              image="/static/img/others/Text_Color_Step2.png"
              alt="Paella dish"
              style={{
                objectFit: "contain",
                backgroundPosition: "center",
                width: "100%",
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Text_Colors;
