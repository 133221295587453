import {
  Dashboard,
  Event,
  People,
  Settings,
  Public,
  InsertDriveFile,
  Apps,
  AccessTime,
  MergeType,
  Timeline,
  SignalCellularAlt,
} from "@mui/icons-material";

const pagesSection = [
  {
    href: "/calendar/dashboard",
    icon: Dashboard,
    title: "Dashboard",
  },
  {
    href: "/calendar/event-types",
    icon: MergeType,
    title: "Event Types",
  },
  {
    href: "/calendar/bookings",
    icon: Event,
    title: "Bookings",
  },
  {
    href: "/calendar/my-availability",
    icon: AccessTime,
    title: "Availability",
  },
  {
    href: "/calendar/teams",
    icon: People,
    title: "Teams",
  },
  {
    href: "/events",
    icon: Apps,
    title: "Apps",
  },
  {
    href: "/calendar/routing-forms",
    icon: InsertDriveFile,
    title: "Routing Forms",
  },
  {
    href: "/events",
    icon: Timeline,
    title: "Workflows",
  },
  // {
  //   href: "/events",
  //   icon: SignalCellularAlt,
  //   title: "Insights",
  // },
  {
    href: "/preview",
    icon: Public,
    title: "View Public Page",
  },
  {
    href: "/",
    icon: Settings,
    title: "Settings",
  },
];

const calendarItems = [
  {
    title: "Calendar",
    pages: pagesSection,
  },
  // {
  //   title: "Elements",
  //   pages: elementsSection,
  // },
  // {
  //   title: "Mira Pro",
  //   pages: docsSection,
  // },
];

export default calendarItems;
