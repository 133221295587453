import React, { useState } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "../../../../Styles/Custom.css";

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  MenuItem,
  Card as MuiCard,
  CardMedia as MuiCardMedia,
  AvatarGroup as MuiAvatarGroup,
} from "@mui/material";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import LanguageIcon from "@mui/icons-material/Language";
import { tableCellClasses } from "@mui/material/TableCell";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const CustomTableRow = styled(TableRow)`
  // &:nth-of-type(odd) {
  //   background-color: rgba(0, 0, 0, 0.025);
  // }
`;
const QuillWrapper = styled.div`
  .ql-editor {
    min-height: 200px;
  }
`;
const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: ${(props) => props.theme.palette.common.black};
    color: ${(props) => props.theme.palette.common.white};
  }
  &.${tableCellClasses.body} {
    font-size: 14px;
  }
`;
// Data
let id = 0;
function createData(name, calories, fat, carbs, protein) {
  id += 1;
  return { id, name, calories, fat, carbs, protein };
}

function EventEntryTab() {
  const { t } = useTranslation();
  return (
    <Grid justifyContent="space-between" container spacing={10}>
      <Grid item md={12}>
        <Typography variant="h3" gutterBottom display="inline">
          Availability
        </Typography>
        <Typography>
          Configure what information should be captured during registration
        </Typography>
      </Grid>
      <Grid item md={12}>
        <Grid container spacing={6}>
          <Grid item md={12}>
            <Box>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Availability
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                >
                  <MenuItem value={10}>Working Hours - Default</MenuItem>
                  <MenuItem value={10}>Working Hours 1</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <TableBody>
              <TableRow>
                <TableCell style={{ borderBottom: "0" }}>
                  <Typography
                    variant="h7"
                    gutterBottom
                    style={{ fontWeight: "bold" }}
                  >
                    Monday
                  </Typography>
                </TableCell>
                <TableCell style={{ borderBottom: "0" }}>
                  9:00 am - 5:00 pm
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ borderBottom: "0" }}>
                  <Typography
                    variant="h7"
                    gutterBottom
                    style={{ fontWeight: "bold" }}
                  >
                    Tuesday
                  </Typography>
                </TableCell>
                <TableCell style={{ borderBottom: "0" }}>
                  9:00 am - 5:00 pm
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ borderBottom: "0" }}>
                  <Typography
                    variant="h7"
                    gutterBottom
                    style={{ fontWeight: "bold" }}
                  >
                    Wednesday
                  </Typography>
                </TableCell>
                <TableCell style={{ borderBottom: "0" }}>
                  9:00 am - 5:00 pm
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ borderBottom: "0" }}>
                  <Typography
                    variant="h7"
                    gutterBottom
                    style={{ fontWeight: "bold" }}
                  >
                    Thursday
                  </Typography>
                </TableCell>
                <TableCell style={{ borderBottom: "0" }}>
                  9:00 am - 5:00 pm
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ borderBottom: "0" }}>
                  <Typography
                    variant="h7"
                    gutterBottom
                    style={{ fontWeight: "bold" }}
                  >
                    Friday
                  </Typography>
                </TableCell>
                <TableCell style={{ borderBottom: "0" }}>
                  9:00 am - 5:00 pm
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ borderBottom: "0" }}>
                  <Typography
                    variant="h7"
                    gutterBottom
                    style={{
                      fontWeight: "bold",
                      textDecoration: "line-through",
                    }}
                  >
                    Saturday
                  </Typography>
                </TableCell>
                <TableCell style={{ borderBottom: "0" }}>Unavailable</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ borderBottom: "0" }}>
                  <Typography
                    variant="h7"
                    gutterBottom
                    style={{
                      fontWeight: "bold",
                      textDecoration: "line-through",
                    }}
                  >
                    Sunday
                  </Typography>
                </TableCell>
                <TableCell style={{ borderBottom: "0" }}>Unavailable</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Divider my={6} />
        <TableContainer style={{ background: "#eee" }}>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <TableBody>
              <TableRow>
                <TableCell style={{ borderBottom: "0" }}>
                  <IconButton
                    aria-label="Edit"
                    size="small"
                    aria-haspopup="true"
                    variant="contained"
                    color="secondary"
                  >
                    <LanguageIcon
                      style={{
                        fontSize: "1rem",
                        position: "relative",
                        bottom: "1px",
                        color: "#000",
                      }}
                    />
                  </IconButton>
                  Asia/Calcutta
                </TableCell>
                <TableCell align="right" style={{ borderBottom: "0" }}>
                  <Typography
                    variant="h7"
                    gutterBottom
                    style={{ fontWeight: "bold" }}
                  >
                    Edit Availability
                    <IconButton
                      aria-label="Edit"
                      size="small"
                      aria-haspopup="true"
                      variant="contained"
                      color="secondary"
                    >
                      <EditIcon
                        style={{
                          fontSize: "1rem",
                          position: "relative",
                          bottom: "1px",
                          color: "#000",
                        }}
                      />
                    </IconButton>
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

function Availability() {
  return (
    <React.Fragment>
      <EventEntryTab />
    </React.Fragment>
  );
}

export default Availability;
