import React, { useState } from "react";
import styled from "@emotion/styled";
import { NavLink, json } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Copy, Image, Search as SearchIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import { darken } from "polished";
import { Link } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import "../../../../Styles/Custom.css";
import Switch from "@mui/material/Switch";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import Avatar from "@mui/material/Avatar";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { red } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import CardActions from "@mui/material/CardActions";
import PropTypes from "prop-types";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import PersonIcon from "@mui/icons-material/Person";
import { blue } from "@mui/material/colors";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  Paper as MuiPaper,
  TableCell,
  TableRow,
  Typography,
  Card as MuiCard,
  CardMedia as MuiCardMedia,
  AvatarGroup as MuiAvatarGroup,
  FormControlLabel,
} from "@mui/material";

import { green, orange } from "@mui/material/colors";

import {
  Add as AddIcon,
  ArrowBack as ArrowBackIcon,
  FilterList,
  Download,
  Mail,
  ContentCopy,
  Visibility,
  CloudUpload as MuiCloudUpload,
  FileUpload,
  Videocam,
  InsertPhoto,
} from "@mui/icons-material";

import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import { tableCellClasses } from "@mui/material/TableCell";
import { spacing } from "@mui/system";

import Code from "../../../../components/Code";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const CustomTableRow = styled(TableRow)`
  // &:nth-of-type(odd) {
  //   background-color: rgba(0, 0, 0, 0.025);
  // }
`;
const CloudUpload = styled(MuiCloudUpload)(spacing);
const QuillWrapper = styled.div`
  .ql-editor {
    min-height: 200px;
  }
`;
const CenteredContent = styled.div`
  text-align: center;
`;
const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 0 auto ${(props) => props.theme.spacing(2)};
`;
const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: ${(props) => props.theme.palette.common.black};
    color: ${(props) => props.theme.palette.common.white};
  }
  &.${tableCellClasses.body} {
    font-size: 14px;
  }
`;
// Data
let id = 0;
function createData(name, calories, fat, carbs, protein) {
  id += 1;
  return { id, name, calories, fat, carbs, protein };
}

function DisplayOptions({ handleChildData }) {
  const [ButtonStyle, setButtonStyle] = React.useState("Button");
  const [Mode, setMode] = React.useState("Standard");
  const sendDataToParent = (d) => {
    handleChildData(d);
  };

  const [Media, setMedia] = React.useState("Default");
  const [MediaType, setMediaType] = React.useState("Video");

  const [value, setValue] = React.useState("female");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const [open1, setOpen1] = React.useState(false);
  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };
  const handleListItemClick = (value) => {
    //onClose(value);
    setOpen(false);
    setButtonStyle(value);
    console.log(value);
  };
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");

  const [IsOneImage, setIsOneImage] = useState("false");
  const handleIsOneImageChange = (event) => {
    setIsOneImage(JSON.stringify(event.target.checked));
  };
  return (
    <Grid justifyContent="space-between" container>
      <Grid item md={12}>
        <Grid container spacing={6}>
          <Grid item md={12} style={{ marginBottom: "15px" }}>
            <div style={{ marginTop: "10px" }}>
              {Mode == "Standard" ? (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginRight: "15px" }}
                  onClick={(e) => {
                    setMode("Standard");
                    sendDataToParent("Standard");
                  }}
                >
                  Standard Mode
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ marginRight: "15px" }}
                  onClick={(e) => {
                    setMode("Standard");
                    sendDataToParent("Standard");
                  }}
                >
                  Standard Mode
                </Button>
              )}
              {Mode == "Developer" ? (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginRight: "15px" }}
                  onClick={(e) => {
                    setMode("Developer");
                    sendDataToParent("Developer");
                  }}
                >
                  Developer Mode
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ marginRight: "15px" }}
                  onClick={(e) => {
                    setMode("Developer");
                    sendDataToParent("Developer");
                  }}
                >
                  Developer Mode
                </Button>
              )}
            </div>
          </Grid>
        </Grid>
        {Mode == "Standard" ? (
          <Grid container spacing={6}>
            <Grid item md={5} style={{ marginBottom: "15px" }}>
              <Typography variant="h3" gutterBottom>
                Website Integration
              </Typography>
              <Box mb={3}>
                <InputLabel style={{ marginBottom: "10px" }}>
                  1. Button Style
                </InputLabel>
                <Grid container>
                  {ButtonStyle == "Button" ? (
                    <React.Fragment>
                      <Grid
                        md={5}
                        style={{ marginRight: "10px" }}
                        onClick={() => setButtonStyle("Button")}
                      >
                        <Card>
                          <CardMedia
                            component="img"
                            height="194"
                            image="/static/img/others/button.png"
                            alt="Paella dish"
                            style={{
                              objectFit: "contain",
                              backgroundPosition: "center",
                              width: "100%",
                              border: "2px solid #376fd0",
                              height: "140px",
                              borderRadius: "10px",
                            }}
                          />
                        </Card>
                        <Typography
                          variant="h6"
                          style={{
                            textAlign: "center",
                            padding: "10px",
                            fontSize: "0.9rem",
                            borderBottom: "2px solid #376fd0",
                          }}
                        >
                          Button
                        </Typography>
                      </Grid>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Grid
                        md={5}
                        style={{ marginRight: "10px" }}
                        onClick={() => setButtonStyle("Button")}
                      >
                        <Card>
                          <CardMedia
                            component="img"
                            height="194"
                            image="/static/img/others/button.png"
                            alt="Paella dish"
                            style={{
                              objectFit: "contain",
                              backgroundPosition: "center",
                              width: "100%",
                              // border: "1px solid #b4b4b4",
                              height: "140px",
                              borderRadius: "10px",
                            }}
                          />
                        </Card>
                        <Typography
                          variant="h6"
                          style={{
                            textAlign: "center",
                            padding: "10px",
                            fontSize: "0.9rem",
                          }}
                        >
                          Button
                        </Typography>
                      </Grid>
                    </React.Fragment>
                  )}
                  ;
                  {ButtonStyle == "Button+Text(Old)" ||
                  ButtonStyle == "Button+Text(New)" ? (
                    <React.Fragment>
                      <Grid
                        md={5}
                        style={{ marginRight: "10px" }}
                        //onClick={() => setButtonStyle("Button+Text(Old)")}
                        onClick={handleClickOpen}
                      >
                        <Card>
                          <CardMedia
                            component="img"
                            height="194"
                            image="/static/img/others/button+Text.png"
                            alt="Paella dish"
                            style={{
                              objectFit: "contain",
                              backgroundPosition: "center",
                              width: "100%",
                              border: "2px solid #376fd0",
                              height: "140px",
                              borderRadius: "10px",
                            }}
                          />
                        </Card>
                        <Typography
                          variant="h6"
                          style={{
                            textAlign: "center",
                            padding: "10px",
                            fontSize: "0.9rem",
                            borderBottom: "2px solid #376fd0",
                          }}
                        >
                          Button + Text (Old Style)
                        </Typography>
                      </Grid>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Grid
                        md={5}
                        style={{ marginRight: "10px" }}
                        //onClick={() => setButtonStyle("Button+Text(Old)")}
                        onClick={handleClickOpen}
                      >
                        <Card>
                          <CardMedia
                            component="img"
                            height="194"
                            image="/static/img/others/button+Text.png"
                            alt="Paella dish"
                            style={{
                              objectFit: "contain",
                              backgroundPosition: "center",
                              width: "100%",
                              // border: "1px solid #b4b4b4",
                              height: "140px",
                              borderRadius: "10px",
                            }}
                          />
                        </Card>
                        <Typography
                          variant="h6"
                          style={{
                            textAlign: "center",
                            padding: "10px",
                            fontSize: "0.9rem",
                          }}
                        >
                          Button + Text (Old Style)
                        </Typography>
                      </Grid>
                    </React.Fragment>
                  )}
                  <Dialog onClose={handleClose} open={open}>
                    <DialogTitle>Select Style</DialogTitle>
                    <List sx={{ pt: 0 }}>
                      <ListItem disableGutters>
                        <ListItemButton
                          onClick={() =>
                            handleListItemClick("Button+Text(Old)")
                          }
                        >
                          <ListItemText primary="Old Style" />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disableGutters>
                        <ListItemButton
                          onClick={() =>
                            handleListItemClick("Button+Text(New)")
                          }
                        >
                          <ListItemText primary="New Style" />
                        </ListItemButton>
                      </ListItem>
                    </List>
                  </Dialog>
                </Grid>
              </Box>
              <Box mb={4}>
                <InputLabel>2. Position</InputLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={value}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="female"
                    control={<Radio />}
                    label="Left"
                  />
                  <FormControlLabel
                    value="male"
                    control={<Radio />}
                    label="Right"
                  />
                </RadioGroup>
              </Box>
              <Box mb={4}>
                <InputLabel>3. Square Button</InputLabel>
                <FormGroup>
                  <FormControlLabel
                    control={<Switch defaultChecked />}
                    label=""
                  />
                </FormGroup>
              </Box>
              <Box mb={4}>
                <InputLabel style={{ marginBottom: "10px" }}>
                  4. Media
                </InputLabel>
                <Grid container>
                  <Grid md={5} style={{ marginRight: "10px" }}>
                    <Card>
                      <Grid
                        container
                        style={{
                          objectFit: "contain",
                          backgroundPosition: "center",
                          width: "100%",
                          borderRadius: "10px",
                          height: "140px",
                        }}
                      >
                        {MediaType == "Video" ? (
                          <Grid
                            md={6}
                            style={{
                              textAlign: "center",
                              display: "flex",
                              alignItems: "center",
                            }}
                            onClick={() => {
                              setMediaType("Video");
                              setMedia("Default");
                            }}
                          >
                            <CenteredContent style={{ marginLeft: "20px" }}>
                              <label>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  component="span"
                                  style={{ border: "2px solid" }}
                                >
                                  <Videocam
                                    mr={2}
                                    style={{ width: "1.5em", height: "2em" }}
                                  />
                                </Button>
                              </label>
                            </CenteredContent>
                          </Grid>
                        ) : (
                          <Grid
                            md={6}
                            style={{
                              textAlign: "center",
                              display: "flex",
                              alignItems: "center",
                            }}
                            onClick={() => {
                              setMediaType("Video");
                              setMedia("Default");
                            }}
                          >
                            <CenteredContent style={{ marginLeft: "20px" }}>
                              <label>
                                <Button
                                  variant="contained"
                                  color="inherit"
                                  component="span"
                                >
                                  <Videocam
                                    mr={2}
                                    style={{ width: "1.5em", height: "2em" }}
                                  />
                                </Button>
                              </label>
                            </CenteredContent>
                          </Grid>
                        )}
                        {MediaType == "Image" ? (
                          <Grid
                            md={6}
                            style={{
                              textAlign: "center",
                              display: "flex",
                              alignItems: "center",
                            }}
                            onClick={() => {
                              setMediaType("Image");
                              setMedia("Default");
                            }}
                          >
                            <CenteredContent>
                              <label>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  component="span"
                                  style={{ border: "2px solid" }}
                                >
                                  <InsertPhoto
                                    mr={2}
                                    style={{ width: "1.5em", height: "2em" }}
                                  />
                                </Button>
                              </label>
                            </CenteredContent>
                          </Grid>
                        ) : (
                          <Grid
                            md={6}
                            style={{
                              textAlign: "center",
                              display: "flex",
                              alignItems: "center",
                            }}
                            onClick={() => {
                              setMediaType("Image");
                              setMedia("Default");
                            }}
                          >
                            <CenteredContent>
                              <label>
                                <Button
                                  variant="contained"
                                  color="inherit"
                                  component="span"
                                >
                                  <InsertPhoto
                                    mr={2}
                                    style={{ width: "1.5em", height: "2em" }}
                                  />
                                </Button>
                              </label>
                            </CenteredContent>
                          </Grid>
                        )}
                      </Grid>
                    </Card>
                    {Media == "Default" ? (
                      <Typography
                        variant="h6"
                        style={{
                          textAlign: "center",
                          padding: "10px",
                          fontSize: "0.9rem",
                          borderBottom: "2px solid #376fd0",
                        }}
                      >
                        Upload default media
                      </Typography>
                    ) : (
                      <Typography
                        variant="h6"
                        style={{
                          textAlign: "center",
                          padding: "10px",
                          fontSize: "0.9rem",
                        }}
                      >
                        Upload default media
                      </Typography>
                    )}
                  </Grid>
                  <Grid md={5}>
                    <Card onClick={handleClickOpen1}>
                      <CenteredContent>
                        <label>
                          <Button
                            variant="contained"
                            color="inherit"
                            component="span"
                            style={{
                              objectFit: "contain",
                              backgroundPosition: "center",
                              width: "100%",
                              borderRadius: "10px",
                              height: "140px",
                            }}
                          >
                            <FileUpload
                              mr={2}
                              style={{ width: "2em", height: "2em" }}
                            />
                          </Button>
                        </label>
                      </CenteredContent>
                    </Card>
                    <React.Fragment>
                      <Dialog
                        fullWidth={fullWidth}
                        maxWidth={maxWidth}
                        open={open1}
                        onClose={handleClose}
                        PaperProps={{
                          component: "form",
                          onSubmit: (event) => {
                            event.preventDefault();
                            const formData = new FormData(event.currentTarget);
                            const formJson = Object.fromEntries(
                              formData.entries()
                            );
                            const email = formJson.email;
                            console.log(email);
                            handleClose();
                          },
                        }}
                      >
                        <DialogContent>
                          <Grid container spacing={6}>
                            <Grid item md={4} style={{ marginBottom: "15px" }}>
                              <FormGroup style={{ padding: "10px 0 10px 0" }}>
                                <FormControlLabel
                                  control={
                                    <Switch onChange={handleIsOneImageChange} />
                                  }
                                  label="Use one image or video for all steps"
                                  style={{ marginRight: "0" }}
                                />
                              </FormGroup>
                              {IsOneImage == "false" ? (
                                <React.Fragment>
                                  <Typography
                                    variant="h6"
                                    gutterBottom
                                    display="inline"
                                  >
                                    Step 1 - Button + Text
                                  </Typography>
                                  <Card style={{ padding: "10px 0 10px 0" }}>
                                    <CenteredContent>
                                      <input
                                        accept="image/*"
                                        style={{ display: "none" }}
                                        id="raised-button-file"
                                        multiple
                                        type="file"
                                      />
                                      <label htmlFor="raised-button-file">
                                        <Button
                                          variant="contained"
                                          color="inherit"
                                          component="span"
                                          style={{
                                            objectFit: "contain",
                                            backgroundPosition: "center",
                                            width: "100%",
                                            borderRadius: "10px",
                                            height: "140px",
                                          }}
                                        >
                                          <CloudUpload mr={2} /> Upload your
                                          image or video
                                        </Button>
                                      </label>
                                    </CenteredContent>
                                  </Card>
                                  <Typography
                                    variant="h6"
                                    gutterBottom
                                    display="inline"
                                  >
                                    Step 2 - Small Button
                                  </Typography>
                                  <Card style={{ padding: "10px 0 10px 0" }}>
                                    <CenteredContent>
                                      <input
                                        accept="image/*"
                                        style={{ display: "none" }}
                                        id="raised-button-file"
                                        multiple
                                        type="file"
                                      />
                                      <label htmlFor="raised-button-file">
                                        <Button
                                          variant="contained"
                                          color="inherit"
                                          component="span"
                                          style={{
                                            objectFit: "contain",
                                            backgroundPosition: "center",
                                            width: "100%",
                                            borderRadius: "10px",
                                            height: "140px",
                                          }}
                                        >
                                          <CloudUpload mr={2} /> Upload your
                                          image or video
                                        </Button>
                                      </label>
                                    </CenteredContent>
                                  </Card>
                                  <Typography
                                    variant="h6"
                                    gutterBottom
                                    display="inline"
                                  >
                                    Step 3 - Welcome Screen
                                  </Typography>
                                  <Card style={{ padding: "10px 0 10px 0" }}>
                                    <CenteredContent>
                                      <input
                                        accept="image/*"
                                        style={{ display: "none" }}
                                        id="raised-button-file"
                                        multiple
                                        type="file"
                                      />
                                      <label htmlFor="raised-button-file">
                                        <Button
                                          variant="contained"
                                          color="inherit"
                                          component="span"
                                          style={{
                                            objectFit: "contain",
                                            backgroundPosition: "center",
                                            width: "100%",
                                            borderRadius: "10px",
                                            height: "140px",
                                          }}
                                        >
                                          <CloudUpload mr={2} /> Upload your
                                          image or video
                                        </Button>
                                      </label>
                                    </CenteredContent>
                                  </Card>
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  <Typography
                                    variant="h6"
                                    gutterBottom
                                    display="inline"
                                  >
                                    All Steps
                                  </Typography>
                                  <Card style={{ padding: "10px 0 10px 0" }}>
                                    <CenteredContent>
                                      <input
                                        accept="image/*"
                                        style={{ display: "none" }}
                                        id="raised-button-file"
                                        multiple
                                        type="file"
                                      />
                                      <label htmlFor="raised-button-file">
                                        <Button
                                          variant="contained"
                                          color="inherit"
                                          component="span"
                                          style={{
                                            objectFit: "contain",
                                            backgroundPosition: "center",
                                            width: "100%",
                                            borderRadius: "10px",
                                            height: "140px",
                                          }}
                                        >
                                          <CloudUpload mr={2} /> Upload your
                                          image or video
                                        </Button>
                                      </label>
                                    </CenteredContent>
                                  </Card>
                                </React.Fragment>
                              )}
                            </Grid>
                            <Grid item md={8} style={{ marginBottom: "15px" }}>
                              <FormGroup style={{ padding: "10px 0 10px 0" }}>
                                <Typography
                                  variant="h6"
                                  gutterBottom
                                  display="inline"
                                >
                                  Preview
                                </Typography>
                              </FormGroup>
                              <Card>
                                <CardMedia
                                  component="img"
                                  height="194"
                                  image="/static/img/others/MediaPreview.png"
                                  alt="Paella dish"
                                  style={{
                                    objectFit: "contain",
                                    backgroundPosition: "center",
                                    width: "100%",
                                    borderRadius: "10px",
                                    height: "540px",
                                  }}
                                />
                              </Card>
                            </Grid>
                          </Grid>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleClose}>Close</Button>
                          <Button type="submit">Submit</Button>
                        </DialogActions>
                      </Dialog>
                    </React.Fragment>
                    {Media == "Own" ? (
                      <Typography
                        variant="h6"
                        style={{
                          textAlign: "center",
                          padding: "10px",
                          fontSize: "0.9rem",
                          borderBottom: "2px solid #376fd0",
                        }}
                      >
                        Upload your own media
                      </Typography>
                    ) : (
                      <Typography
                        variant="h6"
                        style={{
                          textAlign: "center",
                          padding: "10px",
                          fontSize: "0.9rem",
                        }}
                      >
                        Upload your own media
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Box>
              <Box mb={4}>
                <InputLabel>
                  5. Minimize small Button
                  <FormControlLabel
                    label=""
                    control={<Switch defaultChecked />}
                    style={{ marginLeft: "0px" }}
                  />
                </InputLabel>
                <Grid container>
                  <Grid md={5} style={{ marginRight: "10px" }}>
                    <Card>
                      <CardMedia
                        component="img"
                        height="194"
                        image="/static/img/others/MinimiseSmallButton.svg"
                        alt="Paella dish"
                        style={{
                          objectFit: "contain",
                          backgroundPosition: "center",
                          width: "100%",
                          borderRadius: "10px",
                          height: "140px",
                        }}
                      />
                    </Card>
                  </Grid>
                  <Grid md={5}>
                    <Card>
                      <CenteredContent>
                        <input
                          accept="image/*"
                          style={{ display: "none" }}
                          id="raised-button-file"
                          multiple
                          type="file"
                        />
                        <label htmlFor="raised-button-file">
                          <Button
                            variant="contained"
                            color="inherit"
                            component="span"
                            style={{
                              objectFit: "contain",
                              backgroundPosition: "center",
                              width: "100%",
                              borderRadius: "10px",
                              height: "140px",
                            }}
                          >
                            <CloudUpload mr={2} /> Select Picture
                          </Button>
                        </label>
                      </CenteredContent>
                    </Card>
                  </Grid>
                </Grid>
                <FormGroup>
                  <FormControlLabel
                    control={<Switch defaultChecked />}
                    label="Show Preview"
                  />
                </FormGroup>
              </Box>
            </Grid>
            <Grid item md={7} style={{ marginBottom: "15px" }}>
              <Typography variant="h3" gutterBottom>
                Preview
              </Typography>
              <CardMedia
                component="img"
                image="/static/img/others/WidgetPreview.png"
                alt="Paella dish"
                style={{
                  objectFit: "contain",
                  backgroundPosition: "center",
                  width: "100%",
                }}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={6}>
            <Grid item md={6} style={{ marginBottom: "15px" }}>
              {/* <Typography variant="h3" gutterBottom>
                Integrate your own design by adding the following code in your
                button
              </Typography> */}
              <Box mb={4}>
                <InputLabel>
                  Integrate your own design by adding the following code in your
                  button
                </InputLabel>
                <Typography variant="subtitle1" gutterBottom my={4}>
                  <Code>{`<button onclick="pathadvice.connect()">Call Agent</button>`}</Code>
                </Typography>
                <Button variant="outlined" color="primary">
                  Copy
                </Button>
              </Box>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default DisplayOptions;
