import React, { useState, useRef, useEffect } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Tabs from "@mui/material/Tabs";
import { ChevronLeft, ChevronRight, Search as SearchIcon } from "react-feather";
import { darken } from "polished";
import { useTranslation } from "react-i18next";
import "../../../App.css";
import { Link, NavLink } from "react-router-dom";
import {
  ScheduleComponent,
  Day,
  Week,
  WorkWeek,
  Month,
  Agenda,
  Inject,
  Resize,
  DragAndDrop,
} from "@syncfusion/ej2-react-schedule";

import {
  Badge,
  Paper,
  Typography,
  Divider as MuiDivider,
  List,
  ListItem,
  ListItemText,
  Grid,
  Card,
  IconButton,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableRow,
  DialogContentText,
  TextField,
} from "@mui/material";

import { PersonAdd } from "@mui/icons-material";

import { tableCellClasses } from "@mui/material/TableCell";

import {
  AccessTime,
  CalendarMonth,
  Language,
  Videocam,
  ViewColumn,
  CalendarViewMonth,
  ModeEditOutline,
  Add as AddIcon,
} from "@mui/icons-material";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
const Divider = styled(MuiDivider)(spacing);

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = [
  { title: "The Shawshank Redemption", year: 1994 },
  { title: "The Godfather", year: 1972 },
  { title: "The Godfather: Part II", year: 1974 },
  { title: "The Dark Knight", year: 2008 },
  { title: "12 Angry Men", year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: "Pulp Fiction", year: 1994 },
  {
    title: "The Lord of the Rings: The Return of the King",
    year: 2003,
  },
];

const ChatContainer = styled(Grid)`
  width: 100%;
  height: 50vh;
  height: 100%;
`;

const ChatSidebar = styled(Grid)`
  border-right: 1px solid ${(props) => props.theme.palette.divider};
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: ${(props) => props.theme.palette.common.black};
    color: ${(props) => props.theme.palette.common.white};
  }
  &.${tableCellClasses.body} {
    font-size: 14px;
  }
`;

const CustomTableRow = styled(TableRow)`
  // &:nth-of-type(odd) {
  //   background-color: rgba(0, 0, 0, 0.025);
  // }
`;

// Data
let id = 0;
function createData(name, calories, fat, carbs, protein) {
  id += 1;
  return { id, name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

function MonthView() {
  const [value, setValue] = React.useState(dayjs("2022-04-17"));
  const [tabValue, setTabValue] = React.useState("1");
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [showAddGuest, setShowAddGuest] = useState(false);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const Search = styled.div`
    border-radius: 2px;
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
    position: relative;
    width: 100%;

    &:hover {
      background-color: ${(props) =>
        darken(0.05, props.theme.header.background)};
    }
  `;
  const SearchIconWrapper = styled.div`
    width: 50px;
    height: 100%;
    position: absolute;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 22px;
      height: 22px;
    }
  `;
  const Input = styled(InputBase)`
    color: inherit;
    width: 100%;
    > input {
      color: ${(props) => props.theme.header.search.color};
      padding-top: ${(props) => props.theme.spacing(2.5)};
      padding-right: ${(props) => props.theme.spacing(2.5)};
      padding-bottom: ${(props) => props.theme.spacing(2.5)};
      padding-left: ${(props) => props.theme.spacing(12)};
    }
  `;

  const { t } = useTranslation();

  return (
    <ChatContainer container component={Card}>
      <ChatSidebar item xs={12} md={4} lg={4}>
        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        >
          <ListItem alignItems="flex-start">
            <ListItemText
              // primary="This is the title"
              style={{ fontSize: "18px !important", fontWeight: "bold" }}
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                    style={{ fontSize: "18px", fontWeight: "bold" }}
                  >
                    This is the demo title
                  </Typography>
                  <br />
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                    style={{ fontSize: "15px" }}
                  >
                    <IconButton
                      aria-label="Edit"
                      size="small"
                      aria-haspopup="true"
                      variant="contained"
                      color="secondary"
                    >
                      <AccessTime
                        style={{
                          fontSize: "1rem",
                          position: "relative",
                          bottom: "1px",
                          color: "#000",
                        }}
                      />
                    </IconButton>
                    30 mins
                  </Typography>
                  <br />
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                    style={{ fontSize: "15px" }}
                  >
                    <IconButton
                      aria-label="Edit"
                      size="small"
                      aria-haspopup="true"
                      variant="contained"
                      color="secondary"
                    >
                      <Videocam
                        style={{
                          fontSize: "1rem",
                          position: "relative",
                          bottom: "1px",
                          color: "#000",
                        }}
                      />
                    </IconButton>
                    Cal Video
                  </Typography>
                  <br />
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                    style={{ fontSize: "15px" }}
                  >
                    <IconButton
                      aria-label="Edit"
                      size="small"
                      aria-haspopup="true"
                      variant="contained"
                      color="secondary"
                    >
                      <Language
                        style={{
                          fontSize: "1rem",
                          position: "relative",
                          bottom: "1px",
                          color: "#000",
                        }}
                      />
                    </IconButton>
                    Asia/Kolkata
                    <IconButton
                      aria-label="Edit"
                      size="small"
                      aria-haspopup="true"
                      variant="contained"
                      color="secondary"
                      onClick={() => setOpen(true)}
                    >
                      <ModeEditOutline
                        style={{
                          fontSize: "1rem",
                          position: "relative",
                          bottom: "1px",
                          color: "#000",
                        }}
                      />
                    </IconButton>
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
        </List>
        <Paper mt={4}>
          <div>
            <Dialog
              open={open}
              onClose={() => setOpen(false)}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">
                Timezone
                <br />
                <small style={{ color: "rgba(0, 0, 0, 0.6)" }}>
                  Set the timezone in which your event is occuring
                </small>
              </DialogTitle>
              <DialogContent>
                <Typography>
                  <Search className="searchBar">
                    <SearchIconWrapper>
                      <SearchIcon />
                    </SearchIconWrapper>
                    <Input
                      placeholder={t("Search for a country or a timezone")}
                    />
                  </Search>
                </Typography>
                <CustomizedTableDemo />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpen(false)} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </Paper>
      </ChatSidebar>
      <ChatSidebar item xs={12} md={5} lg={5}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DateCalendar", "DateCalendar"]}>
            <DemoItem label="">
              <DateCalendar
                value={value}
                onChange={(newValue) => setValue(newValue)}
                style={{ width: "100%" }}
              />
            </DemoItem>
          </DemoContainer>
        </LocalizationProvider>
      </ChatSidebar>
      <ChatSidebar item xs={12} md={3} lg={3} style={{ border: "0" }}>
        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        >
          <ListItem alignItems="flex-start">
            <Grid justifyContent="space-between" container spacing={10}>
              <Grid item>
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                  style={{ fontSize: "18px", fontWeight: "bold" }}
                >
                  Fri 22, April
                </Typography>
              </Grid>
              <Grid item style={{ paddingTop: "20px" }}>
                <div>
                  <TabContext value={tabValue}>
                    <Box
                      sx={{ borderBottom: 1, borderColor: "divider" }}
                      style={{ position: "relative", bottom: "15px" }}
                    >
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                      >
                        <Tab label="12h" value="1" />
                        <Tab label="24h" value="2" />
                      </TabList>
                    </Box>
                  </TabContext>
                </div>
              </Grid>
            </Grid>
          </ListItem>
        </List>
        <Grid item md={12}>
          <Grid
            container
            spacing={6}
            style={{ overflowY: "scroll", height: "50vh" }}
          >
            <Grid item md={12} style={{ paddingTop: "10px" }}>
              <Button
                variant="outlined"
                size="small"
                style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                onClick={() => setOpenModal(true)}
              >
                9:00 am
              </Button>
            </Grid>
            <Grid item md={12} style={{ paddingTop: "10px" }}>
              <Button
                variant="outlined"
                size="small"
                style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                onClick={() => setOpenModal(true)}
              >
                9:00 am
              </Button>
            </Grid>
            <Grid item md={12} style={{ paddingTop: "10px" }}>
              <Button
                variant="outlined"
                size="small"
                style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                onClick={() => setOpenModal(true)}
              >
                9:00 am
              </Button>
            </Grid>
            <Grid item md={12} style={{ paddingTop: "10px" }}>
              <Button
                variant="outlined"
                size="small"
                style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                onClick={() => setOpenModal(true)}
              >
                9:00 am
              </Button>
            </Grid>
            <Grid item md={12} style={{ paddingTop: "10px" }}>
              <Button
                variant="outlined"
                size="small"
                style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                onClick={() => setOpenModal(true)}
              >
                9:00 am
              </Button>
            </Grid>
            <Grid item md={12} style={{ paddingTop: "10px" }}>
              <Button
                variant="outlined"
                size="small"
                style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                onClick={() => setOpenModal(true)}
              >
                9:00 am
              </Button>
            </Grid>
            <Grid item md={12} style={{ paddingTop: "10px" }}>
              <Button
                variant="outlined"
                size="small"
                style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                onClick={() => setOpenModal(true)}
              >
                9:30 am
              </Button>
            </Grid>
            <Grid item md={12} style={{ paddingTop: "10px" }}>
              <Button
                variant="outlined"
                size="small"
                style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                onClick={() => setOpenModal(true)}
              >
                9:30 am
              </Button>
            </Grid>
            <Grid item md={12} style={{ paddingTop: "10px" }}>
              <Button
                variant="outlined"
                size="small"
                style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                onClick={() => setOpenModal(true)}
              >
                9:30 am
              </Button>
            </Grid>
            <Grid item md={12} style={{ paddingTop: "10px" }}>
              <Button
                variant="outlined"
                size="small"
                style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                onClick={() => setOpenModal(true)}
              >
                9:30 am
              </Button>
            </Grid>
            <Grid item md={12} style={{ paddingTop: "10px" }}>
              <Button
                variant="outlined"
                size="small"
                style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                onClick={() => setOpenModal(true)}
              >
                9:30 am
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </ChatSidebar>
      <Paper mt={4}>
        <div>
          <Dialog
            open={openModal}
            onClose={() => setOpenModal(false)}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              Confirm your details
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                To subscribe to this website, please enter your email address
                here. We will send updates occasionally.
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Your Name"
                type="text"
                fullWidth
              />
              <TextField
                margin="dense"
                id="name"
                label="Email Address"
                type="text"
                fullWidth
              />
              <TextField
                margin="dense"
                id="biography"
                label="Additional notes"
                type="text"
                fullWidth
                multiline={true}
                rows={3}
                maxRows={4}
              />
              {showAddGuest == false ? (
                <Button
                  size="small"
                  color="primary"
                  onClick={() => setShowAddGuest(true)}
                >
                  <PersonAdd style={{ fontSize: "1rem", marginRight: "5px" }} />
                  Add Guests
                </Button>
              ) : (
                <Table1 />
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenModal(false)} color="primary">
                Cancel
              </Button>
              <Button component={Link} to="/book/confirm" color="primary">
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Paper>
    </ChatContainer>
  );
}

function TableRows({ rows, tableRowRemove, onValUpdate }) {
  return rows.map((rowsData, index) => {
    var opt = "Email";
    const { name, email, profile } = rowsData;
    return (
      <tr key={index}>
        <td>
          <TextField
            id="username"
            label={opt}
            defaultValue={name}
            variant="outlined"
            fullWidth
            my={2}
            rows={4}
            maxRows={6}
            name="name"
            onChange={(event) => onValUpdate(index, event)}
            style={{ marginBottom: "10px" }}
          />
        </td>
        <td style={{ textAlign: "center" }}>
          <DeleteIcon onClick={() => tableRowRemove(index)} />
        </td>
      </tr>
    );
  });
}
var i = false;

function Table1() {
  const [rows, initRow] = useState([]);
  const addRowTable = () => {
    const data = {
      name: "",
    };
    initRow([...rows, data]);
  };
  const tableRowRemove = (index) => {
    const dataRow = [...rows];
    dataRow.splice(index, 1);
    initRow(dataRow);
  };
  const onValUpdate = (i, event) => {
    const { name, value } = event.target;
    const data = [...rows];
    data[i][name] = value;
    initRow(data);
  };

  if (i == false) {
    addRowTable();
    i = true;
  }
  return (
    <>
      <Typography variant="body2" gutterBottom>
        <button
          onClick={addRowTable}
          style={{
            border: "0",
            backgroundColor: "transparent",
            fontWeight: "bold",
            color: "#376fd1",
            width: "100%",
            textAlign: "right",
          }}
          color="primary"
        >
          Add
          <AddIcon style={{ position: "relative", top: "7px" }} />
        </button>
      </Typography>
      <table style={{ width: "100%" }}>
        <tbody>
          <TableRows
            rows={rows}
            tableRowRemove={tableRowRemove}
            onValUpdate={onValUpdate}
          />
        </tbody>
      </table>
    </>
  );
}

const WeekApp = () => {
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [showAddGuest, setShowAddGuest] = useState(false);

  const initialLoad = useRef(true);
  const scheduleRef = useRef(null);

  useEffect(() => {
    if (initialLoad.current) {
      const allDayAppointmentSection =
        scheduleRef.current.element.querySelector(
          ".e-all-day-appointment-section"
        );
      if (allDayAppointmentSection) {
        allDayAppointmentSection.click();
      }
      initialLoad.current = false;
    }
  }, []);

  let data = [
    {
      Id: 1,
      Subject: "9:30",
      StartTime: new Date(2018, 1, 15, 9, 30),
      EndTime: new Date(2018, 1, 15, 10, 0),
    },
    {
      Id: 2,
      Subject: "10:30",
      StartTime: new Date(2018, 1, 12, 10, 30),
      EndTime: new Date(2018, 1, 12, 11, 0),
    },
    {
      Id: 3,
      Subject: "9:00",
      StartTime: new Date(2018, 1, 13, 9, 0),
      EndTime: new Date(2018, 1, 13, 9, 30),
    },
    {
      Id: 4,
      Subject: "13:00",
      StartTime: new Date(2018, 1, 14, 13, 0),
      EndTime: new Date(2018, 1, 14, 13, 30),
    },
  ];

  const onPopupOpen = (args) => {
    let isEmptyCell =
      args.target.classList.contains("e-work-cells") ||
      args.target.classList.contains("e-header-cells"); // checking whether the cell is empty or not
    if ((args.type === "QuickInfo" || args.type === "Editor") && isEmptyCell) {
      args.cancel = true;
    } else {
      args.cancel = true;
      setOpenModal(true);
    }
  };
  const eventSettings = { dataSource: data };

  return (
    <>
      <ScheduleComponent
        height="550px"
        ref={scheduleRef}
        selectedDate={new Date(2018, 1, 15)}
        eventSettings={eventSettings}
        popupOpen={onPopupOpen}
        views={["Week", "WorkWeek"]}
        showHeaderBar={false}
        hideStartTime={true}
        hideEndTime={true}
      >
        {/* <Inject
          services={[Day, Week, WorkWeek, Month, Agenda, DragAndDrop, Resize]}
        /> */}
        <Inject services={[Day, Week, WorkWeek]} />
      </ScheduleComponent>
      <Paper mt={4}>
        <div>
          <Dialog
            open={openModal}
            onClose={() => setOpenModal(false)}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              Confirm your details
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                To subscribe to this website, please enter your email address
                here. We will send updates occasionally.
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Your Name"
                type="text"
                fullWidth
              />
              <TextField
                margin="dense"
                id="name"
                label="Email Address"
                type="text"
                fullWidth
              />
              <TextField
                margin="dense"
                id="biography"
                label="Additional notes"
                type="text"
                fullWidth
                multiline={true}
                rows={3}
                maxRows={4}
              />
              {showAddGuest == false ? (
                <Button
                  size="small"
                  color="primary"
                  onClick={() => setShowAddGuest(true)}
                >
                  <PersonAdd style={{ fontSize: "1rem", marginRight: "5px" }} />
                  Add Guests
                </Button>
              ) : (
                <Table1 />
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenModal(false)} color="primary">
                Cancel
              </Button>
              <Button component={Link} to="/book/confirm" color="primary">
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Paper>
    </>
  );
};

function WeekView() {
  const [value, setValue] = React.useState(dayjs("2022-04-17"));
  const [tabValue, setTabValue] = React.useState("1");
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [showAddGuest, setShowAddGuest] = useState(false);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const Search = styled.div`
    border-radius: 2px;
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
    position: relative;
    width: 100%;

    &:hover {
      background-color: ${(props) =>
        darken(0.05, props.theme.header.background)};
    }
  `;
  const SearchIconWrapper = styled.div`
    width: 50px;
    height: 100%;
    position: absolute;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 22px;
      height: 22px;
    }
  `;
  const Input = styled(InputBase)`
    color: inherit;
    width: 100%;
    > input {
      color: ${(props) => props.theme.header.search.color};
      padding-top: ${(props) => props.theme.spacing(2.5)};
      padding-right: ${(props) => props.theme.spacing(2.5)};
      padding-bottom: ${(props) => props.theme.spacing(2.5)};
      padding-left: ${(props) => props.theme.spacing(12)};
    }
  `;

  const { t } = useTranslation();

  return (
    <ChatContainer container component={Card}>
      <ChatSidebar item xs={12} md={3} lg={3}>
        <List
          sx={{
            width: "100%",
            // maxWidth: 360,
            bgcolor: "background.paper",
            paddingTop: "0",
          }}
        >
          <ListItem alignItems="flex-start">
            <ListItemText
              // primary="This is the title"
              style={{ fontSize: "18px !important", fontWeight: "bold" }}
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      paddingTop: "0",
                    }}
                  >
                    This is the demo title
                  </Typography>
                  <br />
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                    style={{ fontSize: "15px" }}
                  >
                    <IconButton
                      aria-label="Edit"
                      size="small"
                      aria-haspopup="true"
                      variant="contained"
                      color="secondary"
                    >
                      <AccessTime
                        style={{
                          fontSize: "1rem",
                          position: "relative",
                          bottom: "1px",
                          color: "#000",
                        }}
                      />
                    </IconButton>
                    30 mins
                  </Typography>
                  <br />
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                    style={{ fontSize: "15px" }}
                  >
                    <IconButton
                      aria-label="Edit"
                      size="small"
                      aria-haspopup="true"
                      variant="contained"
                      color="secondary"
                    >
                      <Videocam
                        style={{
                          fontSize: "1rem",
                          position: "relative",
                          bottom: "1px",
                          color: "#000",
                        }}
                      />
                    </IconButton>
                    Cal Video
                  </Typography>
                  <br />
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                    style={{ fontSize: "15px" }}
                  >
                    <IconButton
                      aria-label="Edit"
                      size="small"
                      aria-haspopup="true"
                      variant="contained"
                      color="secondary"
                    >
                      <Language
                        style={{
                          fontSize: "1rem",
                          position: "relative",
                          bottom: "1px",
                          color: "#000",
                        }}
                      />
                    </IconButton>
                    Asia/Kolkata
                    <IconButton
                      aria-label="Edit"
                      size="small"
                      aria-haspopup="true"
                      variant="contained"
                      color="secondary"
                      onClick={() => setOpen(true)}
                    >
                      <ModeEditOutline
                        style={{
                          fontSize: "1rem",
                          position: "relative",
                          bottom: "1px",
                          color: "#000",
                        }}
                      />
                    </IconButton>
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      components={["DateCalendar", "DateCalendar"]}
                    >
                      <DemoItem label="">
                        <DateCalendar
                          value={value}
                          onChange={(newValue) => setValue(newValue)}
                          style={{ width: "100%" }}
                        />
                      </DemoItem>
                    </DemoContainer>
                  </LocalizationProvider>
                </React.Fragment>
              }
            />
          </ListItem>
        </List>
        <Paper mt={4}>
          <div>
            <Dialog
              open={open}
              onClose={() => setOpen(false)}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">
                Timezone
                <br />
                <small style={{ color: "rgba(0, 0, 0, 0.6)" }}>
                  Set the timezone in which your event is occuring
                </small>
              </DialogTitle>
              <DialogContent>
                <Typography>
                  <Search className="searchBar">
                    <SearchIconWrapper>
                      <SearchIcon />
                    </SearchIconWrapper>
                    <Input
                      placeholder={t("Search for a country or a timezone")}
                    />
                  </Search>
                </Typography>
                <CustomizedTableDemo />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpen(false)} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </Paper>
      </ChatSidebar>
      {/* <ChatSidebar item xs={12} md={5} lg={5}>
       
      </ChatSidebar> */}
      <ChatSidebar item xs={12} md={9} lg={9} style={{ border: "0" }}>
        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          <ListItem alignItems="flex-start">
            <Grid justifyContent="space-between" container spacing={10}>
              <Grid item style={{ paddingTop: "30px" }}>
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                  style={{ fontSize: "18px", fontWeight: "bold" }}
                >
                  Mar 13- 18, 2024
                </Typography>
                <ChevronLeft style={{ position: "relative", top: "5px" }} />
                <ChevronRight style={{ position: "relative", top: "5px" }} />
                <Button
                  variant="outlined"
                  color="inherit"
                  size="small"
                  style={{
                    padding: "8px",
                    position: "absolute",
                    top: "-4px",
                  }}
                >
                  Today
                </Button>
              </Grid>
              <Grid item style={{ paddingTop: "20px" }}>
                <div>
                  <TabContext value={tabValue}>
                    <Box
                      sx={{ borderBottom: 1, borderColor: "divider" }}
                      style={{ position: "relative", bottom: "15px" }}
                    >
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                      >
                        <Tab label="12h" value="1" />
                        <Tab label="24h" value="2" />
                      </TabList>
                    </Box>
                  </TabContext>
                </div>
              </Grid>
            </Grid>
          </ListItem>
        </List>
        <Grid item md={12}>
          <Grid container>
            <WeekApp />
          </Grid>
        </Grid>
      </ChatSidebar>
      <Paper mt={4}>
        <div>
          <Dialog
            open={openModal}
            onClose={() => setOpenModal(false)}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              Confirm your details
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                To subscribe to this website, please enter your email address
                here. We will send updates occasionally.
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Your Name"
                type="text"
                fullWidth
              />
              <TextField
                margin="dense"
                id="name"
                label="Email Address"
                type="text"
                fullWidth
              />
              <TextField
                margin="dense"
                id="biography"
                label="Additional notes"
                type="text"
                fullWidth
                multiline={true}
                rows={3}
                maxRows={4}
              />
              {showAddGuest == false ? (
                <Button
                  size="small"
                  color="primary"
                  onClick={() => setShowAddGuest(true)}
                >
                  <PersonAdd style={{ fontSize: "1rem", marginRight: "5px" }} />
                  Add Guests
                </Button>
              ) : (
                <Table1 />
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenModal(false)} color="primary">
                Cancel
              </Button>
              <Button component={Link} to="/book/confirm" color="primary">
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Paper>
    </ChatContainer>
  );
}

function ColumnView() {
  const [value, setValue] = React.useState(dayjs("2022-04-17"));
  const [tabValue, setTabValue] = React.useState("1");
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [showAddGuest, setShowAddGuest] = useState(false);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const Search = styled.div`
    border-radius: 2px;
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
    position: relative;
    width: 100%;

    &:hover {
      background-color: ${(props) =>
        darken(0.05, props.theme.header.background)};
    }
  `;
  const SearchIconWrapper = styled.div`
    width: 50px;
    height: 100%;
    position: absolute;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 22px;
      height: 22px;
    }
  `;
  const Input = styled(InputBase)`
    color: inherit;
    width: 100%;
    > input {
      color: ${(props) => props.theme.header.search.color};
      padding-top: ${(props) => props.theme.spacing(2.5)};
      padding-right: ${(props) => props.theme.spacing(2.5)};
      padding-bottom: ${(props) => props.theme.spacing(2.5)};
      padding-left: ${(props) => props.theme.spacing(12)};
    }
  `;

  const { t } = useTranslation();

  return (
    <ChatContainer container component={Card}>
      <ChatSidebar item xs={12} md={3} lg={3}>
        <List
          sx={{
            width: "100%",
            maxWidth: 360,
            bgcolor: "background.paper",
            paddingTop: "0",
          }}
        >
          <ListItem alignItems="flex-start">
            <ListItemText
              // primary="This is the title"
              style={{ fontSize: "18px !important", fontWeight: "bold" }}
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      paddingTop: "0",
                    }}
                  >
                    This is the demo title
                  </Typography>
                  <br />
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                    style={{ fontSize: "15px" }}
                  >
                    <IconButton
                      aria-label="Edit"
                      size="small"
                      aria-haspopup="true"
                      variant="contained"
                      color="secondary"
                    >
                      <AccessTime
                        style={{
                          fontSize: "1rem",
                          position: "relative",
                          bottom: "1px",
                          color: "#000",
                        }}
                      />
                    </IconButton>
                    30 mins
                  </Typography>
                  <br />
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                    style={{ fontSize: "15px" }}
                  >
                    <IconButton
                      aria-label="Edit"
                      size="small"
                      aria-haspopup="true"
                      variant="contained"
                      color="secondary"
                    >
                      <Videocam
                        style={{
                          fontSize: "1rem",
                          position: "relative",
                          bottom: "1px",
                          color: "#000",
                        }}
                      />
                    </IconButton>
                    Cal Video
                  </Typography>
                  <br />
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                    style={{ fontSize: "15px" }}
                  >
                    <IconButton
                      aria-label="Edit"
                      size="small"
                      aria-haspopup="true"
                      variant="contained"
                      color="secondary"
                    >
                      <Language
                        style={{
                          fontSize: "1rem",
                          position: "relative",
                          bottom: "1px",
                          color: "#000",
                        }}
                      />
                    </IconButton>
                    Asia/Kolkata
                    <IconButton
                      aria-label="Edit"
                      size="small"
                      aria-haspopup="true"
                      variant="contained"
                      color="secondary"
                      onClick={() => setOpen(true)}
                    >
                      <ModeEditOutline
                        style={{
                          fontSize: "1rem",
                          position: "relative",
                          bottom: "1px",
                          color: "#000",
                        }}
                      />
                    </IconButton>
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      components={["DateCalendar", "DateCalendar"]}
                    >
                      <DemoItem label="">
                        <DateCalendar
                          value={value}
                          onChange={(newValue) => setValue(newValue)}
                          style={{ width: "100%" }}
                        />
                      </DemoItem>
                    </DemoContainer>
                  </LocalizationProvider>
                </React.Fragment>
              }
            />
          </ListItem>
        </List>
        <Paper mt={4}>
          <div>
            <Dialog
              open={open}
              onClose={() => setOpen(false)}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">
                Timezone
                <br />
                <small style={{ color: "rgba(0, 0, 0, 0.6)" }}>
                  Set the timezone in which your event is occuring
                </small>
              </DialogTitle>
              <DialogContent>
                <Typography>
                  <Search className="searchBar">
                    <SearchIconWrapper>
                      <SearchIcon />
                    </SearchIconWrapper>
                    <Input
                      placeholder={t("Search for a country or a timezone")}
                    />
                  </Search>
                </Typography>
                <CustomizedTableDemo />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpen(false)} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </Paper>
      </ChatSidebar>
      {/* <ChatSidebar item xs={12} md={5} lg={5}>
       
      </ChatSidebar> */}
      <ChatSidebar item xs={12} md={9} lg={9} style={{ border: "0" }}>
        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          <ListItem alignItems="flex-start">
            <Grid justifyContent="space-between" container spacing={10}>
              <Grid item style={{ paddingTop: "30px" }}>
                {/* <Grid container>
                  <Grid md={6}>

                  </Grid>
                </Grid> */}
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                  style={{ fontSize: "18px", fontWeight: "bold" }}
                >
                  Mar 13- 18, 2024
                </Typography>
                <ChevronLeft style={{ position: "relative", top: "5px" }} />
                <ChevronRight style={{ position: "relative", top: "5px" }} />
                <Button
                  variant="outlined"
                  color="inherit"
                  size="small"
                  style={{
                    padding: "8px",
                    position: "absolute",
                    top: "-4px",
                  }}
                >
                  Today
                </Button>
              </Grid>
              <Grid item style={{ paddingTop: "20px" }}>
                <div>
                  <TabContext value={tabValue}>
                    <Box
                      sx={{ borderBottom: 1, borderColor: "divider" }}
                      style={{ position: "relative", bottom: "15px" }}
                    >
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                      >
                        <Tab label="12h" value="1" />
                        <Tab label="24h" value="2" />
                      </TabList>
                    </Box>
                  </TabContext>
                </div>
              </Grid>
            </Grid>
          </ListItem>
        </List>
        <Grid item md={12} style={{ overflowY: "scroll", height: "60vh" }}>
          <Grid container>
            <Grid md={2}>
              <Grid
                item
                md={12}
                style={{ paddingTop: "10px", textAlign: "center" }}
              >
                <Typography
                  style={{
                    width: "90%",
                    padding: "10px",
                    marginLeft: "5%",
                    fontWeight: "bold",
                  }}
                >
                  WED 13
                </Typography>
              </Grid>
              <Grid item md={12} style={{ paddingTop: "10px" }}>
                <Button
                  variant="outlined"
                  size="small"
                  style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                  onClick={() => setOpenModal(true)}
                >
                  9:00 am
                </Button>
              </Grid>
              <Grid item md={12} style={{ paddingTop: "10px" }}>
                <Button
                  variant="outlined"
                  size="small"
                  style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                  onClick={() => setOpenModal(true)}
                >
                  9:30 am
                </Button>
              </Grid>
              <Grid item md={12} style={{ paddingTop: "10px" }}>
                <Button
                  variant="outlined"
                  size="small"
                  style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                  onClick={() => setOpenModal(true)}
                >
                  10:00 am
                </Button>
              </Grid>
              <Grid item md={12} style={{ paddingTop: "10px" }}>
                <Button
                  variant="outlined"
                  size="small"
                  style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                  onClick={() => setOpenModal(true)}
                >
                  10:30 am
                </Button>
              </Grid>
              <Grid item md={12} style={{ paddingTop: "10px" }}>
                <Button
                  variant="outlined"
                  size="small"
                  style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                  onClick={() => setOpenModal(true)}
                >
                  11:00 am
                </Button>
              </Grid>
              <Grid item md={12} style={{ paddingTop: "10px" }}>
                <Button
                  variant="outlined"
                  size="small"
                  style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                  onClick={() => setOpenModal(true)}
                >
                  11:30 am
                </Button>
              </Grid>
              <Grid item md={12} style={{ paddingTop: "10px" }}>
                <Button
                  variant="outlined"
                  size="small"
                  style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                  onClick={() => setOpenModal(true)}
                >
                  12:00 am
                </Button>
              </Grid>
              <Grid item md={12} style={{ paddingTop: "10px" }}>
                <Button
                  variant="outlined"
                  size="small"
                  style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                  onClick={() => setOpenModal(true)}
                >
                  12:30 am
                </Button>
              </Grid>
            </Grid>
            <Grid md={2}>
              <Grid
                item
                md={12}
                style={{ paddingTop: "10px", textAlign: "center" }}
              >
                <Typography
                  style={{
                    width: "90%",
                    padding: "10px",
                    marginLeft: "5%",
                    fontWeight: "bold",
                  }}
                >
                  WED 14
                </Typography>
              </Grid>
              <Grid item md={12} style={{ paddingTop: "10px" }}>
                <Button
                  variant="outlined"
                  size="small"
                  style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                  onClick={() => setOpenModal(true)}
                >
                  9:00 am
                </Button>
              </Grid>
              <Grid item md={12} style={{ paddingTop: "10px" }}>
                <Button
                  variant="outlined"
                  size="small"
                  style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                  onClick={() => setOpenModal(true)}
                >
                  9:30 am
                </Button>
              </Grid>
              <Grid item md={12} style={{ paddingTop: "10px" }}>
                <Button
                  variant="outlined"
                  size="small"
                  style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                  onClick={() => setOpenModal(true)}
                >
                  10:00 am
                </Button>
              </Grid>
              <Grid item md={12} style={{ paddingTop: "10px" }}>
                <Button
                  variant="outlined"
                  size="small"
                  style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                  onClick={() => setOpenModal(true)}
                >
                  10:30 am
                </Button>
              </Grid>
              <Grid item md={12} style={{ paddingTop: "10px" }}>
                <Button
                  variant="outlined"
                  size="small"
                  style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                  onClick={() => setOpenModal(true)}
                >
                  11:00 am
                </Button>
              </Grid>
              <Grid item md={12} style={{ paddingTop: "10px" }}>
                <Button
                  variant="outlined"
                  size="small"
                  style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                  onClick={() => setOpenModal(true)}
                >
                  11:30 am
                </Button>
              </Grid>
              <Grid item md={12} style={{ paddingTop: "10px" }}>
                <Button
                  variant="outlined"
                  size="small"
                  style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                  onClick={() => setOpenModal(true)}
                >
                  12:00 am
                </Button>
              </Grid>
              <Grid item md={12} style={{ paddingTop: "10px" }}>
                <Button
                  variant="outlined"
                  size="small"
                  style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                  onClick={() => setOpenModal(true)}
                >
                  12:30 am
                </Button>
              </Grid>
            </Grid>
            <Grid md={2}>
              <Grid
                item
                md={12}
                style={{ paddingTop: "10px", textAlign: "center" }}
              >
                <Typography
                  style={{
                    width: "90%",
                    padding: "10px",
                    marginLeft: "5%",
                    fontWeight: "bold",
                  }}
                >
                  WED 15
                </Typography>
              </Grid>
              <Grid item md={12} style={{ paddingTop: "10px" }}>
                <Button
                  variant="outlined"
                  size="small"
                  style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                  onClick={() => setOpenModal(true)}
                >
                  9:00 am
                </Button>
              </Grid>
              <Grid item md={12} style={{ paddingTop: "10px" }}>
                <Button
                  variant="outlined"
                  size="small"
                  style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                  onClick={() => setOpenModal(true)}
                >
                  9:30 am
                </Button>
              </Grid>
              <Grid item md={12} style={{ paddingTop: "10px" }}>
                <Button
                  variant="outlined"
                  size="small"
                  style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                  onClick={() => setOpenModal(true)}
                >
                  10:00 am
                </Button>
              </Grid>
              <Grid item md={12} style={{ paddingTop: "10px" }}>
                <Button
                  variant="outlined"
                  size="small"
                  style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                  onClick={() => setOpenModal(true)}
                >
                  10:30 am
                </Button>
              </Grid>
              <Grid item md={12} style={{ paddingTop: "10px" }}>
                <Button
                  variant="outlined"
                  size="small"
                  style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                  onClick={() => setOpenModal(true)}
                >
                  11:00 am
                </Button>
              </Grid>
              <Grid item md={12} style={{ paddingTop: "10px" }}>
                <Button
                  variant="outlined"
                  size="small"
                  style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                  onClick={() => setOpenModal(true)}
                >
                  11:30 am
                </Button>
              </Grid>
              <Grid item md={12} style={{ paddingTop: "10px" }}>
                <Button
                  variant="outlined"
                  size="small"
                  style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                  onClick={() => setOpenModal(true)}
                >
                  12:00 am
                </Button>
              </Grid>
              <Grid item md={12} style={{ paddingTop: "10px" }}>
                <Button
                  variant="outlined"
                  size="small"
                  style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                  onClick={() => setOpenModal(true)}
                >
                  12:30 am
                </Button>
              </Grid>
            </Grid>
            <Grid md={2}>
              <Grid
                item
                md={12}
                style={{ paddingTop: "10px", textAlign: "center" }}
              >
                <Typography
                  style={{
                    width: "90%",
                    padding: "10px",
                    marginLeft: "5%",
                    fontWeight: "bold",
                  }}
                >
                  WED 16
                </Typography>
              </Grid>
              <Grid item md={12} style={{ paddingTop: "10px" }}>
                <Button
                  variant="outlined"
                  size="small"
                  style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                  onClick={() => setOpenModal(true)}
                >
                  9:00 am
                </Button>
              </Grid>
              <Grid item md={12} style={{ paddingTop: "10px" }}>
                <Button
                  variant="outlined"
                  size="small"
                  style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                  onClick={() => setOpenModal(true)}
                >
                  9:30 am
                </Button>
              </Grid>
              <Grid item md={12} style={{ paddingTop: "10px" }}>
                <Button
                  variant="outlined"
                  size="small"
                  style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                  onClick={() => setOpenModal(true)}
                >
                  10:00 am
                </Button>
              </Grid>
              <Grid item md={12} style={{ paddingTop: "10px" }}>
                <Button
                  variant="outlined"
                  size="small"
                  style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                  onClick={() => setOpenModal(true)}
                >
                  10:30 am
                </Button>
              </Grid>
              <Grid item md={12} style={{ paddingTop: "10px" }}>
                <Button
                  variant="outlined"
                  size="small"
                  style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                  onClick={() => setOpenModal(true)}
                >
                  11:00 am
                </Button>
              </Grid>
              <Grid item md={12} style={{ paddingTop: "10px" }}>
                <Button
                  variant="outlined"
                  size="small"
                  style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                  onClick={() => setOpenModal(true)}
                >
                  11:30 am
                </Button>
              </Grid>
              <Grid item md={12} style={{ paddingTop: "10px" }}>
                <Button
                  variant="outlined"
                  size="small"
                  style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                  onClick={() => setOpenModal(true)}
                >
                  12:00 am
                </Button>
              </Grid>
              <Grid item md={12} style={{ paddingTop: "10px" }}>
                <Button
                  variant="outlined"
                  size="small"
                  style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                  onClick={() => setOpenModal(true)}
                >
                  12:30 am
                </Button>
              </Grid>
            </Grid>
            <Grid md={2}>
              <Grid
                item
                md={12}
                style={{ paddingTop: "10px", textAlign: "center" }}
              >
                <Typography
                  style={{
                    width: "90%",
                    padding: "10px",
                    marginLeft: "5%",
                    fontWeight: "bold",
                  }}
                >
                  WED 17
                </Typography>
              </Grid>
              <Grid item md={12} style={{ paddingTop: "10px" }}>
                <Button
                  variant="outlined"
                  size="small"
                  style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                  onClick={() => setOpenModal(true)}
                >
                  9:00 am
                </Button>
              </Grid>
              <Grid item md={12} style={{ paddingTop: "10px" }}>
                <Button
                  variant="outlined"
                  size="small"
                  style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                  onClick={() => setOpenModal(true)}
                >
                  9:30 am
                </Button>
              </Grid>
              <Grid item md={12} style={{ paddingTop: "10px" }}>
                <Button
                  variant="outlined"
                  size="small"
                  style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                  onClick={() => setOpenModal(true)}
                >
                  10:00 am
                </Button>
              </Grid>
              <Grid item md={12} style={{ paddingTop: "10px" }}>
                <Button
                  variant="outlined"
                  size="small"
                  style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                  onClick={() => setOpenModal(true)}
                >
                  10:30 am
                </Button>
              </Grid>
              <Grid item md={12} style={{ paddingTop: "10px" }}>
                <Button
                  variant="outlined"
                  size="small"
                  style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                  onClick={() => setOpenModal(true)}
                >
                  11:00 am
                </Button>
              </Grid>
              <Grid item md={12} style={{ paddingTop: "10px" }}>
                <Button
                  variant="outlined"
                  size="small"
                  style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                  onClick={() => setOpenModal(true)}
                >
                  11:30 am
                </Button>
              </Grid>
              <Grid item md={12} style={{ paddingTop: "10px" }}>
                <Button
                  variant="outlined"
                  size="small"
                  style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                  onClick={() => setOpenModal(true)}
                >
                  12:00 am
                </Button>
              </Grid>
              <Grid item md={12} style={{ paddingTop: "10px" }}>
                <Button
                  variant="outlined"
                  size="small"
                  style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                  onClick={() => setOpenModal(true)}
                >
                  12:30 am
                </Button>
              </Grid>
            </Grid>
            <Grid md={2}>
              <Grid
                item
                md={12}
                style={{ paddingTop: "10px", textAlign: "center" }}
              >
                <Typography
                  style={{
                    width: "90%",
                    padding: "10px",
                    marginLeft: "5%",
                    fontWeight: "bold",
                  }}
                >
                  WED 18
                </Typography>
              </Grid>
              <Grid item md={12} style={{ paddingTop: "10px" }}>
                <Button
                  variant="outlined"
                  size="small"
                  style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                  onClick={() => setOpenModal(true)}
                >
                  9:00 am
                </Button>
              </Grid>
              <Grid item md={12} style={{ paddingTop: "10px" }}>
                <Button
                  variant="outlined"
                  size="small"
                  style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                  onClick={() => setOpenModal(true)}
                >
                  9:30 am
                </Button>
              </Grid>
              <Grid item md={12} style={{ paddingTop: "10px" }}>
                <Button
                  variant="outlined"
                  size="small"
                  style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                  onClick={() => setOpenModal(true)}
                >
                  10:00 am
                </Button>
              </Grid>
              <Grid item md={12} style={{ paddingTop: "10px" }}>
                <Button
                  variant="outlined"
                  size="small"
                  style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                  onClick={() => setOpenModal(true)}
                >
                  10:30 am
                </Button>
              </Grid>
              <Grid item md={12} style={{ paddingTop: "10px" }}>
                <Button
                  variant="outlined"
                  size="small"
                  style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                  onClick={() => setOpenModal(true)}
                >
                  11:00 am
                </Button>
              </Grid>
              <Grid item md={12} style={{ paddingTop: "10px" }}>
                <Button
                  variant="outlined"
                  size="small"
                  style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                  onClick={() => setOpenModal(true)}
                >
                  11:30 am
                </Button>
              </Grid>
              <Grid item md={12} style={{ paddingTop: "10px" }}>
                <Button
                  variant="outlined"
                  size="small"
                  style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                  onClick={() => setOpenModal(true)}
                >
                  12:00 am
                </Button>
              </Grid>
              <Grid item md={12} style={{ paddingTop: "10px" }}>
                <Button
                  variant="outlined"
                  size="small"
                  style={{ width: "90%", padding: "8px", marginLeft: "5%" }}
                  onClick={() => setOpenModal(true)}
                >
                  12:30 am
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ChatSidebar>
      <Paper mt={4}>
        <div>
          <Dialog
            open={openModal}
            onClose={() => setOpenModal(false)}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              Confirm your details
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                To subscribe to this website, please enter your email address
                here. We will send updates occasionally.
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Your Name"
                type="text"
                fullWidth
              />
              <TextField
                margin="dense"
                id="name"
                label="Email Address"
                type="text"
                fullWidth
              />
              <TextField
                margin="dense"
                id="biography"
                label="Additional notes"
                type="text"
                fullWidth
                multiline={true}
                rows={3}
                maxRows={4}
              />
              {showAddGuest == false ? (
                <Button
                  size="small"
                  color="primary"
                  onClick={() => setShowAddGuest(true)}
                >
                  <PersonAdd style={{ fontSize: "1rem", marginRight: "5px" }} />
                  Add Guests
                </Button>
              ) : (
                <Table1 />
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenModal(false)} color="primary">
                Cancel
              </Button>
              <Button component={Link} to="/book/confirm" color="primary">
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Paper>
    </ChatContainer>
  );
}

function CustomizedTableDemo() {
  return (
    <Card mb={6} mt={6}>
      <Paper style={{ padding: "0" }}>
        <Table>
          <TableBody>
            {rows.map((row) => (
              <CustomTableRow key={row.id}>
                <CustomTableCell component="th" scope="row">
                  UTC -12:00
                </CustomTableCell>
                <CustomTableCell align="right">
                  Canada/Yukon Time
                  <br />
                  <small style={{ color: "rgba(0, 0, 0, 0.6)" }}>
                    (UTC-07:00) Yukon
                  </small>
                </CustomTableCell>
              </CustomTableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Card>
  );
}

function Book() {
  const [view, setView] = React.useState(0);

  const handleChange = (event, newValue) => {
    setView(newValue);
  };
  return (
    <React.Fragment>
      {view == 0 ? (
        <Wrapper style={{ width: "60%" }}>
          <Helmet title="Book" />
          <Grid justifyContent="space-between" container spacing={10}>
            <Grid item>
              <Typography variant="h3" gutterBottom display="inline">
                Equitech Softwares
              </Typography>
            </Grid>
            <Grid item>
              <div>
                <Tabs
                  value={view}
                  onChange={handleChange}
                  aria-label="icon tabs example"
                >
                  <Tab icon={<CalendarMonth />} aria-label="phone" />
                  <Tab icon={<CalendarViewMonth />} aria-label="favorite" />
                  <Tab icon={<ViewColumn />} aria-label="person" />
                </Tabs>
              </div>
            </Grid>
          </Grid>

          <Divider my={6} />

          <MonthView />
        </Wrapper>
      ) : view == 1 ? (
        <Wrapper style={{ width: "100%" }}>
          <Helmet title="Book" />
          <Grid justifyContent="space-between" container spacing={10}>
            <Grid item>
              <Typography variant="h3" gutterBottom display="inline">
                Equitech Softwares
              </Typography>
            </Grid>
            <Grid item>
              <div>
                <Tabs
                  value={view}
                  onChange={handleChange}
                  aria-label="icon tabs example"
                >
                  <Tab icon={<CalendarMonth />} aria-label="phone" />
                  <Tab icon={<CalendarViewMonth />} aria-label="favorite" />
                  <Tab icon={<ViewColumn />} aria-label="person" />
                </Tabs>
              </div>
            </Grid>
          </Grid>

          <Divider my={6} />
          <WeekView />
        </Wrapper>
      ) : (
        <Wrapper style={{ width: "95%" }}>
          <Helmet title="Book" />
          <Grid justifyContent="space-between" container spacing={10}>
            <Grid item>
              <Typography variant="h3" gutterBottom display="inline">
                Equitech Softwares
              </Typography>
            </Grid>
            <Grid item>
              <div>
                <Tabs
                  value={view}
                  onChange={handleChange}
                  aria-label="icon tabs example"
                >
                  <Tab icon={<CalendarMonth />} aria-label="phone" />
                  <Tab icon={<CalendarViewMonth />} aria-label="favorite" />
                  <Tab icon={<ViewColumn />} aria-label="person" />
                </Tabs>
              </div>
            </Grid>
          </Grid>

          <Divider my={6} />

          <ColumnView />
        </Wrapper>
      )}
    </React.Fragment>
  );
}

export default Book;
