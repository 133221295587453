import React, { useState } from "react";
import styled from "@emotion/styled";
import ListItem from "@mui/material/ListItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import "../../../../Styles/Custom.css";
import Switch from "@mui/material/Switch";
import InputAdornment from "@mui/material/InputAdornment";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Checkbox,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  Menu,
  MenuItem,
  InputBase,
  DialogActions,
  TextField,
  CardContent,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card as MuiCard,
  CardMedia as MuiCardMedia,
  CardActions,
  AvatarGroup as MuiAvatarGroup,
  FormControlLabel,
} from "@mui/material";
import { green, orange } from "@mui/material/colors";
import {
  Add as AddIcon,
  ArrowBack as ArrowBackIcon,
  FilterList,
  Download,
  Mail,
  ContentCopy,
  Visibility,
} from "@mui/icons-material";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import LanguageIcon from "@mui/icons-material/Language";
import { tableCellClasses } from "@mui/material/TableCell";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const CustomTableRow = styled(TableRow)`
  // &:nth-of-type(odd) {
  //   background-color: rgba(0, 0, 0, 0.025);
  // }
`;
const QuillWrapper = styled.div`
  .ql-editor {
    min-height: 200px;
  }
`;
const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: ${(props) => props.theme.palette.common.black};
    color: ${(props) => props.theme.palette.common.white};
  }
  &.${tableCellClasses.body} {
    font-size: 14px;
  }
`;
// Data
let id = 0;
function createData(name, calories, fat, carbs, protein) {
  id += 1;
  return { id, name, calories, fat, carbs, protein };
}
const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];
function CustomizedTableDemo() {
  return (
    <Card mb={6} mt={6}>
      <Paper>
        <Table>
          <TableBody>
            {rows.map((row) => (
              <CustomTableRow key={row.id}>
                <CustomTableCell component="th" scope="row">
                  UTC -12:00
                </CustomTableCell>
                <CustomTableCell align="right">
                  Canada/Yukon Time
                  <br />
                  <small style={{ color: "rgba(0, 0, 0, 0.6)" }}>
                    (UTC-07:00) Yukon
                  </small>
                </CustomTableCell>
              </CustomTableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Card>
  );
}

function EventEntryTab() {
  const [maxWidth, setMaxWidth] = useState("sm");
  const handleMaxWidthChange = (event) => {
    setMaxWidth(event.target.value);
  };

  const [limitBookingFrequency, setlimitBookingFrequency] = useState("false");
  const handleLimitBookingFrequencyChange = (event) => {
    setlimitBookingFrequency(JSON.stringify(event.target.checked));
  };

  const [limitBookingDuration, setlimitBookingDuration] = useState("false");
  const handleLimitBookingDurationChange = (event) => {
    setlimitBookingDuration(JSON.stringify(event.target.checked));
  };

  const [limitFutureBookings, setlimitFutureBookings] = useState("false");
  const handleLimitFutureBookingsChange = (event) => {
    setlimitFutureBookings(JSON.stringify(event.target.checked));
  };

  const [limitByDate, setLimitByDate] = useState("true");
  const handleLimitByDateChange = (event) => {
    setLimitByDate(event.target.value);
  };

  const [offsetStartTimes, setOffsetStartTimes] = useState("false");
  const handleOffsetStartTimesChange = (event) => {
    setOffsetStartTimes(JSON.stringify(event.target.checked));
  };

  const [value, setValue] = React.useState([
    dayjs("2022-04-17"),
    dayjs("2022-04-21"),
  ]);

  return (
    <Grid justifyContent="space-between" container spacing={10}>
      <Grid item md={12}>
        <Typography variant="h3" gutterBottom display="inline">
          Limits
        </Typography>
        <Typography>
          Configure what information should be captured during registration
        </Typography>
      </Grid>
      <Grid item md={12}>
        {/* <Typography variant="h6" gutterBottom>
            Basic Information
          </Typography> */}
        <Box style={{ marginBottom: "15px" }}>
          <Grid container spacing={6}>
            <Grid item md={6} style={{ marginBottom: "15px" }}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel htmlFor="max-width">Before Event</InputLabel>
                <Select
                  autoFocus
                  value={maxWidth}
                  onChange={handleMaxWidthChange}
                  label="maxWidth"
                  inputProps={{
                    name: "max-width",
                    id: "max-width",
                  }}
                >
                  <MenuItem value={false}>No Buffer Time</MenuItem>
                  <MenuItem value="xs">5 Minutes</MenuItem>
                  <MenuItem value="sm">10 Minutes</MenuItem>
                  <MenuItem value="md">15 Minutes</MenuItem>
                  <MenuItem value="lg">20 Minutes</MenuItem>
                  <MenuItem value="xl">30 Minutes</MenuItem>
                  <MenuItem value="xl">45 Minutes</MenuItem>
                  <MenuItem value="xl">60 Minutes</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6} style={{ marginBottom: "15px" }}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel htmlFor="max-width">After Event</InputLabel>
                <Select
                  autoFocus
                  value={maxWidth}
                  onChange={handleMaxWidthChange}
                  label="maxWidth"
                  inputProps={{
                    name: "max-width",
                    id: "max-width",
                  }}
                >
                  <MenuItem value={false}>No Buffer Time</MenuItem>
                  <MenuItem value="xs">5 Minutes</MenuItem>
                  <MenuItem value="sm">10 Minutes</MenuItem>
                  <MenuItem value="md">15 Minutes</MenuItem>
                  <MenuItem value="lg">20 Minutes</MenuItem>
                  <MenuItem value="xl">30 Minutes</MenuItem>
                  <MenuItem value="xl">45 Minutes</MenuItem>
                  <MenuItem value="xl">60 Minutes</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item md={6}>
              <Grid container spacing={6}>
                <Grid item md={6} style={{ marginBottom: "15px" }}>
                  <TextField
                    id="username"
                    label="Minimum Notice"
                    defaultValue=""
                    variant="outlined"
                    fullWidth
                    my={2}
                  />
                </Grid>
                <Grid item md={6}>
                  <Select
                    style={{ width: "100%" }}
                    autoFocus
                    value={maxWidth}
                    onChange={handleMaxWidthChange}
                    label="maxWidth"
                    inputProps={{
                      name: "max-width",
                      id: "max-width",
                    }}
                  >
                    <MenuItem value={false}>Minutes</MenuItem>
                    <MenuItem value="xs">5 Hours</MenuItem>
                    <MenuItem value="sm">Days</MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6} style={{ marginBottom: "15px" }}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel htmlFor="max-width">Time Slot Intervals</InputLabel>
                <Select
                  autoFocus
                  value={maxWidth}
                  onChange={handleMaxWidthChange}
                  label="maxWidth"
                  inputProps={{
                    name: "max-width",
                    id: "max-width",
                  }}
                >
                  <MenuItem value={false}>Use Event Length (Default)</MenuItem>
                  <MenuItem value="xs">5 Minutes</MenuItem>
                  <MenuItem value="sm">10 Minutes</MenuItem>
                  <MenuItem value="md">15 Minutes</MenuItem>
                  <MenuItem value="lg">20 Minutes</MenuItem>
                  <MenuItem value="xl">30 Minutes</MenuItem>
                  <MenuItem value="xl">45 Minutes</MenuItem>
                  <MenuItem value="xl">60 Minutes</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box style={{ marginTop: "15px", marginBottom: "15px" }}>
          <Grid item md={12}>
            <ListItem alignItems="flex-start" disablePadding>
              <Grid container>
                <Grid md={6}>
                  <Typography variant="subtitle1">
                    Limit booking frequency
                    <Typography
                      color="success"
                      style={{ paddingLeft: "12px !important" }}
                    >
                      <small
                        style={{
                          position: "relative",
                          bottom: "4px",
                          paddingLeft: "2px",
                        }}
                      >
                        Limit how many times this event can be booked
                      </small>
                    </Typography>
                  </Typography>
                </Grid>
                <Grid md={6}>
                  <Switch
                    inputProps={{ "aria-label": "controlled" }}
                    style={{ float: "right" }}
                    onChange={handleLimitBookingFrequencyChange}
                  />
                </Grid>
              </Grid>
            </ListItem>
          </Grid>
          {limitBookingFrequency == "true" ? (
            <div>
              <Grid container spacing={6}>
                <Grid
                  item
                  md={1}
                  style={{ marginBottom: "15px", marginTop: "15px" }}
                >
                  <Checkbox
                    style={{ position: "relative", top: "5px" }}
                  ></Checkbox>
                </Grid>
                <Grid
                  item
                  md={3}
                  style={{ marginBottom: "15px", marginTop: "15px" }}
                >
                  <TextField
                    id="username"
                    // label="Offset By"
                    defaultValue="1"
                    variant="outlined"
                    fullWidth
                    // my={2}
                  />
                </Grid>
                <Grid
                  item
                  md={3}
                  style={{ marginBottom: "15px", marginTop: "15px" }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    {/* <InputLabel htmlFor="max-width">After Event</InputLabel> */}
                    <Select
                      disabled
                      autoFocus
                      value={maxWidth}
                      onChange={handleMaxWidthChange}
                      // label="maxWidth"
                      inputProps={{
                        name: "max-width",
                        id: "max-width",
                      }}
                    >
                      <MenuItem value="sm">Per Day</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={6}>
                <Grid
                  item
                  md={1}
                  style={{ marginBottom: "15px", marginTop: "15px" }}
                >
                  <Checkbox
                    style={{ position: "relative", top: "5px" }}
                  ></Checkbox>
                </Grid>
                <Grid
                  item
                  md={3}
                  style={{ marginBottom: "15px", marginTop: "15px" }}
                >
                  <TextField
                    id="username"
                    // label="Offset By"
                    defaultValue="1"
                    variant="outlined"
                    fullWidth
                    // my={2}
                  />
                </Grid>
                <Grid
                  item
                  md={3}
                  style={{ marginBottom: "15px", marginTop: "15px" }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    {/* <InputLabel htmlFor="max-width">After Event</InputLabel> */}
                    <Select
                      disabled
                      autoFocus
                      value={maxWidth}
                      onChange={handleMaxWidthChange}
                      // label="maxWidth"
                      inputProps={{
                        name: "max-width",
                        id: "max-width",
                      }}
                    >
                      <MenuItem value="sm">Per Week</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={6}>
                <Grid
                  item
                  md={1}
                  style={{ marginBottom: "15px", marginTop: "15px" }}
                >
                  <Checkbox
                    style={{ position: "relative", top: "5px" }}
                  ></Checkbox>
                </Grid>
                <Grid
                  item
                  md={3}
                  style={{ marginBottom: "15px", marginTop: "15px" }}
                >
                  <TextField
                    id="username"
                    // label="Offset By"
                    defaultValue="1"
                    variant="outlined"
                    fullWidth
                    // my={2}
                  />
                </Grid>
                <Grid
                  item
                  md={3}
                  style={{ marginBottom: "15px", marginTop: "15px" }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    {/* <InputLabel htmlFor="max-width">After Event</InputLabel> */}
                    <Select
                      disabled
                      autoFocus
                      value={maxWidth}
                      onChange={handleMaxWidthChange}
                      // label="maxWidth"
                      inputProps={{
                        name: "max-width",
                        id: "max-width",
                      }}
                    >
                      <MenuItem value="sm">Per Month</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={6}>
                <Grid
                  item
                  md={1}
                  style={{ marginBottom: "15px", marginTop: "15px" }}
                >
                  <Checkbox
                    style={{ position: "relative", top: "5px" }}
                  ></Checkbox>
                </Grid>
                <Grid
                  item
                  md={3}
                  style={{ marginBottom: "15px", marginTop: "15px" }}
                >
                  <TextField
                    id="username"
                    // label="Offset By"
                    defaultValue="1"
                    variant="outlined"
                    fullWidth
                    // my={2}
                  />
                </Grid>
                <Grid
                  item
                  md={3}
                  style={{ marginBottom: "15px", marginTop: "15px" }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    {/* <InputLabel htmlFor="max-width">After Event</InputLabel> */}
                    <Select
                      disabled
                      autoFocus
                      value={maxWidth}
                      onChange={handleMaxWidthChange}
                      // label="maxWidth"
                      inputProps={{
                        name: "max-width",
                        id: "max-width",
                      }}
                    >
                      <MenuItem value="sm">Per Year</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </div>
          ) : (
            <></>
          )}
        </Box>
        <Divider />
        <Box style={{ marginTop: "15px", marginBottom: "15px" }}>
          <Grid item md={12}>
            <ListItem alignItems="flex-start" disablePadding>
              <Grid container>
                <Grid md={6}>
                  <Typography variant="subtitle1">
                    Limit booking only first slot
                    <Typography
                      color="success"
                      style={{ paddingLeft: "12px !important" }}
                    >
                      <small
                        style={{
                          position: "relative",
                          bottom: "4px",
                          paddingLeft: "2px",
                        }}
                      >
                        Allow only the first slot of every day to be booked
                      </small>
                    </Typography>
                  </Typography>
                </Grid>
                <Grid md={6}>
                  <Switch
                    inputProps={{ "aria-label": "controlled" }}
                    style={{ float: "right" }}
                  />
                </Grid>
              </Grid>
            </ListItem>
            {/* <Switch inputProps={{ "aria-label": "controlled" }} />
            <ListItemText primary="Allow booker to select duration" /> */}
          </Grid>
        </Box>
        <Divider />
        <Box style={{ marginTop: "15px", marginBottom: "15px" }}>
          <Grid item md={12}>
            <ListItem alignItems="flex-start" disablePadding>
              <Grid container>
                <Grid md={6}>
                  <Typography variant="subtitle1">
                    Limit total booking duration
                    <Typography
                      color="success"
                      style={{ paddingLeft: "12px !important" }}
                    >
                      <small
                        style={{
                          position: "relative",
                          bottom: "4px",
                          paddingLeft: "2px",
                        }}
                      >
                        Limit total amount of time that this event can be booked
                      </small>
                    </Typography>
                  </Typography>
                </Grid>
                <Grid md={6}>
                  <Switch
                    inputProps={{ "aria-label": "controlled" }}
                    style={{ float: "right" }}
                    onChange={handleLimitBookingDurationChange}
                  />
                </Grid>
              </Grid>
            </ListItem>
            {/* <Switch inputProps={{ "aria-label": "controlled" }} />
            <ListItemText primary="Allow booker to select duration" /> */}
          </Grid>
          {limitBookingDuration == "true" ? (
            <div>
              <Grid container spacing={6}>
                <Grid
                  item
                  md={1}
                  style={{ marginBottom: "15px", marginTop: "15px" }}
                >
                  <Checkbox
                    style={{ position: "relative", top: "5px" }}
                  ></Checkbox>
                </Grid>
                <Grid
                  item
                  md={3}
                  style={{ marginBottom: "15px", marginTop: "15px" }}
                >
                  <TextField
                    id="username"
                    // label="Offset By"
                    defaultValue="60"
                    variant="outlined"
                    fullWidth
                    // my={2}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button disabled>Minutes</Button>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid
                  item
                  md={3}
                  style={{ marginBottom: "15px", marginTop: "15px" }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    {/* <InputLabel htmlFor="max-width">After Event</InputLabel> */}
                    <Select
                      disabled
                      autoFocus
                      value={maxWidth}
                      onChange={handleMaxWidthChange}
                      // label="maxWidth"
                      inputProps={{
                        name: "max-width",
                        id: "max-width",
                      }}
                    >
                      <MenuItem value="sm">Per Day</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={6}>
                <Grid
                  item
                  md={1}
                  style={{ marginBottom: "15px", marginTop: "15px" }}
                >
                  <Checkbox
                    style={{ position: "relative", top: "5px" }}
                  ></Checkbox>
                </Grid>
                <Grid
                  item
                  md={3}
                  style={{ marginBottom: "15px", marginTop: "15px" }}
                >
                  <TextField
                    id="username"
                    // label="Offset By"
                    defaultValue="60"
                    variant="outlined"
                    fullWidth
                    // my={2}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button disabled>Minutes</Button>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid
                  item
                  md={3}
                  style={{ marginBottom: "15px", marginTop: "15px" }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    {/* <InputLabel htmlFor="max-width">After Event</InputLabel> */}
                    <Select
                      disabled
                      autoFocus
                      value={maxWidth}
                      onChange={handleMaxWidthChange}
                      // label="maxWidth"
                      inputProps={{
                        name: "max-width",
                        id: "max-width",
                      }}
                    >
                      <MenuItem value="sm">Per Week</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={6}>
                <Grid
                  item
                  md={1}
                  style={{ marginBottom: "15px", marginTop: "15px" }}
                >
                  <Checkbox
                    style={{ position: "relative", top: "5px" }}
                  ></Checkbox>
                </Grid>
                <Grid
                  item
                  md={3}
                  style={{ marginBottom: "15px", marginTop: "15px" }}
                >
                  <TextField
                    id="username"
                    // label="Offset By"
                    defaultValue="60"
                    variant="outlined"
                    fullWidth
                    // my={2}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button disabled>Minutes</Button>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid
                  item
                  md={3}
                  style={{ marginBottom: "15px", marginTop: "15px" }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    {/* <InputLabel htmlFor="max-width">After Event</InputLabel> */}
                    <Select
                      disabled
                      autoFocus
                      value={maxWidth}
                      onChange={handleMaxWidthChange}
                      // label="maxWidth"
                      inputProps={{
                        name: "max-width",
                        id: "max-width",
                      }}
                    >
                      <MenuItem value="sm">Per Month</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={6}>
                <Grid
                  item
                  md={1}
                  style={{ marginBottom: "15px", marginTop: "15px" }}
                >
                  <Checkbox
                    style={{ position: "relative", top: "5px" }}
                  ></Checkbox>
                </Grid>
                <Grid
                  item
                  md={3}
                  style={{ marginBottom: "15px", marginTop: "15px" }}
                >
                  <TextField
                    id="username"
                    // label="Offset By"
                    defaultValue="60"
                    variant="outlined"
                    fullWidth
                    // my={2}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button disabled>Minutes</Button>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid
                  item
                  md={3}
                  style={{ marginBottom: "15px", marginTop: "15px" }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    {/* <InputLabel htmlFor="max-width">After Event</InputLabel> */}
                    <Select
                      disabled
                      autoFocus
                      value={maxWidth}
                      onChange={handleMaxWidthChange}
                      // label="maxWidth"
                      inputProps={{
                        name: "max-width",
                        id: "max-width",
                      }}
                    >
                      <MenuItem value="sm">Per Year</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </div>
          ) : (
            <></>
          )}
        </Box>
        <Divider />
        <Box style={{ marginTop: "15px", marginBottom: "15px" }}>
          <Grid item md={12}>
            <ListItem alignItems="flex-start" disablePadding>
              <Grid container>
                <Grid md={6}>
                  <Typography variant="subtitle1">
                    Limit future bookings
                    <Typography
                      color="success"
                      style={{ paddingLeft: "12px !important" }}
                    >
                      <small
                        style={{
                          position: "relative",
                          bottom: "4px",
                          paddingLeft: "2px",
                        }}
                      >
                        Limit how far in the future this event can be booked
                      </small>
                    </Typography>
                  </Typography>
                </Grid>
                <Grid md={6}>
                  <Switch
                    inputProps={{ "aria-label": "controlled" }}
                    style={{ float: "right" }}
                    onChange={handleLimitFutureBookingsChange}
                  />
                </Grid>
              </Grid>
            </ListItem>
            {/* <Switch inputProps={{ "aria-label": "controlled" }} />
            <ListItemText primary="Allow booker to select duration" /> */}
          </Grid>
          {limitFutureBookings == "true" ? (
            <Grid container spacing={6}>
              <Grid item md={6} style={{ marginTop: "15px" }}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="Limit By Days"
                      onChange={handleLimitByDateChange}
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="Limit By Date Range"
                      onChange={handleLimitByDateChange}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item md={12}>
                {limitByDate == "true" ? (
                  <Grid container spacing={6}>
                    <Grid item md={3}>
                      <TextField
                        id="username"
                        label="No of days"
                        defaultValue=""
                        variant="outlined"
                        fullWidth
                        my={2}
                      />
                    </Grid>
                    <Grid item md={3}>
                      <Select
                        style={{ width: "100%" }}
                        autoFocus
                        value={maxWidth}
                        onChange={handleMaxWidthChange}
                        label="maxWidth"
                        inputProps={{
                          name: "max-width",
                          id: "max-width",
                        }}
                      >
                        <MenuItem value="xs">Business Days</MenuItem>
                        <MenuItem value="sm">Calendar Days</MenuItem>
                      </Select>
                    </Grid>
                    <Grid item md={3}>
                      <Typography style={{ position: "relative", top: "15px" }}>
                        into the future
                      </Typography>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container spacing={6}>
                    <Grid item md={3}>
                      <DatePicker
                        id="username"
                        label="From Date"
                        variant="outlined"
                        fullWidth
                        slotProps={{ textField: { fullWidth: true } }}
                      />
                    </Grid>
                    <Grid item md={3}>
                      <DatePicker
                        id="username"
                        label="To Date"
                        variant="outlined"
                        fullWidth
                        slotProps={{ textField: { fullWidth: true } }}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
        </Box>
        <Divider />
        <Box style={{ marginTop: "15px", marginBottom: "15px" }}>
          <Grid item md={12}>
            <ListItem alignItems="flex-start" disablePadding>
              <Grid container>
                <Grid md={6}>
                  <Typography variant="subtitle1">
                    Offset start times
                    <Typography
                      color="success"
                      style={{ paddingLeft: "12px !important" }}
                    >
                      <small
                        style={{
                          position: "relative",
                          bottom: "4px",
                          paddingLeft: "2px",
                        }}
                      >
                        Offset timeslots shown to bookers by a specified number
                        of minutes
                      </small>
                    </Typography>
                  </Typography>
                </Grid>
                <Grid md={6}>
                  <Switch
                    inputProps={{ "aria-label": "controlled" }}
                    style={{ float: "right" }}
                    onChange={handleOffsetStartTimesChange}
                  />
                </Grid>
              </Grid>
            </ListItem>
          </Grid>
          {offsetStartTimes == "true" ? (
            <Grid container spacing={6}>
              <Grid
                item
                md={6}
                style={{ marginBottom: "15px", marginTop: "15px" }}
              >
                <TextField
                  id="username"
                  label="Offset By"
                  defaultValue=""
                  variant="outlined"
                  fullWidth
                  my={2}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button disabled>Minutes</Button>
                      </InputAdornment>
                    ),
                  }}
                />
                <small>
                  e.g. this will show time slots to your bookers at 9:00 AM
                  instead of 9:00 AM
                </small>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
        </Box>
        <Button variant="contained" color="primary">
          Save
        </Button>
      </Grid>
    </Grid>
  );
}

function Limits() {
  return (
    <React.Fragment>
      <EventEntryTab />
    </React.Fragment>
  );
}

export default Limits;
