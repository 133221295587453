import React, { useState } from "react";
import styled from "@emotion/styled";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import "../../../../Styles/Custom.css";
import Switch from "@mui/material/Switch";
import CardMedia from "@mui/material/CardMedia";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Input from "@mui/material/Input";
import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import ReactQuill from "react-quill";
import ListItem from "@mui/material/ListItem";

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  Paper as MuiPaper,
  TableCell,
  TableRow,
  Typography,
  Card as MuiCard,
  CardMedia as MuiCardMedia,
  AvatarGroup as MuiAvatarGroup,
  FormControlLabel,
  Alert as MuiAlert,
  Select,
  MenuItem,
} from "@mui/material";

import { green, orange } from "@mui/material/colors";

import {
  Add as AddIcon,
  ArrowBack as ArrowBackIcon,
  FilterList,
  Download,
  Mail,
  ContentCopy,
  Visibility,
  CloudUpload as MuiCloudUpload,
  FileUpload,
  Videocam,
  InsertPhoto,
} from "@mui/icons-material";

import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import { tableCellClasses } from "@mui/material/TableCell";
import { spacing } from "@mui/system";

import Code from "../../../../components/Code";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);
const Alert = styled(MuiAlert)(spacing);
const CustomTableRow = styled(TableRow)`
  // &:nth-of-type(odd) {
  //   background-color: rgba(0, 0, 0, 0.025);
  // }
`;
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));
const CloudUpload = styled(MuiCloudUpload)(spacing);
const QuillWrapper = styled.div`
  .ql-editor {
    min-height: 200px;
  }
`;
const CenteredContent = styled.div`
  text-align: center;
`;
const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 0 auto ${(props) => props.theme.spacing(2)};
`;
const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: ${(props) => props.theme.palette.common.black};
    color: ${(props) => props.theme.palette.common.white};
  }
  &.${tableCellClasses.body} {
    font-size: 14px;
  }
`;
// Data
let id = 0;
function createData(name, calories, fat, carbs, protein) {
  id += 1;
  return { id, name, calories, fat, carbs, protein };
}

function QRConnectSettings({ handleChildData }) {
  const [ButtonStyle, setButtonStyle] = React.useState("Button");
  const [Mode, setMode] = React.useState("Standard");
  const sendDataToParent = (d) => {
    handleChildData(d);
  };

  const [IsCustomizeableButton, setIsCustomizeableButton] = useState("false");
  const handleCustomizeableButtonChange = (event) => {
    setIsCustomizeableButton(JSON.stringify(event.target.checked));
  };
  const [IsEnablePrivacy, setIsEnablePrivacy] = useState("false");
  const handleIsEnablePrivacyChange = (event) => {
    setIsEnablePrivacy(JSON.stringify(event.target.checked));
  };

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Grid justifyContent="space-between" container>
      <Grid item md={12}>
        <Typography variant="h3" gutterBottom>
          Setup your QR Connect
        </Typography>
        {/* <Alert mt={3} mb={3} severity="info">
          Please create all your texts in English or leave the default texts.
          You can manage the translations here Translations. We always show the
          widget in the browser language the user has chosen. If this is not
          available, English will be default.
        </Alert> */}
        <Grid container spacing={6}>
          <Grid item md={5} style={{ marginBottom: "15px" }}>
            {/* <Typography variant="h6" gutterBottom>
              1. Button + Text
            </Typography> */}
            <Box mb={4} mt={4}>
              <Grid item md={12} mb={4}>
                <FormControl fullWidth my={2} variant="outlined">
                  <TextField
                    label="QR Connect Name"
                    id="biography"
                    variant="outlined"
                    defaultValue=""
                  />
                </FormControl>
              </Grid>
              <Grid item md={12} style={{ marginBottom: "15px" }}>
                <FormControl style={{ width: "100%" }}>
                  <InputLabel htmlFor="Team">Team</InputLabel>
                  <Select autoFocus value="Sales" label="Team">
                    <MenuItem value="Sales">Sales</MenuItem>
                    <MenuItem value="Support">Support</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={12}>
                <Box>
                  <Grid
                    item
                    md={12}
                    style={{
                      padding: "15px",
                      border: "1px solid #c4c4c4",
                      marginBottom: "15px",
                    }}
                  >
                    <ListItem alignItems="flex-start" disablePadding>
                      <Grid container>
                        <Grid md={10}>
                          <Typography variant="subtitle1">Niaz Khan</Typography>
                        </Grid>
                        <Grid md={2}>
                          <DeleteIcon style={{ float: "right" }} />
                        </Grid>
                      </Grid>
                    </ListItem>
                  </Grid>
                </Box>
                <Box>
                  <Grid
                    item
                    md={12}
                    style={{
                      padding: "15px",
                      border: "1px solid #c4c4c4",
                      marginBottom: "15px",
                    }}
                  >
                    <ListItem alignItems="flex-start" disablePadding>
                      <Grid container>
                        <Grid md={10}>
                          <Typography variant="subtitle1">James</Typography>
                        </Grid>
                        <Grid md={2}>
                          <DeleteIcon style={{ float: "right" }} />
                        </Grid>
                      </Grid>
                    </ListItem>
                  </Grid>
                </Box>
              </Grid>
              <Grid item md={12}>
                <InputLabel>
                  Directly show up contact form, when no Agent is online
                  <FormControlLabel
                    label=""
                    control={<Switch />}
                    style={{ marginLeft: "0px" }}
                  />
                </InputLabel>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default QRConnectSettings;
