import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import MenuList from "@mui/material/MenuList";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ContentCut from "@mui/icons-material/ContentCut";
import ContentCopy from "@mui/icons-material/ContentCopy";
import ContentPaste from "@mui/icons-material/ContentPaste";
import Cloud from "@mui/icons-material/Cloud";
import People from "../../pages/Events/People";
import EventEntry from "../../pages/Events/EventEntry";
import RegistrationForm from "../../pages/Events/RegistrationForm";
import Sessions from "../../pages/Events/Sessions";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  TextField as MuiTextField,
  Typography,
  Grid,
  Button,
  CardContent,
  Paper as MuiPaper,
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  Toolbar,
  Chip,
  DialogActions,
  TextField,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import {
  Add as AddIcon,
  ArrowBack as ArrowBackIcon,
  Share,
} from "@mui/icons-material";

import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const ToolbarTitle = styled.div`
  min-width: 620px;

  // ${(props) => props.theme.breakpoints.up("md")} {
  //   min-width: 900px;
  // }
`;

function EventHeader() {
  const [anchorEl1, setAnchorEl1] = React.useState(null);

  const handleClickShowing = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleCloseShowing = (event) => {
    setAnchorEl1(null);
  };

  const [value, setValue] = React.useState(0);

  return (
    <Card mb={6}>
      <CardContent>
        <Toolbar style={{ paddingLeft: "0" }}>
          <ToolbarTitle>
            <Typography
              variant="subtitle1"
              style={{ paddingLeft: "12px", fontWeight: "bold" }}
            >
              Virtual Event1
              <small
                style={{
                  fontWeight: "normal",
                  position: "relative",
                  bottom: "1px",
                  paddingLeft: "10px",
                  color: "rgb(244, 67, 54)",
                }}
              >
                Upcoming
              </small>
              <Typography
                color="success"
                style={{ paddingLeft: "12px !important" }}
              >
                <CalendarMonthOutlinedIcon fontSize="small" />
                <small
                  style={{
                    position: "relative",
                    bottom: "4px",
                    paddingLeft: "2px",
                  }}
                >
                  Wed 20 Dec 2023, 11:00 AM IST - Mon 25 Dec 2023, 09:30 AM IST
                  • Virtual Event
                </small>
              </Typography>
            </Typography>
          </ToolbarTitle>
          {/* <Spacer /> */}
          <Grid container display="block" textAlign="right">
            <Grid container>
              <Grid md={12} className="filter">
                <Button
                  variant="contained"
                  color="inherit"
                  sx={{ mr: 2, display: { xs: "none", md: "inline-flex" } }}
                >
                  <Share />
                  Share Event
                </Button>
                <Button
                  variant="contained"
                  color="inherit"
                  sx={{ mr: 2, display: { xs: "none", md: "inline-flex" } }}
                >
                  <VisibilityIcon />
                  View Event
                </Button>
                <Button
                  variant="contained"
                  sx={{ mr: 2, display: { xs: "none", md: "inline-flex" } }}
                >
                  <PlayArrowIcon />
                  Start Event
                </Button>
                <IconButton
                  aria-label="Edit"
                  size="large"
                  aria-owns={anchorEl1 ? "simple-menu-showing" : undefined}
                  aria-haspopup="true"
                  onClick={handleClickShowing}
                  variant="contained"
                  color="secondary"
                  sx={{ mr: 2, display: { xs: "block", md: "none" } }}
                >
                  <FilterAltIcon />
                </IconButton>
                <IconButton
                  aria-label="View"
                  size="large"
                  aria-owns={anchorEl1 ? "simple-menu-showing" : undefined}
                  aria-haspopup="true"
                  onClick={handleClickShowing}
                  variant="contained"
                  color="secondary"
                  sx={{ mr: 2, display: { xs: "block", md: "none" } }}
                >
                  <VisibilityIcon />
                </IconButton>
                <Menu
                  id="simple-menu-showing"
                  anchorEl={anchorEl1}
                  open={Boolean(anchorEl1)}
                  onClose={handleCloseShowing}
                >
                  <MenuItem>All</MenuItem>
                  <MenuItem>Upcoming</MenuItem>
                  <MenuItem>Ongoing</MenuItem>
                  <MenuItem>Paused</MenuItem>
                  <MenuItem>Completed</MenuItem>
                </Menu>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </CardContent>
    </Card>
  );
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function RegistrationTab() {
  const [RTab, setRTab] = React.useState("Event Entry");

  // const handleClickShowing = (event) => {
  //   setAnchorEl1(event.currentTarget);
  // };
  return (
    <Grid container>
      <Grid md={3}>
        <Paper>
          <MenuList style={{ height: "300px", padding: "0" }}>
            {RTab == "Event Entry" ? (
              <MenuItem
                onClick={(e) => {
                  setRTab("Event Entry");
                }}
                style={{
                  borderLeft: "3px solid",
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                  minHeight: "50px",
                }}
              >
                <ListItemText>Event Entry</ListItemText>
              </MenuItem>
            ) : (
              <MenuItem
                onClick={(e) => {
                  setRTab("Event Entry");
                }}
                style={{ minHeight: "50px" }}
              >
                <ListItemText>Event Entry</ListItemText>
              </MenuItem>
            )}
            {RTab == "Registration Form" ? (
              <MenuItem
                onClick={(e) => {
                  setRTab("Registration Form");
                }}
                style={{
                  borderLeft: "3px solid",
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                  minHeight: "50px",
                }}
              >
                <ListItemText>Registration Form</ListItemText>
              </MenuItem>
            ) : (
              <MenuItem
                onClick={(e) => {
                  setRTab("Registration Form");
                }}
                style={{ minHeight: "50px" }}
              >
                <ListItemText>Registration Form</ListItemText>
              </MenuItem>
            )}
          </MenuList>
        </Paper>
      </Grid>
      <Grid md={9} sx={{ paddingLeft: "25px" }}>
        {RTab == "Event Entry" ? <EventEntry /> : <RegistrationForm />}
      </Grid>
    </Grid>
  );
}

function PeopleTab() {
  const [open, setOpen] = useState(false);
  return (
    <Grid container>
      <Grid md={3}>
        <Paper>
          <MenuList style={{ height: "300px", padding: "0" }}>
            <MenuItem
              style={{
                borderLeft: "3px solid",
                backgroundColor: "rgba(0, 0, 0, 0.04)",
                minHeight: "50px",
              }}
            >
              <ListItemText>Attendees</ListItemText>
            </MenuItem>
            <MenuItem style={{ minHeight: "50px" }}>
              <ListItemText>Speakers</ListItemText>
            </MenuItem>
            <MenuItem style={{ minHeight: "50px" }}>
              <ListItemText>Exhibitors</ListItemText>
            </MenuItem>
            <MenuItem style={{ minHeight: "50px" }}>
              <ListItemText>Hosts</ListItemText>
            </MenuItem>
            <MenuItem style={{ minHeight: "50px" }}>
              <ListItemText>Attendee Interests</ListItemText>
            </MenuItem>
          </MenuList>
        </Paper>
        <Typography
          variant="body2"
          textAlign="right"
          style={{ paddingTop: "10px" }}
        >
          <Button
            size="small"
            variant="contained"
            color="primary"
            sx={{ mx: "0.5rem" }}
            onClick={() => setOpen(true)}
          >
            <AddIcon /> Add
          </Button>
        </Typography>
      </Grid>
      <Grid md={9} sx={{ paddingLeft: "25px" }}>
        <People />
      </Grid>
      <Paper mt={4}>
        <div>
          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title" mb={0}>
              Add New People
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Add people to the people list. For a seamless one-click entry
                for your people, please fill the form field.
              </DialogContentText>
              <br />
              <Grid container spacing={6}>
                <Grid item md={12}>
                  <TextField
                    id="username"
                    label="People Name"
                    defaultValue=""
                    variant="outlined"
                    fullWidth
                    my={2}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen(false)} color="primary">
                Cancel
              </Button>
              <Button onClick={() => setOpen(false)} color="primary">
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Paper>
    </Grid>
  );
}

function SessionTab() {
  const [open, setOpen] = useState(false);
  return (
    <Grid container>
      <Grid md={3}>
        <Paper>
          <MenuList style={{ height: "300px", padding: "0" }}>
            <MenuItem
              style={{
                borderLeft: "3px solid",
                backgroundColor: "rgba(0, 0, 0, 0.04)",
                minHeight: "50px",
              }}
            >
              <ListItemText>Session List</ListItemText>
            </MenuItem>
            <MenuItem style={{ minHeight: "50px" }}>
              <ListItemText>Session Recordings</ListItemText>
            </MenuItem>
            <MenuItem style={{ minHeight: "50px" }}>
              <ListItemText>Pre-recorded Videos</ListItemText>
            </MenuItem>
            <MenuItem style={{ minHeight: "50px" }}>
              <ListItemText>Session Settings</ListItemText>
            </MenuItem>
          </MenuList>
        </Paper>
      </Grid>
      <Grid md={9} sx={{ paddingLeft: "25px" }}>
        <Sessions />
      </Grid>
      <Paper mt={4}>
        <div>
          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title" mb={0}>
              Add New People
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Add people to the people list. For a seamless one-click entry
                for your people, please fill the form field.
              </DialogContentText>
              <br />
              <Grid container spacing={6}>
                <Grid item md={12}>
                  <TextField
                    id="username"
                    label="People Name"
                    defaultValue=""
                    variant="outlined"
                    fullWidth
                    my={2}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen(false)} color="primary">
                Cancel
              </Button>
              <Button onClick={() => setOpen(false)} color="primary">
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Paper>
    </Grid>
  );
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <Helmet title="Event View" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Event View
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/events/dashboard">
              Dashboard
            </Link>
            <Link component={NavLink} to="/events/events">
              Events List
            </Link>
            <Typography> Event View</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            <Button
              variant="contained"
              color="inherit"
              sx={{ mx: "0.5rem" }}
              component={Link}
              to="/events/events"
            >
              <ArrowBackIcon />
              Back
            </Button>
          </div>
        </Grid>
      </Grid>
      <Divider my={6} />
      <EventHeader />
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Summary" {...a11yProps(0)} />
            <Tab label="Registration" {...a11yProps(1)} />
            <Tab label="People" {...a11yProps(2)} />
            <Tab label="Sessions" {...a11yProps(3)} />
            <Tab label="Event Spaces" {...a11yProps(4)} />
            <Tab label="Branding" {...a11yProps(5)} />
            <Tab label="Integrations" {...a11yProps(6)} />
            <Tab label="Workflows" {...a11yProps(7)} />
            <Tab label="Analytics" {...a11yProps(8)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          Summary
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <RegistrationTab />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <PeopleTab />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <SessionTab />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          Event Spaces
        </CustomTabPanel>
        <CustomTabPanel value={value} index={5}>
          Branding
        </CustomTabPanel>
        <CustomTabPanel value={value} index={6}>
          Integrations
        </CustomTabPanel>
        <CustomTabPanel value={value} index={7}>
          Workflows
        </CustomTabPanel>
        <CustomTabPanel value={value} index={8}>
          Analytics
        </CustomTabPanel>
      </Box>
    </React.Fragment>
  );
}
