import React, { useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Copy, Search as SearchIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import { darken } from "polished";
import { Link } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import "../../../../Styles/Custom.css";
import Switch from "@mui/material/Switch";
import MenuList from "@mui/material/MenuList";
import EventSetup from "../../../pages/Calendar/EventTypes/EventSetup";
import Availability from "../../../pages/Calendar/EventTypes/Availability";
import Limits from "../../../pages/Calendar/EventTypes/Limits";
import Assignment from "../../../pages/Calendar/EventTypes/Assignment";
import Recurring from "../../../pages/Calendar/EventTypes/Recurring";
import Advanced from "../../../pages/Calendar/EventTypes/Advanced";

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Checkbox,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  Menu,
  MenuItem,
  InputBase,
  DialogActions,
  TextField,
  CardContent,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card as MuiCard,
  CardMedia as MuiCardMedia,
  CardActions,
  AvatarGroup as MuiAvatarGroup,
  FormControlLabel,
} from "@mui/material";
import { green, orange } from "@mui/material/colors";
import {
  Add as AddIcon,
  ArrowBack as ArrowBackIcon,
  FilterList,
  Download,
  Mail,
  ContentCopy,
  Visibility,
  Code as CodeIcon,
  Share,
} from "@mui/icons-material";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import LinkIcon from "@mui/icons-material/Link";
import CommentIcon from "@mui/icons-material/Comment";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function EventHeader() {
  const [anchorEl1, setAnchorEl1] = React.useState(null);

  const handleClickShowing = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleCloseShowing = (event) => {
    setAnchorEl1(null);
  };
  const ToolbarTitle = styled.div`
    min-width: 620px;

    // ${(props) => props.theme.breakpoints.up("md")} {
    //   min-width: 900px;
    // }
  `;

  const [value, setValue] = React.useState(0);
  const [age, setAge] = React.useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Toolbar style={{ paddingLeft: "0" }}>
          <ToolbarTitle>
            <Typography
              variant="subtitle1"
              style={{ paddingLeft: "12px", fontWeight: "bold" }}
            >
              30 Min Meeting
              {/* <small
                style={{
                  fontWeight: "normal",
                  position: "relative",
                  bottom: "1px",
                  paddingLeft: "10px",
                  color: "rgb(244, 67, 54)",
                }}
              >
                Upcoming
              </small> */}
              <Typography
                color="success"
                style={{ paddingLeft: "12px !important" }}
              >
                {/* <CalendarMonthOutlinedIcon fontSize="small" /> */}
                <small
                  style={{
                    position: "relative",
                    bottom: "4px",
                    paddingLeft: "2px",
                  }}
                >
                  Lorem Ipsum has been the industry's standard dummy text ever
                  since the 1500s, when an unknown printer took a galley of type
                  and scrambled it to make a type specimen book
                </small>
              </Typography>
            </Typography>
          </ToolbarTitle>
          {/* <Spacer /> */}
          <Grid container display="block" textAlign="right">
            <Grid container>
              <Grid md={12} className="filter">
                {/* <Button
                  variant="contained"
                  color="inherit"
                  sx={{ mr: 2, display: { xs: "none", md: "inline-flex" } }}
                >
                  <Share />
                  Share Event
                </Button> */}
                {/* <Button
                  variant="contained"
                  color="inherit"
                  sx={{ mr: 2, display: { xs: "none", md: "inline-flex" } }}
                >
                  <VisibilityIcon />
                  View Event
                </Button> */}
                <Tooltip title="Hide from profile">
                  <Switch
                    onChange={handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Tooltip>
                <Tooltip title="Preview">
                  <IconButton
                    aria-label="Edit"
                    size="large"
                    component={NavLink}
                    to="/calendar/edit-event-type"
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Copy link to event">
                  <IconButton
                    aria-label="Edit"
                    size="large"
                    component={NavLink}
                    to="/calendar/edit-event-type"
                  >
                    <LinkIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Embed">
                  <IconButton
                    aria-label="Edit"
                    size="large"
                    component={NavLink}
                    to="/calendar/edit-event-type"
                  >
                    <CodeIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton
                    aria-label="Edit"
                    size="large"
                    component={NavLink}
                    to="/calendar/edit-event-type"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
                {/* <Button
                  variant="contained"
                  sx={{ mr: 2, display: { xs: "none", md: "inline-flex" } }}
                >
                  Save
                </Button> */}
                <IconButton
                  aria-label="Edit"
                  size="large"
                  aria-owns={anchorEl1 ? "simple-menu-showing" : undefined}
                  aria-haspopup="true"
                  onClick={handleClickShowing}
                  variant="contained"
                  color="secondary"
                  sx={{ mr: 2, display: { xs: "block", md: "none" } }}
                >
                  <FilterAltIcon />
                </IconButton>
                <IconButton
                  aria-label="View"
                  size="large"
                  aria-owns={anchorEl1 ? "simple-menu-showing" : undefined}
                  aria-haspopup="true"
                  onClick={handleClickShowing}
                  variant="contained"
                  color="secondary"
                  sx={{ mr: 2, display: { xs: "block", md: "none" } }}
                >
                  <VisibilityIcon />
                </IconButton>
                <Menu
                  id="simple-menu-showing"
                  anchorEl={anchorEl1}
                  open={Boolean(anchorEl1)}
                  onClose={handleCloseShowing}
                >
                  <MenuItem>All</MenuItem>
                  <MenuItem>Upcoming</MenuItem>
                  <MenuItem>Ongoing</MenuItem>
                  <MenuItem>Paused</MenuItem>
                  <MenuItem>Completed</MenuItem>
                </Menu>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </CardContent>
    </Card>
  );
}

function RegistrationTab() {
  const [RTab, setRTab] = React.useState("Event Entry");

  return (
    <Grid container>
      <Grid md={3}>
        <Paper>
          <MenuList style={{ height: "600px" }}>
            {RTab == "Event Setup" ? (
              <MenuItem
                onClick={(e) => {
                  setRTab("Event Setup");
                }}
                style={{
                  borderLeft: "3px solid",
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                  minHeight: "50px",
                }}
              >
                <ListItem
                  disableGutters
                  secondaryAction={
                    <IconButton aria-label="comment">
                      <KeyboardArrowRightIcon />
                    </IconButton>
                  }
                >
                  <ListItemText
                    primary="Event Setup"
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          30 mins
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </MenuItem>
            ) : (
              <MenuItem
                onClick={(e) => {
                  setRTab("Event Setup");
                }}
                style={{ minHeight: "50px" }}
              >
                <ListItem disableGutters>
                  <ListItemText
                    primary="Event Setup"
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          30 mins
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </MenuItem>
            )}
            {RTab == "Availability" ? (
              <MenuItem
                onClick={(e) => {
                  setRTab("Availability");
                }}
                style={{
                  borderLeft: "3px solid",
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                  minHeight: "50px",
                }}
              >
                <ListItem
                  disableGutters
                  secondaryAction={
                    <IconButton aria-label="comment">
                      <KeyboardArrowRightIcon />
                    </IconButton>
                  }
                >
                  <ListItemText
                    primary="Availability"
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          Working Hours
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </MenuItem>
            ) : (
              <MenuItem
                onClick={(e) => {
                  setRTab("Availability");
                }}
                style={{ minHeight: "50px" }}
              >
                <ListItem disableGutters>
                  <ListItemText
                    primary="Availability"
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          Working Hours
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </MenuItem>
            )}
            {RTab == "Assignment" ? (
              <MenuItem
                onClick={(e) => {
                  setRTab("Assignment");
                }}
                style={{
                  borderLeft: "3px solid",
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                  minHeight: "50px",
                }}
              >
                <ListItem
                  disableGutters
                  secondaryAction={
                    <IconButton aria-label="comment">
                      <KeyboardArrowRightIcon />
                    </IconButton>
                  }
                >
                  <ListItemText
                    primary="Assignment"
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          Collective
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </MenuItem>
            ) : (
              <MenuItem
                onClick={(e) => {
                  setRTab("Assignment");
                }}
                style={{ minHeight: "50px" }}
              >
                <ListItem disableGutters>
                  <ListItemText
                    primary="Assignment"
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          Collective
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </MenuItem>
            )}
            {RTab == "Limits" ? (
              <MenuItem
                onClick={(e) => {
                  setRTab("Limits");
                }}
                style={{
                  borderLeft: "3px solid",
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                  minHeight: "50px",
                }}
              >
                <ListItem
                  disableGutters
                  secondaryAction={
                    <IconButton aria-label="comment">
                      <KeyboardArrowRightIcon />
                    </IconButton>
                  }
                >
                  <ListItemText
                    primary="Limits"
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          How often you can be booked
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </MenuItem>
            ) : (
              <MenuItem
                onClick={(e) => {
                  setRTab("Limits");
                }}
                style={{ minHeight: "50px" }}
              >
                <ListItem disableGutters>
                  <ListItemText
                    primary="Limits"
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          How often you can be booked
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </MenuItem>
            )}
            {RTab == "Advanced" ? (
              <MenuItem
                onClick={(e) => {
                  setRTab("Advanced");
                }}
                style={{
                  borderLeft: "3px solid",
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                  minHeight: "50px",
                }}
              >
                <ListItem
                  disableGutters
                  secondaryAction={
                    <IconButton aria-label="comment">
                      <KeyboardArrowRightIcon />
                    </IconButton>
                  }
                >
                  <ListItemText
                    primary="Advanced"
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          Calendar settings & more
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </MenuItem>
            ) : (
              <MenuItem
                onClick={(e) => {
                  setRTab("Advanced");
                }}
                style={{ minHeight: "50px" }}
              >
                <ListItem disableGutters>
                  <ListItemText
                    primary="Advanced"
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          Calendar settings & more
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </MenuItem>
            )}
            {RTab == "Recurring" ? (
              <MenuItem
                onClick={(e) => {
                  setRTab("Recurring");
                }}
                style={{
                  borderLeft: "3px solid",
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                  minHeight: "50px",
                }}
              >
                <ListItem
                  disableGutters
                  secondaryAction={
                    <IconButton aria-label="comment">
                      <KeyboardArrowRightIcon />
                    </IconButton>
                  }
                >
                  <ListItemText
                    primary="Recurring"
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          Set up a repeating schedule
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </MenuItem>
            ) : (
              <MenuItem
                onClick={(e) => {
                  setRTab("Recurring");
                }}
                style={{ minHeight: "50px" }}
              >
                <ListItem disableGutters>
                  <ListItemText
                    primary="Recurring"
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          Set up a repeating schedule
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </MenuItem>
            )}
            {RTab == "Apps" ? (
              <MenuItem
                onClick={(e) => {
                  setRTab("Apps");
                }}
                style={{
                  borderLeft: "3px solid",
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                  minHeight: "50px",
                }}
              >
                <ListItem
                  disableGutters
                  secondaryAction={
                    <IconButton aria-label="comment">
                      <KeyboardArrowRightIcon />
                    </IconButton>
                  }
                >
                  <ListItemText
                    primary="Apps"
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          0 apps, 0 active
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </MenuItem>
            ) : (
              <MenuItem
                onClick={(e) => {
                  setRTab("Apps");
                }}
                style={{ minHeight: "50px" }}
              >
                <ListItem disableGutters>
                  <ListItemText
                    primary="Apps"
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          0 apps, 0 active
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </MenuItem>
            )}
            {RTab == "Workflows" ? (
              <MenuItem
                onClick={(e) => {
                  setRTab("Workflows");
                }}
                style={{
                  borderLeft: "3px solid",
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                  minHeight: "50px",
                }}
              >
                <ListItem
                  disableGutters
                  secondaryAction={
                    <IconButton aria-label="comment">
                      <KeyboardArrowRightIcon />
                    </IconButton>
                  }
                >
                  <ListItemText
                    primary="Workflows"
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          0 active
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </MenuItem>
            ) : (
              <MenuItem
                onClick={(e) => {
                  setRTab("Workflows");
                }}
                style={{ minHeight: "50px" }}
              >
                <ListItem disableGutters>
                  <ListItemText
                    primary="Workflows"
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          0 active
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </MenuItem>
            )}
            {RTab == "Webhooks" ? (
              <MenuItem
                onClick={(e) => {
                  setRTab("Webhooks");
                }}
                style={{
                  borderLeft: "3px solid",
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                  minHeight: "50px",
                }}
              >
                <ListItem
                  disableGutters
                  secondaryAction={
                    <IconButton aria-label="comment">
                      <KeyboardArrowRightIcon />
                    </IconButton>
                  }
                >
                  <ListItemText
                    primary="Webhooks"
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          0 active
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </MenuItem>
            ) : (
              <MenuItem
                onClick={(e) => {
                  setRTab("Webhooks");
                }}
                style={{ minHeight: "50px" }}
              >
                <ListItem disableGutters>
                  <ListItemText
                    primary="Webhooks"
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          0 active
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </MenuItem>
            )}
          </MenuList>
        </Paper>
      </Grid>
      <Grid md={9} sx={{ paddingLeft: "25px" }}>
        {RTab == "Event Setup" ? (
          <EventSetup />
        ) : RTab == "Availability" ? (
          <Availability />
        ) : RTab == "Limits" ? (
          <Limits />
        ) : RTab == "Advanced" ? (
          <Advanced />
        ) : RTab == "Assignment" ? (
          <Assignment />
        ) : (
          <Recurring />
        )}
      </Grid>
    </Grid>
  );
}

function EventTypeEdit() {
  return (
    <React.Fragment>
      <Helmet title="Event Types" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Edit Event Types
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/calendar/dashboard">
              Dashboard
            </Link>
            <Link component={NavLink} to="/calendar/event-types">
              Events Type List
            </Link>
            <Typography>Edit Events Types</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            <Button
              variant="contained"
              color="inherit"
              sx={{ mx: "0.5rem" }}
              component={Link}
              to="/calendar/event-types"
            >
              <ArrowBackIcon />
              Back
            </Button>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />
      <EventHeader />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <RegistrationTab />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default EventTypeEdit;
