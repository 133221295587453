import React, { useState } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Search as SearchIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import { darken, margin, padding } from "polished";
import ListItem from "@mui/material/ListItem";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { Link } from "react-router-dom";

import {
  Badge,
  Box,
  Grid,
  Card,
  TextField as MuiTextField,
  Typography,
  Avatar,
  Fab,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Button as MuiButton,
  Menu,
  MenuItem,
  Tooltip,
  IconButton,
  DialogActions,
  TableCell,
  TableRow,
  TableBody,
  Table,
  Paper as MuiPaper,
  DialogContent,
  DialogTitle,
  Dialog,
  Chip as MuiChip,
  Avatar as MuiAvatar,
  Button,
  Checkbox,
  TableContainer,
  TableHead,
  TablePagination,
  TableSortLabel,
  Toolbar,
  InputBase,
  TextField,
  CardContent,
  DialogContentText,
  Card as MuiCard,
  CardMedia as MuiCardMedia,
  CardActions,
  AvatarGroup as MuiAvatarGroup,
  FormControlLabel,
  FormControl as MuiFormControl,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Add as AddIcon,
  ArrowBack as ArrowBackIcon,
} from "@mui/icons-material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { green, orange } from "@mui/material/colors";

import { spacing, textAlign } from "@mui/system";

import SendIcon from "@mui/icons-material/Send";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);
const FormControl = styled(MuiFormControl)(spacing);

const Chip = styled(MuiChip)`
  ${spacing};

  background: ${(props) => props.paid && green[500]};
  background: ${(props) => props.sent && orange[700]};
  color: ${(props) =>
    (props.paid || props.sent) && props.theme.palette.common.white};
`;

const ChatContainer = styled(Grid)`
  width: 100%;
  height: 75vh;
`;

const ChatSidebar = styled(Grid)`
  border-right: 1px solid ${(props) => props.theme.palette.divider};
`;

const ChatMain = styled(Grid)``;

const ChatMessages = styled.div`
  overflow-y: scroll;
  height: calc(58vh - 90px);
`;

const ChatMessage = styled.div`
  margin: 30px;
  text-align: ${(props) => props.position};
`;

const ChatMessageInner = styled.div`
  display: inline-block;
`;

const ChatMessageTime = styled(Typography)`
  text-align: right;
  opacity: 0.8;
`;

const ChatMessageAvatar = styled(Avatar)`
  position: relative;
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-right: ${(props) => props.theme.spacing(2)};
`;

const ChatMessageBubble = styled.div`
  display: inline-block;
  margin-right: auto;
  background: ${(props) =>
    props.highlighted
      ? props.theme.palette.secondary.main
      : props.theme.palette.action.hover};
  color: ${(props) =>
    props.highlighted
      ? props.theme.palette.common.white
      : props.theme.palette.text.primary};
  border-radius: 3px;
  padding: ${(props) => props.theme.spacing(2)};
  margin-bottom: ${(props) => props.theme.spacing(1)};
  ${(props) => props.theme.shadows[1]};
`;

const ChatMessageBubbleName = styled(Typography)`
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
`;

const ChatInput = styled(Grid)`
  min-height: 94px;
  padding: ${(props) => props.theme.spacing(5)};
`;

function ChatMessageComponent({
  name,
  message,
  time,
  avatar,
  position = "left",
}) {
  return (
    <ChatMessage position={position}>
      <ChatMessageInner>
        <ChatMessageAvatar alt="Lucy Lavender" src={avatar} />
        <ChatMessageBubble highlighted={position === "right"}>
          <Box>
            <ChatMessageBubbleName variant="body1">
              {name}
            </ChatMessageBubbleName>
          </Box>
          <Typography variant="body2">{message}</Typography>
        </ChatMessageBubble>
        <ChatMessageTime variant="body2">{time}</ChatMessageTime>
      </ChatMessageInner>
    </ChatMessage>
  );
}

function ChatWindow() {
  const [RTab, setRTab] = React.useState("All");
  return (
    <ChatContainer container component={Card}>
      <ChatSidebar item xs={12} md={10} lg={10}>
        <ChatMessages>
          <ChatMessageComponent
            name="Remy Sharp <johndoe@gmail.com>"
            avatar="/static/img/avatars/avatar-2.jpg"
            message="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
            time="14, Jan 2024 07:25 PM"
            position="left"
          />
          <ChatMessageComponent
            name="You <johndoe@gmail.com>"
            avatar="/static/img/avatars/avatar-1.jpg"
            message="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
            time="14, Jan 2024 07:25 PM"
            position="right"
          />
          <ChatMessageComponent
            name="Remy Sharp <johndoe@gmail.com>"
            avatar="/static/img/avatars/avatar-2.jpg"
            message="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
            time="14, Jan 2024 07:25 PM"
            position="left"
          />
          <ChatMessageComponent
            name="You <johndoe@gmail.com>"
            avatar="/static/img/avatars/avatar-1.jpg"
            message="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
            time="14, Jan 2024 07:25 PM"
            position="right"
          />
          <ChatMessageComponent
            name="Remy Sharp <johndoe@gmail.com>"
            avatar="/static/img/avatars/avatar-2.jpg"
            message="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
            time="14, Jan 2024 07:25 PM"
            position="left"
          />
        </ChatMessages>
        <Divider />
        <ChatInput container>
          <Grid container spacing={6}>
            <Grid item md={12}>
              <FormControl fullWidth my={2} variant="outlined">
                <TextField
                  label="Type your message"
                  id="biography"
                  multiline={true}
                  rows={3}
                  maxRows={4}
                  variant="outlined"
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item md={3} style={{ marginTop: "15px" }}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel htmlFor="max-width">Input Type</InputLabel>
                <Select
                  autoFocus
                  value="xs"
                  label="Input Type"
                  inputProps={{
                    name: "max-width",
                    id: "max-width",
                  }}
                >
                  <MenuItem value="xs">Open</MenuItem>
                  <MenuItem value="sm">Pending</MenuItem>
                  <MenuItem value="md">Closed</MenuItem>
                  <MenuItem value="md">Resolved & Closed</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={3} style={{ marginTop: "15px" }}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel htmlFor="max-width">Priority Level</InputLabel>
                <Select
                  autoFocus
                  value="xs"
                  label="Input Type"
                  inputProps={{
                    name: "max-width",
                    id: "max-width",
                  }}
                >
                  <MenuItem value="xs">Low</MenuItem>
                  <MenuItem value="sm">Medium</MenuItem>
                  <MenuItem value="md">High</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={3} style={{ marginTop: "15px" }}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel htmlFor="max-width">Assign To</InputLabel>
                <Select
                  autoFocus
                  value="xs"
                  label="Input Type"
                  inputProps={{
                    name: "max-width",
                    id: "max-width",
                  }}
                >
                  <MenuItem value="xs">James</MenuItem>
                  <MenuItem value="sm">Niaz</MenuItem>
                  <MenuItem value="md">Shabbir</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={3} style={{ marginTop: "20px" }}>
              <Button
                color="primary"
                variant="contained"
                endIcon={<SendIcon />}
                size="large"
              >
                Submit
                {/* <Fab color="primary" aria-label="add" size="medium">
                  <SendIcon />
                </Fab> */}
              </Button>
            </Grid>
          </Grid>
          {/* <Grid item style={{ flexGrow: 1 }}>
            <TextField variant="outlined" label="Type your message" fullWidth />
          </Grid>
          <Grid item>
            <Box ml={2}>
              <Fab color="primary" aria-label="add" size="medium">
                <SendIcon />
              </Fab>
            </Box>
          </Grid> */}
        </ChatInput>
      </ChatSidebar>
      <ChatSidebar item xs={12} md={2} lg={2}>
        <ChatMessages style={{ overflow: "hidden" }}>
          <Box>
            <Grid item md={12}>
              <ListItem alignItems="flex-start">
                <Grid container>
                  <Grid md={12}>
                    <Typography variant="subtitle1">
                      Status
                      <Typography
                        color="success"
                        style={{ paddingLeft: "12px !important" }}
                      >
                        <Chip label="Chip Outlined" />
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid md={12} style={{ marginTop: "10px" }}>
                    <Typography variant="subtitle1">
                      Updated At
                      <Typography
                        color="success"
                        style={{
                          paddingLeft: "12px !important",
                          fontWeight: "bold",
                        }}
                      >
                        14, Jan 2024 07:25 PM
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid md={12} style={{ marginTop: "10px" }}>
                    <Typography variant="subtitle1">
                      Customer
                      <Typography
                        color="success"
                        style={{
                          paddingLeft: "12px !important",
                          fontWeight: "bold",
                        }}
                      >
                        John Doe
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
            </Grid>
          </Box>
        </ChatMessages>
      </ChatSidebar>
    </ChatContainer>
  );
}

function SupportTicketAdd() {
  const [open, setOpen] = useState(false);
  return (
    <React.Fragment>
      <Helmet title="Add New Support Ticket" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Add New Support Ticket
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/widget/dashboard">
              Dashboard
            </Link>
            <Link component={NavLink} to="/widget/support-tickets">
              Support Tickets
            </Link>
            <Typography>Add New Support Ticket</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            <Button
              variant="contained"
              color="inherit"
              sx={{ mx: "0.5rem" }}
              component={Link}
              to="/widget/support-tickets"
            >
              <ArrowBackIcon />
              Back
            </Button>
            {/* <Button
              variant="contained"
              component={Link}
              color="primary"
              to="/widget/add-support-ticket"
            >
              <AddIcon />
              New Support Ticket
            </Button> */}
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <ChatWindow />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default SupportTicketAdd;
